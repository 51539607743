<template>
<div class="mypage-wrap">
  <div class="my-page-container">
    <!-- Edit My Information Section -->
    <h2>{{ uiText.editProfile }}</h2>
    <form @submit.prevent="saveProfile" class="profile-form">
      <!-- Profile Image Upload -->
      <div class="profile-image-wrapper">
        <img v-if="profileImageUrl" :src="profileImageUrl" alt="Profile Image" class="profile-image" />
        <input type="file" @change="handleImageUpload" accept="image/*" id="profileImage" ref="profileImage" style="display: none;" />
        <button type="button" @click="triggerFileInput" class="image-upload-button">
          {{ uiText.profileImage }}
        </button>
      </div>
      <!-- Nickname Input -->
      <div class="input-wrapper">
        <label for="nickname">{{ uiText.nickname }}</label>
        <input type="text" v-model="nickname" id="nickname" required :class="{'has-value': nickname}" />
      </div>
      <!-- Introduction Input -->
      <div class="input-wrapper">
        <label for="introduce">{{ uiText.introduction }}</label>
        <textarea 
          v-model="introduce" 
          id="introduce" 
          required 
          :class="{'has-value': introduce}"
          rows="4"
        ></textarea>
      </div>
      <!-- Instagram ID Input -->
      <div class="input-wrapper">
        <label for="instagramId">{{ uiText.instagramId }}</label>
        <input type="text" v-model="instagramId" id="instagramId" :class="{'has-value': instagramId}" />
      </div>
      <!-- Email Input -->
      <div class="input-wrapper">
        <label for="email">{{ uiText.email }}</label>
        <input type="text" v-model="email" id="email" required :class="{'has-value': email}" :disabled="authMethod === 'email'" />
      </div>
      <!-- Phone Number Input -->
      <div class="input-wrapper">
        <label for="phone">{{ uiText.phoneNumber }}</label>
        <input type="text" v-model="phone" id="phone" required maxlength="13" :class="{'has-value': phone}" :disabled="authMethod === 'phone'" />
      </div>
      <!-- Tag Selection -->
      <div class="input-wrapper">
        <span>{{ uiText.tags }}</span>
        <div class="genre-wrapper">
          <div class="genre-container">
            <div v-for="tag in service" :key="tag" :class="['genre-item', { selected: selectedServices.includes(tag) }]" @click="toggleService(tag)">
              {{ translateService(tag) }}
            </div>
          </div>
        </div>
      </div>
      <!-- Main Genre Selection -->
      <div class="input-wrapper">
        <span>{{ uiText.mainGenre }}</span>
        <div class="genre-wrapper">
          <div class="genre-container">
            <div v-for="genre in genres" :key="genre" :class="['genre-item', { selected: mainGenre === genre }]" @click="selectMainGenre(genre)">
              {{ translateGenre(genre) }}
            </div>
          </div>
        </div>
      </div>
      <!-- Sub Genre Selection -->
      <div class="input-wrapper">
        <span>{{ uiText.subGenre }}</span>
        <div class="genre-container">
          <div v-for="genre in availableSubGenres" :key="genre" :class="['genre-item', { selected: subGenres.includes(genre) }]" @click="toggleSubGenre(genre)">
            {{ translateGenre(genre) }}
          </div>
        </div>
        <div v-if="subGenres.length === 2" class="warning-msg">
          {{ uiText.subGenreWarning }}
        </div>
      </div>
      <!-- Minimum Rate Input -->
      <div class="input-wrapper">
        <label for="minRate">{{ uiText.minimumRate }}</label>
        <div class="input-price-container">
          <input 
            type="text" 
            :value="minRate === 'Price on Request' ? minRate : minRate.toLocaleString()" 
            @input="e => minRate = isMinRatePrivate ? 'Price on Request' : Number(e.target.value.replace(/[^\d]/g, ''))" 
            id="minRate" 
            required 
            :class="{'has-value': minRate}" 
            :disabled="isMinRatePrivate"
          />
          <div class="private-checkbox-wrapper">
            <input 
              type="checkbox" 
              v-model="isMinRatePrivate" 
              @change="toggleMinRatePrivacy"
              :id="'minRatePrivate'"
              class="private-checkbox"
            />
            <label :for="'minRatePrivate'" class="private-label">{{ uiText.private }}</label>
          </div>
        </div>
      </div>
      <!-- Hourly Rate Input -->
      <div class="input-wrapper">
        <label for="hourlyRate">{{ uiText.hourRate }}</label>
        <div class="input-price-container">
          <input 
            type="text" 
            :value="hourlyRate === 'Price on Request' ? hourlyRate : hourlyRate.toLocaleString()" 
            @input="e => hourlyRate = isHourlyRatePrivate ? 'Price on Request' : Number(e.target.value.replace(/[^\d]/g, ''))" 
            id="hourlyRate" 
            required 
            :class="{'has-value': hourlyRate}" 
            :disabled="isHourlyRatePrivate"
          />
          <div class="private-checkbox-wrapper">
            <input 
              type="checkbox" 
              v-model="isHourlyRatePrivate" 
              @change="toggleHourlyRatePrivacy"
              :id="'hourlyRatePrivate'"
              class="private-checkbox"
            />
            <label :for="'hourlyRatePrivate'" class="private-label">{{ uiText.private }}</label>
          </div>
        </div>
      </div>

      <!-- Currency Selection -->
      <div class="input-wrapper">
        <label for="currency">{{ uiText.currency }}</label>
        <select 
          v-model="Currency" 
          id="currency"
          :class="{'has-value': Currency}"
        >
          <option value="KRW">KRW (₩)</option>
          <option value="USD">USD ($)</option>
          <option value="EUR">EUR (€)</option>
          <option value="GBP">GBP (£)</option>
          <option value="JPY">JPY (¥)</option>
        </select>
      </div>

      <!-- Country Selection -->
      <div class="input-wrapper">
        <label for="country">{{ uiText.country }}</label>
        <select 
          v-model="country" 
          id="country"
          :class="{'has-value': country}"
        >
          <option value="Korea">Korea</option>
          <option value="Japan">Japan</option>
          <option value="United Kingdom">United Kingdom</option>
          <option value="United States">United States</option>
          <option value="Australia">Australia</option>
          <option value="Thailand">Thailand</option>
        </select>
      </div>

      <!-- Location Setting -->
      <div class="input-wrapper">
        <label for="location">{{ uiText.location }}</label>
        <input 
          type="text" 
          v-model="location.address" 
          id="location" 
          readonly 
          required 
          :class="{'has-value': location.address}" 
          :placeholder="uiText.locationPlaceholder" 
        />
        <div class="map-container">
          <div id="map" ref="mapElement"></div>
          <button type="button" @click="setCurrentLocation" class="location-button">
            <span class="material-icons">my_location</span>
          </button>
        </div>
      </div>
      <!-- Form Action Buttons -->
      <div class="form-actions">
        <button type="button" @click="cancelEdit" class="cancel-button">{{ uiText.cancel }}</button>
        <button type="submit" class="save-button">{{ uiText.save }}</button>
      </div>
      <!-- Error Message Display -->
      <div v-if="errorMessage" class="error-msg">{{ errorMessage }}</div>
    </form>
  </div>
</div>
</template>

<script>
import { ref, computed, onMounted } from "vue"; 
import { db, storage } from "@/firebase"; 
import { doc, getDoc, setDoc, serverTimestamp } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { ref as storageRef, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import router from "@/router";
import { Loader } from '@googlemaps/js-api-loader';

export default {
  name: "TattooistMyPage",
  setup() {
    const nickname = ref(""); 
    const instagramId = ref("");
    const email = ref("");
    const introduce = ref("");
    const phone = ref("");
    const location = ref({
      address: "",
      latitude: 37.5665,
      longitude: 126.978
    });
    const mapElement = ref(null);
    const map = ref(null);
    const marker = ref(null);
    const geocoder = ref(null);
    const mainGenre = ref("");
    const subGenres = ref([]);
    const genres = [
      "Emotional", "Oriental", "Lettering", "Linework", "Blackwork", "Black & Grey",
      "Watercolor", "New School", "Mini Tattoo", "Old School", "Irezumi", "Illustration",
      "Color Tattoo", "Cover Up", "Chicano", "Touch Up", "Tribal", "Anime/Cartoon"
    ];
    const service = [
      "Card Payment", "Parking Available", "Installment Payment", "Female Tattooist", 
      "Male Tattooist", "Same Day Booking", "Late Night Service", "Private Studio", 
      "Separate Restrooms", "WiFi", "Waiting Area", "Night Service", "Smoking Area"
    ];
    const minRate = ref(50000);
    const hourlyRate = ref(50000);
    const isMinRatePrivate = ref(false);
    const isHourlyRatePrivate = ref(false);
    const profileImage = ref(null);
    const profileImageUrl = ref("");
    const authMethod = ref("");
    const errorMessage = ref("");
    const userId = ref("");
    const auth = getAuth();
    const selectedServices = ref([]);
    const Currency = ref("KRW");
    const country = ref("Korea");

    const translations = {
      en: {
        save: 'Save',
        editProfile: 'Edit Profile',
        profileImage: 'Profile Image',
        nickname: 'Nickname',
        introduction: 'Introduction',
        instagramId: 'Instagram ID',
        email: 'Email',
        phoneNumber: 'Phone Number',
        tags: 'Tags',
        mainGenre: 'Main Genre',
        subGenre: 'Sub Genre (Up to 2)',
        subGenreWarning: 'You can select up to 2 sub genres.',
        minimumRate: 'Minimum Rate',
        private: 'Private',
        hourRate: 'Hour Rate',
        currency: 'Currency',
        location: 'Location',
        locationPlaceholder: 'Set your location',
        cancel: 'Cancel',
        country: 'Country',
        genres: {
          "Emotional": "Emotional",
          "Oriental": "Oriental",
          "Lettering": "Lettering",
          "Linework": "Linework",
          "Blackwork": "Blackwork",
          "Black & Grey": "Black & Grey",
          "Watercolor": "Watercolor",
          "New School": "New School",
          "Mini Tattoo": "Mini Tattoo",
          "Old School": "Old School",
          "Irezumi": "Irezumi",
          "Illustration": "Illustration",
          "Color Tattoo": "Color Tattoo",
          "Cover Up": "Cover Up",
          "Chicano": "Chicano",
          "Touch Up": "Touch Up",
          "Tribal": "Tribal",
          "Anime/Cartoon": "Anime/Cartoon"
        },
        services: {
          "Card Payment": "Card Payment",
          "Parking Available": "Parking Available",
          "Installment Payment": "Installment Payment",
          "Female Tattooist": "Female Tattooist",
          "Male Tattooist": "Male Tattooist",
          "Same Day Booking": "Same Day Booking",
          "Late Night Service": "Late Night Service",
          "Private Studio": "Private Studio",
          "Separate Restrooms": "Separate Restrooms",
          "WiFi": "WiFi",
          "Waiting Area": "Waiting Area",
          "Night Service": "Night Service",
          "Smoking Area": "Smoking Area"
        }
      },
      ko: {
        save: '저장',
        editProfile: '프로필 수정',
        profileImage: '프로필 이미지',
        nickname: '닉네임',
        introduction: '소개',
        instagramId: '인스타그램 ID',
        email: '이메일',
        phoneNumber: '전화번호',
        tags: '태그',
        mainGenre: '메인장르',
        subGenre: '서브장르 (최대 2개)',
        subGenreWarning: '최대 2개의 서브장르를 선택할 수 있습니다.',
        minimumRate: '최소 비용',
        private: '비공개',
        hourRate: '시간당 비용',
        currency: '통화',
        location: '위치',
        locationPlaceholder: '위치를 설정하세요',
        cancel: '취소',
        country: '국가',
        genres: {
          "Emotional": "감성타투",
          "Oriental": "동양화",
          "Lettering": "레터링",
          "Linework": "라인워크",
          "Blackwork": "블랙워크",
          "Black & Grey": "블랙 & 그레이",
          "Watercolor": "수채화",
          "New School": "뉴스쿨",
          "Mini Tattoo": "미니타투",
          "Old School": "올드스쿨",
          "Irezumi": "이레즈미",
          "Illustration": "일러스트",
          "Color Tattoo": "컬러타투",
          "Cover Up": "커버업",
          "Chicano": "치카노",
          "Touch Up": "터치업",
          "Tribal": "트라이벌",
          "Anime/Cartoon": "애니/만화"
        },
        services: {
          "Card Payment": "카드결제",
          "Parking Available": "주차가능",
          "Installment Payment": "할부결제",
          "Female Tattooist": "여성 타투이스트",
          "Male Tattooist": "남성 타투이스트",
          "Same Day Booking": "당일예약",
          "Late Night Service": "심야작업",
          "Private Studio": "개인스튜디오",
          "Separate Restrooms": "남/여화장실",
          "WiFi": "와이파이",
          "Waiting Area": "대기공간",
          "Night Service": "야간서비스",
          "Smoking Area": "흡연구역"
        }
      },
      jp: {
        save: '保存',
        editProfile: 'プロフィール編集',
        profileImage: 'プロフィール画像',
        nickname: 'ニックネーム',
        introduction: '紹介',
        instagramId: 'インスタグラムID',
        email: 'メールアドレス',
        phoneNumber: '電話番号',
        tags: 'タグ',
        mainGenre: 'メインジャンル',
        subGenre: 'サブジャンル (最大2つ)',
        subGenreWarning: '最大2つのサブジャンルを選択できます。',
        minimumRate: '最低料金',
        private: '非公開',
        hourRate: '時間単価',
        currency: '通貨',
        location: '場所',
        locationPlaceholder: '場所を設定してください',
        cancel: 'キャンセル',
        country: '国',
        genres: {
          "Emotional": "感情的",
          "Oriental": "東洋",
          "Lettering": "レタリング",
          "Linework": "ラインワーク",
          "Blackwork": "ブラックワーク",
          "Black & Grey": "ブラック＆グレー",
          "Watercolor": "水彩画",
          "New School": "ニュー スクール",
          "Mini Tattoo": "ミニ タトゥー",
          "Old School": "オールド スクール",
          "Irezumi": "入れ墨",
          "Illustration": "イラスト",
          "Color Tattoo": "カラー タトゥー",
          "Cover Up": "カバーアップ",
          "Chicano": "チカーノ",
          "Touch Up": "タッチアップ",
          "Tribal": "トライバル",
          "Anime/Cartoon": "アニメ/漫画"
        },
        services: {
          "Card Payment": "カード決済",
          "Parking Available": "駐車場あり",
          "Installment Payment": "分割払い",
          "Female Tattooist": "女性タトゥーアーティスト",
          "Male Tattooist": "男性タトゥーアーティスト",
          "Same Day Booking": "当日予約",
          "Late Night Service": "深夜サービス",
          "Private Studio": "プライベートスタジオ",
          "Separate Restrooms": "別々のトイレ",
          "WiFi": "WiFi",
          "Waiting Area": "待合室",
          "Night Service": "夜間サービス",
          "Smoking Area": "喫煙エリア"
        }
      },
    };

    const currentLanguage = ref(localStorage.getItem('selectedLanguage') || 'en');

    const translateGenre = (genre) => {
      return translations[currentLanguage.value].genres[genre] || genre;
    };

    const translateService = (tag) => {
      return translations[currentLanguage.value].services[tag] || tag;
    };

    const selectedLanguage = ref(localStorage.getItem('selectedLanguage') || 'en');
    const uiText = ref(translations[selectedLanguage.value]);

    let googleMaps = null;

    // Initialize Google Maps Loader only once
    const initGoogleMaps = async () => {
      if (!googleMaps) {
        const loader = new Loader({
          apiKey: "AIzaSyAdpdeB1s5S-ZS9BSyhD5AjTHgN1VvBoms",
          version: "weekly",
        });
        googleMaps = await loader.load();
      }
      return googleMaps;
    };

    // Computed property: exclude main genre from sub-genres list
    const availableSubGenres = computed(() => {
      return genres.filter(g => g !== mainGenre.value);
    });

    // Main genre selection function
    const selectMainGenre = (genre) => {
      if (mainGenre.value === genre) {
        mainGenre.value = "";
      } else {
        mainGenre.value = genre;
        // Remove selected main genre from sub-genres
        if (subGenres.value.includes(genre)) {
          subGenres.value = subGenres.value.filter(g => g !== genre);
        }
      }
    };

    // Sub-genre toggle function
    const toggleSubGenre = (genre) => {
      if (!genre || subGenres.value.includes(genre)) {
        subGenres.value = subGenres.value.filter(g => g !== genre);
      } else {
        if (subGenres.value.length < 2) {
          subGenres.value.push(genre);
        } else {
          alert(uiText.value.subGenreWarning);
        }
      }
    };

    // Enable editing function
    const enableEditing = () => {
    };

    // Cancel edit function
    const cancelEdit = () => {
      window.location.href = '/MyPage';
    };

    // Service toggle function
    const toggleService = (tag) => {
      if (selectedServices.value.includes(tag)) {
        selectedServices.value = selectedServices.value.filter(t => t !== tag);
      } else {
        selectedServices.value.push(tag);
      }
    };

    // Profile save function
    const saveProfile = async () => {
      const userDoc = doc(db, "users", userId.value);
      try {
        await setDoc(userDoc, {
          nickname: nickname.value,
          instagramId: instagramId.value,
          email: email.value,
          introduce: introduce.value,
          phoneNumber: phone.value,
          location: location.value,
          mainGenre: mainGenre.value,
          subGenres: subGenres.value,
          service: selectedServices.value,
          hourlyRate: hourlyRate.value,
          minRate: minRate.value,
          profileImageUrl: profileImageUrl.value,
          authMethod: authMethod.value,
          updatedAt: serverTimestamp(),
          currency: Currency.value,
          country: country.value,
        }, { merge: true });

        errorMessage.value = "";
        alert("Profile has been saved successfully.");
        router.push('/MyPage');
      } catch (error) {
        console.error("Failed to save profile:", error);
        errorMessage.value = "Failed to save profile.";
      }
    };

    // Profile image upload function
    const handleImageUpload = async (event) => {
      const file = event.target.files[0];
      if (file) {
        if (!userId.value) { 
          errorMessage.value = "User ID not found. Please login again.";
          console.error("User ID is undefined.");
          return;
        }
        
        const storagePath = `profileImages/${userId.value}/${file.name}`;
        const storageReference = storageRef(storage, storagePath); 
        
        const uploadTask = uploadBytesResumable(storageReference, file);
        
        uploadTask.on(
          "state_changed",
          null,
          (error) => {
            console.error("Upload failed:", error);
            errorMessage.value = "Failed to upload file.";  
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              profileImageUrl.value = downloadURL;
              await setDoc(doc(db, "users", userId.value), {
                profileImageUrl: downloadURL,
                updatedAt: serverTimestamp(),
              }, { merge: true });
            } catch (error) {
              console.error("Error getting download URL:", error);
              errorMessage.value = "Failed to get image URL.";
            }
          }
        );
      }
    };

    // 위치 정보 업데이트 함수
    const updateLocationFromLatLng = async (latLng) => {
      const lat = typeof latLng.lat === 'function' ? latLng.lat() : latLng.lat;
      const lng = typeof latLng.lng === 'function' ? latLng.lng() : latLng.lng;

      try {
        const result = await geocoder.value.geocode({
          location: { lat, lng },
          language: 'en'
        });
        if (result.results[0]) {
          location.value.address = result.results[0].formatted_address;
        }
      } catch (error) {
        console.error('주소 변환 실패:', error);
      }
    };

    // 지도 초기화 및 마커 설정 함수
    const initMap = async () => {
      try {
        if (!mapElement.value) {
          console.error('Map element not found');
          return;
        }

        const google = await initGoogleMaps();
        geocoder.value = new google.maps.Geocoder();
        
        map.value = new google.maps.Map(mapElement.value, {
          center: { lat: location.value.latitude, lng: location.value.longitude },
          zoom: 15,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          zoomControl: true,
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER
          }
        });

        // 기존 마커가 있으면 제거
        if (marker.value) {
          marker.value.setMap(null);
        }

        // 새 마커 생성
        marker.value = new google.maps.Marker({
          position: { lat: location.value.latitude, lng: location.value.longitude },
          map: map.value,
          draggable: true,
        });

        // 마커 드래그 이벤트
        marker.value.addListener('dragend', async () => {
          const position = marker.value.getPosition();
          location.value.latitude = position.lat();
          location.value.longitude = position.lng();
          await updateLocationFromLatLng(position);
        });

        // 지도 클릭 이벤트 추가
        map.value.addListener('click', async (event) => {
          const clickedPosition = event.latLng;
          marker.value.setPosition(clickedPosition);
          location.value.latitude = clickedPosition.lat();
          location.value.longitude = clickedPosition.lng();
          await updateLocationFromLatLng(clickedPosition);
        });

      } catch (error) {
        console.error('지도 초기화 실패:', error);
      }
    };

    // 현재 위치 설정 함수
    const setCurrentLocation = async () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            location.value.latitude = position.coords.latitude;
            location.value.longitude = position.coords.longitude;

            if (map.value) {
              const newPosition = { 
                lat: location.value.latitude, 
                lng: location.value.longitude 
              };
              
              // 지도 중심 이동
              map.value.setCenter(newPosition);

              // 기존 마커의 위치만 업데이트
              if (marker.value) {
                marker.value.setPosition(newPosition);
              }

              await updateLocationFromLatLng(newPosition);
            }
          },
          (error) => {
            console.error('위치 정보 가져오기 실패:', error);
          }
        );
      } else {
        console.error('Geolocation이 지원되지 않습니다.');
      }
    };

    const triggerFileInput = () => {
      profileImage.value.click();
    };

    // 사용자 데이터 로드 함수
    const loadUserData = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          router.push('/login');
          return;
        }

        userId.value = user.uid;
        const userDoc = doc(db, "users", userId.value);
        const userSnapshot = await getDoc(userDoc);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          
          // 기본 데이터 설정
          nickname.value = userData.nickname || "";
          instagramId.value = userData.instagramId || "";
          email.value = userData.email || "";
          introduce.value = userData.introduce || "";
          phone.value = userData.phoneNumber || "";
          profileImageUrl.value = userData.profileImageUrl || "";
          authMethod.value = userData.authMethod || "";
          mainGenre.value = userData.mainGenre || "";
          subGenres.value = Array.isArray(userData.subGenres) ? userData.subGenres : [];
          selectedServices.value = Array.isArray(userData.service) ? userData.service : [];

          // location 데이터 설정
          if (userData.location && 
              typeof userData.location === 'object' && 
              'address' in userData.location) {
            location.value = {
              address: userData.location.address,
              latitude: userData.location.latitude || 37.5665,
              longitude: userData.location.longitude || 126.978
            };
          }

          // 비용 설정
          if (userData.hourlyRate === "Price on Request") {
            isHourlyRatePrivate.value = true;
            hourlyRate.value = "Price on Request";
          } else {
            isHourlyRatePrivate.value = false;
            hourlyRate.value = userData.hourlyRate || 0;
          }

          if (userData.minRate === "Price on Request") {
            isMinRatePrivate.value = true;
            minRate.value = "Price on Request";
          } else {
            isMinRatePrivate.value = false;
            minRate.value = userData.minRate || 0;
          }

          Currency.value = userData.currency || 'KRW';
          country.value = userData.country || 'Korea';
        }

        // 데이터 로드 후 지도 초기화
        await initMap();
      } catch (error) {
        console.error("Error loading user data:", error);
      }
    };

    const toggleMinRatePrivacy = () => {
      if (isMinRatePrivate.value) {
        minRate.value = "Price on Request";
      } else {
        minRate.value = 50000; // 초기화 또는 다른 로직에 따라 설정
      }
    };

    const toggleHourlyRatePrivacy = () => {
      if (isHourlyRatePrivate.value) {
        hourlyRate.value = "Price on Request";
      } else {
        hourlyRate.value = 50000; // 초기화 또는 다른 로직에 따라 설정
      }
    };

    onMounted(async () => {
      try {
        await loadUserData();
        await initMap();
      } catch (error) {
        console.error('컴포넌트 마운트 에러:', error);
      }
    });

    return {
      nickname,
      introduce,
      instagramId,
      email,
      phone,
      location,
      mapElement,
      map,
      marker,
      mainGenre,
      subGenres,
      service,
      genres,
      availableSubGenres,
      hourlyRate,
      minRate,
      profileImageUrl,
      authMethod,
      errorMessage,
      enableEditing,
      cancelEdit,
      saveProfile,
      handleImageUpload,
      setCurrentLocation,
      selectMainGenre,
      toggleSubGenre,
      profileImage,
      triggerFileInput,
      selectedServices,
      toggleService,
      isMinRatePrivate,
      isHourlyRatePrivate,
      toggleMinRatePrivacy,
      toggleHourlyRatePrivacy,
      Currency,
      uiText,
      country,
      translations,
      translateGenre,
      translateService,
    };
  },
};
</script>

<style scoped>
.mypage-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
  margin-top: 60px;
  width: 100%;
  padding: 0 0 20px 0;
}
/* 전체 페이지 컨테이너 */
.my-page-container {
  width: 85%;
  margin-top: 10px;
  background-color: white;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.my-page-container h2 {
  margin: 10px 0 0 0;
}

/* 폼 스타일 */
.profile-image-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #f4f4f4;
  border-radius: 10px;
  margin-top: 20px;
  border: 1px solid #ccc;
}

/* 입력 필드 스타일 */
.input-wrapper {
  display: flex;
  flex-direction: column;
}

.input-wrapper label {
  position: relative;
  top: 35px;
  left: 10px;
  margin-bottom: 5px;
  color: #888;
}

.input-wrapper input {
  padding: 35px 10px 10px 10px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-size: 16px;
}

.input-wrapper input[readonly] {
  background-color: #f5f5f5;
}

input:focus {
  outline: none;
}

/* 장르 선택 스타일 */
.input-wrapper span {
  margin: 20px 0 0 3px;
}

.genre-container {
  display: flex;
  overflow-x: auto;
  gap: 8px;
  padding: 10px 0;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}

.genre-container::-webkit-scrollbar {
  display: none;
}

.genre-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.genre-item {
  flex: 0 0 auto;
  padding: 10px 12px;
  border-radius: 12px;
  text-align: center;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  background: #f8fafc;
  border: 2px solid #eef2f7;
  white-space: nowrap;
  scroll-snap-align: start;
}

.genre-item.selected {
  background: #333;
  color: white;
  border-color: #333;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.2);
}

/* 스크롤 화살표 추가 (선택사항) */
.genre-wrapper {
  position: relative;
}

.input-wrapper textarea {
  padding: 35px 10px 10px 10px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-size: 16px;
  resize: vertical;
  min-height: 100px;
  line-height: 1.5;
}

.input-wrapper textarea:focus {
  outline: none;
}

/* 경고 메시지 */
.warning-msg {
  color: #ff4444;
  font-size: 14px;
  margin-top: 5px;
}

/* 위치 버튼 스타일 */
.location-button {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: white;
  color: #333;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
  box-shadow: 0 2px 6px rgba(0,0,0,0.3);
}

.location-button:active {
  background-color: #f5f5f5;
}

.location-button span {
  margin: 0;
  font-size: 20px;
}

/* 프로필 이미지 스타일 */

.profile-image {
  width: 80px;
  height: 80px;
  border-radius: 25%;
  object-fit: cover;
  border: 2px solid #ccc;
}

.image-upload-button {
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
}

/* 에러 메시지 */
.error-msg {
  color: #ff4444;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}

/* 폼 액션 버튼 */
.form-actions {
  display: flex;
  justify-content: right;
  gap: 20px;
  margin: 10px 0;
}

.save-button {
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 12px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.cancel-button {
  background-color: #ccc;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 12px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

/* 지도 컨테이너 */
.map-container {
  position: relative;
  width: 100%;
  height: 300px;
  margin: 10px 0;
}

#map {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.input-price-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}

.input-price-container input[type="text"] {
  flex: 1;
  width: 100%;
  box-sizing: border-box;
}

.private-checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: -30px;
  padding-bottom: 30px;
}

.private-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.private-label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  font-size: 14px;
  color: #666;
  user-select: none;
  display: flex;
  align-items: center;
  margin: 0;
  top: 0;
  transform: none;
  background: none;
}

.private-label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 2px solid #ddd;
  border-radius: 4px;
  background-color: white;
  transition: all 0.2s ease;
}

.private-label:after {
  content: '';
  position: absolute;
  left: 7px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  opacity: 0;
  transition: all 0.2s ease;
}

.private-checkbox:checked + .private-label:before {
  background-color: #333;
  border-color: #333;
}

.private-checkbox:checked + .private-label:after {
  opacity: 1;
}

.private-checkbox:focus + .private-label:before {
  box-shadow: 0 0 0 2px rgba(51, 51, 51, 0.2);
}

/* 호버 효과 */
.private-label:hover:before {
  border-color: #333;
}

/* 비활성화된 입력 필드 스타일 */
input[type="text"]:disabled {
  background-color: #f5f5f5;
  color: #666;
  cursor: not-allowed;
}

.input-wrapper select {
  padding: 35px 10px 10px 10px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-size: 16px;
  background-color: white;
  cursor: pointer;
  width: 100%;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 1em;
}

.input-wrapper select:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.input-wrapper select:focus {
  outline: none;
  border-color: #333;
}
</style>
