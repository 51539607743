<template>
    <div class="container">
      <div class="banner">
        <h2>TATTOOMAP</h2>
      </div>
      <div class="content">
        <h2 v-if="!connectedAccountId">Get ready for take off</h2>
        <p v-if="!connectedAccountId">TATTOOMAP is the world's leading air travel platform: join our team of pilots to help people travel faster.</p>
        <h2 v-if="connectedAccountId">Add information to start accepting money</h2>
        <p v-if="connectedAccountId">TATTOOMAP partners with Stripe to help you receive payments while keeping your personal and bank details secure.</p>
        <div v-if="!accountCreatePending && !connectedAccountId">
          <button @click="createAccount">Sign up</button>
        </div>
        <div v-if="connectedAccountId && !accountLinkCreatePending && !connectedAccountUpdated">
          <button @click="createAccountLink">Add information</button>
        </div>
        <p v-if="error" class="error">Something went wrong!</p>
        <div v-if="connectedAccountId || accountCreatePending || accountLinkCreatePending" class="dev-callout">
          <p v-if="connectedAccountId">Your connected account ID is: <code class="bold">{{ connectedAccountId }}</code></p>
          <p v-if="accountCreatePending">Creating a connected account...</p>
          <p v-if="accountLinkCreatePending">Creating a new Account Link...</p>
        </div>
        <div class="info-callout">
          <p>
            This is a sample app for Stripe-hosted Connect onboarding. <a href="https://docs.stripe.com/connect/onboarding/quickstart?connect-onboarding-surface=hosted" target="_blank" rel="noopener noreferrer">View docs</a>
          </p>
        </div>
        <div v-if="connectedAccountUpdated" class="success-message">
          <h2>온보딩이 완료되었습니다!</h2>
          <p>이제 결제를 받을 수 있습니다.</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { getAuth } from 'firebase/auth';

  export default {
    data() {
      return {
        accountCreatePending: false,
        accountLinkCreatePending: false,
        error: false,
        connectedAccountId: null,
        apiBaseUrl: 'https://asia-northeast3-TattooMap3.cloudfunctions.net/sendNotification',
        userId: null,
        connectedAccountUpdated: false
      };
    },
    async created() {
      // Firebase Auth를 통해 현재 로그인한 사용자의 ID 가져오기
      const auth = getAuth();
      const user = auth.currentUser;
      
      if (user) {
        this.userId = user.uid;
        // 기존 Stripe 계정 정보 확인
        await this.checkExistingAccount();
        
        // URL 파라미터에서 account ID 확인
        const urlParams = new URLSearchParams(window.location.search);
        const accountId = urlParams.get('account');
        
        // 온보딩 완료 후 돌아온 경우 상태 업데이트
        if (accountId) {
          await this.checkAccountStatus(accountId);
        }
      } else {
        // 로그인하지 않은 경우 처리
        this.error = true;
        console.error('사용자가 로그인하지 않았습니다.');
      }
    },
    methods: {
      createAccount() {
        if (!this.userId) {
          this.error = true;
          return;
        }

        this.accountCreatePending = true;
        this.error = false;
        
        fetch(`${this.apiBaseUrl}/account`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            userId: this.userId
          })
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(json => {
          this.accountCreatePending = false;
          this.connectedAccountId = json.account;
        })
        .catch(error => {
          console.error('Error:', error);
          this.error = true;
          this.accountCreatePending = false;
        });
      },
      
      createAccountLink() {
        if (!this.userId) {
          this.error = true;
          return;
        }

        this.accountLinkCreatePending = true;
        this.error = false;
        
        fetch(`${this.apiBaseUrl}/account_link`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            account: this.connectedAccountId,
            userId: this.userId
          }),
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(json => {
          this.accountLinkCreatePending = false;
          if (json.url) {
            window.location.href = json.url;
          }
          if (json.error) {
            this.error = true;
          }
        })
        .catch(error => {
          console.error('Error:', error);
          this.error = true;
          this.accountLinkCreatePending = false;
        });
      },
      async checkAccountStatus(accountId) {
        try {
          const response = await fetch(`${this.apiBaseUrl}/account_status`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              accountId,
              userId: this.userId
            })
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          this.connectedAccountUpdated = data.isOnboarded;
          this.connectedAccountId = accountId;

          if (data.isOnboarded) {
            // 온보딩이 완료된 경우 처리
            console.log('Stripe 온보딩이 완료되었습니다.');
          }
        } catch (error) {
          console.error('계정 상태 확인 중 오류 발생:', error);
          this.error = true;
        }
      },
      // 기존 Stripe 계정 확인 메서드 추가
      async checkExistingAccount() {
        try {
          const response = await fetch(`${this.apiBaseUrl}/check_existing_account`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              userId: this.userId
            })
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          if (data.accountId) {
            this.connectedAccountId = data.accountId;
            this.connectedAccountUpdated = data.isOnboarded;
          }
        } catch (error) {
          console.error('기존 계정 확인 중 오류 발생:', error);
        }
      }
    },
  };
  </script>