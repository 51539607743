<template>
  <div class="favorite-page">
    <div class="page-header">
      <h2>즐겨찾기한 타투이스트</h2>
    </div>

    <div v-if="loading" class="loading-container">
      <div class="loading-spinner"></div>
      <p>불러오는 중...</p>
    </div>

    <div v-else-if="favorites.length === 0" class="empty-state">
      <i class="fas fa-heart-broken"></i>
      <p>아직 즐겨찾기한 타투이스트가 없습니다.</p>
    </div>

    <div v-else class="favorite-list">
      <div v-for="artist in favorites" 
           :key="artist.id" 
           class="artist-card"
           @click="goToProfile(artist.id)">
        <div class="artist-info">
          <img :src="artist.profileImageUrl || defaultAvatar" 
               :alt="artist.nickname"
               class="artist-avatar" />
          <div class="artist-details">
            <h3>{{ artist.nickname }}</h3>
            <p class="location">
              <i class="fas fa-map-marker-alt"></i>
              {{ (artist.location.address || '위치 정보 없음').split(' ').slice(0,2).join(' ') }}
            </p>
            <div class="genres">
              <span class="main-genre">{{ artist.mainGenre }}</span>
              <span v-for="genre in artist.subGenres" 
                    :key="genre" 
                    class="sub-genre">
                {{ genre }}
              </span>
            </div>
          </div>
        </div>
        <button class="remove-btn" @click.stop="removeFavorite(artist.id)">
          <i class="fas fa-heart"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { auth, db } from '@/firebase';
import { collection, doc, getDoc, deleteDoc, getDocs } from 'firebase/firestore';
import defaultAvatar from '@/assets/default-avatar.png';

export default {
  name: 'GuestFavorite',
  setup() {
    const router = useRouter();
    const favorites = ref([]);
    const loading = ref(true);

    const loadFavorites = async () => {
      try {
        const userId = auth.currentUser?.uid;
        
        if (!userId) {
          router.push('/login');
          return;
        }

        const favoritesRef = collection(db, `users/${userId}/favorites`);
        const favoritesSnapshot = await getDocs(favoritesRef);
        
        const favoritesList = await Promise.all(
          favoritesSnapshot.docs.map(async (document) => {
            const docData = document.data();
            const artistDocRef = doc(db, 'users', docData.artistId);
            const artistData = await getDoc(artistDocRef);
            
            if (artistData.exists()) {
              return {
                id: artistData.id,
                ...artistData.data()
              };
            }
            return null;
          })
        );

        favorites.value = favoritesList.filter(artist => artist !== null);
        
      } catch (error) {
        console.error('즐겨찾기 로드 에러 상세:', error);
        console.error('에러 스택:', error.stack);
      } finally {
        loading.value = false;
      }
    };

    const removeFavorite = async (artistId) => {
      try {
        const userId = auth.currentUser?.uid;
        if (!userId) return;

        const favoriteRef = doc(db, `users/${userId}/favorites/${artistId}`);
        await deleteDoc(favoriteRef);
        
        // UI 업데이트
        favorites.value = favorites.value.filter(artist => artist.id !== artistId);
      } catch (error) {
        console.error('즐겨찾기 삭제 실패:', error);
      }
    };

    const goToProfile = (artistId) => {
      router.push(`/tattooist/${artistId}`);
    };

    onMounted(() => {
      loadFavorites();
    });

    return {
      favorites,
      loading,
      defaultAvatar,
      removeFavorite,
      goToProfile
    };
  }
};
</script>

<style scoped>
.favorite-page {
  padding: 20px;
  margin: 60px auto;
}

.page-header {
  margin-bottom: 24px;
  text-align: center;
}

.page-header h2 {
  font-size: 24px;
  color: #333;
}

.favorite-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.artist-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s ease;
}

.artist-card:hover {
  transform: translateY(-2px);
}

.artist-info {
  display: flex;
  align-items: center;
  gap: 16px;
}

.artist-avatar {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  object-fit: cover;
}

.artist-details {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.artist-details h3 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.location {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #666;
  font-size: 14px;
  margin: 0;
}

.genre {
  color: #888;
  font-size: 14px;
  margin: 0;
}

.remove-btn {
  background: none;
  border: none;
  color: #ff4757;
  font-size: 20px;
  cursor: pointer;
  padding: 8px;
  transition: transform 0.2s ease;
}

.remove-btn:hover {
  transform: scale(1.1);
}

.empty-state {
  text-align: center;
  padding: 40px;
  color: #666;
}

.empty-state i {
  font-size: 48px;
  margin-bottom: 16px;
  color: #ff4757;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #333;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 16px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 480px) {
  .artist-card {
    padding: 12px;
  }

  .artist-avatar {
    width: 50px;
    height: 50px;
  }

  .artist-details h3 {
    font-size: 16px;
  }

  .location, .genre {
    font-size: 12px;
  }
}

.genres {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 4px;
}

.main-genre {
  background-color: #1a1a1a;
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
}

.sub-genre {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eee;
  color: #1a1a1a;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
}

.min-rate {
  color: #666;
  font-size: 14px;
  margin: 4px 0 0 0;
  display: flex;
  align-items: center;
  gap: 4px;
}
</style> 