<template>
  <div class="designer-container">
    <div class="toolbar">
      <div class="product-select">
        <p>제품 수: {{ products.length }}</p>
        <select 
          v-model="selectedProduct" 
          @change="loadBlueprint" 
          class="mobile-select"
        >
          <option value="">제품을 선택하세요</option>
          <option 
            v-for="product in products" 
            :key="product.id" 
            :value="product.id"
          >
            {{ product.title }}
          </option>
        </select>
      </div>

      <div class="design-tools">
        <button @click="addImage" class="tool-btn">
          <span class="icon">🖼️</span>
        </button>
        <button @click="addText" class="tool-btn">
          <span class="icon">T</span>
        </button>
        <input
          type="color"
          v-model="selectedColor"
          class="color-picker"
          title="색상 선택"
        >
      </div>
    </div>

    <div class="design-area">
      <div class="preview-container">
        <canvas ref="canvas" id="fabric-canvas"></canvas>
        <div v-if="!fabricCanvas" class="loading-message">
          캔버스를 초기화하는 중...
        </div>
      </div>
      
      <div class="design-properties" v-if="selectedElement">
        <h3>속성 편집</h3>
        <div class="property-group" v-if="selectedElement.type === 'text'">
          <label>텍스트</label>
          <input type="text" v-model="selectedElement.text" class="mobile-input">
          
          <label>폰트 크기</label>
          <input type="range" 
                 v-model="selectedElement.fontSize" 
                 min="10" 
                 max="100" 
                 class="mobile-range">
          
          <label>폰트</label>
          <select v-model="selectedElement.fontFamily" class="mobile-select">
            <option value="Arial">Arial</option>
            <option value="Times New Roman">Times New Roman</option>
            <option value="Helvetica">Helvetica</option>
          </select>
        </div>

        <div class="property-group">
          <label>위치 조정</label>
          <div class="position-controls">
            <button @click="moveElement('left')" class="direction-btn">←</button>
            <button @click="moveElement('up')" class="direction-btn">↑</button>
            <button @click="moveElement('down')" class="direction-btn">↓</button>
            <button @click="moveElement('right')" class="direction-btn">→</button>
          </div>
          
          <label>회전</label>
          <input type="range" 
                 v-model="selectedElement.rotation" 
                 min="0" 
                 max="360" 
                 class="mobile-range">
        </div>
      </div>
    </div>

    <div class="action-buttons">
      <button @click="saveDesign" class="action-btn save-btn">저장</button>
      <button @click="loadSavedDesign" class="action-btn load-btn">불러오기</button>
      <button @click="createProduct" class="action-btn create-btn">상품 생성</button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, onUnmounted } from 'vue'
import { getFirestore, collection, addDoc, getDocs, query, where } from 'firebase/firestore'
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage'
import { getAuth } from 'firebase/auth'
import { useRoute } from 'vue-router'
import axios from 'axios'

const canvas = ref(null)
const fabricCanvas = ref(null)
const selectedElement = ref(null)
const selectedProduct = ref('')
const selectedColor = ref('#000000')
const products = ref([])

const db = getFirestore()
const storage = getStorage()
const auth = getAuth()
const route = useRoute()

// Firebase Functions 경유로 변경
const printifyApi = axios.create({
  baseURL: 'https://asia-northeast3-tattoomap3.cloudfunctions.net/api',
  headers: {
    'Content-Type': 'application/json'
  }
})

// 요소 이동 함수 추가
const moveElement = (direction) => {
  if (!selectedElement.value) return
  
  const MOVE_AMOUNT = 10
  switch(direction) {
    case 'left':
      selectedElement.value.left -= MOVE_AMOUNT
      break
    case 'right':
      selectedElement.value.left += MOVE_AMOUNT
      break
    case 'up':
      selectedElement.value.top -= MOVE_AMOUNT
      break
    case 'down':
      selectedElement.value.top += MOVE_AMOUNT
      break
  }
  fabricCanvas.value.renderAll()
}

// Canvas 크기 조정 함수
const resizeCanvas = () => {
  if (!fabricCanvas.value) return
  
  const containerWidth = window.innerWidth * 0.9
  const canvasSize = Math.min(containerWidth, 500)
  
  fabricCanvas.value.setWidth(canvasSize)
  fabricCanvas.value.setHeight(canvasSize)
  fabricCanvas.value.renderAll()
}

// Canvas 크기 설정
const initCanvas = () => {
  console.log('[initCanvas] 시작', canvas.value)
  if (!canvas.value) {
    console.error('[initCanvas] canvas element가 없습니다')
    return
  }

  try {
    const containerWidth = window.innerWidth * 0.9
    const canvasSize = Math.min(containerWidth, 500)
    console.log('[initCanvas] 캔버스 크기 설정:', canvasSize)

    canvas.value.width = canvasSize
    canvas.value.height = canvasSize

    fabricCanvas.value = new window.fabric.Canvas('fabric-canvas', {
      width: canvasSize,
      height: canvasSize,
      backgroundColor: '#ffffff'
    })
    
    console.log('[initCanvas] Fabric 캔버스 초기화 성공:', fabricCanvas.value)
    console.log('[initCanvas] 이벤트 리스너 등록 시도')
    fabricCanvas.value.on('selection:created', handleSelection)
    fabricCanvas.value.on('selection:updated', handleSelection)
    console.log('[initCanvas] 이벤트 리스너 등록 완료')

  } catch (error) {
    console.error('[initCanvas] 초기화 실패:', error)
  }
}

// 제품 목록 초기화 함수 수정
const initProducts = async () => {
  console.log('[initProducts] 제품 목록 초기화 시작')
  try {
    const response = await printifyApi.get(`/products/${route.params.productId}`)
    console.log('[initProducts] API 응답:', response)
    
    if (response.data) {
      products.value = [{
        id: response.data.id,
        title: response.data.title,
        description: response.data.description,
        images: response.data.images.map(img => img.src),
        category: response.data.category,
        printAreas: response.data.printAreas,
        variants: response.data.variants
      }]
      console.log('[initProducts] 처리된 제품:', products.value)

      // 제품 로드 완료 후 블루프린트 로드
      if (products.value.length > 0) {
        selectedProduct.value = products.value[0].id
        await loadBlueprint()
      }
    }
  } catch (error) {
    console.error('[initProducts] 제품 목록 로드 실패:', error)
  }
}

// Blueprint 로드 함수 수정
const loadBlueprint = async () => {
  console.log('[loadBlueprint] 시작, 선택된 제품 ID:', selectedProduct.value)
  if (!selectedProduct.value) {
    console.warn('[loadBlueprint] 제품이 선택되지 않았습니다')
    return
  }

  try {
    const response = await printifyApi.get(`/catalog/blueprints/${selectedProduct.value}`)
    console.log('[loadBlueprint] 전체 응답 데이터:', response)

    // 프론트 템플릿 존재 여부 확인
    if (response.data?.templates?.front) {
      console.log('[loadBlueprint] 프론트 템플릿 상세:', response.data.templates.front)
      const templateUrl = response.data.templates.front

      if (fabricCanvas.value && templateUrl) {
        // 기존 배경 이미지 제거
        if (fabricCanvas.value.backgroundImage) {
          fabricCanvas.value.setBackgroundImage(null)
        }
        
        // 새로운 배경 이미지 설정
        window.fabric.Image.fromURL(templateUrl, (img) => {
          const canvasWidth = fabricCanvas.value.width
          const canvasHeight = fabricCanvas.value.height
          const scale = Math.min(
            canvasWidth / img.width,
            canvasHeight / img.height
          )
          
          img.scale(scale)
          
          fabricCanvas.value.setBackgroundImage(img, 
            fabricCanvas.value.renderAll.bind(fabricCanvas.value), 
            {
              originX: 'center',
              originY: 'center',
              left: canvasWidth / 2,
              top: canvasHeight / 2
            }
          )

          // 프린트 영역 설정
          if (response.data.templates.print_areas?.front) {
            setPrintArea(response.data.templates.print_areas.front)
          }
        })
      }
    } else {
      console.error('[loadBlueprint] 유효한 템플릿 데이터 없음')
    }
  } catch (error) {
    console.error('[loadBlueprint] 상세 에러:', {
      message: error.message,
      config: error.config,
      response: error.response?.data
    })
  }
}

// 제품 선택 옵션 렌더링 확인
watch(products, (newProducts) => {
  console.log('[제품 목록 변경]', newProducts)
}, { immediate: true, deep: true })

// 텍스트 추가 함수 수정
const addText = () => {
  console.log('[addText] 실행, 캔버스 상태:', fabricCanvas.value)
  if (!fabricCanvas.value) {
    console.error('[addText] Canvas가 초기화되지 않았습니다.')
    return
  }

  try {
    const text = new window.fabric.Text('텍스트를 입력하세요', {
      left: 100,
      top: 100,
      fontSize: 30,
      fill: selectedColor.value,
      fontFamily: 'Arial'
    })
    
    console.log('[addText] 텍스트 객체 생성 성공:', text)
    fabricCanvas.value.add(text)
    fabricCanvas.value.setActiveObject(text)
    fabricCanvas.value.renderAll()
    
    console.log('[addText] 캔버스에 텍스트 추가 완료')
  } catch (error) {
    console.error('[addText] 에러 발생:', error)
  }
}

// 이미지 추가 함수 수정
const addImage = () => {
  console.log('[addImage] 실행')
  if (!fabricCanvas.value) {
    console.error('[addImage] Canvas가 초기화되지 않았습니다.')
    return
  }

  const input = document.createElement('input')
  input.type = 'file'
  input.accept = 'image/*'
  
  input.onchange = (e) => {
    console.log('[addImage] 파일 선택됨:', e.target.files[0])
    const file = e.target.files[0]
    if (!file) return

    try {
      const reader = new FileReader()
      reader.onload = (event) => {
        window.fabric.Image.fromURL(event.target.result, (img) => {
          // 이미지 크기 조정
          const maxSize = 200
          const scale = Math.min(maxSize / img.width, maxSize / img.height)
          
          img.scale(scale)
          img.set({
            left: 50,
            top: 50
          })
          
          fabricCanvas.value.add(img)
          fabricCanvas.value.setActiveObject(img)
          fabricCanvas.value.renderAll()
          
          console.log('[addImage] 이미지 추가 성공')
        })
      }
      reader.readAsDataURL(file)
    } catch (error) {
      console.error('[addImage] 에러 발생:', error)
    }
  }

  input.click()
}

// 선택된 요소 감지
const handleSelection = (e) => {
  selectedElement.value = e.target
  console.log('선택된 요소:', selectedElement.value)
}

// 프린트 영역 설정 함수 추가
const setPrintArea = (printArea) => {
  if (!fabricCanvas.value || !printArea) return

  // 기존 프린트 영역 제거
  const existingPrintArea = fabricCanvas.value.getObjects().find(obj => obj.id === 'printArea')
  if (existingPrintArea) {
    fabricCanvas.value.remove(existingPrintArea)
  }

  // 새로운 프린트 영역 추가
  const rect = new window.fabric.Rect({
    id: 'printArea',
    left: printArea.x || 100,
    top: printArea.y || 100,
    width: printArea.width || 200,
    height: printArea.height || 200,
    fill: 'rgba(0,0,0,0.1)',
    stroke: 'rgba(0,0,0,0.3)',
    strokeWidth: 1,
    strokeDashArray: [5, 5],
    selectable: false,
    evented: false
  })
  
  fabricCanvas.value.add(rect)
  fabricCanvas.value.renderAll()
  console.log('프린트 영역 설정 완료')
}

onMounted(() => {
  console.log('컴포넌트 마운트 시작')
  initCanvas()
  initProducts()
  window.addEventListener('resize', resizeCanvas)
  console.log('컴포넌트 마운트 완료')
})

onUnmounted(() => {
  // 이벤트 리스너 제거
  if (fabricCanvas.value) {
    fabricCanvas.value.off('selection:created', handleSelection)
    fabricCanvas.value.off('selection:updated', handleSelection)
  }
  window.removeEventListener('resize', resizeCanvas)
})

// 디자인 저장
const saveDesign = async () => {
  console.log('[saveDesign] 시작')
  if (!auth.currentUser) {
    console.warn('[saveDesign] 사용자 미인증')
    alert('로그인이 필요합니다.')
    route.push('/login')
    return
  }

  try {
    console.log('[saveDesign] 캔버스 JSON 변환 시도')
    const json = fabricCanvas.value.toJSON()
    console.log('[saveDesign] JSON 변환 성공:', json)

    console.log('[saveDesign] 데이터 URL 생성 시도')
    const dataUrl = fabricCanvas.value.toDataURL({ format: 'png', quality: 1 })
    console.log('[saveDesign] 데이터 URL 생성 완료')

    // Firebase Storage에 미리보기 이미지 저장
    const imageRef = storageRef(storage, `designs/${auth.currentUser.uid}/${Date.now()}.png`)
    const response = await fetch(dataUrl)
    const blob = await response.blob()
    await uploadBytes(imageRef, blob)
    const previewUrl = await getDownloadURL(imageRef)

    // Firestore에 디자인 데이터 저장
    const designData = {
      userId: auth.currentUser.uid,
      designJson: json,
      previewUrl,
      productId: selectedProduct.value,
      createdAt: new Date(),
      updatedAt: new Date()
    }

    console.log('[saveDesign] Firestore 저장 시작')
    await addDoc(collection(db, 'designs'), designData)
    console.log('[saveDesign] Firestore 저장 성공')
    alert('디자인이 저장되었습니다!')
  } catch (error) {
    console.error('[saveDesign] 전체 프로세스 실패:', error)
    alert('디자인 저장에 실패했습니다.')
  }
}

// 저장된 디자인 불러오기
const loadSavedDesign = async () => {
  if (!auth.currentUser) {
    alert('로그인이 필요합니다.')
    route.push('/login')
    return
  }

  try {
    const designsRef = collection(db, 'designs')
    const q = query(designsRef, where('userId', '==', auth.currentUser.uid))
    const querySnapshot = await getDocs(q)
    
    const designs = []
    querySnapshot.forEach((doc) => {
      designs.push({
        id: doc.id,
        ...doc.data()
      })
    })

    if (designs.length === 0) {
      alert('저장된 디자인이 없습니다.')
      return
    }

    // 가장 최근 디자인 불러오기
    const latestDesign = designs[designs.length - 1]
    fabricCanvas.value.loadFromJSON(latestDesign.designJson, () => {
      fabricCanvas.value.renderAll()
      selectedProduct.value = latestDesign.productId
      alert('디자인을 불러왔습니다!')
    })

  } catch (error) {
    console.error('디자인 로드 실패:', error)
    alert('디자인을 불러오는데 실패했습니다.')
  }
}

// 상품 생성
const createProduct = async () => {
  if (!selectedProduct.value) {
    alert('제품을 선택해주세요.')
    return
  }

  if (!auth.currentUser) {
    alert('로그인이 필요합니다.')
    route.push('/login')
    return
  }

  try {
    // 로딩 상태 표시
    alert('상품을 생성하는 중입니다. 잠시만 기다려주세요...')

    // 캔버스 데이터를 Firebase Storage에 저장
    const dataUrl = fabricCanvas.value.toDataURL({
      format: 'png',
      quality: 1
    })

    // Firebase Storage에 이미지 저장
    const imageRef = storageRef(storage, `products/${auth.currentUser.uid}/${Date.now()}.png`)
    const blobResponse = await fetch(dataUrl)
    const blob = await blobResponse.blob()
    await uploadBytes(imageRef, blob)
    const imageUrl = await getDownloadURL(imageRef)

    // Firestore에 상품 정보 저장
    const productData = {
      userId: auth.currentUser.uid,
      title: '커스텀 디자인 상품',
      description: '사용자 정의 디자인이 적용된 상품입니다.',
      productType: selectedProduct.value,
      imageUrl: imageUrl,
      price: 29.99,
      createdAt: new Date(),
      status: 'pending', // 관리자 승인 대기 상태
      category: products.value.find(p => p.id === selectedProduct.value).category,
      printAreas: products.value.find(p => p.id === selectedProduct.value).printAreas,
      variants: products.value.find(p => p.id === selectedProduct.value).variants
    }

    await addDoc(collection(db, 'products'), productData)

    // 성공 메시지
    alert('상품이 생성되었습니다! 관리자 승인 후 판매가 시작됩니다.')

  } catch (error) {
    console.error('상품 생성 실패:', error)
    alert('상품 생성에 실패했습니다.')
  }
}

// 색상 변경 감지
watch(selectedColor, (newColor) => {
  if (selectedElement.value && selectedElement.value.type === 'text') {
    selectedElement.value.set('fill', newColor)
    fabricCanvas.value.renderAll()
  }
})
</script>

<style scoped>
.designer-container {
  margin-top: 20px;
  padding: 10px;
}

.toolbar {
  margin-bottom: 20px;
}

.product-select {
  margin-bottom: 15px;
}

.mobile-select {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: white;
}

.design-tools {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.tool-btn {
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 25px;
  background-color: #007bff;
  color: white;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.preview-container {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  aspect-ratio: 1;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background-color: #f8f9fa;
}

.design-properties {
  padding: 15px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.property-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
}

.mobile-input,
.mobile-select {
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 100%;
}

.mobile-range {
  width: 100%;
  height: 40px;
}

.color-picker {
  width: 50px;
  height: 50px;
  padding: 0;
  border: none;
  border-radius: 25px;
  cursor: pointer;
}

.position-controls {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  justify-items: center;
  padding: 10px;
}

.direction-btn {
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 20px;
  background-color: #6c757d;
  color: white;
  font-size: 18px;
  cursor: pointer;
}

.action-buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 15px;
  position: sticky;
  bottom: 0;
  background-color: white;
  box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
}

.action-btn {
  flex: 1;
  padding: 15px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;
}

.save-btn {
  background-color: #28a745;
  color: white;
}

.load-btn {
  background-color: #17a2b8;
  color: white;
}

.create-btn {
  background-color: #dc3545;
  color: white;
}

.action-btn:hover {
  opacity: 0.9;
}

.action-btn:active {
  transform: scale(0.98);
}

@media (max-width: 768px) {
  .designer-container {
    padding: 5px;
  }

  .toolbar {
    padding: 5px;
  }

  .tool-btn {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }

  .design-properties {
    padding: 10px;
  }

  .action-btn {
    padding: 12px;
    font-size: 14px;
  }

  .action-buttons {
    grid-template-columns: 1fr;
  }
}

.loading-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #666;
}

canvas {
  width: 100% !important;
  height: 100% !important;
}
</style> 