<template>
  <div class="payment-details-page">
    <div class="details-container">
      <h2>결제 상세 내역</h2>
      <div v-if="loading" class="loading">로딩 중...</div>
      <div v-else-if="error" class="error">
        {{ error }}
      </div>
      <div v-else class="details-content">
        <div class="detail-item">
          <span class="label">결제 ID:</span>
          <span class="value">{{ paymentDetails.id }}</span>
        </div>
        <div class="detail-item">
          <span class="label">결제 금액:</span>
          <span class="value">{{ formatAmount(paymentDetails.amount) }} {{ paymentDetails.currency.toUpperCase() }}</span>
        </div>
        <div class="detail-item">
          <span class="label">결제 상태:</span>
          <span class="value" :class="'status-' + paymentDetails.status">
            {{ getStatusText(paymentDetails.status) }}
          </span>
        </div>
        <div class="detail-item">
          <span class="label">결제 설명:</span>
          <span class="value">{{ paymentDetails.description }}</span>
        </div>
        <div class="detail-item">
          <span class="label">결제 일시:</span>
          <span class="value">{{ formatDate(paymentDetails.created) }}</span>
        </div>
        <div class="detail-item" v-if="paymentDetails.receipt_url">
          <span class="label">영수증:</span>
          <a :href="paymentDetails.receipt_url" target="_blank" class="receipt-link">영수증 보기</a>
        </div>
      </div>
      <div class="buttons">
        <button @click="goBack" class="back-button">뒤로 가기</button>
        <button @click="goToHome" class="home-button">홈으로</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      paymentDetails: null,
      loading: true,
      error: null,
      apiBaseUrl: 'https://asia-northeast3-tattoomap3.cloudfunctions.net/sendNotification',
    };
  },
  async mounted() {
    try {
      const paymentIntentId = this.$route.params.paymentId;
      if (!paymentIntentId) {
        throw new Error('결제 ID를 찾을 수 없습니다.');
      }

      const response = await fetch(`${this.apiBaseUrl}/check_payment_status`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          paymentIntentId: paymentIntentId
        })
      });

      if (!response.ok) {
        throw new Error('결제 정보를 불러오는데 실패했습니다.');
      }

      const data = await response.json();
      this.paymentDetails = data;
    } catch (error) {
      console.error('결제 상세 정보 조회 중 오류:', error);
      this.error = error.message;
    } finally {
      this.loading = false;
    }
  },
  methods: {
    formatAmount(amount) {
      return new Intl.NumberFormat().format(amount);
    },
    formatDate(timestamp) {
      return new Date(timestamp * 1000).toLocaleString('ko-KR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    },
    getStatusText(status) {
      const statusMap = {
        succeeded: '결제 완료',
        processing: '처리 중',
        requires_payment_method: '결제 수단 필요',
        requires_confirmation: '확인 필요',
        requires_action: '추가 인증 필요',
        canceled: '취소됨'
      };
      return statusMap[status] || status;
    },
    goBack() {
      this.$router.back();
    },
    goToHome() {
      this.$router.push('/');
    }
  }
};
</script>

<style scoped>
.payment-details-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 20px;
}

.details-container {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
}

h2 {
  color: #2c3e50;
  margin-bottom: 2rem;
  text-align: center;
}

.details-content {
  margin: 2rem 0;
}

.detail-item {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #eee;
}

.detail-item:last-child {
  border-bottom: none;
}

.label {
  color: #666;
  font-weight: 500;
}

.value {
  color: #2c3e50;
  font-weight: 600;
}

.status-succeeded {
  color: #4CAF50;
}

.status-processing {
  color: #2196F3;
}

.status-requires_payment_method,
.status-requires_confirmation,
.status-requires_action {
  color: #FFC107;
}

.status-canceled {
  color: #F44336;
}

.receipt-link {
  color: #2196F3;
  text-decoration: none;
}

.receipt-link:hover {
  text-decoration: underline;
}

.buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 2rem;
}

button {
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s;
}

.back-button {
  background-color: #f8f9fa;
  color: #2c3e50;
  border: 1px solid #ddd;
}

.home-button {
  background-color: #4CAF50;
  color: white;
}

button:hover {
  opacity: 0.9;
  transform: translateY(-1px);
}

.loading {
  text-align: center;
  color: #666;
  padding: 2rem;
}

.error {
  color: #F44336;
  text-align: center;
  padding: 2rem;
}
</style> 