<template>
  <div class="membership-wrap">
    <div class="membership-container">
      <h2 class="title">{{ uiText.membershipTitle }}</h2>
      
      <!-- 현재 등급 표시 -->
      <div class="current-tier">
        <h2>{{ uiText.currentTier }}: <span :class="currentTier.toLowerCase()">{{ currentTier }}</span></h2>
        <div v-if="currentTier !== 'FREE'" class="current-tier-info">
          <div class="membership-dates">
            <p class="tier-period">{{ uiText.startDate }}: {{ formatDate(membershipStartDate) }}</p>
            <p class="tier-period">{{ uiText.endDate }}: {{ formatDate(membershipEndDate) }}</p>
          </div>
          <button class="cancel-btn" @click="showCancelModal = true">
            {{ uiText.cancelMembership }}
          </button>
        </div>
      </div>

      <!-- 결제 주기 선택 -->
      <div class="billing-toggle">
        <button 
          :class="{ active: billingCycle === 'monthly' }" 
          @click="billingCycle = 'monthly'"
        >
          {{ uiText.monthlyBilling }}
        </button>
        <button 
          :class="{ active: billingCycle === 'yearly' }" 
          @click="billingCycle = 'yearly'"
        >
          {{ uiText.yearlyBilling }} <span class="discount-badge">{{ uiText.yearlyDiscount }}</span>
        </button>
      </div>

      <!-- 등급 선택 카드 -->
      <div class="tier-cards">
        <div 
          v-for="tier in membershipTiers" 
          :key="tier.name"
          class="tier-card"
          :class="{ 
            'selected': selectedTier === tier.name,
            'current': currentTier === tier.name,
            'disabled': !isUpgradeable(tier.name) && tier.name !== currentTier
          }"
        >
          <div class="tier-header">
            <span class="tier-badge" :class="tier.name.toLowerCase()">{{ tier.name }}</span>
            <div class="price">
              <div class="price-amount">
                <span class="amount">
                  {{ displayPrice(tier) }}
                </span>
                <span class="period">/{{ billingCycle === 'yearly' ? uiText.year : uiText.month }}</span>
              </div>
              <div v-if="billingCycle === 'yearly' && tier.price > 0" class="monthly-price">
                {{ displayMonthlyPrice(tier) }}/{{ uiText.month }}
              </div>
            </div>
          </div>
          <div class="benefits">
            <ul class="benefits-list">
              <li v-for="benefit in tier.benefits" :key="benefit">{{ benefit }}</li>
            </ul>
          </div>
          <button 
            class="select-btn"
            :disabled="currentTier === tier.name"
            @click="handleSubmit(tier)"
          >
            {{ currentTier === tier.name ? uiText.currentPlan : uiText.selectPlan }}
          </button>
        </div>
      </div>

      <!-- 취소 확인 모달 -->
      <div v-if="showCancelModal" class="modal-overlay">
        <div class="modal-content cancel-modal">
          <h3 class="modal-title">{{ uiText.cancelConfirmTitle }}</h3>
          
          <!-- 경고 메시지 -->
          <div class="warning-message">
            <i class="fas fa-exclamation-triangle"></i>
            <p>{{ uiText.cancelWarning }}</p>
          </div>

          <!-- 현재 멤버십 정보 -->
          <div class="current-plan-info">
            <div class="plan-details">
              <p><strong>{{ uiText.currentPlan }}:</strong> {{ currentTier }}</p>
              <p><strong>{{ uiText.validUntil }}:</strong> {{ formatDate(membershipEndDate) }}</p>
            </div>
          </div>

          <!-- 잃게 될 혜택 목록 -->
          <div class="benefits-loss">
            <h4>{{ uiText.benefitsLossTitle }}</h4>
            <ul class="benefits-loss-list">
              <li v-for="benefit in getCurrentTierBenefits()" :key="benefit">
                <i class="fas fa-minus-circle"></i>
                {{ benefit }}
              </li>
            </ul>
          </div>

          <!-- 버튼 영역 -->
          <div class="modal-actions">
            <button class="keep-btn" @click="showCancelModal = false">
              {{ uiText.keepMembership }}
            </button>
            <button class="cancel-confirm-btn" @click="confirmCancel">
              {{ uiText.confirmCancel }}
            </button>
          </div>
        </div>
      </div>

      <!-- 등급별 혜택 비교 섹션 -->
      <div class="benefits-comparison">
        <h3 class="comparison-title">{{ uiText.benefitsComparison }}</h3>
        
        <!-- 비교 테이블 -->
        <div class="comparison-table">
          <table>
            <thead>
              <tr>
                <th>{{ uiText.benefits }}</th>
                <th>FREE</th>
                <th>PREMIUM</th>
                <th>PROFESSIONAL</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ uiText.portfolioLimit }}</td>
                <td>6{{ uiText.count }}</td>
                <td>{{ uiText.unlimited }}</td>
                <td>{{ uiText.unlimited }}</td>
              </tr>
              <tr>
                <td>{{ uiText.platformFee }}</td>
                <td>20%</td>
                <td>5%</td>
                <td>0%</td>
              </tr>
              <tr>
                <td>{{ uiText.profileCustomization }}</td>
                <td><i class="fas fa-times"></i></td>
                <td><i class="fas fa-check"></i></td>
                <td><i class="fas fa-check"></i></td>
              </tr>
              <tr>
                <td>{{ uiText.portfolioPriority }}</td>
                <td><i class="fas fa-times"></i></td>
                <td><i class="fas fa-check"></i></td>
                <td><i class="fas fa-check"></i></td>
              </tr>
              <tr>
                <td>{{ uiText.statsAnalysis }}</td>
                <td><i class="fas fa-times"></i></td>
                <td><i class="fas fa-check"></i></td>
                <td><i class="fas fa-check"></i></td>
              </tr>
              <tr>
                <td>{{ uiText.mainPagePriority }}</td>
                <td><i class="fas fa-times"></i></td>
                <td><i class="fas fa-times"></i></td>
                <td><i class="fas fa-check"></i></td>
              </tr>
              <tr>
                <td>{{ uiText.promotionBanner }}</td>
                <td><i class="fas fa-times"></i></td>
                <td><i class="fas fa-times"></i></td>
                <td><i class="fas fa-check"></i></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- 수수료 절감 분석 섹션 -->
      <div class="savings-analysis" v-if="currentTier !== 'FREE'">
        <h2>{{ uiText.savingsAnalysis }}</h2>
        
        <!-- 거래 금액 선택 슬라이더 -->
        <div class="amount-slider">
          <div class="slider-header">
            <span>{{ uiText.sampleAmount }}</span>
            <span class="amount-display">${{ sampleAmount }}</span>
          </div>
          <div class="slider-container">
            <input 
              type="range" 
              v-model="sampleAmount" 
              :min="minAmount" 
              :max="maxAmount" 
              :step="stepAmount"
              class="slider"
            >
            <div class="slider-marks">
              <span>$100</span>
              <span>$1,000</span>
              <span>$2,000</span>
            </div>
          </div>
        </div>

        <div class="savings-stats">
          <div class="stat-item">
            <div class="stat-label">{{ uiText.sampleAmount }}</div>
            <div class="stat-value">${{ sampleAmount }}</div>
          </div>
          <div class="stat-item highlight">
            <div class="stat-label">{{ uiText.monthlySavings }}</div>
            <div class="stat-value">${{ monthlyEstimate }}</div>
            <div class="stat-desc">{{ uiText.stripeNotice }}</div>
          </div>
          <div class="stat-item">
            <div class="stat-label">{{ uiText.yearlySavings }}</div>
            <div class="stat-value">${{ yearlyEstimate }}</div>
            <div class="stat-desc">{{ uiText.stripeNotice }}</div>
          </div>
        </div>
        
        <!-- 등급별 수수료 비교 -->
        <div class="fee-comparison">
          <div class="fee-row" v-for="(rate, tier) in tierFeeRates" :key="tier">
            <div class="fee-tier">{{ tier }}</div>
            <div class="fee-calc">
              <div class="fee-rate">{{ uiText.platformFee }} {{ rate * 100 }}%</div>
              <div class="fee-example">
                플랫폼 수수료: ${{ (sampleAmount * rate).toFixed(2) }}
                <span v-if="tier !== currentTier.toLowerCase()" 
                  :class="{
                    'savings': rate > tierFeeRates[currentTier.toLowerCase()],
                    'savings-positive': rate < tierFeeRates[currentTier.toLowerCase()]
                  }">
                  ({{ rate > tierFeeRates[currentTier.toLowerCase()] ? '+' : '-' }}${{ Math.abs(sampleAmount * (rate - tierFeeRates[currentTier.toLowerCase()])).toFixed(2) }})
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="savings-note">
          * 위 금액은 ${{ sampleAmount }} 거래 기준 예시이며, 실제 거래 금액에 따라 절감액은 달라질 수 있습니다.
          {{ currentTier === 'FREE' ? '지금 업그레이드하고 수수료를 절감하세요!' : '' }}
          <br>
          * {{ uiText.stripeNotice }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '@/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { loadStripe } from '@stripe/stripe-js';

export default {
  name: 'UserClass',
  setup() {
    const auth = getAuth();
    const selectedTier = ref(null);
    const currentTier = ref('FREE');
    const billingCycle = ref('monthly');
    const showCancelModal = ref(false);
    const membershipStartDate = ref(null);
    const membershipEndDate = ref(null);
    const sampleAmount = ref(1000);
    const minAmount = ref(100);
    const maxAmount = ref(2000);
    const stepAmount = ref(100);
    const stripe = ref(null);
    const loading = ref(false);
    const error = ref(null);

    // 데이터 로딩 상태 추가
    const isLoading = ref(true);

    // 수수료 비율 정의
    const tierFeeRates = {
        free: 0.20,
        premium: 0.05,
        professional: 0
    };

    // 기존 상수들 위에 추가
    const apiUrl = 'https://asia-northeast3-tattoomap3.cloudfunctions.net/sendNotification';
    const membershipData = ref(null);

    // 사용자 데이터 로딩 함수
    const loadUserData = async () => {
        try {
            const user = auth.currentUser;
            if (!user) {
                console.error('사용자가 로그인하지 않았습니다.');
                return;
            }

            const userDocRef = doc(db, "users", user.uid);
            const userDoc = await getDoc(userDocRef);
            
            if (userDoc.exists()) {
                const userData = userDoc.data();
                
                if (userData.membership) {
                    membershipData.value = userData.membership; // membershipData 설정
                    currentTier.value = userData.membership.tier || 'FREE';
                    billingCycle.value = userData.membership.billingCycle || 'monthly';
                    membershipStartDate.value = userData.membership.currentPeriodStart?.toDate() || null;
                    membershipEndDate.value = userData.membership.currentPeriodEnd?.toDate() || null;

                    // 멤버십 만료 체크
                    if (membershipEndDate.value && new Date() > membershipEndDate.value) {
                        await handleMembershipExpiration(userDocRef);
                    }
                } else {
                    // membership 데이터가 없는 경우 기본값 설정
                    await setDefaultMembership(userDocRef);
                }
            } else {
                // 사용자 문서가 없는 경우 기본값으로 생성
                await setDefaultMembership(userDocRef);
            }
        } catch (error) {
            console.error('사용자 데이터 로딩 중 오류 발생:', error);
        } finally {
            isLoading.value = false;
        }
    };

    // 기본 멤버십 설정 함수
    const setDefaultMembership = async (userDocRef) => {
        try {
            await setDoc(userDocRef, {
                membership: {
                    tier: 'FREE',
                    startDate: serverTimestamp(),
                    endDate: null,
                    billingCycle: 'monthly',
                    features: getMembershipFeatures('FREE'),
                    updatedAt: serverTimestamp()
                }
            }, { merge: true });

            currentTier.value = 'FREE';
            billingCycle.value = 'monthly';
            membershipStartDate.value = new Date();
            membershipEndDate.value = null;
        } catch (error) {
            console.error('기본 멤버십 설정 중 오류 발생:', error);
        }
    };

    // 멤버십 만료 처리 함수
    const handleMembershipExpiration = async (userDocRef) => {
        try {
            await setDoc(userDocRef, {
                membership: {
                    tier: 'FREE',
                    startDate: serverTimestamp(),
                    endDate: null,
                    billingCycle: 'monthly',
                    features: getMembershipFeatures('FREE'),
                    updatedAt: serverTimestamp()
                }
            }, { merge: true });

            currentTier.value = 'FREE';
            billingCycle.value = 'monthly';
            membershipStartDate.value = new Date();
            membershipEndDate.value = null;
        } catch (error) {
            console.error('멤버십 만료 처리 중 오류 발생:', error);
        }
    };

    // 컴포넌트 마운트 시 데이터 로딩
    onMounted(async () => {
        await loadUserData();
        try {
            stripe.value = await loadStripe('pk_live_51QjVC3BuVHdObWAK7lswTxJxIOYKQelKSGmeVk4o86vhPvwjVST6Qb2AFp5exKsxe60Ig8UQchf4AplZuOy7VXMN00BKvSDc9y');
        } catch (err) {
            console.error('Stripe 로드 실패:', err);
            error.value = '결제 시스템 초기화에 실패했습니다.';
        }
    });

    // auth 상태 변경 감지
    onAuthStateChanged(auth, async (user) => {
        if (user) {
            await loadUserData();
        } else {
            // 로그아웃 시 기본값으로 초기화
            currentTier.value = 'FREE';
            billingCycle.value = 'monthly';
            membershipStartDate.value = null;
            membershipEndDate.value = null;
        }
    });

    const translations = {
      ko: {
        membershipTitle: "멤버십 등급",
        currentTier: "현재 등급",
        startDate: "시작일",
        endDate: "종료일",
        cancelMembership: "멤버십 해지",
        monthlyBilling: "월간 결제",
        yearlyBilling: "연간 결제",
        yearlyDiscount: "20% 할인",
        currentPlan: "현재 이용중인 요금제",
        selectPlan: "선택하기",
        cancelConfirmTitle: "멤버십 해지 확인",
        cancelConfirmMessage: "정말로 멤버십을 해지하시겠습니까?",
        validUntil: "유효기간",
        confirmCancel: "해지하기",
        keepMembership: "유지하기",
        benefitsComparison: "등급별 혜택 비교",
        benefits: "혜택",
        portfolioLimit: "포트폴리오 등록",
        platformFee: "플랫폼 수수료",
        unlimited: "무제한",
        month: "월",
        year: "년",
        savingsAnalysis: "수수료 절감 분석",
        sampleAmount: "거래\n예시 금액",
        monthlySavings: "월간\n절감액",
        yearlySavings: "연간\n예상 절감액",
        stripeNotice: "결제 처리 수수료(Stripe)는 별도로 부과됩니다",
        basicProfile: "기본 프로필",
        chatFeature: "채팅 기능",
        reservationManagement: "예약 관리",
        profileCustomization: "프로필 커스터마이징",
        portfolioPriority: "포트폴리오 우선 노출",
        statsAnalysis: "통계 분석",
        premiumSupport: "프리미엄 고객 지원",
        mainPagePriority: "메인 페이지 우선 노출",
        promotionBanner: "프로모션 배너",
        vipSupport: "VIP 고객 지원",
        expertBadge: "전문가 인증 뱃지",
        count: "개",
        free: "무료",
        cancelWarning: "멤버십 해지 시 현재 이용 중인 모든 프리미엄 혜택이 중단됩니다.",
        benefitsLossTitle: "해지 시 잃게 되는 혜택",
      },
      en: {
        membershipTitle: "Membership Tiers",
        currentTier: "Current Tier",
        startDate: "Start Date",
        endDate: "End Date",
        cancelMembership: "Cancel Membership",
        monthlyBilling: "Monthly Billing",
        yearlyBilling: "Yearly Billing",
        yearlyDiscount: "20% Off",
        currentPlan: "Current Plan",
        selectPlan: "Select Plan",
        cancelConfirmTitle: "Confirm Cancellation",
        cancelConfirmMessage: "Are you sure you want to cancel your membership?",
        validUntil: "Valid Until",
        confirmCancel: "Confirm Cancel",
        keepMembership: "Keep Membership",
        benefitsComparison: "Benefits Comparison",
        benefits: "Benefits",
        portfolioLimit: "Portfolio Limit",
        platformFee: "Platform Fee",
        unlimited: "Unlimited",
        month: "month",
        year: "year",
        savingsAnalysis: "Fee Savings Analysis",
        sampleAmount: "Sample\nTransaction Amount",
        monthlySavings: "Monthly\nSavings",
        yearlySavings: "Yearly\nEstimated Savings",
        stripeNotice: "Stripe processing fee is charged separately",
        basicProfile: "Basic Profile",
        chatFeature: "Chat Feature",
        reservationManagement: "Reservation Management",
        profileCustomization: "Profile Customization",
        portfolioPriority: "Portfolio Priority Listing",
        statsAnalysis: "Statistics Analysis",
        premiumSupport: "Premium Customer Support",
        mainPagePriority: "Main Page Priority",
        promotionBanner: "Promotion Banner",
        vipSupport: "VIP Customer Support",
        expertBadge: "Expert Verification Badge",
        count: "",
        free: "Free",
        cancelWarning: "All premium benefits will be terminated upon cancellation of your membership.",
        benefitsLossTitle: "Benefits You'll Lose",
      },
      jp: {
        membershipTitle: "メンバーシップ等級",
        currentTier: "現在の等級",
        startDate: "開始日",
        endDate: "終了日",
        cancelMembership: "メンバーシップ解約",
        monthlyBilling: "月間決済",
        yearlyBilling: "年間決済",
        yearlyDiscount: "20%オフ",
        currentPlan: "現在のプラン",
        selectPlan: "プラン選択",
        cancelConfirmTitle: "解約確認",
        cancelConfirmMessage: "本当にメンバーシップを解約しますか？",
        validUntil: "有効期限",
        confirmCancel: "解約する",
        keepMembership: "継続する",
        benefitsComparison: "等級別特典比較",
        benefits: "特典",
        portfolioLimit: "ポートフォリオ登録",
        platformFee: "プラットフォーム手数料",
        unlimited: "無制限",
        month: "月",
        year: "年",
        savingsAnalysis: "手数料節約分析",
        sampleAmount: "取引例金額",
        monthlySavings: "月間節約額",
        yearlySavings: "年間予想節約額",
        stripeNotice: "決済処理手数料(Stripe)は別途請求されます",
        basicProfile: "基本プロフィール",
        chatFeature: "チャット機能",
        reservationManagement: "予約管理",
        profileCustomization: "プロフィールカスタマイズ",
        portfolioPriority: "ポートフォリオ優先表示",
        statsAnalysis: "統計分析",
        premiumSupport: "プレミアムサポート",
        mainPagePriority: "メインページ優先表示",
        promotionBanner: "プロモーションバナー",
        vipSupport: "VIPサポート",
        expertBadge: "専門家認証バッジ",
        count: "個",
        free: "無料",
        cancelWarning: "メンバーシップを解約すると、現在ご利用中のすべてのプレミアム特典が終了します。",
        benefitsLossTitle: "解約後に失われる特典",
      }
    };

    const currentLang = ref(localStorage.getItem('selectedLanguage') || 'ko');
    const uiText = computed(() => translations[currentLang.value]);

    const membershipTiers = computed(() => [
      {
        name: "FREE",
        price: 0,
        displayPrice: uiText.value.free,
        benefits: [
          `${uiText.value.portfolioLimit} 6`,
          `${uiText.value.platformFee} 20%`,
          uiText.value.basicProfile,
          uiText.value.chatFeature,
          uiText.value.reservationManagement
        ]
      },
      {
        name: "PREMIUM",
        price: 29,
        displayPrice: `$29`,
        benefits: [
          `${uiText.value.portfolioLimit} ${uiText.value.unlimited}`,
          `${uiText.value.platformFee} 5%`,
          uiText.value.profileCustomization,
          uiText.value.portfolioPriority,
          uiText.value.statsAnalysis,
          uiText.value.premiumSupport
        ]
      },
      {
        name: "PROFESSIONAL",
        price: 79,
        displayPrice: `$79`,
        benefits: [
          `${uiText.value.portfolioLimit} ${uiText.value.unlimited}`,
          `${uiText.value.platformFee} 0%`,
          uiText.value.profileCustomization,
          uiText.value.mainPagePriority,
          uiText.value.promotionBanner,
          uiText.value.vipSupport,
          uiText.value.expertBadge
        ]
      }
    ]);

    // 등급 순위 정의
    const tierRanks = {
      'FREE': 0,
      'PREMIUM': 1,
      'PROFESSIONAL': 2
    };

    // 선택 가능한 등급인지 확인
    const isUpgradeable = (tierName) => {
      const currentRank = tierRanks[currentTier.value];
      const selectedRank = tierRanks[tierName];
      return selectedRank > currentRank;
    };

    const calculateEndDate = (billingType) => {
      const now = new Date();
      if (billingType === 'yearly') {
        // 1년(365일) 후의 날짜 계산
        return new Date(now.getTime() + 365 * 24 * 60 * 60 * 1000);
      } else {
        // 30일 후의 날짜 계산
        return new Date(now.getTime() + 30 * 24 * 60 * 60 * 1000);
      }
    };

    const handleSubmit = async (tier) => {
      try {
        const user = auth.currentUser;
        if (!user) {
          alert('로그인이 필요합니다.');
          return;
        }

        // 현재 등급보다 낮은 등급은 선택 불가
        if (!isUpgradeable(tier.name)) {
          alert('현재 등급보다 낮은 등급으로 변경할 수 없습니다.');
          return;
        }

        // Stripe 결제 처리 호출
        await handleSubscription(tier.name, billingCycle.value);

        // 결제 성공 후의 처리는 webhook에서 자동으로 처리됨
        selectedTier.value = null;

      } catch (error) {
        console.error('Error updating membership:', error);
        alert('멤버십 업데이트 중 오류가 발생했습니다.');
      }
    };

    const getMembershipFeatures = (tierName) => {
      switch (tierName) {
        case 'PREMIUM':
          return {
            portfolioLimit: 999,
            customProfile: true,
            priorityListing: true,
            analytics: true
          };
        case 'PROFESSIONAL':
          return {
            portfolioLimit: 999,
            customProfile: true,
            priorityListing: true,
            bannerAds: true,
            analytics: true,
            vipManagement: true
          };
        case 'FREE':
        default:
          return {
            portfolioLimit: 6,
            customProfile: false,
            priorityListing: false,
            analytics: false,
            bannerAds: false,
            vipManagement: false
          };
      }
    };

    const formatDate = (date) => {
      if (!date) return '-';
      const d = new Date(date);
      return `${d.getFullYear()}년 ${d.getMonth() + 1}월 ${d.getDate()}일`;
    };

    const cancelMembership = async () => {
      try {
        const user = auth.currentUser;
        if (!user) throw new Error('로그인이 필요합니다');

        isLoading.value = true;
        error.value = '';

        // membershipData에서 stripeSubscriptionId 확인
        const subscriptionId = membershipData.value?.stripeSubscriptionId;
        if (!subscriptionId) {
          throw new Error('유효한 구독 정보를 찾을 수 없습니다');
        }

        const idToken = await user.getIdToken();
        const response = await fetch(`${apiUrl}/stripe/cancel-subscription`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`
          },
          body: JSON.stringify({
            subscriptionId: subscriptionId
          })
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || '멤버십 해지에 실패했습니다');
        }

        const result = await response.json();
        console.log('해지 성공:', result);

        showCancelModal.value = false;
        await loadUserData(); // 데이터 새로고침

      } catch (error) {
        console.error('멤버십 해지 오류:', error);
        error.value = error.message;
      } finally {
        isLoading.value = false;
      }
    };

    // 절감액 계산
    const monthlyEstimate = computed(() => {
      const currentTierLower = currentTier.value.toLowerCase();
      const currentRate = tierFeeRates[currentTierLower];
      const freeTierRate = tierFeeRates['free'];
      return Math.round(sampleAmount.value * (freeTierRate - currentRate));
    });

    const yearlyEstimate = computed(() => monthlyEstimate.value * 12);

    // 가격 표시 computed 속성 수정
    const displayPrice = computed(() => (tier) => {
      if (tier.price === 0) return uiText.value.free;
      
      if (billingCycle.value === 'yearly') {
        const yearlyPrice = Math.round(tier.price * 12 * 0.8); // 20% 할인
        return `$${yearlyPrice}`;
      }
      return `$${tier.price}`;
    });

    // 월 평균 금액 계산을 위한 새로운 computed 속성 추가
    const displayMonthlyPrice = computed(() => (tier) => {
      if (tier.price === 0) return uiText.value.free;
      
      if (billingCycle.value === 'yearly') {
        const monthlyPrice = Math.round(tier.price * 0.8); // 20% 할인된 월 금액
        return `$${monthlyPrice}`;
      }
      return `$${tier.price}`;
    });

    // STRIPE_PRICES 테스트 가격 ID로 설정
    const STRIPE_PRICES = {
      PREMIUM: {
        monthly: 'price_1Qrr5DBuVHdObWAK6kMveonI', // Premium 월간 테스트 Price ID
        yearly: 'price_1Qrr6xBuVHdObWAKdXXSTJFb'   // Premium 연간 테스트 Price ID
      },
      PROFESSIONAL: {
        monthly: 'price_1Qrr6HBuVHdObWAKb53NDSwf', // Professional 월간 테스트 Price ID
        yearly: 'price_1Qrr7PBuVHdObWAKgbOEqiJO'   // Professional 연간 테스트 Price ID
      }
    };

    // 구독 처리 함수
    const handleSubscription = async (tier, cycle) => {
      try {
        loading.value = true;
        error.value = null;

        const user = auth.currentUser;
        if (!user) throw new Error('로그인이 필요합니다.');

        // 백엔드에 세션 생성 요청
        const response = await fetch('https://asia-northeast3-tattoomap3.cloudfunctions.net/sendNotification/create_subscription', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            priceId: STRIPE_PRICES[tier][cycle],
            userId: user.uid,
            customerEmail: user.email,
          }),
        });

        const session = await response.json();

        if (session.error) {
          throw new Error(session.error);
        }

        // Stripe Checkout으로 리디렉션
        const result = await stripe.value.redirectToCheckout({
          sessionId: session.sessionId,
        });

        if (result.error) {
          throw new Error(result.error.message);
        }

      } catch (err) {
        console.error('구독 처리 중 오류:', err);
        error.value = err.message;
      } finally {
        loading.value = false;
      }
    };

    // 구독 취소 함수
    const cancelSubscription = async () => {
      try {
        loading.value = true;
        error.value = null;

        const user = auth.currentUser;
        if (!user) throw new Error('로그인이 필요합니다.');

        const response = await fetch(`${apiUrl}/cancel-subscription`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: user.uid,
            subscriptionId: membershipData.value?.stripeSubscriptionId
          }),
        });

        const result = await response.json();

        if (result.error) {
          throw new Error(result.error);
        }

        showCancelModal.value = false;
        await loadUserData();

      } catch (err) {
        console.error('구독 취소 중 오류:', err);
        error.value = err.message;
      } finally {
        loading.value = false;
      }
    };

    const getCurrentTierBenefits = () => {
      const tier = membershipTiers.value.find(t => t.name === currentTier.value);
      return tier ? tier.benefits : [];
    };

    const confirmCancel = () => {
      // 최종 확인 후 해지 처리
      cancelMembership();
    };

    return {
      currentTier,
      selectedTier,
      membershipTiers,
      uiText,
      handleSubmit,
      billingCycle,
      formatDate,
      showCancelModal,
      cancelMembership,
      membershipStartDate,
      membershipEndDate,
      translations,
      isUpgradeable,
      monthlyEstimate,
      yearlyEstimate,
      sampleAmount,
      minAmount,
      maxAmount,
      stepAmount,
      tierFeeRates,
      displayPrice,
      displayMonthlyPrice,
      isLoading,
      handleSubscription,
      cancelSubscription,
      loading,
      error,
      calculateEndDate,
      membershipData,
      getCurrentTierBenefits,
      confirmCancel
    };
  }
};
</script>

<style scoped>
.membership-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 20px;
  margin-top: 60px;
}

.membership-container {
  max-width: 1400px;
  width: 100%;
}

.title {
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 2rem;
  color: #1a1a1a;
}

.current-tier {
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #1a1a1a;
}

.billing-toggle {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.billing-toggle button {
  padding: 0.8rem 1.5rem;
  border: 1px solid #ddd;
  border-radius: 12px;
  background: white;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
  position: relative;
}

.billing-toggle button.active {
  background: #4CAF50;
  color: white;
  border-color: #4CAF50;
}

.discount-badge {
  position: absolute;
  top: -10px;
  right: -10px;
  background: #ff4757;
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.75rem;
  font-weight: bold;
}

.tier-cards {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
}

.tier-card {
  background: white;
  border-radius: 20px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0,0,0,0.05);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  height: auto;
}

.tier-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.tier-badge {
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 12px;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
}

.tier-badge.free {
  background-color: #e9ecef;
  color: #495057;
}

.tier-badge.premium {
  background-color: #339af0;
  color: white;
}

.tier-badge.professional {
  background-color: #862e9c;
  color: white;
}

.price {
  text-align: center;
}

.price-amount {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 0.25rem;
  margin-bottom: 0.5rem;
}

.amount {
  font-size: 2.5rem;
  font-weight: 700;
  color: #1a1a1a;
}

.period {
  font-size: 1rem;
  color: #666;
}

.monthly-price {
  font-size: 0.9rem;
  color: #666;
  margin-top: 0.5rem;
}

.benefits {
  margin: 1.5rem 0;
}

.benefits-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.benefits-list li {
  padding-left: 1.5rem;
  position: relative;
  margin-bottom: 0.8rem;
  font-size: 0.95rem;
  color: #4a4a4a;
  line-height: 1.4;
}

.benefits-list li:before {
  content: "✓";
  position: absolute;
  left: 0;
  color: #4CAF50;
  font-weight: bold;
}

.benefits-list li:last-child {
  margin-bottom: 0;
}

.select-btn {
  width: 100%;
  padding: 1rem;
  border: none;
  border-radius: 12px;
  background-color: #4CAF50;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: auto;
}

.current-tier-info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 10px;
  flex-direction: column;
}

.cancel-btn {
  padding: 8px 16px;
  border: 1px solid #ff4757;
  border-radius: 8px;
  background: transparent;
  color: #ff4757;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* 모달 스타일 */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 30px;
  border-radius: 16px;
  max-width: 400px;
  width: 90%;
}

.modal-info {
  margin: 20px 0;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 8px;
}

.modal-actions {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.cancel-close-btn {
  padding: 12px 24px;
  background: #f8f9fa;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  flex: 1;
}

.tier-card.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.benefits-comparison {
  margin-top: 3rem;
  padding: 2rem;
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.05);
}

.comparison-title {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  color: #333;
}

.comparison-table {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 2rem;
}

th, td {
  padding: 8px;
  text-align: center;
  border-bottom: 1px solid #eee;
}

th {
  background: #f8f9fa;
  font-weight: 600;
}

td:first-child {
  text-align: left;
  font-weight: 500;
}

.fa-check {
  color: #4CAF50;
}

.fa-times {
  color: #ff4757;
}

.fee-savings {
  margin-top: 2rem;
}

.fee-savings h4 {
  text-align: center;
  margin-bottom: 1.5rem;
}

.fee-chart {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.fee-bar {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.fee-label {
  width: 120px;
  font-weight: 500;
}

.fee-amount {
  height: 30px;
  background: #4CAF50;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 1rem;
  border-radius: 4px;
  transition: width 0.3s ease;
}

.savings-analysis {
  margin-top: 2rem;
  padding: 2rem;
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.05);
}

.savings-stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin: 1.5rem 0;
}

.stat-item {
  text-align: center;
  padding: 1.5rem;
  background: #f8f9fa;
  border-radius: 12px;
}

.stat-item.highlight {
  background: #4CAF50;
  color: white;
}

.stat-label {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  white-space: pre-wrap;
}

.stat-value {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.stat-desc {
  font-size: 0.8rem;
  opacity: 0.8;
}

.fee-comparison {
  margin-top: 2rem;
}

.fee-row {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #eee;
}

.fee-tier {
  font-weight: 500;
  text-transform: uppercase;
}

.fee-calc {
  text-align: right;
}

.fee-rate {
  font-weight: bold;
  margin-bottom: 0.25rem;
}

.fee-example {
  font-size: 0.9rem;
  color: #666;
}

.savings {
  color: #af4c4c;
}

.savings-positive {
  color: #4CAF50;
}

.savings-note {
  margin-top: 1.5rem;
  font-size: 0.9rem;
  color: #666;
  text-align: center;
  font-style: italic;
}

.amount-slider {
  background: #f8f9fa;
  padding: 1.5rem;
  border-radius: 12px;
  margin-bottom: 2rem;
}

.slider-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  font-weight: 500;
}

.amount-display {
  font-size: 1.2rem;
  color: #4CAF50;
  font-weight: bold;
}

.slider-container {
  position: relative;
}

.slider {
  width: 100%;
  height: 8px;
  -webkit-appearance: none;
  background: #ddd;
  border-radius: 4px;
  outline: none;
  margin: 1rem 0;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #4CAF50;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.2s;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #4CAF50;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.2s;
  border: none;
}

.slider-marks {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  font-size: 0.8rem;
  color: #666;
}

.stripe-notice {
  margin-top: 1rem;
  padding: 0.8rem;
  background-color: #f8f9fa;
  border-radius: 6px;
  font-size: 0.9rem;
  color: #666;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.stripe-notice i {
  color: #4CAF50;
}

.cancel-modal {
  max-width: 500px;
  width: 90%;
}

.modal-title {
  color: #ff4757;
  text-align: center;
  margin-bottom: 1.5rem;
}

.warning-message {
  background-color: #fff3f3;
  border-left: 4px solid #ff4757;
  padding: 1rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.warning-message i {
  color: #ff4757;
  font-size: 1.5rem;
}

.current-plan-info {
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1.5rem;
}

.plan-details {
  margin-top: 0.5rem;
}

.benefits-loss {
  margin-bottom: 1.5rem;
}

.benefits-loss-list {
  list-style: none;
  padding: 0;
}

.benefits-loss-list li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  color: #666;
}

.benefits-loss-list i {
  color: #ff4757;
}

.post-cancel-info {
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1.5rem;
}

.post-cancel-info ul {
  list-style: none;
  padding: 0;
  margin-top: 0.5rem;
}

.post-cancel-info li {
  margin-bottom: 0.5rem;
  padding-left: 1.2rem;
  position: relative;
}

.post-cancel-info li:before {
  content: "•";
  position: absolute;
  left: 0;
  color: #666;
}

.modal-actions {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

.keep-btn {
  flex: 1;
  padding: 1rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: background-color 0.3s;
}

.cancel-confirm-btn {
  flex: 1;
  padding: 1rem;
  background-color: #ccc;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: background-color 0.3s;
}
</style>
