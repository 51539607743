<template>
  <div class="studio-detail-container" v-if="studio">
    <!-- 기본 정보 헤더 섹션 -->
    <div class="studio-header">
      <h1 class="studio-title">{{ studio.title }}</h1>
      <div class="author-info">
        <img :src="studio.profileUrl" :alt="studio.nickname" class="author-avatar">
        <div class="author-details">
          <span class="author-name">{{ studio.nickname }}</span>
          <span class="post-date">{{ formatDate(studio.createdAt) }}</span>
        </div>
      </div>
    </div>

    <!-- 이미지 갤러리 섹션 -->
    <div class="image-gallery">
      <div class="main-image">
        <img :src="currentImage" :alt="studio.title">
      </div>
      <div class="thumbnail-list">
        <img 
          v-for="(image, index) in studio.images" 
          :key="index"
          :src="image"
          :alt="`${studio.title} - 이미지 ${index + 1}`"
          @click="currentImage = image"
          :class="{ active: currentImage === image }"
        >
      </div>
    </div>

    <!-- 스튜디오 정보 섹션 -->
    <div class="studio-info">
      <!-- 샵 소개 섹션 -->
      <section class="info-section">
        <h2 class="section-title">샵 소개</h2>
        <div class="info-content">
          <p class="about-text">{{ studio.about }}</p>
        </div>
      </section>

      <!-- 위치 정보 섹션 -->
      <section class="info-section">
        <h2 class="section-title">위치 정보</h2>
        <div class="info-content">
          <div class="info-item">
            <i class="fas fa-map-marker-alt"></i>
            <span>{{ studio.place }}</span>
          </div>
        </div>
      </section>

      <!-- 연락처 섹션 -->
      <section class="info-section">
        <h2 class="section-title">연락처</h2>
        <div class="info-content">
          <div class="info-item" v-if="studio.phone">
            <i class="fas fa-phone"></i>
            {{ studio.phone }}
          </div>
          <div class="info-item" v-if="studio.instagram">
            <i class="fab fa-instagram"></i>
            <a :href="`https://instagram.com/${studio.instagram}`" target="_blank">
              @{{ studio.instagram }}
            </a>
          </div>
          <div class="info-item" v-if="studio.website">
            <i class="fas fa-globe"></i>
            <a :href="studio.website" target="_blank">웹사이트 방문</a>
          </div>
        </div>
      </section>

      <!-- 편의시설 섹션 -->
      <section class="info-section">
        <h2 class="section-title">편의시설</h2>
        <div class="service-tags">
          <span v-for="(service, index) in studio.service" 
                :key="index" 
                class="service-tag">
            {{ service }}
          </span>
        </div>
      </section>

      <!-- 이용 정보 섹션 -->
      <section class="info-section">
        <h2 class="section-title">이용 요금</h2>
        <div class="info-content">
          <div class="info-item" v-if="studio?.feePercent > 0">
            <i class="fas fa-percentage"></i>
            <span>수수료: <span class="fee">{{ studio?.feePercent }}%</span></span>
          </div>
          <div class="info-item" v-if="studio?.feeDeposit > 0">
            <i class="fas fa-money-check"></i>
            <span>보증금: <span class="fee">{{ formatPrice(studio?.feeDeposit) }}</span></span>
          </div>
          <div class="info-item" v-if="studio?.feeRent > 0">
            <i class="fas fa-home"></i>
            <span>월세: <span class="fee">{{ formatPrice(studio?.feeRent) }}</span></span>
          </div>
          <!-- 요금 정보가 없는 경우 표시할 메시지 -->
          <div class="info-item no-fee" v-if="!hasFees">
            <i class="fas fa-info-circle"></i>
            <span>등록된 요금 정보가 없습니다</span>
          </div>
        </div>
      </section>

      <!-- 지원하기 버튼 (스튜디오 정보 섹션 마지막에 추가) -->
      <div class="apply-button-container">
        <button class="apply-button" @click="openModal">
          <i class="fas fa-paper-plane"></i> 지원하기
        </button>
      </div>
    
      <!-- 뒤로가기 버튼 추가 -->
      <button class="back-button" @click="router.back()">
        <i class="fas fa-list"/>
      </button>

      <!-- 삭제 버튼 (작성자인 경우에만 표시) -->
      <button v-if="isAuthor" class="delete-button" @click="confirmDelete">
        <i class="fas fa-trash-alt"></i>
      </button>
    </div>

    <!-- 지원하기 모달 -->
    <Transition name="modal-fade">
      <div class="modal" v-if="isModalOpen" @click.self="closeModal">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">지원서 작성</h3>
            <button class="close-button" @click="closeModal">
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="application">지원 내용</label>
              <textarea 
                id="application"
                v-model="applicationContent"
                placeholder="자신을 소개하고 지원하고 싶은 이유를 작성해주세요..."
                rows="6"
              ></textarea>
              <span class="char-count" :class="{ 'near-limit': applicationContent.length > 400 }">
                {{ applicationContent.length }}/500
              </span>
            </div>
          </div>
          <div class="modal-footer">
            <button class="cancel-button" @click="closeModal">
              <i class="fas fa-times"></i> 취소
            </button>
            <button 
              class="submit-button" 
              @click="submitApplication"
              :disabled="!applicationContent.trim() || isSubmitting"
            >
              <i class="fas fa-paper-plane"></i>
              {{ isSubmitting ? '제출 중...' : '제출하기' }}
            </button>
          </div>
        </div>
      </div>
    </Transition>
  </div>
  <div v-else class="loading">
    로딩 중...
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { doc, getDoc, collection, addDoc, serverTimestamp, deleteDoc } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { db } from '@/firebase'

const route = useRoute()
const router = useRouter()
const auth = getAuth()

const studio = ref(null)
const currentImage = ref('')
const isModalOpen = ref(false)
const applicationContent = ref('')
const isSubmitting = ref(false)

// 스튜디오 데이터 가져오기
const fetchStudioDetail = async () => {
  try {
    const studioId = route.params.id
    const studioDoc = await getDoc(doc(db, 'studios', studioId))
    
    if (studioDoc.exists()) {
      studio.value = {
        id: studioDoc.id,
        ...studioDoc.data(),
        createdAt: studioDoc.data().createdAt?.toDate()
      }
      currentImage.value = studio.value.images[0]
    }
  } catch (error) {
    console.error('Error fetching studio details:', error)
  }
}

// 날짜 포맷팅 함수
const formatDate = (date) => {
  if (!date) return ''
  return new Date(date).toLocaleDateString('ko-KR', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })
}

// 가격 포맷팅 함수
const formatPrice = (price) => {
  return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

// 요금 정보 존재 여부 확인
const hasFees = computed(() => {
  return (studio.value?.feePercent > 0 || 
          studio.value?.feeDeposit > 0 || 
          studio.value?.feeRent > 0)
})

// 작성자 여부 확인
const isAuthor = computed(() => {
  return auth.currentUser?.uid === studio.value?.userId
})

const openModal = () => {
  const user = auth.currentUser
  if (!user) {
    alert('로그인이 필요한 서비스입니다.')
    router.push('/login')
    return
  }
  isModalOpen.value = true
}

const closeModal = () => {
  isModalOpen.value = false
  applicationContent.value = ''
}

const submitApplication = async () => {
  if (!applicationContent.value.trim()) return
  
  const user = auth.currentUser
  if (!user) {
    alert('로그인이 필요한 서비스입니다.')
    return
  }
  
  try {
    isSubmitting.value = true
    
    // Requests 서브컬렉션에 지원서 저장
    const requestsRef = collection(db, 'studios', studio.value.id, 'Requests')
    await addDoc(requestsRef, {
      userId: user.uid,
      content: applicationContent.value.trim(),
      createdAt: serverTimestamp()
    })

    alert('지원서가 성공적으로 제출되었습니다.')
    closeModal()
  } catch (error) {
    console.error('Error submitting application:', error)
    alert('지원서 제출 중 오류가 발생했습니다.')
  } finally {
    isSubmitting.value = false
  }
}

// 삭제 확인 및 처리
const confirmDelete = async () => {
  if (confirm('정말로 이 게시글을 삭제하시겠습니까?')) {
    try {
      await deleteDoc(doc(db, 'studios', studio.value.id))
      alert('게시글이 삭제되었습니다.')
      router.push('/studio')
    } catch (error) {
      console.error('Error deleting studio:', error)
      alert('삭제 중 오류가 발생했습니다.')
    }
  }
}

onMounted(() => {
  fetchStudioDetail()
})
</script>

<style scoped>
.studio-detail-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 12px;
  margin-top: 60px;
}

/* 헤더 스타일 */
.studio-header {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.studio-title {
  font-size: 2rem;
  font-weight: 700;
  color: #333;
  margin-top: 0;
  margin-bottom: 12px;
  line-height: 1.2;
}

.author-info {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.author-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.author-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.author-name {
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
}

.post-date {
  font-size: 0.9rem;
  color: #666;
}

/* 이미지 갤러리 스타일 */
.image-gallery {
  margin-bottom: 40px;
}

.main-image {
  width: 100%;
  height: 400px;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.main-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbnail-list {
  display: flex;
  gap: 12px;
  overflow-x: auto;
  padding: 10px 0;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.thumbnail-list::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.thumbnail-list img {
  width: 100px;
  height: 100px;
  border-radius: 12px;
  object-fit: cover;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 2px solid transparent;
}

.thumbnail-list img.active {
  border-color: #007bff;
}

/* 정보 섹션 스타일 */
.studio-info {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 100px;
}

.info-section {
  background: #fff;
  border-radius: 16px;
  padding: 24px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
}

.section-title {
  font-size: 1.3rem;
  font-weight: 600;
  color: #333;
  margin-top: 0;
  margin-bottom: 20px;
  padding-bottom: 12px;
  border-bottom: 2px solid #f0f0f0;
}

.info-content {
  padding: 0 12px;
}

.about-text {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #444;
  white-space: pre-line;
}

.info-item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-size: 1.1rem;
  color: #555;
}

.info-item i {
  width: 24px;
  margin-right: 16px;
  color: #007bff;
}

.info-item a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.2s ease;
}

.fee {
  font-weight: 600;
  color: #dc3545;
  margin-left: 4px;
}

.no-fee {
  color: #6c757d;
  font-style: italic;
}

/* 서비스 태그 스타일 */
.service-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.service-tag {
  background: #f8f9fa;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 0.95rem;
  color: #495057;
  transition: all 0.2s ease;
  border: 1px solid #e9ecef;
}

/* 로딩 스타일 */
.loading {
  text-align: center;
  padding: 40px;
  font-size: 1.2rem;
  color: #666;
}

/* 지원하기 버튼 스타일 */
.apply-button-container {
  position: fixed;
  bottom: 3%;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 100;
}

.apply-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 14px 26px;
  border-radius: 30px;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.3);
  display: flex;
  align-items: center;
  gap: 8px;
}

/* 모달 트랜지션 */
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: all 0.3s ease;
}

.modal-fade-enter-from,
.modal-fade-leave-to {
  opacity: 0;
  transform: scale(1.1);
}

/* 모달 스타일 */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px;
}

.modal-content {
  background: white;
  border-radius: 20px;
  width: 100%;
  max-width: 600px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transform: translateY(0);
  transition: transform 0.3s ease;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  background: #f8f9fa;
  border-bottom: 1px solid #eee;
}

.modal-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.2rem;
  color: #666;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.modal-body {
  padding: 24px;
}

.form-group {
  position: relative;
}

.form-group label {
  display: block;
  font-size: 1rem;
  font-weight: 500;
  color: #444;
  margin-bottom: 8px;
}

textarea {
  width: 100%;
  padding: 16px;
  border: 2px solid #e9ecef;
  border-radius: 12px;
  font-size: 1rem;
  line-height: 1.6;
  resize: vertical;
  min-height: 150px;
  transition: all 0.2s ease;
  box-sizing: border-box;
  font-family: inherit;
}

textarea:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 4px rgba(0, 123, 255, 0.1);
}

.char-count {
  position: absolute;
  bottom: 8px;
  right: 16px;
  font-size: 0.9rem;
  color: #adb5bd;
}

.char-count.near-limit {
  color: #dc3545;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 20px 24px;
  background: #f8f9fa;
  border-top: 1px solid #eee;
}

.cancel-button,
.submit-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.cancel-button {
  background: #f1f3f5;
  border: none;
  color: #495057;
}

.submit-button {
  background: #007bff;
  border: none;
  color: white;
}

.submit-button:disabled {
  background: #b3d7ff;
  cursor: not-allowed;
  transform: none;
}

.back-button {
  position: fixed;
  bottom: 3%;
  left: 3%;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  background: white;
  border: none;
  border-radius: 30px;
  font-size: 1rem;
  color: #333;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  z-index: 100;
}

.delete-button {
  position: fixed;
  bottom: 3%;
  right: 3%;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  background: #dc3545;
  border: none;
  border-radius: 30px;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(220, 53, 69, 0.3);
  transition: all 0.3s ease;
  z-index: 100;
}

.delete-button i {
  font-size: 1.1rem;
}
</style> 