<template>
  <div class="payment-page">
    <h2>결제하기</h2>
    <div v-if="loading">로딩 중...</div>
    <div v-else>
      <p>금액: {{ amount }} {{ currency }}</p>
      <p>설명: {{ description }}</p>
      <div id="payment-element" style="min-height: 100px;"></div>
      <button @click="handlePayment" :disabled="isProcessing || !isPaymentElementReady">
        {{ isProcessing ? '처리 중...' : '결제하기' }}
      </button>
      <div v-if="paymentStatus" class="payment-status">
        <p>결제 상태: {{ paymentStatus }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';

import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

export default {
  data() {
    return {
      amount: null,
      currency: null,
      description: null,
      stripe: null,
      elements: null,
      loading: true,
      isProcessing: false,
      isPaymentElementReady: false,
      paymentStatus: null,
      clientSecret: null,
      apiBaseUrl: 'https://asia-northeast3-tattoomap3.cloudfunctions.net/sendNotification',
    };
  },
  async mounted() {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        throw new Error('로그인이 필요합니다.');
      }

      const db = getFirestore();
      const paymentRequestId = this.$route.params.paymentRequestId;
      
      // Firestore에서 paymentIntentId 조회
      const paymentRequestRef = doc(db, "users", user.uid, "paymentRequests", paymentRequestId);
      const paymentRequestDoc = await getDoc(paymentRequestRef);
      
      if (!paymentRequestDoc.exists()) {
        throw new Error('결제 요청 정보를 찾을 수 없습니다.');
      }

      const paymentIntentId = paymentRequestDoc.data().paymentIntentId;
      if (!paymentIntentId) {
        throw new Error('결제 정보가 유효하지 않습니다.');
      }

      // 결제 상태 확인 API 호출
      const response = await fetch(`${this.apiBaseUrl}/check_payment_status`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          paymentIntentId: paymentIntentId
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const paymentData = await response.json();
      console.log('응답 데이터:', paymentData);

      if (!paymentData || !paymentData.clientSecret) {
        throw new Error('유효하지 않은 결제 데이터입니다.');
      }

      this.amount = paymentData.amount || 0;
      this.currency = (paymentData.currency || 'USD').toUpperCase();
      this.description = paymentData.description || '';
      this.clientSecret = paymentData.clientSecret;

      // DOM이 렌더링될 때까지 기다림
      await this.$nextTick();
      
      // Stripe 초기화
      const stripePublicKey = 'pk_live_51QjVC3BuVHdObWAK7lswTxJxIOYKQelKSGmeVk4o86vhPvwjVST6Qb2AFp5exKsxe60Ig8UQchf4AplZuOy7VXMN00BKvSDc9y';
      if (!stripePublicKey) {
        throw new Error('Stripe 공개키가 설정되지 않았습니다.');
      }

      this.stripe = await loadStripe(stripePublicKey);
      this.elements = this.stripe.elements({
        clientSecret: this.clientSecret,
        appearance: {
          theme: 'stripe',
        },
      });

      // 약간의 지연 후 payment-element 마운트 시도
      setTimeout(async () => {
        try {
          const paymentElementContainer = document.getElementById('payment-element');
          if (!paymentElementContainer) {
            throw new Error('결제 폼 요소를 찾을 수 없습니다.');
          }

          const paymentElement = this.elements.create('payment');
          await paymentElement.mount('#payment-element');
          this.isPaymentElementReady = true;
        } catch (error) {
          console.error('Payment Element 마운트 중 오류:', error);
          this.paymentStatus = '결제 폼 로딩에 실패했습니다: ' + error.message;
        }
      }, 100);

    } catch (error) {
      console.error('결제 정보 조회 중 오류 발생:', error);
      this.paymentStatus = '결제 정보 조회에 실패했습니다: ' + error.message;
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async handlePayment() {
      this.isProcessing = true;
      try {
        const { error } = await this.stripe.confirmPayment({
          elements: this.elements,
          confirmParams: {
            return_url: `${window.location.origin}/payment-success`,
          },
        });

        if (error) {
          this.paymentStatus = '결제 실패: ' + error.message;
        } else {
          this.paymentStatus = '결제가 성공적으로 처리되었습니다.';
        }
      } catch (error) {
        console.error('결제 처리 중 오류 발생:', error);
        this.paymentStatus = '결제 처리 중 오류가 발생했습니다.';
      } finally {
        this.isProcessing = false;
      }
    }
  }
};
</script>

<style scoped>
.payment-page {
  max-width: 500px;
  margin: 0 auto;
  margin-top: 100px;
  padding: 20px;
}

#payment-element {
  margin: 20px 0;
  min-height: 100px;
}

button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.payment-status {
  margin-top: 20px;
  padding: 15px;
  background-color: #f5f5f5;
  border-radius: 5px;
}
</style>