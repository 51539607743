<template>
  <div class="welcome-message-container">
    <div class="welcome-message-wrap">
      <div class="header-section">
        <h2>{{ uiText.welcomeMessageManagement }}</h2>
      </div>

      <div class="message-section">
        <div class="message-box">
          <h3>{{ uiText.writeWelcomeMessage }}</h3>
          <textarea 
            v-model="welcomeMessage" 
            :placeholder="uiText.welcomeMessagePlaceholder"
            rows="5"
          ></textarea>
          <div class="button-container">
            <button @click="saveWelcomeMessage" class="save-button">
              <i class="fas fa-save"></i> {{ uiText.save }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { db } from '@/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useRouter } from 'vue-router';

export default {
  name: 'SettingWelcomeMessage',
  setup() {
    const welcomeMessage = ref('');
    const auth = getAuth();
    const router = useRouter();

    const translations = {
      en: {
        welcomeMessageManagement: 'Welcome Message Management',
        writeWelcomeMessage: 'Write a welcome message for your profile.',
        welcomeMessagePlaceholder: 'ex) Looking for a specific tattoo design?',
        save: 'Save',
        saveSuccess: 'Welcome message has been saved.',
        saveFailure: 'Failed to save.',
      },
      ko: {
        welcomeMessageManagement: '환영 메시지 관리',
        writeWelcomeMessage: '프로필에 표시될 환영 메시지를 작성하세요.',
        welcomeMessagePlaceholder: '예) 어떤 타투를 원하시나요?',
        save: '저장',
        saveSuccess: '환영 메시지가 저장되었습니다.',
        saveFailure: '저장에 실패했습니다.',
      },
      jp: {
        welcomeMessageManagement: 'ウェルカムメッセージ管理',
        writeWelcomeMessage: 'プロファイルに表示されるウェルカムメッセージを書いてください。',
        welcomeMessagePlaceholder: '例) 特定のタトゥーデザインをお探しですか？',
        save: '保存',
        saveSuccess: 'ウェルカムメッセージが保存されました。',
        saveFailure: '保存に失敗しました。',
      },
    };

    const selectedLanguage = ref(localStorage.getItem('selectedLanguage') || 'en');
    const uiText = ref(translations[selectedLanguage.value]);

    const loadWelcomeMessage = async () => {
      const userId = auth.currentUser.uid;
      const userDoc = doc(db, 'users', userId);
      const userSnapshot = await getDoc(userDoc);
      
      if (userSnapshot.exists()) {
        welcomeMessage.value = userSnapshot.data().welcome || '';
      }
    };

    const saveWelcomeMessage = async () => {
      try {
        const userId = auth.currentUser.uid;
        const userDoc = doc(db, 'users', userId);
        await updateDoc(userDoc, {
          welcome: welcomeMessage.value
        });
        alert(uiText.value.saveSuccess);
        router.push('/mypage');
      } catch (error) {
        console.error('Failed to save welcome message:', error);
        alert(uiText.value.saveFailure);
      }
    };

    onMounted(() => {
      loadWelcomeMessage();
    });

    return {
      welcomeMessage,
      saveWelcomeMessage,
      uiText,
    };
  }
};
</script>

<style scoped>
.welcome-message-container {
  margin-top: 60px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.welcome-message-wrap {
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.header-section h2 {
  margin: 0;
}

.message-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.message-box {
  background: white;
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

textarea {
  width: 100%;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  resize: vertical;
  font-size: 14px;
  min-height: 120px;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.save-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
}
</style>
