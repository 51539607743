<template>
  <div class="design-manager-container">
    <div class="header-section">
      <h2 class="main-title">{{ uiText.designManagement }}</h2>
      <div class="edit-toggle">
        <button 
          v-if="!isEditing" 
          @click="enableEditing" 
          class="edit-button" 
          :disabled="currentTier === 'FREE'"
          :class="{ 'disabled': currentTier === 'FREE' }"
        >
          <i class="fas fa-plus"></i> {{ uiText.add }}
        </button>
        <button v-else @click="cancelEdit" class="cancel-button">
          <i class="fas fa-times"></i> {{ uiText.cancel }}
        </button>
      </div>
    </div>

    <div v-if="errorMessage" class="error-banner">
      <i class="fas fa-exclamation-circle"></i>
      {{ errorMessage }}
    </div>

    <!-- FREE 등급 사용자를 위한 알림 배너 -->
    <div v-if="currentTier === 'FREE'" class="limit-banner">
      <i class="fas fa-info-circle"></i>
      {{ uiText.designUploadRestricted }}
      <router-link to="/membership" class="upgrade-link">
        {{ uiText.upgradeMembership }}
      </router-link>
    </div>

    <!-- Design Upload/Edit Form -->
    <transition name="slide-fade">
      <form 
        v-if="isEditing" 
        @submit.prevent="handleSubmit" 
        class="design-form"
        :class="{ 'disabled': isUploadLimitReached }"
      >
        <div class="form-header">
          <h3>{{ editDesignId ? uiText.editDesign : uiText.registerNewDesign }}</h3>
        </div>

        <div class="form-grid">
          <div class="form-left">
            <div class="form-group image-upload">
              <label for="designImage">
                <div class="upload-placeholder" v-if="!previewImage">
                  <i class="fas fa-cloud-upload-alt"></i>
                  <span>{{ uiText.selectImage }}</span>
                  <p class="upload-guide">
                    {{ uiText.recommendations }}<br>
                    - {{ uiText.imageFormat }}<br>
                    - {{ uiText.maxFileSize }}<br>
                    - {{ uiText.recommendedResolution }}
                  </p>
                </div>
                <img v-else :src="previewImage" alt="Preview" class="preview-image" />
              </label>
              <input
                type="file"
                id="designImage"
                @change="handleImageChange"
                accept="image/*"
                hidden
              />
            </div>
          </div>

          <div class="form-right">
            <div class="form-group">
              <label for="description">{{ uiText.designDescription }}</label>
              <textarea
                id="description"
                v-model="description"
                required
                :placeholder="uiText.describeDesign"
              ></textarea>
            </div>
            
            <div class="form-group">
              <label for="price">{{ uiText.estimatedCost }}</label>
              <div class="price-input-group">
                <input
                  type="number"
                  id="price"
                  v-model="price"
                  :required="!isPrivatePrice"
                  :disabled="isPrivatePrice"
                  :placeholder="uiText.enterEstimatedCost"
                />
                <div class="private-toggle">
                  <input 
                    type="checkbox" 
                    id="privatePrice" 
                    v-model="isPrivatePrice"
                  />
                  <label for="privatePrice">{{ uiText.priceOnRequest }}</label>
                </div>
              </div>
            </div>
            
            <div class="form-group">
              <label for="timeSpent">{{ uiText.estimatedTime }}</label>
              <input
                type="number"
                id="timeSpent"
                v-model="timeSpent"
                required
                :placeholder="uiText.enterEstimatedTime"
              />
            </div>

            <div class="form-group">
              <label>{{ uiText.genre }}</label>
              <div class="genre-container">
                <div 
                  v-for="genre in genres" 
                  :key="genre" 
                  :class="['genre-item', { selected: mainGenre === genre }]" 
                  @click="selectMainGenre(genre)"
                >
                  {{ translateGenre(genre) }}
                </div>
              </div>
            </div>

            <div class="form-actions">
              <button type="submit" class="save-button">
                <i class="fas fa-check"></i>
                {{ editDesignId ? uiText.saveChanges : uiText.register }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </transition>

    <!-- Design List -->
    <div class="design-section">
      <div class="section-header">
        <h3>{{ uiText.myDesignList }}</h3>
        <div class="design-stats">
          <span>{{ designs.length }} {{ uiText.totalDesigns }}</span>
          <span v-if="currentTier === 'FREE'" class="design-limit">
            ({{ uiText.limit }}: 6)
          </span>
        </div>
      </div>

      <div v-if="loading" class="loading-container">
        <div class="loading-spinner"></div>
        <span>{{ uiText.loadingDesigns }}</span>
      </div>

      <div v-else-if="designs.length === 0" class="empty-state">
        <i class="fas fa-paint-brush"></i>
        <p>{{ uiText.noDesignsRegistered }}</p>
        <button @click="enableEditing" class="add-first-button">
          {{ uiText.registerFirstDesign }}
        </button>
      </div>

      <div v-else class="designs-grid">
        <div v-for="design in designs" :key="design.id" class="design-item">
          <div class="image-container">
            <img :src="design.imageUrl" alt="Design Image" class="design-image" />
            <div class="overlay">
              <div class="overlay-content">
                <div class="design-info">
                  <div class="info-row">
                    <i class="fas fa-pen"></i>
                    <p>{{ design.description }}</p>
                  </div>
                  <div class="info-row">
                    <i class="fas fa-clock"></i>
                    <p>{{ design.timeSpent }} {{ uiText.hours }}</p>
                  </div>
                  <div class="info-row">
                    <i class="fas fa-coins"></i>
                    <p>{{ design.price === 'Price on Request' ? 'Price on Request' : `${design.price?.toLocaleString()}` }}</p>
                  </div>
                  <div class="info-row">
                    <i class="fas fa-tag"></i>
                    <p>{{ translateGenre(design.mainGenre) }}</p>
                  </div>
                </div>
                <div class="action-buttons">
                  <button @click="editDesign(design)" class="edit-button">
                    <i class="fas fa-edit"></i> {{ uiText.edit }}
                  </button>
                  <button @click="deleteDesign(design.id)" class="delete-button">
                    <i class="fas fa-trash-alt"></i> {{ uiText.delete }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import Compressor from "compressorjs";
import { db, storage, auth } from "@/firebase";
import { 
  collection, 
  addDoc, 
  getDocs,
  getDoc,
  query, 
  where, 
  doc, 
  updateDoc, 
  deleteDoc, 
  serverTimestamp 
} from "firebase/firestore";
import { 
  ref as storageRef, 
  uploadBytesResumable, 
  getDownloadURL, 
  deleteObject 
} from "firebase/storage";
import { onAuthStateChanged } from "firebase/auth";

export default {
  name: "DesignManager",
  setup() {
    const isEditing = ref(false);
    const editDesignId = ref(null);
    const description = ref("");
    const timeSpent = ref(0);
    const price = ref(0);
    const isPrivatePrice = ref(false);
    const genres = [
      "Emotional", "Oriental", "Lettering", "Linework", "Blackwork", "Black & Grey",
      "Watercolor", "New School", "Mini Tattoo", "Old School", "Irezumi", "Illustration",
      "Color Tattoo", "Cover Up", "Chicano", "Touch Up", "Tribal", "Anime/Cartoon"
    ];
    const mainGenre = ref("");

    const designImage = ref(null);
    const previewImage = ref("");
    const designs = ref([]);
    const loading = ref(false);
    const errorMessage = ref("");
    const userId = ref("");
    const currentTier = ref('FREE'); // 기본값 FREE로 설정

    const translations = {
      en: {
        designManagement: 'Design Management',
        add: 'Add',
        cancel: 'Cancel',
        editDesign: 'Edit Design',
        registerNewDesign: 'Register New Design',
        selectImage: 'Select Image',
        recommendations: 'Recommendations:',
        imageFormat: 'Image Format: JPG, PNG',
        maxFileSize: 'Max File Size: 10MB',
        recommendedResolution: 'Recommended Resolution: 1920x1920 or less',
        designDescription: 'Design Description',
        describeDesign: 'Please describe your design',
        estimatedCost: 'Estimated Cost',
        enterEstimatedCost: 'Enter estimated cost',
        priceOnRequest: 'Price on Request',
        estimatedTime: 'Estimated Time',
        enterEstimatedTime: 'Enter estimated time',
        genre: 'Genre',
        saveChanges: 'Save Changes',
        register: 'Register',
        myDesignList: 'My Design List',
        totalDesigns: 'designs',
        loadingDesigns: 'Loading designs...',
        noDesignsRegistered: 'No designs registered',
        registerFirstDesign: 'Register Your First Design',
        hours: 'hours',
        edit: 'Edit',
        delete: 'Delete',
        designLimitReached: "Free members can only register up to 6 designs.",
        upgradeMembership: "Upgrade Membership",
        designLimitReachedMessage: "Free members can only register up to 6 designs.\nUpgrade your membership for unlimited design uploads.",
        limit: "Limit",
        genres: {
          "Emotional": "Emotional",
          "Oriental": "Oriental",
          "Lettering": "Lettering",
          "Linework": "Linework",
          "Blackwork": "Blackwork",
          "Black & Grey": "Black & Grey",
          "Watercolor": "Watercolor",
          "New School": "New School",
          "Mini Tattoo": "Mini Tattoo",
          "Old School": "Old School",
          "Irezumi": "Irezumi",
          "Illustration": "Illustration",
          "Color Tattoo": "Color Tattoo",
          "Cover Up": "Cover Up",
          "Chicano": "Chicano",
          "Touch Up": "Touch Up",
          "Tribal": "Tribal",
          "Anime/Cartoon": "Anime/Cartoon"
        },
        designUploadRestricted: "Design upload is available for Premium and Professional members only.",
      },
      ko: {
        designManagement: '디자인 관리',
        add: '추가',
        cancel: '취소',
        editDesign: '디자인 수정',
        registerNewDesign: '새 디자인 등록',
        selectImage: '이미지 선택',
        recommendations: '권장 사항:',
        imageFormat: '이미지 형식: JPG, PNG',
        maxFileSize: '최대 파일 크기: 10MB',
        recommendedResolution: '권장 해상도: 1920x1920 이하',
        designDescription: '디자인 설명',
        describeDesign: '디자인을 설명해주세요',
        estimatedCost: '예상 비용',
        enterEstimatedCost: '예상 비용을 입력하세요',
        priceOnRequest: '비공개',
        estimatedTime: '예상 시간',
        enterEstimatedTime: '예상 시간을 입력하세요',
        genre: '장르',
        saveChanges: '변경 사항 저장',
        register: '등록',
        myDesignList: '내 디자인 목록',
        totalDesigns: '개',
        loadingDesigns: '디자인 불러오는 중...',
        noDesignsRegistered: '등록된 디자인이 없습니다',
        registerFirstDesign: '첫 디자인을 등록하세요',
        hours: '시간',
        edit: '수정',
        delete: '삭제',
        designLimitReached: "무료 회원은 최대 6개의 디자인만 등록할 수 있습니다.",
        upgradeMembership: "멤버십 업그레이드하기",
        designLimitReachedMessage: "무료 회원은 최대 6개의 디자인만 등록할 수 있습니다.\n멤버십 업그레이드를 통해 무제한으로 등록하실 수 있습니다.",
        limit: "제한",
        genres: {
          "Emotional": "감성타투",
          "Oriental": "동양화",
          "Lettering": "레터링",
          "Linework": "라인워크",
          "Blackwork": "블랙워크",
          "Black & Grey": "블랙 & 그레이",
          "Watercolor": "수채화",
          "New School": "뉴스쿨",
          "Mini Tattoo": "미니타투",
          "Old School": "올드스쿨",
          "Irezumi": "이레즈미",
          "Illustration": "일러스트",
          "Color Tattoo": "컬러타투",
          "Cover Up": "커버업",
          "Chicano": "치카노",
          "Touch Up": "터치업",
          "Tribal": "트라이벌",
          "Anime/Cartoon": "애니/만화"
        },
        designUploadRestricted: "디자인 업로드는 프리미엄 및 프로페셔널 회원만 이용 가능합니다.",
      },
      jp: {
        designManagement: 'デザイン管理',
        add: '追加',
        cancel: 'キャンセル',
        editDesign: 'デザインを編集',
        registerNewDesign: '新しいデザインを登録',
        selectImage: '画像を選択',
        recommendations: '推奨事項:',
        imageFormat: '画像形式: JPG, PNG',
        maxFileSize: '最大ファイルサイズ: 10MB',
        recommendedResolution: '推奨解像度: 1920x1920以下',
        designDescription: 'デザイン説明',
        describeDesign: 'デザインを説明してください',
        estimatedCost: '予想費用',
        enterEstimatedCost: '予想費用を入力してください',
        priceOnRequest: '非公開',
        estimatedTime: '予想時間',
        enterEstimatedTime: '予想時間を入力してください',
        genre: 'ジャンル',
        saveChanges: '変更を保存',
        register: '登録',
        myDesignList: '私のデザイン一覧',
        totalDesigns: '件',
        loadingDesigns: 'デザインを読み込み中...',
        noDesignsRegistered: '登録されたデザインがありません',
        registerFirstDesign: '最初のデザインを登録してください',
        hours: '時間',
        edit: '編集',
        delete: '削除',
        designLimitReached: "無料会員は最大6つのデザインまでしか登録できません。",
        upgradeMembership: "メンバーシップをアップグレード",
        designLimitReachedMessage: "無料会員は最大6つのデザインまでしか登録できません。\nメンバーシップをアップグレードすると無制限で登録できます。",
        limit: "制限",
        genres: {
          "Emotional": "感情的",
          "Oriental": "東洋",
          "Lettering": "レタリング",
          "Linework": "ラインワーク",
          "Blackwork": "ブラックワーク",
          "Black & Grey": "ブラック＆グレー",
          "Watercolor": "水彩画",
          "New School": "ニュー スクール",
          "Mini Tattoo": "ミニ タトゥー",
          "Old School": "オールド スクール",
          "Irezumi": "入れ墨",
          "Illustration": "イラスト",
          "Color Tattoo": "カラー タトゥー",
          "Cover Up": "カバーアップ",
          "Chicano": "チカーノ",
          "Touch Up": "タッチアップ",
          "Tribal": "トライバル",
          "Anime/Cartoon": "アニメ/漫画"
        },
        designUploadRestricted: "デザインのアップロードはプレミアム会員とプロフェッショナル会員のみご利用いただけます。",
      },
    };

    const selectedLanguage = ref(localStorage.getItem('selectedLanguage') || 'en');
    const uiText = ref(translations[selectedLanguage.value]);

    const translateGenre = (genre) => {
      return translations[selectedLanguage.value].genres[genre] || genre;
    };

    // Design Load Function
    const loadDesigns = async () => {
      loading.value = true;
      errorMessage.value = "";
      try {
        if (!userId.value) {
          errorMessage.value = "Login is required.";
          designs.value = [];
          return;
        }

        const q = query(collection(db, "designs"), where("userId", "==", userId.value));
        const querySnapshot = await getDocs(q);
        designs.value = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      } catch (error) {
        console.error("Design Load Error:", error);
        errorMessage.value = "Failed to load designs.";
      } finally {
        loading.value = false;
      }
    };

    // Firebase Authentication State Change
    const checkAuth = () => {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          userId.value = user.uid;
          await loadUser(); // 사용자 데이터 로드
          await loadDesigns();
        } else {
          errorMessage.value = "Login is required.";
          designs.value = [];
        }
      });
    };

    // Image Change Handling
    const handleImageChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        new Compressor(file, {
          quality: 0.6,
          success(compressedResult) {
            designImage.value = compressedResult;
            const reader = new FileReader();
            reader.readAsDataURL(compressedResult);
            reader.onload = () => {
              previewImage.value = reader.result;
            };
          },
          error(err) {
            console.error("Image Compression Error:", err);
            errorMessage.value = "Failed to compress image.";
          },
        });
      }
    };

    // Storage Path Extraction Function
    const getStoragePathFromUrl = (url) => {
      try {
        const urlObj = new URL(url);
        const path = urlObj.pathname.split('/o/')[1];
        if (!path) return null;
        return decodeURIComponent(path.split('?')[0]);
      } catch (error) {
        console.error("Storage Path Extraction Error:", error);
        return null;
      }
    };

    // Design Submission Handling
    const handleSubmit = async () => {
      if (currentTier.value === 'FREE') {
        alert(uiText.value.designUploadRestricted);
        return;
      }

      if (!designImage.value && !editDesignId.value) {
        alert("Please select an image.");
        return;
      }

      if (!mainGenre.value) {
        alert("Please select a main genre.");
        return;
      }

      if (loading.value === true) {
        alert("Already working. Please wait.");
        return;
      }

      loading.value = true;
      errorMessage.value = "";

      try {
        let downloadURL = previewImage.value;

        if (designImage.value) {
          const timestamp = Date.now();
          const imageName = `${timestamp}_${designImage.value.name}`;
          const imageStorageRef = storageRef(storage, `designs/${userId.value}/${imageName}`);
          const uploadTask = uploadBytesResumable(imageStorageRef, designImage.value);

          // Add Upload Progress Indicator
          uploadTask.on('state_changed', 
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');
            }
          );

          await new Promise((resolve, reject) => {
            uploadTask.on(
              "state_changed",
              null,
              (error) => reject(error),
              () => resolve()
            );
          });

          downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        }

        const designData = {
          description: description.value,
          timeSpent: timeSpent.value,
          price: isPrivatePrice.value ? "Price on Request" : price.value,
          mainGenre: mainGenre.value,
          userId: userId.value,
          uploadTime: serverTimestamp(),
          imageUrl: downloadURL,
        };

        if (editDesignId.value) {
          const designDoc = doc(db, "designs", editDesignId.value);
          await updateDoc(designDoc, designData);
        } else {
          await addDoc(collection(db, "designs"), designData);
        }

        resetForm();
        await loadDesigns();
      } catch (error) {
        console.error("Design Save Error:", error);
        errorMessage.value = "Failed to save design. Please try again later.";
      } finally {
        loading.value = false;
        isEditing.value = false;
      }
    };

    // Design Edit
    const editDesign = (design) => {
      isEditing.value = true;
      editDesignId.value = design.id;
      description.value = design.description;
      timeSpent.value = design.timeSpent;
      price.value = design.price || 0;
      mainGenre.value = design.mainGenre;
      previewImage.value = design.imageUrl;
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };

    // Design Delete
    const deleteDesign = async (designId) => {
      const userConfirmed = confirm(
        "Are you sure you want to delete this design?\n" +
        "Deleted design cannot be recovered."
      );
      if (!userConfirmed) return;

      if (loading.value === true) {
        alert("Already working. Please wait.");
        return;
      }

      loading.value = true;
      errorMessage.value = "";

      try {
        const designDoc = doc(db, "designs", designId);
        const designSnapshot = await getDoc(designDoc);
        
        if (designSnapshot.exists()) {
          const designData = designSnapshot.data();
          if (designData.imageUrl) {
            const storagePath = getStoragePathFromUrl(designData.imageUrl);
            if (storagePath) {
              const imageRef = storageRef(storage, storagePath);
              try {
                await deleteObject(imageRef);
                console.log("Image Deletion Success:", storagePath);
              } catch (err) {
                console.warn("Image Deletion Failure:", err);
              }
            }
          }
          await deleteDoc(designDoc);
          await loadDesigns();
          alert("Design deleted successfully.");
        }
      } catch (error) {
        console.error("Design Deletion Error:", error);
        errorMessage.value = "Failed to delete design. Please try again later.";
      } finally {
        loading.value = false;
      }
    };

    // Edit Mode Activation
    const enableEditing = () => {
      if (currentTier.value === 'FREE') {
        alert(uiText.value.designUploadRestricted);
        return;
      }
      isEditing.value = true;
      editDesignId.value = null;
      resetForm();
    };

    // Edit Cancel
    const cancelEdit = () => {
      isEditing.value = false;
      editDesignId.value = null;
      resetForm();
    };

    // Form Initialization
    const resetForm = () => {
      description.value = "";
      timeSpent.value = "";
      price.value = "";
      isPrivatePrice.value = false;
      mainGenre.value = "";
      designImage.value = null;
      previewImage.value = "";
      errorMessage.value = "";
    };

    // Main Genre Selection
    const selectMainGenre = (genre) => {
      if (!isEditing.value) return;
      mainGenre.value = mainGenre.value === genre ? "" : genre;
    };

    // Timestamp Format Conversion
    const formatTimestamp = (timestamp) => {
      if (!timestamp) return "";
      const date = timestamp.toDate();
      return date.toLocaleString();
    };

    // 디자인 업로드 제한 확인
    const isUploadLimitReached = computed(() => {
      return currentTier.value === 'FREE' && designs.value.length >= 6;
    });

    // 사용자 데이터 로딩 함수 수정
    const loadUser = async () => {
      try {
        const userDoc = doc(db, "users", userId.value);
        const userSnapshot = await getDoc(userDoc);
        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          // 멤버십 등급 정보 가져오기
          if (userData.membership && userData.membership.tier) {
            currentTier.value = userData.membership.tier;
          }
        }
      } catch (error) {
        console.error("사용자 데이터 로드 오류:", error);
      }
    };

    onMounted(() => {
      checkAuth();
    });

    return {
      isEditing,
      editDesignId,
      description,
      timeSpent,
      price,
      isPrivatePrice,
      genres,
      mainGenre,
      designImage,
      previewImage,
      designs,
      loading,
      errorMessage,
      enableEditing,
      cancelEdit,
      handleImageChange,
      handleSubmit,
      editDesign,
      deleteDesign,
      selectMainGenre,
      formatTimestamp,
      uiText,
      translateGenre,
      currentTier,
      isUploadLimitReached,
    };
  },
};
</script>

<style scoped>
.design-manager-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px 10px;
  margin-top: 60px;
}

/* Header Section Styles */
.header-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  gap: 20px;
}

.main-title {
  font-size: 2rem;
  font-weight: 700;
  color: #1a1a1a;
  margin: 0;
}

/* Button Styles */
.edit-toggle {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.edit-button, 
.save-button {
  background: #333;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cancel-button,
.delete-button {
  background: #666;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
}

.form-actions {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 30px;
}

/* Form Styles */
.design-form {
  background: #fff;
  border-radius: 20px;
  padding: 15px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.form-header {
  margin-bottom: 30px;
}

.form-header h3 {
  font-size: 1.5rem;
  color: #1a1a1a;
  margin: 0;
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
}

.form-group {
  margin-bottom: 20px;
}

.image-upload {
  width: 100%;
  aspect-ratio: 1;
  border: 2px dashed #ddd;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
}

.upload-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  color: #666;
  padding: 20px;
  text-align: center;
}

.preview-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Input Field Styles */
input[type="text"],
input[type="number"],
textarea {
  width: 90%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
}

textarea {
  width: calc(100% - 30px);
  min-height: 120px;
  padding: 12px;
  border: 2px solid #e5e7eb;
  border-radius: 12px;
  resize: vertical;
  transition: border-color 0.3s ease;
}

/* Genre Selection Styles */
.genre-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.genre-item {
  padding: 8px 16px;
  border: 2px solid #e5e7eb;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
}

.genre-item.selected {
  background: #333;
  color: white;
  border-color: #333;
}

/* Design Grid Styles */
.designs-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
}

.design-item {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.image-container {
  position: relative;
  width: 100%;
  aspect-ratio: 1;
}

.design-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity 0.3s;
  display: flex;
  align-items: center;
  padding: 20px;
}

.design-item:hover .overlay {
  opacity: 1;
}

.overlay-content {
  color: white;
  text-align: center;
}

.info-row {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 30px;
}

.info-row i {
  width: 20px;
  color: #fff;
}

.info-row p {
  margin: 0;
  color: #fff;
}

.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.design-section {
  margin-top: 40px;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.design-stats {
  color: #6b7280;
  font-size: 0.9rem;
}

.empty-state {
  text-align: center;
  padding: 60px 20px;
  background: #f9fafb;
  border-radius: 16px;
}

.empty-state i {
  font-size: 3rem;
  color: #9ca3af;
  margin-bottom: 20px;
}

.add-first-button {
  margin-top: 20px;
  background: #333;
  color: white;
  padding: 12px 24px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Loading and Error Styles */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 40px;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #333;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.error-banner {
  background: #ff4444;
  color: white;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Transition Animation */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

/* Additional Style for Upload Guide */
.upload-guide {
  font-size: 0.8rem;
  color: #666;
  margin-top: 10px;
  line-height: 1.4;
}

/* Additional Style for Price Input Group */
.price-input-group {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;
}

.private-toggle {
  display: flex;
  align-items: center;
  gap: 6px;
}

.private-toggle input[type="checkbox"] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.private-toggle label {
  font-size: 0.9rem;
  color: #666;
  cursor: pointer;
}

/* Disabled Input Field Style */
input:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.limit-banner {
  background: #fff3cd;
  color: #856404;
  padding: 12px 20px;
  border-radius: 12px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.upgrade-link {
  margin-left: auto;
  color: #0056b3;
  text-decoration: none;
  font-weight: 600;
}

.upgrade-link:hover {
  text-decoration: underline;
}

.design-limit {
  margin-left: 8px;
  color: #666;
  font-size: 0.9em;
}

.form.disabled {
  opacity: 0.7;
  pointer-events: none;
}

.design-stats {
  display: flex;
  align-items: center;
  gap: 8px;
}

.edit-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #cccccc;
}

.edit-button:disabled {
  pointer-events: none;
}
</style> 