<template>
  <div id="app">
    <Navbar />
    <div v-if="isLoading" class="loading-spinner">
      ...
    </div>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue'
import { ref } from 'vue'
import { auth } from './firebase'
import { onAuthStateChanged } from 'firebase/auth'

export default {
  name: 'App',
  components: {
    Navbar
  },
  setup() {
    const isLoading = ref(true)

    onAuthStateChanged(auth, () => {
      isLoading.value = false
    })

    return {
      isLoading,
    }
  }
}
</script>

<style>
#app {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

#app::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.loading-spinner {
  display: none;
}
</style>
