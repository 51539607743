<template>
  <div class="gallery-container">
    <!-- 장르 필터 섹션 -->
    <div class="filter-section">
      <!-- 검색바 -->
      <div class="search-bar">
        <i class="fas fa-search"></i>
        <input v-model="searchQuery" :placeholder="uiText.search" />
      </div>
      
      <!-- 필터 토글 버튼 -->
      <div class="filter-toggle" @click="isFilterOpen = !isFilterOpen">
        <div class="filter-header">
          <div class="filter-buttons">
            <button @click.stop="handleResetFilters" class="filter-button">
              <i class="fas fa-rotate"></i> {{ uiText.reset }}
            </button>
          </div>
          <i :class="['fas', isFilterOpen ? 'fa-chevron-up' : 'fa-chevron-down']"></i>
        </div>
      </div>

      <!-- 필터 섹션 -->
      <div class="filters" :class="{ 'filters-open': isFilterOpen }">
        <!-- 지역 필터 -->
        <div class="filter-section">
          <h3 class="filter-title">{{ uiText.locationFilter }}</h3>
          <div class="filter-group location-filters">
            <select v-model="selectedCity" class="filter-select city-select">
              <option value="all">{{ uiText.all }}</option>
              <option v-for="city in places" :key="city" :value="city">
                {{ city }}
              </option>
            </select>
          </div>
        </div>

        <!-- 장르 필터 -->
        <div class="filter-section">
          <h3 class="filter-title">{{ uiText.genreFilter }}</h3>
          <div class="filter-group">
            <select v-model="selectedGenre" class="filter-select genre-select">
              <option value="all">{{ uiText.allGenres }}</option>
              <option v-for="genre in genres" :key="genre" :value="genre">
                {{ translateGenre(genre) }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="masonry-grid">
      <div class="masonry-column">
        <div v-for="portfolio in displayedLeftColumnItems" 
             :key="portfolio.id" 
             class="masonry-item"
             @click="showDetail(portfolio)">
          <div class="portfolio-image">
            <img 
              :src="portfolio.imageUrl" 
              :alt="portfolio.description"
              @load="onImageLoad($event)"
            />
          </div>
        </div>
      </div>
      <div class="masonry-column">
        <div v-for="portfolio in displayedRightColumnItems" 
             :key="portfolio.id" 
             class="masonry-item"
             @click="showDetail(portfolio)">
          <div class="portfolio-image">
            <img 
              :src="portfolio.imageUrl" 
              :alt="portfolio.description"
              @load="onImageLoad($event)"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- 로딩 인디케이터 추가 -->
    <div v-if="isLoading" class="loading-indicator">
      <div class="loading-spinner"></div>
      <p>{{ uiText.loading }}</p>
    </div>

    <!-- 더 이상 로드할 데이터가 없을 때 표시 -->
    <div v-if="noMoreData" class="no-more-data">
      {{ uiText.noMoreData }}
    </div>

    <!-- 포트폴리오 상세 모달 -->
    <transition name="modal">
      <div v-if="selectedPortfolio" class="modal" @click="closeDetail">
        <div class="modal-content" @click.stop>
          <button class="close-btn" @click="closeDetail">
            <i class="fas fa-times"></i>
          </button>

          <div class="modal-body">
            <div class="modal-image-container">
              <img 
                :src="selectedPortfolio.imageUrl" 
                :alt="selectedPortfolio.description"
                class="modal-image"
              />
            </div>

            <div class="modal-info">
              <!-- 타투이스트 정보 -->
              <div class="artist-section">
                <div class="artist-profile" @click="navigateToProfile(selectedPortfolio.userId)">
                  <img 
                    :src="selectedPortfolio.artistProfileUrl || defaultAvatar" 
                    :alt="selectedPortfolio.artistNickname" 
                    class="artist-avatar"
                  />
                  <div class="artist-info">
                    <span class="artist-name">{{ selectedPortfolio.artistNickname }}</span>
                    <span class="artist-role">{{ selectedPortfolio.detailAddress || uiText.noAddress }}</span>
                  </div>
                </div>
              </div>

              <!-- 작품 정보 -->
              <div class="work-info">
                <div class="info-group description">
                  <label>{{ uiText.description }}</label>
                  <p>{{ selectedPortfolio.description || uiText.noDescription }}</p>
                </div>
                
                <div class="info-group">
                  <label>{{ uiText.workTime }}</label>
                  <div class="time-info">
                    {{ selectedPortfolio.timeSpent }} {{ uiText.hours }}
                  </div>
                </div>

                <div class="info-group description">
                  <label>{{ uiText.cost }}</label>
                  <p>{{ selectedPortfolio.price === 'Price on Request' ? uiText.POR : selectedPortfolio.price.toLocaleString() + ' ' + selectedPortfolio.currency }}</p>
                </div>

                <div class="info-group description">
                  <label>{{ uiText.genre }}</label>
                  <p>{{ translateGenre(selectedPortfolio.mainGenre) }}</p>
                </div>
              </div>

              <!-- 프로필 버튼과 문의하기 버튼 -->
              <div class="action-section">
                <button 
                  class="profile-btn"
                  @click="navigateToProfile(selectedPortfolio.userId)"
                >
                  <i class="fas fa-user"></i>
                  {{ uiText.viewProfile }}
                </button>
                <button 
                  class="inquiry-btn"
                  @click="sendPortfolioInquiry(selectedPortfolio)"
                >
                  <i class="fas fa-comments"></i>
                  {{ uiText.sendInquiry }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, nextTick, watch } from 'vue';
import { collection, query, getDocs, where, addDoc, serverTimestamp, doc, getDoc } from 'firebase/firestore';
import { db } from '@/firebase';
import defaultAvatar from '@/assets/default-avatar.png';
import { useRouter } from 'vue-router';
import { getAuth } from 'firebase/auth';
import { incrementPageView } from '@/utils/pageView';

export default {
  name: 'TattooGallery',
  setup() {
    const portfolios = ref([]);
    const searchQuery = ref('');
    const selectedPortfolio = ref(null);
    const selectedGenre = ref('all');
    const selectedCity = ref('all');
    const router = useRouter();
    const loading = ref(true);

    // 검색어 자동 완성을 위한 computed 속성 추가
    const searchSuggestions = computed(() => {
      if (!searchQuery.value) return [];
      
      const search = searchQuery.value.toLowerCase();
      const suggestions = new Set();
      
      // 장르 추천
      genres.forEach(genre => {
        if (genre.toLowerCase().includes(search)) {
          suggestions.add(genre);
        }
      });
      
      // 지역 추천
      places.value.forEach(location => {
        if (location.toLowerCase().includes(search)) {
          suggestions.add(location);
        }
      });
      
      return Array.from(suggestions).slice(0, 5); // 최대 5개까지 추천
    });

    // 장르 목록 정의
    const genres = [
      "Emotional", "Oriental", "Lettering", "Linework", "Blackwork", "Black & Grey",
      "Watercolor", "New School", "Mini Tattoo", "Old School", "Irezumi", "Illustration",
      "Color Tattoo", "Cover Up", "Chicano", "Touch Up", "Tribal", "Anime/Cartoon"
    ];

    // 국가별 도시 매핑
    const countryCityMapping = {
      'United Kingdom': ['London', 'Manchester', 'Birmingham', 'Liverpool', 'Leeds', 'Glasgow', 'Edinburgh', 'Bristol', 'Cardiff', 'Belfast'],
      'Korea': ['Seoul', 'Gyeonggi', 'Incheon', 'Gangwon', 'Chungnam', 'Daejeon', 'Chungbuk', 'Sejong', 'Busan', 'Ulsan', 'Daegu', 'Gyeongbuk', 'Gyeongnam', 'Jeonnam', 'Kwangju', 'Jeonbuk', 'Jeju'],
      'Japan': ['Tokyo', 'Osaka', 'Kyoto', 'Yokohama', 'Nagoya', 'Sapporo', 'Fukuoka', 'Kobe', 'Hiroshima', 'Sendai', 'Okinawa'],
      'USA': ['New York', 'Los Angeles', 'Chicago', 'Houston', 'Phoenix', 'Philadelphia', 'San Antonio', 'San Diego', 'Dallas', 'San Jose', 'Miami', 'Las Vegas', 'Seattle', 'Boston', 'Denver', 'Atlanta', 'Portland', 'San Francisco'],
      'Australia': ['Sydney', 'Melbourne', 'Brisbane', 'Perth', 'Adelaide', 'Gold Coast', 'Newcastle', 'Canberra', 'Sunshine Coast', 'Wollongong', 'Hobart', 'Geelong', 'Townsville', 'Cairns', 'Darwin'],
      'Thailand': ['Bangkok', 'Nonthaburi', 'Nakhon Ratchasima', 'Chiang Mai', 'Hat Yai', 'Udon Thani', 'Pak Kret', 'Pattaya', 'Phuket', 'Songkhla', 'Chonburi', 'Ubon Ratchathani'],
      'Germany': ['Berlin', 'Hamburg', 'Munich', 'Cologne', 'Frankfurt', 'Stuttgart', 'Düsseldorf', 'Leipzig', 'Dortmund', 'Essen', 'Bremen', 'Dresden'],
      'Italy': ['Rome', 'Milan', 'Naples', 'Turin', 'Palermo', 'Genoa', 'Bologna', 'Florence', 'Bari', 'Venice', 'Verona'],
      'Brazil': ['São Paulo', 'Rio de Janeiro', 'Brasília', 'Salvador', 'Fortaleza', 'Belo Horizonte', 'Manaus', 'Curitiba', 'Recife', 'Porto Alegre'],
      'Canada': ['Toronto', 'Montreal', 'Vancouver', 'Calgary', 'Edmonton', 'Ottawa', 'Winnipeg', 'Quebec City', 'Hamilton', 'Halifax'],
      'France': ['Paris', 'Marseille', 'Lyon', 'Toulouse', 'Nice', 'Nantes', 'Strasbourg', 'Montpellier', 'Bordeaux', 'Lille'],
      'Spain': ['Madrid', 'Barcelona', 'Valencia', 'Seville', 'Zaragoza', 'Málaga', 'Murcia', 'Palma', 'Bilbao', 'Alicante'],
      'Netherlands': ['Amsterdam', 'Rotterdam', 'The Hague', 'Utrecht', 'Eindhoven', 'Groningen', 'Tilburg', 'Almere', 'Breda', 'Nijmegen'],
      'Russia': ['Moscow', 'Saint Petersburg', 'Novosibirsk', 'Yekaterinburg', 'Kazan', 'Nizhny Novgorod', 'Chelyabinsk', 'Samara', 'Omsk', 'Rostov-on-Don'],
      'Mexico': ['Mexico City', 'Guadalajara', 'Monterrey', 'Puebla', 'Tijuana', 'León', 'Juárez', 'Zapopan', 'Mérida', 'Cancún'],
      'Sweden': ['Stockholm', 'Gothenburg', 'Malmö', 'Uppsala', 'Västerås', 'Örebro', 'Linköping', 'Helsingborg', 'Jönköping', 'Norrköping']
    };

    // 선택된 국가에 따라 places 설정
    const places = computed(() => {
      const selectedCountry = localStorage.getItem('selectedCountry');
      const countryMapping = {
        kr: 'Korea',
        us: 'USA', 
        uk: 'United Kingdom',
        jp: 'Japan',
        au: 'Australia',
        th: 'Thailand',
        de: 'Germany',
        it: 'Italy',
        br: 'Brazil',
        ca: 'Canada',
        fr: 'France',
        es: 'Spain',
        nl: 'Netherlands',
        ru: 'Russia',
        mx: 'Mexico',
        se: 'Sweden'
      };
      const country = countryMapping[selectedCountry] || 'Korea';
      return countryCityMapping[country] || countryCityMapping['Korea'];
    });

    // Firebase Auth 인스턴스 가져오기
    const auth = getAuth();
    // 현재 사용자 ref 생성
    const currentUser = ref(auth.currentUser);

    // URL에서 country 파라미터 가져오기
    const urlParams = new URLSearchParams(window.location.search);
    const countryCode = urlParams.get('country') || localStorage.getItem('selectedCountry') || 'kr';
    
    // 국가 코드를 데이터베이스 형식으로 변환
    const countryMapping = {
      'kr': 'Korea',
      'us': 'USA',
      'uk': 'UK',
      'jp': 'Japan',
      'au': 'Australia',
      'th': 'Thailand',
      'de': 'Germany',
      'it': 'Italy',
      'br': 'Brazil',
      'ca': 'Canada',
      'fr': 'France',
      'es': 'Spain',
      'nl': 'Netherlands',
      'ru': 'Russia',
      'mx': 'Mexico',
      'se': 'Sweden'
    };

    // 선택된 국가 설정
    const selectedCountry = ref(countryMapping[countryCode]);

    const formatDate = (timestamp) => {
      if (!timestamp) return '';
      const date = timestamp.toDate();
      const now = new Date();
      const diff = now - date;
      
      if (diff < 24 * 60 * 60 * 1000) {
        const hours = Math.floor(diff / (60 * 60 * 1000));
        return `${hours}시간 전`;
      }
      
      return date.toLocaleDateString('ko-KR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }).replace(/\./g, '').split(' ').join('.');
    };

    const formatAddress = (location, country) => {
      if (!location) return '위치 미지정';
      
      if (country?.toLowerCase() === 'korea') {
        const parts = location.split(',').map(part => part.trim());
        // 뒤에서 두 개의 주소 부분만 사용
        if (parts.length >= 2) {
          return parts.slice(-2).join(', ');
        }
      }
      
      return location;
    };

    // 스크롤 관련 상태 추가
    const lastScrollPosition = ref(0);
    const isLoadingMore = ref(false);
    const scrollPosition = ref(0);

    const showDetail = async (portfolio) => {
      scrollPosition.value = window.scrollY;
      window.history.pushState({ modalOpen: true, scrollPosition: scrollPosition.value }, '', '');
      try {
        const userDoc = await getDoc(doc(db, 'users', portfolio.userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          selectedPortfolio.value = {
            ...portfolio,
            artistProfileUrl: userData.profileImageUrl,
            artistNickname: userData.nickname,
            detailAddress: formatAddress(userData.location?.address, userData.country) || '주소 정보가 없습니다.',
            city: userData.location?.city
          };
        } else {
          selectedPortfolio.value = {
            ...portfolio,
            artistProfileUrl: defaultAvatar,
            artistNickname: '알 수 없음',
            detailAddress: '주소 정보가 없습니다.'
          };
        }
      } catch (error) {
        console.error('타투이스트 정보 로드 실패:', error);
        selectedPortfolio.value = {
          ...portfolio,
          artistProfileUrl: defaultAvatar,
          artistNickname: '알 수 없음',
          detailAddress: '주소 정보를 불러올 수 없습니다.'
        };
      }
    };

    const closeDetail = () => {
      selectedPortfolio.value = null;
      window.history.back();
    };

    window.addEventListener('popstate', (event) => {
      if (event.state && event.state.modalOpen) {
        window.scrollTo(0, event.state.scrollPosition);
      } else {
        window.scrollTo(0, scrollPosition.value);
      }
    });

    const navigateToProfile = (userId) => {
      if (!userId) {
        console.error('User ID is undefined');
        return;
      }
      router.push(`/tattooist/${userId}`);
    };

    const leftColumnItems = computed(() => {
      const items = filteredPortfolios.value.slice(0, Math.ceil(filteredPortfolios.value.length / 2));
      return items;
    });

    const rightColumnItems = computed(() => {
      const items = filteredPortfolios.value.slice(Math.ceil(filteredPortfolios.value.length / 2));
      return items;
    });

    const onImageLoad = (event) => {
      const img = event.target;
      const naturalRatio = img.naturalHeight / img.naturalWidth;
      const width = img.width;
      const height = width * naturalRatio;
      
      // 이미지 높이에 맞게 컨테이너 조정
      img.parentElement.style.height = `${height}px`;
    };

    const sendPortfolioInquiry = async (portfolio) => {
      try {
        // 로그인 체크
        if (!currentUser.value) {
          alert('로그인이 필요한 서비스입니다.');
          router.push('/login');
          return;
        }

        // 자기 자신과의 채팅 방지
        if (currentUser.value.uid === portfolio.userId) {
          alert('자신과는 채팅할 수 없습니다.');
          return;
        }

        // 1. 채팅방 생성 또는 기존 채팅방 찾기
        const chatRoomsRef = collection(db, 'chatRooms');
        const q = query(
          chatRoomsRef,
          where('participants', 'array-contains', currentUser.value.uid),
        );
        const querySnapshot = await getDocs(q);
        
        let roomId;
        let existingRoom = querySnapshot.docs.find(doc => 
          doc.data().participants.includes(portfolio.userId)
        );

        if (existingRoom) {
          roomId = existingRoom.id;
        } else {
          // 새 채팅방 생성
          const newRoomRef = await addDoc(chatRoomsRef, {
            participants: [currentUser.value.uid, portfolio.userId],
            createdAt: serverTimestamp(),
            lastMessage: {
              content: '새로운 대화가 시작되었습니다.',
              timestamp: serverTimestamp()
            }
          });
          roomId = newRoomRef.id;
        }

        // 2. 포트폴리오 이미지를 메시지로 전송
        const messagesRef = collection(db, 'chatRooms', roomId, 'messages');
        await addDoc(messagesRef, {
          type: 'image',
          images: [portfolio.imageUrl],
          content: '포트폴리오 작품에 대해 문의드립니다.',
          senderId: currentUser.value.uid,
          timestamp: serverTimestamp()
        });

        // 3. 채팅방으로 이동
        router.push(`/chat/${roomId}`);
        closeDetail();

      } catch (error) {
        console.error('문의하기 실패:', error);
        alert('문의하기에 실패했습니다. 다시 시도해주세요.');
      }
    };

    // Auth 상태 변경 감지
    onMounted(() => {
      auth.onAuthStateChanged((user) => {
        currentUser.value = user;
      });

      loadPortfolios();

      // 무한 스크롤을 위한 스크롤 이벤트 리스너 추가
      window.addEventListener('scroll', () => {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 500 && !loading.value) {
          loadMoreItems();
        }
      });

      // popstate 이벤트 핸들러
      window.addEventListener('popstate', () => {
        if (selectedPortfolio.value) {
          selectedPortfolio.value = null;
          // 스크롤 위치 복원
          nextTick(() => {
            window.scrollTo({
              top: scrollPosition.value,
              behavior: 'instant'
            });
          });
        }
      });

      // 페이지 뷰 증가 - async/await 제거
      incrementPageView('TattooGallery').catch(error => {
        console.error('페이지뷰 업데이트 실패:', error);
      });
    });

    onUnmounted(() => {
      window.removeEventListener('popstate', () => {});
    });

    const isFilterOpen = ref(false);

    const resetPagination = () => {
      currentPage.value = 1;
      noMoreData.value = false;
    };

    const handleResetFilters = () => {
      selectedCity.value = 'all';
      selectedGenre.value = 'all';
      searchQuery.value = '';
      resetPagination();
    };

    // 장르 매핑 추가
    const genreMapping = {
      "Emotional": {
        ko: "감성타투",
        jp: "感情的",
        en: "Emotional"
      },
      "Oriental": {
        ko: "동양화",
        jp: "東洋",
        en: "Oriental"
      },
      "Lettering": {
        ko: "레터링",
        jp: "レタリング",
        en: "Lettering"
      },
      "Linework": {
        ko: "라인워크",
        jp: "ラインワーク",
        en: "Linework"
      },
      "Blackwork": {
        ko: "블랙워크",
        jp: "ブラックワーク",
        en: "Blackwork"
      },
      "Black & Grey": {
        ko: "블랙 & 그레이",
        jp: "ブラック＆グレー",
        en: "Black & Grey"
      },
      "Watercolor": {
        ko: "수채화",
        jp: "水彩画",
        en: "Watercolor"
      },
      "New School": {
        ko: "뉴스쿨",
        jp: "ニュースクール",
        en: "New School"
      },
      "Mini Tattoo": {
        ko: "미니타투",
        jp: "ミニタトゥー",
        en: "Mini Tattoo"
      },
      "Old School": {
        ko: "올드스쿨",
        jp: "オールドスクール",
        en: "Old School"
      },
      "Irezumi": {
        ko: "이레즈미",
        jp: "入れ墨",
        en: "Irezumi"
      },
      "Illustration": {
        ko: "일러스트",
        jp: "イラスト",
        en: "Illustration"
      },
      "Color Tattoo": {
        ko: "컬러타투",
        jp: "カラータトゥー",
        en: "Color Tattoo"
      },
      "Cover Up": {
        ko: "커버업",
        jp: "カバーアップ",
        en: "Cover Up"
      },
      "Chicano": {
        ko: "치카노",
        jp: "チカーノ",
        en: "Chicano"
      },
      "Touch Up": {
        ko: "터치업",
        jp: "タッチアップ",
        en: "Touch Up"
      },
      "Tribal": {
        ko: "트라이벌",
        jp: "トライバル",
        en: "Tribal"
      },
      "Anime/Cartoon": {
        ko: "애니/만화",
        jp: "アニメ/コミック",
        en: "Anime/Cartoon"
      }
    };

    // 장르 번역 함수 추가
    const translateGenre = (genre) => {
      const selectedLanguage = localStorage.getItem('selectedLanguage') || 'en';
      if (genreMapping[genre] && genreMapping[genre][selectedLanguage]) {
        return genreMapping[genre][selectedLanguage];
      }
      return genre;
    };

    // translations 객체 수정
    const translations = {
      en: {
        search: 'Search tattoo',
        reset: 'Reset',
        myLocation: 'My Location',
        locationFilter: 'Location Filter',
        all: 'All',
        genreFilter: 'Genre Filter',
        allGenres: 'All Genres',
        description: 'Description',
        noDescription: 'No description available',
        workTime: 'Work Time',
        hours: 'hours',
        cost: 'Cost',
        POR: 'Price on Request',
        genre: 'Genre',
        viewProfile: 'View Profile',
        sendInquiry: 'Send Inquiry',
        noAddress: 'No address information',
        genres: Object.fromEntries(
          Object.entries(genreMapping).map(([key, value]) => [key, value.en])
        )
      },
      ko: {
        search: '타투 검색',
        reset: '초기화',
        myLocation: '내 위치',
        locationFilter: '지역 필터',
        all: '전체',
        genreFilter: '장르 필터',
        allGenres: '전체 장르',
        description: '설명',
        noDescription: '설명이 없습니다.',
        workTime: '작업 시간',
        hours: '시간',
        cost: '작업 비용',
        POR: '문의',
        genre: '장르',
        viewProfile: '프로필 보기',
        sendInquiry: '문의하기',
        noAddress: '주소 정보가 없습니다.',
        genres: Object.fromEntries(
          Object.entries(genreMapping).map(([key, value]) => [key, value.ko])
        )
      },
      jp: {
        search: 'タトゥー検索',
        reset: 'リセット',
        myLocation: '現在地',
        locationFilter: '地域フィルター',
        all: '全て',
        genreFilter: 'ジャンルフィルター',
        allGenres: '全て',
        description: '説明',
        noDescription: '説明がありません',
        workTime: '施術時間',
        hours: '時間',
        cost: '料金',
        POR: 'お問い合わせ',
        genre: 'ジャンル',
        viewProfile: 'プロフィール',
        sendInquiry: '問い合わせ',
        noAddress: '住所情報がありません',
        genres: Object.fromEntries(
          Object.entries(genreMapping).map(([key, value]) => [key, value.jp])
        )
      }
    };

    // 언어 설정
    const selectedLanguage = ref(localStorage.getItem('selectedLanguage') || 'ko');
    const uiText = ref(translations[selectedLanguage.value]);

    // 필터링된 포트폴리오
    const filteredPortfolios = computed(() => {
      if (!portfolios.value || portfolios.value.length === 0) {
        return [];
      }

      let result = [...portfolios.value];

      // 검색어 필터링
      if (searchQuery.value?.trim()) {
        const query = searchQuery.value.toLowerCase().trim();
        result = result.filter(portfolio => {
          // 기본 필드 검색
          const matchDescription = portfolio.description?.toLowerCase().includes(query);
          const matchGenre = portfolio.mainGenre?.toLowerCase().includes(query);
          const matchNickname = portfolio.artistNickname?.toLowerCase().includes(query);
          const matchTags = portfolio.tags?.some(tag => tag.toLowerCase().includes(query));
          
          // 추가 필드 검색
          const matchSubGenres = portfolio.subGenres?.some(genre => genre.toLowerCase().includes(query));
          const matchStyle = portfolio.style?.toLowerCase().includes(query);
          const matchTitle = portfolio.title?.toLowerCase().includes(query);
          
          // 지역 검색 - address에서 검색
          const matchCityInAddress = portfolio.detailAddress?.toLowerCase().includes(searchQuery.value.toLowerCase());

          return matchDescription || matchGenre || matchNickname || matchTags || 
                 matchSubGenres || matchStyle || matchTitle || matchCityInAddress;
        });
      }

      // 장르 필터링
      if (selectedGenre.value && selectedGenre.value !== 'all' && selectedGenre.value !== '전체') {
        result = result.filter(portfolio => {
          const mainGenreMatch = portfolio.mainGenre === selectedGenre.value;
          const subGenreMatch = portfolio.subGenres?.includes(selectedGenre.value);
          return mainGenreMatch || subGenreMatch;
        });
      }

      // 지역 필터링 - 선택한 도시가 주소에 포함되는지 확인
      if (selectedCity.value && selectedCity.value !== 'all') {
        const selectedCityLower = selectedCity.value.toLowerCase();

        result = result.filter(portfolio => {
          const detailAddressLower = portfolio.detailAddress?.toLowerCase();
          
          // detailAddressLower가 정의되어 있는지 확인
          const isIncluded = detailAddressLower && detailAddressLower.includes(selectedCityLower);
          return isIncluded;
        });
      }

      return result;
    });

    // 무한 스크롤 관련 상태 변수들
    const currentPage = ref(1);
    const itemsPerPage = ref(10);
    const isLoading = ref(false);
    const noMoreData = ref(false);

    // 페이지네이션된 포트폴리오 계산
    const paginatedPortfolios = computed(() => {
      const startIndex = 0;
      const endIndex = currentPage.value * itemsPerPage.value;
      return filteredPortfolios.value.slice(startIndex, endIndex);
    });

    // loadPortfolios 함수 수정
    const loadPortfolios = async () => {
      try {
        loading.value = true;

        const usersRef = collection(db, 'users');
        const tattooistQuery = query(
          usersRef,
          where('userType', '==', 'tattooist'),
          where('country', '==', selectedCountry.value)
        );

        const tattooistSnapshot = await getDocs(tattooistQuery);
        const usersData = tattooistSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        const tattooistIds = usersData.map(user => user.id);

        if (tattooistIds.length === 0) {
          portfolios.value = [];
          return;
        }

        const userAddressMap = {};
        usersData.forEach(user => {
          userAddressMap[user.id] = user.location?.address || '주소 정보가 없습니다.';
        });

        const portfoliosRef = collection(db, 'portfolios');
        let portfoliosData = [];

        const portfolioPromises = tattooistIds.map(async (userId) => {
          const portfolioQuery = query(portfoliosRef, where('userId', '==', userId));
          const snapshot = await getDocs(portfolioQuery);
          snapshot.forEach(doc => {
            const data = doc.data();
            portfoliosData.push({
              id: doc.id,
              ...data,
              detailAddress: userAddressMap[userId] || '주소 정보가 없습니다.'
            });
          });
        });

        await Promise.all(portfolioPromises);
        
        // 포트폴리오 섞기
        portfolios.value = [...portfoliosData].sort(() => Math.random() - 0.5);
        
        // 초기 페이지 설정
        currentPage.value = 1;
        noMoreData.value = false;

      } finally {
        loading.value = false;
      }
    };

    // 수정된 컬럼 아이템 계산 로직
    const displayedLeftColumnItems = computed(() => {
      return paginatedPortfolios.value.filter((_, index) => index % 2 === 0);
    });

    const displayedRightColumnItems = computed(() => {
      return paginatedPortfolios.value.filter((_, index) => index % 2 === 1);
    });

    // 수정된 로드 더 보기 함수
    const loadMoreItems = async () => {
      try {
        if (isLoading.value || noMoreData.value || isLoadingMore.value) return;
        
        isLoadingMore.value = true;
        isLoading.value = true;
        
        // 현재 스크롤 위치 저장
        lastScrollPosition.value = window.scrollY;
        
        const totalItems = filteredPortfolios.value.length;
        const currentlyLoadedItems = currentPage.value * itemsPerPage.value;
        
        if (currentlyLoadedItems >= totalItems) {
          noMoreData.value = true;
          return;
        }

        // 다음 페이지 로드
        currentPage.value++;
        
        // 새 데이터가 렌더링될 때까지 잠시 대기
        await nextTick();
        
        // 이전 스크롤 위치로 복원
        window.scrollTo({
          top: lastScrollPosition.value,
          behavior: 'instant'
        });

        // 디바운스를 위한 짧은 지연
        await new Promise(resolve => setTimeout(resolve, 100));
        
      } catch (error) {
        console.error('Failed to load more items:', error);
      } finally {
        isLoading.value = false;
        isLoadingMore.value = false;
      }
    };

    // 개선된 스크롤 핸들러
    const handleScroll = async () => {
      if (isLoadingMore.value) return;

      const scrollPosition = window.innerHeight + window.scrollY;
      const documentHeight = document.documentElement.offsetHeight;
      const threshold = 200;

      // 스크롤이 하단에 도달했을 때만 로드
      if (scrollPosition >= documentHeight - threshold) {
        await loadMoreItems();
      }
    };

    // 스크롤 핸들러에 디바운스 적용
    const debouncedHandleScroll = debounce(handleScroll, 200);

    // 디바운스 유틸리티 함수
    function debounce(func, wait) {
      let timeout;
      return function executedFunction(...args) {
        const later = () => {
          clearTimeout(timeout);
          func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
      };
    }

    onMounted(() => {
      window.addEventListener('scroll', debouncedHandleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', debouncedHandleScroll);
    });

    // 필터 변경 시 페이지네이션 리셋
    watch([selectedCity, selectedGenre, searchQuery], resetPagination);

    return {
      portfolios,
      searchQuery,
      selectedGenre,
      genres,
      filteredPortfolios,
      selectedPortfolio,
      defaultAvatar,
      loading,
      formatDate,
      showDetail,
      closeDetail,
      navigateToProfile,
      leftColumnItems,
      rightColumnItems,
      onImageLoad,
      selectedCity,
      places,
      searchSuggestions,
      selectedCountry,
      uiText,
      formatAddress,
      displayedLeftColumnItems,
      displayedRightColumnItems,
      handleResetFilters,
      isLoading,
      noMoreData,
      handleScroll: debouncedHandleScroll,
      loadMoreItems,
      sendPortfolioInquiry,
      isFilterOpen,
      scrollPosition,
      translateGenre,
    };
  }
}
</script>

<style scoped>
.gallery-container {
  margin: 0 auto;
  margin-top: 70px;
}

.masonry-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-top: 24px;
}

.masonry-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.masonry-item {
  width: 100%;
  break-inside: avoid;
  cursor: pointer;
}

.portfolio-image {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
  background: #f5f5f5;
}

.portfolio-image img {
  width: 100%;
  height: auto;
  display: block;
}

.filter-section {
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
}

.search-bar {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 16px;
  border: 1px solid #ddd;
  border-radius: 12px;
  background: white;
  margin-bottom: 10px;
}

.search-bar input {
  border: none;
  outline: none;
  font-size: 14px;
  width: 100%;
}

.search-bar i {
  color: #666;
}

.filter-toggle {
  user-select: none;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.filter-buttons {
  display: flex;
  gap: 8px;
}

.filter-button {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background: white;
  font-size: 14px;
  cursor: pointer;
}

.filter-button:hover {
  background: #f8f9fa;
}

.filters {
  background: white;
  padding: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
  opacity: 0;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.filters-open {
  padding: 0 16px 16px;
  max-height: 500px;
  opacity: 1;
}

.filter-title {
  font-size: 14px;
  margin-bottom: 8px;
}

.filter-group {
  display: flex;
  gap: 12px;
}

.filter-select {
  flex: 1;
  padding: 12px;
  border: 1px solid #eee;
  border-radius: 12px;
  font-size: 14px;
  color: #333;
  background-color: #fff;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='%23333' d='M6 8L1 3h10z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 16px center;
  transition: all 0.2s ease;
}

.filter-select:focus {
  border-color: #666;
  outline: none;
}

/* 기존 스타일과 충돌할 수 있는 부분 제거 */
.genre-filter,
.location-filter {
  display: none;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  max-height: 100vh;
  overflow-y: auto;
  position: absolute;
  top: 0;
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
}

.modal-body {
  display: flex;
  height: auto;
  width: 99%;
}

.modal-image-container {
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.modal-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.modal-info {
  width: 400px;
  background: white;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.artist-section {
  padding: 10px;
  border-bottom: 1px solid #eee;
  background-color: #fefefe;
}

.artist-profile {
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  border-radius: 12px;
  transition: background-color 0.2s;
}

.artist-avatar {
  width: 50px;
  height: 50px;
  border-radius: 25%;
  object-fit: cover;
  border: 2px solid #ccc;
}

.artist-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.artist-name {
  font-size: 1.1rem;
  font-weight: 600;
  color: #333;
}

.artist-role {
  font-size: 0.9rem;
  color: #666;
}

.work-info {
  flex: 1;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.info-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.info-group label {
  font-size: 0.9rem;
  color: #666;
  font-weight: 500;
}

.style-tag {
  display: inline-flex;
  padding: 8px 16px;
  background: #f5f5f5;
  border-radius: 20px;
  font-size: 0.95rem;
  color: #333;
  align-items: center;
  width: fit-content;
}

.time-info {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #333;
}

.description p {
  margin: 0;
  line-height: 1.6;
  color: #333;
}

.action-section {
  display: flex;
  gap: 10px;
  margin-top: 16px;
}

.profile-btn,
.inquiry-btn {
  flex: 1;
  padding: 12px;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.profile-btn {
  background: #f5f5f5;
  color: #333;
}

.inquiry-btn {
  background: #1a1a1a;
  color: white;
}

@media (max-width: 768px) {
  .modal-body {
    flex-direction: column;
  }

  .modal-info {
    width: 100%;
    height: 60%;
    overflow-y: auto;
  }
}

/* 지역 필터 스타일 추가 */
.location-filter {
  margin-bottom: 8px;
}

.location-info {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #333;
}

.location-info i {
  color: #666;
}

/* 로딩 스피너 스타일 */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #333;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 15px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* 기존 스타일에 추가 */
.location-filters {
  display: flex;
  gap: 8px;
}

.city-select,
.district-select {
  flex: 1;
}

/* 모달 진입/진출 애니메이션 */
.modal-enter-active,
.modal-leave-active {
  transition: all 0.3s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
  transform: translateY(50px);
}

.modal-enter-to,
.modal-leave-from {
  opacity: 1;
  transform: translateY(0);
}

.loading-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-top: 20px;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #333;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.no-more-data {
  text-align: center;
  padding: 20px;
  color: #666;
  font-size: 14px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
  
  