<template>
  <div class="guest-my-page">
    <div class="profile-section">
      <h2>{{ userInfo.displayName }}님 환영합니다</h2>
    </div>

    <div class="menu-grid">
      <!-- 예약 관련 -->
      <div class="menu-item" @click="goToReservations">
        <span class="material-icons">calendar_today</span>
        <p>예약 현황</p>
      </div>

      <!-- 리뷰 관련 -->
      <div class="menu-item" @click="deleteAccount">
        <span class="material-icons">person_remove</span>
        <p>회원탈퇴</p>
      </div>

      <!-- 채팅 목록 -->
      <div class="menu-item" @click="goToChatList">
        <span class="material-icons">chat</span>
        <p>채팅 목록</p>
      </div>

      <!-- 즐겨찾기 -->
      <div class="menu-item" @click="goToFavorites">
        <span class="material-icons">favorite</span>
        <p>즐겨찾기</p>
      </div>
    </div>

    <div class="action-buttons">      
      <button class="logout-button" @click="handleLogout">
        <span class="material-icons">logout</span>
        로그아웃
      </button>
    </div>

    <div v-if="userInfo.role === 'inkers112'" class="admin-access">
      <button @click="promptAdminAccess">관리자 페이지</button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { signOut } from 'firebase/auth';
import { auth, db } from '@/firebase';
import { doc, getDoc, deleteDoc } from 'firebase/firestore';
import { useRouter } from 'vue-router';

export default {
  name: "GuestMyPage",
  setup() {
    const router = useRouter();
    const userInfo = ref({
      displayName: '',
      role: '',
    });

    const loadUserInfo = async () => {
      try {
        const userId = auth.currentUser?.uid;
        if (!userId) {
          router.push('/login');
          return;
        }

        // Firestore에서 사용자 정보 가져오기
        const userDocRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userDocRef);
        
        if (userDoc.exists()) {
          const userData = userDoc.data();
          userInfo.value.displayName = userData.name || '게스트';
          userInfo.value.role = userData.role || '';
        } else {
          console.log('사용자 문서가 없습니다.');
          userInfo.value.displayName = '게스트';
        }
      } catch (error) {
        console.error('사용자 정보 로드 실패:', error);
        userInfo.value.displayName = '게스트';
      }
    };

    onMounted(() => {
      loadUserInfo();
    });

    const goToReservations = () => {
      router.push('/reservations');
    };

    const deleteAccount = async () => {
      try {
        const userId = auth.currentUser?.uid;
        const confirmation = confirm('정말로 회원탈퇴를 하시겠습니까?');
        if (!confirmation) {
          return;
        }

        // Firestore에서 사용자 문서 삭제
        const userDocRef = doc(db, 'users', userId);
        await deleteDoc(userDocRef);

        // Firebase Auth에서 사용자 삭제
        await auth.currentUser.delete();

        alert('회원탈퇴가 완료되었습니다.');
        router.push('/');
      } catch (error) {
        console.error('회원탈퇴 실패:', error);
        alert('회원탈퇴 실패: ' + error.message);
      }
    };

    const goToChatList = () => {
      router.push('/chat');
    };

    const goToFavorites = async () => {
      router.push('/guestfavorite');
    };

    const handleLogout = async () => {
      try {
        await signOut(auth);
        alert('로그아웃되었습니다');
        router.push('/');
      } catch (error) {
        alert('로그아웃 실패: ' + error.message);
      }
    };

    const promptAdminAccess = () => {
      const password = prompt("비밀번호를 입력하세요:");
      if (password === "0910") {
        router.push('/admin');
      } else {
        alert("비밀번호가 틀렸습니다.");
      }
    };

    return {
      userInfo,
      goToReservations,
      goToChatList,
      goToFavorites,
      handleLogout,
      deleteAccount,
      promptAdminAccess,
    };
  }
};
</script>

<style scoped>
.guest-my-page {
  padding: 20px;
  margin: 60px auto;
}

.profile-section {
  text-align: center;
  margin-bottom: 30px;
}

.menu-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

.menu-item {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.menu-item p {
  margin: 0;
}

.menu-item .material-icons {
  font-size: 30px;
  margin-bottom: 10px;
}

.action-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.action-buttons button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.logout-button {
  background-color: #f44336;
  color: white;
}

.logout-button:hover {
  background-color: #d32f2f;
}

.material-icons {
  font-size: 20px;
}

.admin-access {
  margin-top: 20px;
  text-align: center;
}

.admin-access button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.admin-access button:hover {
  background-color: #45a049;
}
</style>
