<template>
  <div class="explore-container">
    <!-- 목록이 있을 때 -->
    <div v-if="posts.length > 0" class="explore-grid">
      <article v-for="post in posts" :key="post.id" class="post-card" @click="gotostudio(post.id)">
        <div class="post-image">
          <img :src="post.images[0]" :alt="post.title">
        </div>
        <div class="post-content">
          <h3 class="post-title">{{ post.title }}</h3>
          <div class="post-meta">
            <div class="author">
              <img :src="post.profileUrl" :alt="post.nickname" class="author-avatar">
              <span class="author-name">{{ post.nickname }}</span>
            </div>
            <div class="post-stats">
              <span class="date">
                {{ formatDate(post.createdAt) }}
              </span>
            </div>
          </div>
        </div>
      </article>
    </div>

    <!-- 목록이 없을 때 -->
    <div v-else class="no-posts">
      <i class="fas fa-store"></i>
      <p>등록된 모집 공고가 없습니다.</p>
      <p class="sub-text">첫 번째 모집 공고를 등록해보세요!</p>
    </div>

    <!-- Add Post Button -->
    <button class="add-post-button" @click="router.push('/studio/create')">
      <i class="fas fa-plus"></i>
      모집 공고
    </button>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { collection, getDocs, query, orderBy } from 'firebase/firestore'
import { db } from '@/firebase.js' // Firebase 초기화 파일 경로에 맞게 수정해주세요

const router = useRouter()
const posts = ref([])

// Firestore에서 데이터 가져오기
const fetchPosts = async () => {
  try {
    const studiosRef = collection(db, 'studios')
    const q = query(studiosRef, orderBy('createdAt', 'desc'))
    const querySnapshot = await getDocs(q)
    
    posts.value = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt?.toDate() // Timestamp를 Date 객체로 변환
    }))
  } catch (error) {
    console.error('Error fetching posts:', error)
  }
}

// 날짜 포맷팅 함수
const formatDate = (date) => {
  if (!date) return ''
  return new Date(date).toLocaleDateString('ko-KR', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })
}

const gotostudio = (id) => {
  router.push(`/studio/${id}`)
}

onMounted(() => {
  fetchPosts()
})
</script>

<style scoped>
.explore-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 60px;
}

.explore-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
}

.post-card {
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  cursor: pointer;
}

.post-image img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.post-content {
  padding: 16px;
}

.post-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 8px;
}

.post-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.author {
  display: flex;
  align-items: center;
  gap: 8px;
}

.author-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.author-name {
  font-size: 0.9rem;
  font-weight: 500;
}

.post-stats {
  display: flex;
  gap: 12px;
  font-size: 0.9rem;
  color: #666;
}

.date {
  font-size: 0.9rem;
  color: #666;
}

.add-post-button {
  position: fixed;
  bottom: 3%;
  right: 3%;
  z-index: 100;
  background: #007bff;
  color: white;
  border: none;
  padding: 14px 26px;
  border-radius: 30px;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.3);
  align-items: center;
}

.add-post-button i {
  font-size: 0.8rem;
}

.no-posts {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 20px;
  text-align: center;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin: 40px auto;
  max-width: 500px;
}

.no-posts i {
  font-size: 48px;
  color: #adb5bd;
  margin-bottom: 16px;
}

.no-posts p {
  margin: 8px 0;
  color: #495057;
  font-size: 1.2rem;
  font-weight: 500;
}

.no-posts .sub-text {
  color: #868e96;
  font-size: 1rem;
  font-weight: normal;
}
</style> 