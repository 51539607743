<template>
  <div class="profile-container" v-if="!loading">
    <div class="profile-header">
      <div class="cover-photo">
        <div class="cover-overlay" :style="coverPhotoStyle"></div>
        <div class="awards-container" v-if="awards.length > 0">
          <div v-for="award in activeAwards" :key="award.id" class="award-badge" @click="toggleTooltip(award)">
            <div v-if="selectedAward === award" class="award-tooltip">
              {{ award.name }}
              <div class="tooltip-arrow"></div>
            </div>
            <img :src="require(`@/assets/award${award.icon}.png`)" :alt="award.name">
          </div>
        </div>
      </div>
      <div class="profile-picture-wrapper">
        <div class="profile-picture">
          <img :src="profile.profileImageUrl" alt="Profile" />
        </div>
      </div>
    </div>

    <div class="profile-content">
      <div class="profile-top">
        <div class="profile-info">
          <h1>{{ profile.nickname }}</h1>
          <p class="nickname" v-if="profile.instagramId">@{{ profile.instagramId }}</p>
        </div>
        <div class="action-buttons">
          <div class="message-btn-container">
            <div class="message-tooltip" v-if="showTooltip">
              <p>{{ profile.nickname }}</p>{{ profile.welcome || 'Thanks for visiting profile.\nIs there any tattoo you want?' }}
              <button class="tooltip-close" @click.stop="showTooltip = false">×</button>
            </div>
            <div class="message-new" />
            <button class="message-btn" :style="{ backgroundImage: `url(${profile.profileImageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }" @click="handleChatClick" />
          </div>
          <button class="follow-btn" @click="toggleFavorite" :class="{ 'is-favorite': isFavorite }">
            <i :class="[isFavorite ? 'fas' : 'fa-regular', 'fa-heart']"></i>
          </button>
        </div>
      </div>

      <div class="profile-details">
        <div class="location" v-if="profile.location?.address">
          <i class="fas fa-location-dot"></i>
          <span>{{ formatAddress(profile.location.address, profile.country) }}</span>
        </div>
        <div class="rate-container">
          <div class="rate" v-if="profile?.minRate !== 'Price on Request'">
            <i class="fas fa-won-sign"></i>
            <span>{{ translateModal('mincost') }}: {{ profile?.minRate?.toLocaleString() || 0 }} {{ profile?.currency }}</span>
          </div>
          <div class="rate" v-if="profile?.hourlyRate !== 'Price on Request'">
            <i class="fas fa-clock"></i>
            <span>{{ translateModal('hourcost') }}: {{ profile?.hourlyRate?.toLocaleString() || 0 }} {{ profile?.currency }}</span>
          </div>
        </div>
      </div>

      <div class="social-links">
        <a v-for="link in multiLinks" :key="link.id" :href="link.url" target="_blank" class="social-link">
          <i class="fas fa-link"></i>
          {{ link.title }}
        </a>
      </div>

      <p class="introduction">{{ profile.introduce }}</p>

      <div class="notice-container">
        <a v-for="notice in notices" :key="notice.id" @click="openNoticeModal(notice)" class="notice-text">
          {{ notice.title }}
        </a>
      </div>

      <div class="tags">
        <span class="tag">#{{ translateGenre(profile.mainGenre) }}</span>
        <span v-for="genre in profile.subGenres" :key="genre" class="tag">
          #{{ translateGenre(genre) }}
        </span>
        <span v-for="service in profile.service" :key="service" class="tag">
          #{{ translateService(service) }}
        </span>
      </div>

      <div class="gallery-section">
        <div class="gallery-tabs">
          <button v-for="tab in tabs" :key="tab.value" @click="currentTab = tab.value" :class="['tab-btn', { active: currentTab === tab.value }]">
            {{ tab.label }}
          </button>
        </div>

        <div class="gallery-grid" v-if="currentGalleryItems.length > 0">
          <div v-for="item in currentGalleryItems" :key="item.id" class="gallery-item" @click="openModal(item)">
            <img :src="item.imageUrl" :alt="item.description" />
          </div>
        </div>
        <div v-else class="no-data-message">
          No {{ currentTab === 'designs' ? 'designs' : 'portfolios' }} available.
        </div>
      </div>
    </div>
  </div>
  <div v-else class="loading">
    Loading...
  </div>

  <!-- 이미지 모달 -->
  <transition name="modal">
    <div v-if="selectedImage" class="modal-overlay" @click="closeModal">
      <div class="modal-content" @click.stop>
        <button class="modal-close" @click="closeModal">×</button>
        <img :src="selectedImage.imageUrl" :alt="selectedImage.description" class="modal-image" />
        <div class="modal-info">
          <p class="modal-description">{{ selectedImage.description }}</p>
          <p class="modal-price">
            {{ translateModal('cost') }} : 
            {{ selectedImage.price === 'Price on Request' ? 
               translateModal('por') : 
               selectedImage.price + ' ' + profile.currency }}
          </p>
          <p class="modal-time">
            {{ translateModal('timeSpent') }}: 
            {{ selectedImage.timeSpent }} 
            {{ translateModal(selectedImage.timeSpent === 1 ? 'hour' : 'hours') }}
          </p>
          <p class="modal-time">
            {{ translateModal('genre') }}: {{ translateGenre(selectedImage.mainGenre) }}
          </p>
        </div>
        <button class="inquiry-btn" @click="sendPortfolioInquiry(selectedImage)">
          <i class="fas fa-comments"></i>
          {{ translateModal('chat') }}
        </button>
      </div>
    </div>
  </transition>

  <!-- 공지사항 모달 수정 -->
  <transition name="modal">
    <div v-if="selectedNotice" class="modal-overlay" @click="closeNoticeModal">
      <div class="modal-content notice-modal" @click.stop>
        <button class="modal-close" @click="closeNoticeModal">×</button>
        <div class="notice-modal-content">
          <h2>{{ selectedNotice.title }}</h2>
          <p>{{ selectedNotice.content }}</p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { ref, computed, onMounted } from 'vue'
import { db } from '@/firebase'
import { doc, getDoc, setDoc, deleteDoc, collection, query, where, getDocs, orderBy, addDoc } from 'firebase/firestore'
import { useRouter, onBeforeRouteLeave, useRoute } from 'vue-router'
import { getAuth } from 'firebase/auth'
import { incrementPageView } from '@/utils/pageView'

export default {
  name: 'TattooArtistProfile',
  props: {
    id: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const router = useRouter()
    const route = useRoute()
    const userId = props.id || router .params.id
    const currentTab = ref('portfolio')
    const profile = ref({})
    const loading = ref(true)
    const designs = ref([])
    const portfolios = ref([])
    const randomPortfolio = ref(null)
    const multiLinks = ref([])
    const notices = ref([])
    const themeColors = ref({
      background: '#000000',
      nickname: '#ffffff', 
      instagramId: '#cccccc',
      infoDetail: '#cccccc',
      link: '#ffffff',
      introduce: '#ffffff',
      noticebox: '#ffffff',
      noticetext: '#cccccc',
      tagbox: '#ffffff',
      tagtext: '#000000',
      galleryButton: '#ffffff',
      galleryButtonText: '#000000'
    })
    const selectedImage = ref(null)
    const auth = getAuth()
    const isFavorite = ref(false)
    const selectedNotice = ref(null)
    const showTooltip = ref(true)
    const awards = ref([])
    const selectedAward = ref(null)
    
    const translations = {
      en: {
        genres: {
          "Emotional": "Emotional",
          "Oriental": "Oriental",
          "Lettering": "Lettering",
          "Linework": "Linework",
          "Blackwork": "Blackwork",
          "Black & Grey": "Black & Grey",
          "Watercolor": "Watercolor",
          "New School": "New School",
          "Mini Tattoo": "Mini Tattoo",
          "Old School": "Old School",
          "Irezumi": "Irezumi",
          "Illustration": "Illustration",
          "Color Tattoo": "Color Tattoo",
          "Cover Up": "Cover Up",
          "Chicano": "Chicano",
          "Touch Up": "Touch Up",
          "Tribal": "Tribal",
          "Anime/Cartoon": "Anime/Cartoon"
        },
        services: {
          "Card Payment": "Card Payment",
          "Parking Available": "Parking Available",
          "Installment Payment": "Installment Payment",
          "Female Tattooist": "Female Tattooist",
          "Male Tattooist": "Male Tattooist",
          "Same Day Booking": "Same Day Booking",
          "Late Night Service": "Late Night Service",
          "Private Studio": "Private Studio",
          "Separate Restrooms": "Separate Restrooms",
          "WiFi": "WiFi",
          "Waiting Area": "Waiting Area",
          "Night Service": "Night Service",
          "Smoking Area": "Smoking Area"
        },
        modal: {
          mincost: "Min",
          hourcost: "Hourly",
          cost: "cost",
          por: "Price on Request",
          timeSpent: "Time Spent",
          genre: "Genre",
          chat: "Chat",
          hour: "hour",
          hours: "hours"
        }
      },
      ko: {
        genres: {
          "Emotional": "감성타투",
          "Oriental": "동양화",
          "Lettering": "레터링",
          "Linework": "라인워크",
          "Blackwork": "블랙워크",
          "Black & Grey": "블랙 & 그레이",
          "Watercolor": "수채화",
          "New School": "뉴스쿨",
          "Mini Tattoo": "미니타투",
          "Old School": "올드스쿨",
          "Irezumi": "이레즈미",
          "Illustration": "일러스트",
          "Color Tattoo": "컬러타투",
          "Cover Up": "커버업",
          "Chicano": "치카노",
          "Touch Up": "터치업",
          "Tribal": "트라이벌",
          "Anime/Cartoon": "애니/만화"
        },
        services: {
          "Card Payment": "카드결제",
          "Parking Available": "주차가능",
          "Installment Payment": "할부결제",
          "Female Tattooist": "여성 타투이스트",
          "Male Tattooist": "남성 타투이스트",
          "Same Day Booking": "당일예약",
          "Late Night Service": "심야작업",
          "Private Studio": "개인스튜디오",
          "Separate Restrooms": "남/여화장실",
          "WiFi": "와이파이",
          "Waiting Area": "대기공간",
          "Night Service": "야간서비스",
          "Smoking Area": "흡연구역"
        },
        modal: {
          mincost: "최소",
          hourcost: "시간당",
          cost: "비용",
          por: "비공개",
          timeSpent: "소요시간",
          genre: "장르",
          chat: "문의하기",
          hour: "시간",
          hours: "시간"
        }
      },
      jp: {
        genres: {
          "Emotional": "感情的",
          "Oriental": "東洋",
          "Lettering": "レタリング",
          "Linework": "ラインワーク",
          "Blackwork": "ブラックワーク",
          "Black & Grey": "ブラック＆グレー",
          "Watercolor": "水彩画",
          "New School": "ニュー スクール",
          "Mini Tattoo": "ミニ タトゥー",
          "Old School": "オールド スクール",
          "Irezumi": "入れ墨",
          "Illustration": "イラスト",
          "Color Tattoo": "カラー タトゥー",
          "Cover Up": "カバーアップ",
          "Chicano": "チカーノ",
          "Touch Up": "タッチアップ",
          "Tribal": "トライバル",
          "Anime/Cartoon": "アニメ/漫画"
        },
        services: {
          "Card Payment": "カード決済",
          "Parking Available": "駐車場あり",
          "Installment Payment": "分割払い",
          "Female Tattooist": "女性タトゥーアーティスト",
          "Male Tattooist": "男性タトゥーアーティスト",
          "Same Day Booking": "当日予約",
          "Late Night Service": "深夜サービス",
          "Private Studio": "プライベートスタジオ",
          "Separate Restrooms": "別々のトイレ",
          "WiFi": "WiFi",
          "Waiting Area": "待合室",
          "Night Service": "夜間サービス",
          "Smoking Area": "喫煙エリア"
        },
        modal: {
          mincost: "最低価格",
          hourcost: "時間料金",
          cost: "コスト",
          por: "希望価格",
          timeSpent: "所要時間",
          genre: "ジャンル",
          chat: "問い合わせ",
          hour: "時間",
          hours: "時間"
        }
      }
    };

    const currentLanguage = ref(localStorage.getItem('selectedLanguage') || 'en'); // 로컬스토리지에서 selectedLanguage를 불러와 기본 언어 설정

    const translateGenre = (genre) => {
      return translations[currentLanguage.value].genres[genre] || genre;
    };

    const translateService = (service) => {
      return translations[currentLanguage.value].services[service] || service;
    };

    const translateModal = (key) => {
      return translations[currentLanguage.value].modal[key] || key;
    };

    const fetchProfile = async () => {
      
      if (!userId) {
        loading.value = false
        return
      }

      try {
        const docRef = doc(db, 'users', userId)

        const docSnap = await getDoc(docRef)
        
        if (docSnap.exists()) {
          profile.value = docSnap.data()
        } else {
          console.error('No such document exists!')
        }
      } catch (error) {
        console.error('Error fetching profile:', error)
        console.error('Error details:', {
          code: error.code,
          stack: error.stack
        })
      } finally {
        loading.value = false
      }
    }

    const fetchDesigns = async () => {
      try {
        const designsRef = collection(db, 'designs')
        const q = query(designsRef, where('userId', '==', userId))
        const querySnapshot = await getDocs(q)
        designs.value = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
      } catch (error) {
        console.error('디자인 불러오기 실패:', error)
      }
    }

    const selectRandomCoverPhoto = () => {
      if (portfolios.value.length > 0) {
        const randomIndex = Math.floor(Math.random() * portfolios.value.length)
        randomPortfolio.value = portfolios.value[randomIndex]
      }
    }

    const fetchPortfolios = async () => {
      try {
        const portfoliosRef = collection(db, 'portfolios')
        const q = query(portfoliosRef, where('userId', '==', userId))
        const querySnapshot = await getDocs(q)
        portfolios.value = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
        selectRandomCoverPhoto()
      } catch (error) {
        console.error('포트폴리오 불러오기 실패:', error)
      }
    }

    const fetchMultiLinks = async () => {
      try {
        const multiLinksRef = collection(db, 'users', userId, 'MultiLinks')
        const q = query(
          multiLinksRef, 
          orderBy('createdAt', 'desc')
        )
        const querySnapshot = await getDocs(q)
        multiLinks.value = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
      } catch (error) {
        console.error('멀티링크 불러오기 실패:', error)
      }
    }

    const fetchNotices = async () => {
      try {
        const noticesRef = collection(db, 'users', userId, 'Notices')
        const q = query(
          noticesRef, 
          orderBy('createdAt', 'desc')
        )
        const querySnapshot = await getDocs(q)
        notices.value = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
      } catch (error) {
        console.error('공지사항 불러오기 실패:', error)
      }
    }

    const coverPhotoStyle = computed(() => ({
      backgroundImage: randomPortfolio.value 
        ? `url(${randomPortfolio.value.imageUrl})` 
        : 'none'
    }))


    const loadThemeColors = async () => {
      if (!userId) return
      
      try {
        const colorDoc = doc(db, 'users', userId, 'colors', 'theme')
        const colorSnapshot = await getDoc(colorDoc)

        if (colorSnapshot.exists()) {
          const colorData = colorSnapshot.data()
          themeColors.value = {
            background: colorData.background || '#000000',
            nickname: colorData.nickname || '#ffffff', 
            instagramId: colorData.instagramId || '#cccccc',
            infoDetail: colorData.infoDetail || '#cccccc',
            link: colorData.link || '#ffffff',
            introduce: colorData.introduce || '#ffffff',
            noticebox: colorData.noticebox || '#ffffff',
            noticetext: colorData.noticetext || '#cccccc',
            tagbox: colorData.tagbox || '#ffffff',
            tagtext: colorData.tagtext || '#000000',
            galleryButton: colorData.galleryButton || '#ffffff',
            galleryButtonText: colorData.galleryButtonText || '#000000'
          }
        }
      } catch (error) {
        console.error('테마 색상 불러오기 실패:', error)
      }
    }

    const checkFavoriteStatus = async () => {
      const currentUser = auth.currentUser
      if (!currentUser || !userId) return

      try {
        const favoriteDoc = doc(db, 'users', currentUser.uid, 'favorites', userId)
        const docSnap = await getDoc(favoriteDoc)
        isFavorite.value = docSnap.exists()
      } catch (error) {
        console.error('즐겨찾기 상태 확인 실패:', error)
      }
    }

    const toggleFavorite = async () => {
      const currentUser = auth.currentUser
      if (!currentUser) {
        alert('Login is required for this service.')
        router.push('/login')
        return
      }

      try {
        const favoriteDoc = doc(db, 'users', currentUser.uid, 'favorites', userId)
        
        if (isFavorite.value) {
          // Remove from favorites
          await deleteDoc(favoriteDoc)
          isFavorite.value = false
        } else {
          // Add to favorites
          await setDoc(favoriteDoc, {
            artistId: userId  // Simply store artistId
          })
          isFavorite.value = true
        }
      } catch (error) {
        console.error('Failed to toggle favorite:', error)
        alert('An error occurred while processing the favorite.')
      }
    }

    const sendPortfolioInquiry = async (portfolio) => {
      const currentUser = auth.currentUser
      if (!currentUser) {
        alert('Login is required for this service.')
        router.push('/login')
        return
      }

      // Prevent chatting with oneself
      if (currentUser.uid === userId) {
        alert('You cannot inquire to yourself.')
        return
      }

      try {
        // Check or create chat room
        const chatRoomsRef = collection(db, 'chatRooms')
        const q = query(
          chatRoomsRef,
          where('participants', 'array-contains', currentUser.uid)
        )
        const querySnapshot = await getDocs(q)
        
        let roomId
        let existingRoom = querySnapshot.docs.find(doc => 
          doc.data().participants.includes(userId)
        )

        if (existingRoom) {
          roomId = existingRoom.id
        } else {
          // Create new chat room
          const newRoomRef = await addDoc(chatRoomsRef, {
            participants: [currentUser.uid, userId],
            createdAt: new Date(),
            lastMessage: {
              content: 'A new conversation has started.',
              timestamp: new Date()
            }
          })
          roomId = newRoomRef.id
        }

        // Send portfolio image as a message
        const messagesRef = collection(db, 'chatRooms', roomId, 'messages')
        await addDoc(messagesRef, {
          type: 'image',
          images: [portfolio.imageUrl],
          content: 'I would like to inquire about the portfolio work.',
          senderId: currentUser.uid,
          timestamp: new Date()
        })

        // Navigate to chat room
        router.push(`/chat/${roomId}`)
        selectedImage.value = null
      } catch (error) {
        console.error('Error occurred while processing inquiry:', error)
        alert('An error occurred while processing the inquiry.')
      }
    }

    const handleChatClick = async () => {
      const currentUser = auth.currentUser
      if (!currentUser) {
        alert('This service requires login.')
        router.push('/login')
        return
      }

      // Prevent chatting with oneself
      if (currentUser.uid === userId) {
        alert('You cannot chat with yourself.')
        return
      }

      try {
        // Check for existing chat room
        const chatRoomsRef = collection(db, 'chatRooms')
        const q = query(
          chatRoomsRef,
          where('participants', 'array-contains', currentUser.uid)
        )
        const querySnapshot = await getDocs(q)
        
        let existingChatRoom = null
        querySnapshot.forEach((doc) => {
          const room = doc.data()
          if (room.participants.includes(userId)) {
            existingChatRoom = { id: doc.id, ...room }
          }
        })

        if (existingChatRoom) {
          // Navigate to existing chat room
          router.push(`/chat/${existingChatRoom.id}`)
        } else {
          // Create a new chat room
          const newChatRoomRef = doc(collection(db, 'chatRooms'))
          await setDoc(newChatRoomRef, {
            participants: [currentUser.uid, userId],
            createdAt: new Date(),
            lastMessage: null,
            lastMessageTime: null
          })
          router.push(`/chat/${newChatRoomRef.id}`)
        }
      } catch (error) {
        console.error('Error occurred while creating/navigating chat room:', error)
        alert('An error occurred while using the chat feature.')
      }
    }

    const openNoticeModal = (notice) => {
      selectedNotice.value = notice;
    };

    const closeNoticeModal = () => {
      selectedNotice.value = null;
    };

    const fetchAwards = async () => {
      try {
        const awardsRef = collection(db, 'users', userId, 'awards');
        const awardsSnapshot = await getDocs(awardsRef);
        awards.value = awardsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
      } catch (error) {
        console.error('수상 뱃지 불러오기 실패:', error);
      }
    };

    const toggleTooltip = (award) => {
      selectedAward.value = selectedAward.value === award ? null : award;
    };

    const activeAwards = computed(() => {
      return awards.value.filter(award => award.on && award.type === 'approved')
    })

    const formatAddress = (address, country) => {
      if (!address) return '';
      
      // country가 korea인 경우 (대소문자 구분 없이)
      if (country?.toLowerCase() === 'korea') {
        const parts = address.split(',').map(part => part.trim());
        // 뒤에서 2개 요소만 가져오기 (시/도와 국가)
        return parts.slice(-2).join(', ');
      }
      
      return address;
    };

    onMounted(async () => {
      fetchProfile()
      fetchDesigns()
      fetchPortfolios()
      fetchMultiLinks()
      fetchNotices()
      loadThemeColors()
      checkFavoriteStatus()
      window.addEventListener('popstate', () => {
        selectedImage.value = null
      })
      
      // 페이지 뷰 증가 (route.params.id를 사용)
      try {
        const tattooistId = route.params.id;  // URL에서 타투이스트 ID 가져오기
        if (tattooistId) {
          await incrementPageView(tattooistId);
        }
      } catch (error) {
        console.error('페이지뷰 업데이트 실패:', error);
      }
      fetchAwards();
    })

    const tabs = [
      { label: 'portfolios', value: 'portfolio' },
      { label: 'designs', value: 'designs' }
    ]

    const currentGalleryItems = computed(() => {
      return currentTab.value === 'designs' ? designs.value : portfolios.value
    })

    let scrollPosition = 0;

    const openModal = (item) => {
      scrollPosition = window.scrollY;
      selectedImage.value = item;
      window.history.pushState({ modalOpen: true, scrollPosition }, '', '');
    }

    const closeModal = () => {
      selectedImage.value = null;
      window.history.back();
    }

    window.addEventListener('popstate', (event) => {
      if (event.state && event.state.modalOpen) {
        selectedImage.value = null;
        window.scrollTo(0, event.state.scrollPosition);
      } else {
        window.scrollTo(0, scrollPosition);
      }
    })

    onBeforeRouteLeave((to, from, next) => {
      if (selectedNotice.value) {
        selectedNotice.value = null
        next(false)
        return
      }
      next()
    })

    return {
      profile,
      currentTab,
      tabs,
      currentGalleryItems,
      loading,
      designs,
      portfolios,
      coverPhotoStyle,
      multiLinks,
      notices,
      themeColors,
      selectedImage,
      openModal,
      closeModal,
      isFavorite,
      toggleFavorite,
      userId,
      handleChatClick,
      sendPortfolioInquiry,
      selectedNotice,
      openNoticeModal,
      closeNoticeModal,
      showTooltip,
      awards,
      selectedAward,
      toggleTooltip,
      activeAwards,
      translateGenre,
      translateService,
      currentLanguage,
      translateModal,
      formatAddress
    }
  }
}
</script>

<style scoped>
/*
v-bind(themeColors.background);
v-bind(`${themeColors.background}CC`);
*/

.profile-container {
  position: absolute;
  top: 0;
  left: 0;
  background-color: v-bind(themeColors.background);
  padding: 30px 0;
  width: 100%;
}

.profile-header {
  position: relative;
  margin-bottom: 32px;
}

.cover-photo {
  height: 192px;
  background: linear-gradient(to right, #000000, #ffffff);
  border-radius: 12px;
  position: relative;
  overflow: hidden;
}

.cover-overlay {
  position: absolute;
  inset: 0;
  opacity: 0.6;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.profile-picture-wrapper {
  position: absolute;
  bottom: -64px;
  left: 32px;
}

.profile-picture {
  position: relative;
  width: 128px;
  height: 128px;
}

.profile-picture img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 4px solid #000000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  object-fit: cover;
}

/* Profile Content Styles */
.profile-content {
  margin-top: 80px;
  padding: 0 20px;
}

.profile-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.profile-info h1 {
  font-size: 30px;
  font-weight: bold;
  margin: 10px 0 0 0;
  color: v-bind(themeColors.nickname);
}

.nickname {
  color: v-bind(themeColors.instagramId);
  margin: 0;
}

.action-buttons {
  display: flex;
  gap: 12px;
}

.message-btn-container {
  position: fixed;
  bottom: 5%;
  right: 5%;
  z-index: 100;
}

.message-tooltip {
  position: absolute;
  bottom: calc(100% + 10px);
  right: 0;
  background: #ffffff;
  color: #000000;
  padding: 16px;
  border-radius: 8px;
  font-size: 14px;
  white-space: pre;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0;
  animation: slideUp 0.3s ease-out 2s forwards;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.message-new {
  position: absolute;
  top: 5px;
  right: 2px;
  width: 12px;
  height: 12px;
  background-color: #ff0000;
  border-radius: 50%;
  z-index: 101;
  opacity: 0;
  animation: fadeIn 0.3s ease-out 2s forwards;
}

.message-tooltip p {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  margin-right: 20px;
}

.tooltip-close {
  position: absolute;
  top: 25%;
  right: 8px;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #666;
  font-size: 25px;
  cursor: pointer;
  padding: 4px;
  line-height: 1;
}

.tooltip-close:hover {
  color: #000;
}

.message-btn {
  position: relative;
  padding: 30px;
  border: none;
  border-radius: 40%;
  cursor: pointer;
  box-shadow: 0 0 4px #000000;
}

.follow-btn {
  padding: 13px 16px;
  border-radius: 8px;
  border: none;
  background: v-bind(themeColors.galleryButton);
  color: v-bind(themeColors.galleryButtonText);
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
}

.follow-btn.is-favorite {
  background: #dc2626;
  color: #fff;
}

.follow-btn i {
  font-size: 14px;
}

.follow-btn:hover {
  opacity: 0.9;
}

/* Profile Details */
.profile-details {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
  color: v-bind(themeColors.infoDetail);
}

.rate-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.location, .rate {
  display: flex;
  align-items: center;
  gap: 8px;
}

.introduction {
  font-size: 16px;
  font-weight: 300;
  color: v-bind(themeColors.introduce);
  letter-spacing: 1px;
  word-break: break-all;
  white-space: pre-wrap;
}

/* Social Links */
.social-links {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 16px;
}

.social-links i {
  font-size: 14px;
  margin: 0;
}

.social-link {
  color: v-bind(themeColors.link);
  border: 1px solid v-bind(themeColors.link);
  padding: 6px 20px;
  border-radius: 20px;
  text-decoration: none;
  transition: all 0.3s ease;
}

.notice-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
}

.notice-container i {
  color: #A855F7;
  margin-right: 10px;
}

.notice-text {
  border: 1px solid v-bind(themeColors.noticebox);
  padding: 10px;
  border-radius: 10px;
  color: v-bind(themeColors.noticetext);
  cursor: pointer;
  transition: opacity 0.2s ease;
}

/* Tags */
.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 24px;
}

.tag {
  font-size: 13px;
  background: v-bind(themeColors.tagbox);
  color: v-bind(themeColors.tagtext);
  padding: 4px 12px;
  border-radius: 5px;
}

/* Gallery */
.gallery-section {
  margin-top: 48px;
}

.gallery-tabs {
  border-bottom: 1px solid #1F2937;
  display: flex;
  justify-content: center;
}

.tab-btn {
  font-size: 14px;
  padding: 8px 16px;
  background: transparent;
  border: none;
  color: v-bind(themeColors.infoDetail);
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
}

.tab-btn.active {
  color: v-bind(themeColors.galleryButtonText);
  border-bottom: 2px solid v-bind(themeColors.galleryButtonText);
  background: v-bind(themeColors.galleryButton);
  border-radius: 10px 10px 0 0;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin-top: 24px;
}

.gallery-item {
  position: relative;
  aspect-ratio: 1;
  overflow: hidden;
  border-radius: 12px;
  cursor: pointer;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.gallery-item:hover img {
  transform: scale(1.05);
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  z-index: 1000;
}

.modal-content {
  background: white;
  max-height: 100vh;
  overflow-y: auto;
  position: absolute;
  top: 0;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.modal-image {
  width: 100vh;
  max-width: 100%;
  height: 65vh;
  object-fit: cover;
}

.modal-info {
  padding: 20px;
  background: #fff;
}

.modal-description {
  font-size: 16px;
  margin-bottom: 10px;
}

.modal-price {
  font-weight: bold;
  color: #8B5CF6;
  margin-bottom: 5px;
}

.modal-time {
  color: #6B7280;
  font-size: 14px;
}

.inquiry-btn {
  padding: 12px;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  gap: 8px;
  background: #1a1a1a;
  color: white;
  width: calc(100% - 20px);
  margin: 10px;
}

.notice-modal {
  max-width: 500px;
  width: 90%;
  background: white;
  border-radius: 16px;
  padding: 24px;
  position: absolute;
}

.notice-modal-content {
  margin-top: 20px;
}

.notice-modal-content h2 {
  font-size: 1.5rem;
  margin-bottom: 16px;
  color: #1a1a1a;
}

.notice-modal-content p {
  font-size: 1rem;
  line-height: 1.6;
  color: #4a4a4a;
  white-space: pre-wrap;
}

/* 모달 진입/진출 애니메이션 */
.modal-enter-active,
.modal-leave-active {
  transition: all 0.3s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
  transform: translateY(50px);
}

.modal-enter-to,
.modal-leave-from {
  opacity: 1;
  transform: translateY(0);
}

.no-data-message {
  text-align: center;
  padding: 160px 0;
  color: v-bind(themeColors.infoDetail);
  font-size: 16px;
}

.awards-container {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  flex-wrap: wrap-reverse;
  max-width: 160px;
  justify-content: flex-end;
  z-index: 2;
}

.award-badge {
  position: relative;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.award-tooltip {
  position: absolute;
  top: 30%;
  right: calc(100% + 10px);
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  animation: fadeIn 0.2s ease-in-out;
  z-index: 10;
}

.tooltip-arrow {
  position: absolute;
  right: -5px;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid rgba(0, 0, 0, 0.8);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-5px, -50%);
  }
  to {
    opacity: 1;
    transform: translate(0, -50%);
  }
}

.award-badge img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  filter: drop-shadow(0 0 2px black);
}
</style>