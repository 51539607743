<template>
  <div class="mobile-product-selector">
    <!-- 헤더 -->
    <div class="sticky-header">
      <h2>제품 선택</h2>
      <!-- 카테고리 스크롤 -->
      <div class="category-scroll">
        <button 
          v-for="category in categories" 
          :key="category.id"
          :class="['category-pill', { active: selectedCategory === category.id }]"
          @click="filterByCategory(category.id)"
        >
          {{ category.name }} ({{ getCategoryCount(category.id) }})
        </button>
      </div>
    </div>

    <!-- 검색 및 정렬 -->
    <div class="filter-options">
      <input 
        type="text" 
        v-model="searchQuery" 
        placeholder="제품 검색..."
        class="search-input"
      >
      <select v-model="sortOption" class="sort-select">
        <option value="name-asc">이름 순</option>
        <option value="name-desc">이름 역순</option>
      </select>
    </div>

    <!-- 제품 목록 -->
    <div class="content-wrapper">
      <div v-if="loading" class="loading-state">
        <p>제품을 불러오는 중...</p>
      </div>
      <div v-else-if="error" class="error-state">
        <p>{{ error }}</p>
      </div>
      <div v-else class="products-container">
        <div class="product-grid">
          <div 
            v-for="product in paginatedProducts" 
            :key="product.id"
            :class="['product-item', { selected: selectedProductId === product.id }]"
            @click="navigateToProduct(product.id)"
          >
            <div class="product-image" v-if="product.images && product.images.length > 0">
              <img 
                :src="product.images[0].src || defaultProductImage" 
                :alt="product.title"
                @error="handleImageError"
              >
            </div>
            <div class="product-details">
              <h3>{{ product.title }}</h3>
              <p class="category-tag">{{ getCategoryName(product.category) }}</p>
              <div class="print-areas" v-if="product.printAreas?.length">
                <span class="badge">인쇄 가능 영역: {{ product.printAreas.length }}개</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 오른쪽 페이지네이션 -->
      <div class="pagination-nav" :class="{ 'has-action-button': selectedProductId }">
        <div class="page-info">{{ currentPage }} / {{ totalPages }}</div>
        <div class="nav-buttons">
          <button 
            :disabled="currentPage === 1"
            @click="currentPage--"
            class="nav-button"
            aria-label="이전 페이지"
          >
            ←
          </button>
          <button 
            :disabled="currentPage === totalPages"
            @click="currentPage++"
            class="nav-button"
            aria-label="다음 페이지"
          >
            →
          </button>
        </div>
      </div>
    </div>

    <!-- 선택 확인 버튼 -->
    <div v-if="selectedProductId" class="action-buttons">
      <button class="confirm-button" @click="confirmSelection">
        이 제품으로 디자인하기
      </button>
    </div>

    <!-- 제품 상세 모달 -->
    <div v-if="selectedProductId" class="product-modal-overlay" @click="closeModal">
      <div class="product-modal" @click.stop>
        <button class="modal-close" @click="closeModal">×</button>
        <ProductDetail 
          :product-id="selectedProductId" 
          @start-design="handleStartDesign"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import axios from 'axios'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '@/firebase'
import ProductDetail from './ProductDetail.vue'
import { useRouter } from 'vue-router'

// API URL 부분 수정
const API_URL = "https://asia-northeast3-tattoomap3.cloudfunctions.net/api/products";

// 또는 환경 변수를 사용하는 방식
// const API_URL = import.meta.env.VITE_FIREBASE_FUNCTIONS_URL + "/products";

// 상태 변수들
const products = ref([])
const loading = ref(true)
const error = ref(null)
const selectedCategory = ref('all')
const selectedProductId = ref(null)
const searchQuery = ref('')
const sortOption = ref('name-asc')
const currentPage = ref(1)
const itemsPerPage = 12

// 상태 저장을 위한 전역 변수들
let savedState = {
  scrollPosition: 0,
  category: 'all',
  page: 1,
  searchQuery: '',
  sortOption: 'name-asc'
};

// Printify 공식 카테고리 기반으로 수정
const categories = [
  { id: 'all', name: '전체' },
  // 의류
  { id: 'MENS', name: '남성의류' },
  { id: 'WOMENS', name: '여성의류' },
  { id: 'KIDS', name: '아동의류' },
  { id: 'ACCESSORIES', name: '의류액세서리' },
  
  // 홈데코
  { id: 'HOME_LIVING', name: '홈/리빙' },
  { id: 'WALL_ART', name: '벽장식' },
  { id: 'BEDDING_BATH', name: '침구/욕실' },
  { id: 'KITCHEN_DINING', name: '주방/식사' },
  
  // 액세서리/소품
  { id: 'PHONE_CASES', name: '폰케이스' },
  { id: 'BAGS', name: '가방' },
  { id: 'JEWELRY', name: '쥬얼리' },
  { id: 'STATIONERY', name: '문구류' },
  
  // 시즌/특별
  { id: 'SEASONAL', name: '시즌용품' },
  { id: 'CHRISTMAS', name: '크리스마스' },
  
  // 기타
  { id: 'PET_SUPPLIES', name: '반려동물' },
  { id: 'TECH', name: '전자기기' },
  { id: 'DRINKWARE', name: '음료용품' },
  { id: 'OUTDOOR', name: '아웃도어' }
];

// Printify 카테고리 기반 분류 함수
const getTitleBasedCategory = (title, description = '') => {
  const text = (title + ' ' + description).toLowerCase();
  
  // 의류 카테고리
  if (text.includes("men's") || text.includes('unisex')) {
    if (text.includes('clothing') || text.includes('shirt') || text.includes('hoodie') || 
        text.includes('jacket') || text.includes('pants')) return 'MENS';
  }
  
  if (text.includes("women's")) {
    if (text.includes('clothing') || text.includes('dress') || text.includes('skirt') || 
        text.includes('leggings')) return 'WOMENS';
  }
  
  if (text.includes('kids') || text.includes('youth') || text.includes('child') || 
      text.includes('infant') || text.includes('baby')) return 'KIDS';
  
  // 액세서리/소품
  if (text.includes('scarf') || text.includes('hat') || text.includes('cap') || 
      text.includes('socks') || text.includes('mask')) return 'ACCESSORIES';
  
  // 홈/리빙
  if (text.includes('pillow') || text.includes('blanket') || text.includes('throw') || 
      text.includes('rug') || text.includes('mat')) return 'HOME_LIVING';
  
  if (text.includes('wall') || text.includes('canvas') || text.includes('poster') || 
      text.includes('frame')) return 'WALL_ART';
  
  if (text.includes('bed') || text.includes('bath') || text.includes('towel') || 
      text.includes('shower')) return 'BEDDING_BATH';
  
  if (text.includes('kitchen') || text.includes('dining') || text.includes('apron') || 
      text.includes('cutting board')) return 'KITCHEN_DINING';
  
  // 시즌/특별
  if (text.includes('christmas') || text.includes('ornament') || 
      text.includes('stocking')) return 'CHRISTMAS';
  
  if (text.includes('seasonal') || text.includes('holiday') || 
      text.includes('valentine') || text.includes('halloween')) return 'SEASONAL';
  
  // 기타 카테고리
  if (text.includes('pet') || text.includes('dog') || text.includes('cat')) return 'PET_SUPPLIES';
  if (text.includes('phone') || text.includes('case')) return 'PHONE_CASES';
  if (text.includes('bag') || text.includes('backpack') || text.includes('tote')) return 'BAGS';
  if (text.includes('mug') || text.includes('tumbler') || text.includes('bottle')) return 'DRINKWARE';
  if (text.includes('tech') || text.includes('mouse') || text.includes('speaker')) return 'TECH';
  if (text.includes('outdoor') || text.includes('camping')) return 'OUTDOOR';
  
  return 'OTHER';
};

// API 호출 함수
const fetchProducts = async () => {
  loading.value = true;
  error.value = null;
  
  try {
    const response = await axios.get(API_URL);
    console.log('API 응답:', response.data.length);
    
    if (response.data && Array.isArray(response.data)) {
      products.value = response.data.map(product => {
        // 제목과 설명을 모두 사용하여 카테고리 결정
        const category = getTitleBasedCategory(product.title, product.description);
        
      
        
        return {
          id: product.id,
          title: product.title,
          description: product.description || '',
          images: Array.isArray(product.images) ? product.images : [],
          variants: Array.isArray(product.variants) ? product.variants.map(variant => ({
            id: variant.id,
            price: calculatePrice(variant.price || 0),
            size: variant.size || '',
            color: variant.color || ''
          })) : [],
          category: category,
          printAreas: product.print_areas || [],
          printDetails: product.print_details || {}
        };
      });

      // 카테고리별 통계 출력
      const stats = categories.map(cat => ({
        category: cat.name,
        count: getCategoryCount(cat.id)
      }));
      
      console.log('카테고리별 제품 수:', stats);
      console.log('미분류(기타) 제품 수:', getCategoryCount('OTHER'));
    }
  } catch (err) {
    console.error('API 에러 상세:', err);
    error.value = `제품 로드 실패: ${err.response?.data?.error || err.message}`;
  } finally {
    loading.value = false;
  }
};

// 컴포넌트 마운트 시 제품 목록 로드
fetchProducts();

// 가격 계산 함수 (마진 추가)
const calculatePrice = (basePrice) => {
  const margin = 1.3; // 30% 마진
  return Math.round(basePrice * margin);
};

// 카테고리 필터링 함수
const filterByCategory = (categoryId) => {
  selectedCategory.value = categoryId;
  currentPage.value = 1; // 카테고리 변경 시 첫 페이지로
  savedState.category = categoryId; // 카테고리 상태 저장
};

// 카테고리 이름 가져오기
const getCategoryName = (category) => {
  const found = categories.find(c => c.id === category);
  return found ? found.name : '기타';
};

// 필터링된 제품 목록
const filteredProducts = computed(() => {
  let result = products.value;

  // 카테고리 필터링
  if (selectedCategory.value !== 'all') {
    result = result.filter(product => product.category === selectedCategory.value);
  }

  // 검색어 필터링
  if (searchQuery.value) {
    const query = searchQuery.value.toLowerCase();
    result = result.filter(product => 
      product.title.toLowerCase().includes(query)
    );
  }

  // 정렬
  result = [...result].sort((a, b) => {
    if (sortOption.value === 'name-asc') {
      return a.title.localeCompare(b.title);
    }
    return b.title.localeCompare(a.title);
  });

  return result;
});

// 페이지네이션된 제품 목록
const paginatedProducts = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage;
  const end = start + itemsPerPage;
  return filteredProducts.value.slice(start, end);
});

// 총 페이지 수
const totalPages = computed(() => 
  Math.ceil(filteredProducts.value.length / itemsPerPage)
);

// 카테고리별 제품 수 계산
const getCategoryCount = (categoryId) => {
  if (categoryId === 'all') return products.value.length;
  return products.value.filter(product => product.category === categoryId).length;
};

// 현재 상태 저장
const saveCurrentState = () => {
  savedState = {
    scrollPosition: window.scrollY,
    category: selectedCategory.value,
    page: currentPage.value,
    searchQuery: searchQuery.value,
    sortOption: sortOption.value
  };
  
  // localStorage에도 저장 (페이지 새로고침 시에도 유지)
  localStorage.setItem('productSelectorState', JSON.stringify(savedState));
};

// 저장된 상태 복원
const restoreSavedState = () => {
  // localStorage에서 상태 복원 시도
  const savedStateFromStorage = localStorage.getItem('productSelectorState');
  if (savedStateFromStorage) {
    savedState = JSON.parse(savedStateFromStorage);
  }

  if (savedState) {
    selectedCategory.value = savedState.category;
    currentPage.value = savedState.page;
    searchQuery.value = savedState.searchQuery;
    sortOption.value = savedState.sortOption;

    // 스크롤 위치 복원
    setTimeout(() => {
      window.scrollTo(0, savedState.scrollPosition);
    }, 100);
  }
};

// 컴포넌트 마운트 시
onMounted(() => {
  loadProducts();
  restoreSavedState();
  window.addEventListener('popstate', handlePopState);
});

// 컴포넌트 언마운트 시
onBeforeUnmount(() => {
  saveCurrentState();
  window.removeEventListener('popstate', handlePopState);
});

// 검색어 변경 감시
watch(searchQuery, (newQuery) => {
  currentPage.value = 1;
  savedState.searchQuery = newQuery;
});

// 정렬 옵션 변경 감시
watch(sortOption, (newOption) => {
  savedState.sortOption = newOption;
});

// 페이지 변경 감시
watch(currentPage, (newPage) => {
  savedState.page = newPage;
});

// Base64 형식의 기본 이미지 데이터
const defaultProductImage = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMDAiIGhlaWdodD0iMjAwIiB2aWV3Qm94PSIwIDAgMjAwIDIwMCI+PHJlY3Qgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0iI2YwZjBmMCIvPjx0ZXh0IHg9IjUwJSIgeT0iNTAlIiBmb250LWZhbWlseT0iQXJpYWwiIGZvbnQtc2l6ZT0iMTYiIGZpbGw9IiM5OTkiIHRleHQtYW5jaG9yPSJtaWRkbGUiIGR5PSIuM2VtIj5ObyBJbWFnZTwvdGV4dD48L3N2Zz4=';

// 이미지 에러 처리 함수 수정
const handleImageError = (event) => {
  event.target.src = defaultProductImage;
};

// 선택 상태에 따라 페이지네이션 위치 조정
watch(selectedProductId, (newValue) => {
  const paginationNav = document.querySelector('.pagination-nav');
  if (paginationNav) {
    if (newValue) {
      paginationNav.classList.add('has-action-button');
    } else {
      paginationNav.classList.remove('has-action-button');
    }
  }
});

// 제품 상세 모달 관련 함수들
const navigateToProduct = (productId) => {
  selectedProductId.value = productId
  document.body.style.overflow = 'hidden'
  // 모달 열릴 때 history state 추가
  window.history.pushState({ modal: true }, '')
}

const closeModal = () => {
  selectedProductId.value = null
  document.body.style.overflow = 'auto'
}

// 뒤로가기 이벤트 처리
const handlePopState = () => {
  if (selectedProductId.value) {
    closeModal()
  }
}

// Firebase에서 제품 목록 가져오기
const loadProducts = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, 'products'));
    products.value = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('제품 목록 로드 실패:', error);
  } finally {
    loading.value = false;
  }
};

// 디자인 시작 처리
const handleStartDesign = (product) => {
  // 모달 닫기
  closeModal();
  // 디자인 페이지로 이동
  router.push({
    name: 'design',
    params: { productId: product.id },
    state: { product }
  });
};

const router = useRouter()
</script>

<style scoped>
.mobile-product-selector {
  padding: 16px;
  height: 100vh;
  overflow-y: auto;
}

.sticky-header {
  position: sticky;
  top: 0;
  background: white;
  z-index: 10;
  padding-bottom: 16px;
}

.category-scroll {
  overflow-x: auto;
  white-space: nowrap;
  padding: 8px 0;
  -webkit-overflow-scrolling: touch;
}

.category-pill {
  display: inline-block;
  padding: 8px 16px;
  margin-right: 8px;
  border-radius: 20px;
  border: 1px solid #ddd;
  background: white;
  font-size: 14px;
  cursor: pointer;
}

.category-pill.active {
  background: #000;
  color: white;
  border-color: #000;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 16px;
  padding: 16px 0;
}

.product-item {
  border: 1px solid #eee;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease;
}

.product-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.product-item.selected {
  border-color: #000;
  box-shadow: 0 0 0 2px #000;
}

.product-image {
  position: relative;
  padding-top: 100%;
}

.product-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-details {
  padding: 12px;
}

.product-details h3 {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

.description {
  font-size: 12px;
  color: #666;
  margin: 4px 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.badge {
  display: inline-block;
  padding: 4px 8px;
  background: #f5f5f5;
  border-radius: 4px;
  font-size: 12px;
  color: #666;
}

.action-buttons {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  background: white;
  box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
}

.confirm-button {
  width: 100%;
  padding: 16px;
  background: #000;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.loading-state, .error-state {
  text-align: center;
  padding: 32px;
  color: #666;
}

.filter-options {
  display: flex;
  gap: 10px;
  margin-bottom: 16px;
  padding: 0 16px;
}

.search-input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
}

.sort-select {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
}

.content-wrapper {
  padding-right: 0; /* 네비게이션 공간 제거 */
  padding-bottom: 80px;
}

.pagination-nav {
  position: fixed;
  right: 16px;
  top: auto;
  bottom: 100px;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.9); /* 반투명 배경 */
  backdrop-filter: blur(8px); /* 블러 효과 */
  border-radius: 20px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 2;
  transform: none;
}

.page-info {
  font-size: 12px;
  color: #666;
  text-align: center;
  padding: 4px 0;
}

.nav-buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.nav-button {
  width: 32px;
  height: 32px;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  transition: all 0.2s ease;
}

.nav-button:hover:not(:disabled) {
  background: rgba(255, 255, 255, 1);
  transform: scale(1.05);
}

.nav-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* 컨텐츠 여백 조정 */
.content-wrapper {
  padding-right: 0; /* 네비게이션 공간 제거 */
  padding-bottom: 80px;
}

.category-tag {
  font-size: 12px;
  color: #666;
  margin: 4px 0;
}

.product-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.product-modal {
  position: relative;
  width: 100%;
  height: 100%;
  background: white;
  overflow-y: auto;
  animation: modalIn 0.3s ease-out;
}

.modal-close {
  position: fixed;
  top: 70px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);
}

@keyframes modalIn {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@media (min-width: 768px) {
  .product-modal {
    width: 90%;
    height: 90%;
    max-width: 1200px;
    max-height: 90vh;
    border-radius: 12px;
  }
}
</style> 