<template>
  <div class="payment-success">
    <div class="success-container">
      <div class="success-icon">
        <i class="fas fa-check"></i>
      </div>
      <h2>결제가 완료되었습니다</h2>
      <div class="payment-details" v-if="paymentDetails">
        <p>결제 금액: {{ formatPrice(paymentDetails.amount) }} {{ paymentDetails.currency.toUpperCase() }}</p>
        <p>상태: {{ getStatusText(paymentDetails.status) }}</p>
      </div>
      <div class="buttons">
        <button @click="goToChatRoom" class="home-button">채팅방으로 돌아가기</button>
        <button @click="viewPaymentDetails" class="details-button">상세 내역 보기</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, updateDoc, getDoc, addDoc, collection } from 'firebase/firestore';

export default {
  data() {
    return {
      paymentDetails: null,
      loading: true,
      error: null,
      apiBaseUrl: 'https://asia-northeast3-tattoomap3.cloudfunctions.net/sendNotification',
    };
  },
  async mounted() {
    try {
      const paymentIntentId = new URLSearchParams(window.location.search).get('payment_intent');
      if (!paymentIntentId) {
        throw new Error('결제 정보를 찾을 수 없습니다.');
      }

      // 결제 상태 확인
      const response = await fetch(`${this.apiBaseUrl}/check_payment_status`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          paymentIntentId: paymentIntentId
        })
      });

      if (!response.ok) {
        throw new Error('결제 정보 조회에 실패했습니다.');
      }

      const data = await response.json();
      this.paymentDetails = data;

      // Firestore 결제 상태 업데이트 및 채팅 메시지 전송
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const db = getFirestore();
        const paymentRef = doc(db, 'users', user.uid, 'payments', paymentIntentId);
        const paymentDoc = await getDoc(paymentRef);
        
        if (paymentDoc.exists()) {
          const paymentData = paymentDoc.data();
          const chatRoomId = paymentData.chatRoomId;

          // 결제 상태 업데이트
          await updateDoc(paymentRef, {
            status: 'completed',
            completedAt: new Date(),
            paymentDetails: data
          });

          // 채팅방에 결제 완료 메시지 전송
          if (chatRoomId) {
            const messagesRef = collection(db, 'chatRooms', chatRoomId, 'messages');
            await addDoc(messagesRef, {
              type: 'payment_complete',
              content: {
                text: '결제가 완료되었습니다.',
                amount: data.amount,
                currency: data.currency,
                paymentIntentId: paymentIntentId,
                timestamp: new Date(),
                status: 'completed'
              },
              senderId: user.uid,
              timestamp: new Date(),
              read: false
            });

            // 채팅방 lastMessage 업데이트
            const roomRef = doc(db, 'chatRooms', chatRoomId);
            await updateDoc(roomRef, {
              lastMessage: {
                content: `${data.amount.toLocaleString()} ${data.currency.toUpperCase()} 결제가 완료되었습니다.`,
                timestamp: new Date()
              }
            });
          }
        }
      }
    } catch (error) {
      console.error('결제 완료 처리 중 오류:', error);
      this.error = error.message;
    } finally {
      this.loading = false;
    }
  },
  methods: {
    getStatusText(status) {
      const statusMap = {
        succeeded: '결제 완료',
        processing: '처리 중',
        requires_payment_method: '결제 수단 필요',
      };
      return statusMap[status] || status;
    },
    async goToChatRoom() {
      try {
        const paymentIntentId = new URLSearchParams(window.location.search).get('payment_intent');
        const auth = getAuth();
        const user = auth.currentUser;
        
        if (user && paymentIntentId) {
          const db = getFirestore();
          const paymentRef = doc(db, 'users', user.uid, 'payments', paymentIntentId);
          const paymentDoc = await getDoc(paymentRef);
          
          if (paymentDoc.exists()) {
            const paymentData = paymentDoc.data();
            if (paymentData.chatRoomId) {
              this.$router.push(`/chat/${paymentData.chatRoomId}`);
              return;
            }
          }
        }
        // 채팅방 ID를 찾을 수 없는 경우 홈으로 이동
        this.$router.push('/');
      } catch (error) {
        console.error('채팅방 이동 중 오류:', error);
        this.$router.push('/');
      }
    },
    formatPrice(price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    viewPaymentDetails() {
      const paymentIntentId = new URLSearchParams(window.location.search).get('payment_intent');
      if (paymentIntentId) {
        this.$router.push(`/payment-details/${paymentIntentId}`);
      }
    }
  }
};
</script>

<style scoped>
.payment-success {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f9fa;
}

.success-container {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 500px;
  width: 90%;
}

.success-icon {
  font-size: 48px;
  color: #4CAF50;
  margin-bottom: 1rem;
  background: #e8f5e9;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
}

h2 {
  color: #2c3e50;
  margin-bottom: 1.5rem;
}

.payment-details {
  margin: 1.5rem 0;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 5px;
  text-align: left;
}

.payment-details p {
  margin: 0.5rem 0;
  color: #4a5568;
}

.buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1.5rem;
}

button {
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s;
}

.home-button {
  background-color: #4CAF50;
  color: white;
}

.details-button {
  background-color: #f8f9fa;
  color: #2c3e50;
  border: 1px solid #ddd;
}

button:hover {
  opacity: 0.9;
  transform: translateY(-1px);
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
</style> 