<template>
  <div class="map-container">
    <!-- 국가 선택 버튼 
    <div class="country-buttons">
      <button 
        v-for="country in countries" 
        :key="country.code"
        @click="moveToCountry(country.code)"
        class="country-button"
        :class="{ active: currentCountry === country.code }"
      >
        {{ t.countries[country.code].flag }}
      </button>
    </div>
    -->

    <!-- 지도와 타투이스트 목록을 감싸는 컨테이너 -->
    <div class="content-container">
      <!-- 지도 영역 -->
      <div class="map-wrapper">
        <div v-if="!isMapLoaded" class="loading-map">
          {{ t.loadingMap }}
        </div>
        <div id="map" class="map-area"></div>
        <!-- 현재 위치 버튼 위치 조정 -->
        <button 
          v-if="isMapLoaded"
          @click="moveToCurrentLocation" 
          class="current-location-button"
          aria-label="현재 위치로 이동"
        >
          <i class="fas fa-crosshairs"></i>
        </button>
      </div>

      <!-- 타투이스트 목록 -->
      <div class="artists-container" v-if="visibleArtists.length">
        <div class="location-info">
          <p class="small-text">{{ t.locationInfo }}</p>
        </div>
        <div class="artists-list">
          <h3>{{ t.visibleArtistsTitle }}</h3>
          <div class="artists-grid">
            <div 
              v-for="artist in visibleArtists" 
              :key="artist.id" 
              class="artist-card"
              @click="navigateToProfile(artist.id)"
            >
              <img :src="artist.profileImageUrl || defaultAvatar" :alt="artist.nickname" class="artist-image">
              <div class="artist-info">
                <h4>{{ artist.nickname }}</h4>
                <p class="genre">{{ translateGenre(artist.mainGenre) }}</p>
                <p class="location">{{ formatLocationAddress(artist) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, defineComponent, onUnmounted, computed, watch } from 'vue';
import { db } from '@/firebase';
import { collection, getDocs} from 'firebase/firestore';
import { useRouter } from 'vue-router';
import defaultAvatar from '@/assets/default-avatar.png';
import { incrementPageView } from '@/utils/pageView';

// Google Maps를 전역 변수로 선언
/* eslint-disable no-undef */

// 상수 정의
const countries = [
  { code: 'KR', name: '한국', flag: '🇰🇷' },
  { code: 'JP', name: '일본', flag: '🇯🇵' },
  { code: 'US', name: '미국', flag: '🇺🇸' },
  { code: 'GB', name: '영국', flag: '🇬🇧' },
  { code: 'AU', name: '호주', flag: '🇦🇺' },
  { code: 'TH', name: '태국', flag: '🇹🇭' },
  { code: 'DE', name: '독일', flag: '🇩🇪' },
  { code: 'IT', name: '이탈리아', flag: '🇮🇹' },
  { code: 'BR', name: '브라질', flag: '🇧🇷' },
  { code: 'CA', name: '캐나다', flag: '🇨🇦' },
  { code: 'FR', name: '프랑스', flag: '🇫🇷' },
  { code: 'ES', name: '스페인', flag: '🇪🇸' },
  { code: 'NL', name: '네덜란드', flag: '🇳🇱' },
  { code: 'MX', name: '멕시코', flag: '🇲🇽' },
  { code: 'SE', name: '스웨덴', flag: '🇸🇪' }
  
];

const centers = {
  'KR': { lat: 37.5665, lng: 126.9780, zoom: 12 },   // 서울
  'JP': { lat: 35.6812, lng: 139.7671, zoom: 12 },   // 도쿄역
  'US': { lat: 40.7128, lng: -74.0060, zoom: 11 },   // 뉴욕
  'GB': { lat: 51.5074, lng: -0.1278, zoom: 11 },    // 런던
  'AU': { lat: -33.8688, lng: 151.2093, zoom: 12 },  // 시드니
  'TH': { lat: 13.7563, lng: 100.5018, zoom: 12 },    // 방콕
  'DE': { lat: 52.5200, lng: 13.4050, zoom: 12 },    // 베를린
  'IT': { lat: 41.9033, lng: 12.4534, zoom: 12 },    // 로마
  'BR': { lat: -14.2350, lng: -51.9253, zoom: 12 }, // 브라질리아
  'CA': { lat: 45.5017, lng: -73.5673, zoom: 12 },  // 캐나다
  'FR': { lat: 46.2276, lng: 2.2137, zoom: 12 },    // 파리
  'ES': { lat: 40.4168, lng: -3.7038, zoom: 12 },    // 마드리드
  'NL': { lat: 52.372775, lng: 4.892222, zoom: 12 }, // 암스테르담
  'MX': { lat: 19.4326, lng: -99.1332, zoom: 12 },   // 멕시코시티
  'SE': { lat: 59.3293, lng: 18.0686, zoom: 12 }     // 스톡홀름
};

// 언어별 번역 데이터 수정
const translations = {
  en: {
    loadingMap: 'Loading map...',
    locationInfo: 'Please contact the tattooist for exact location.',
    visibleArtistsTitle: 'Tattooists shown on current map',
    countries: {
      KR: { name: 'Korea', flag: '🇰🇷' },
      JP: { name: 'Japan', flag: '🇯🇵' },
      US: { name: 'USA', flag: '🇺🇸' },
      GB: { name: 'UK', flag: '🇬🇧' },
      AU: { name: 'Australia', flag: '🇦🇺' },
      TH: { name: 'Thailand', flag: '🇹🇭' },
      DE: { name: 'Germany', flag: '🇩🇪' },
      IT: { name: 'Italy', flag: '🇮🇹' },
      BR: { name: 'Brazil', flag: '🇧🇷' },
      CA: { name: 'Canada', flag: '🇨🇦' },
      FR: { name: 'France', flag: '🇫🇷' },
      ES: { name: 'Spain', flag: '🇪🇸' },
      NL: { name: 'Netherlands', flag: '🇳🇱' },
      MX: { name: 'Mexico', flag: '🇲🇽' },
      SE: { name: 'Sweden', flag: '🇸🇪' }
    },
    genres: {
      '감성타투': 'Emotional',
      '동양화': 'Oriental',
      '레터링': 'Lettering',
      '라인워크': 'Linework',
      '블랙워크': 'Blackwork',
      '블랙앤그레이': 'Black & Grey',
      '수채화': 'Watercolor',
      '뉴스쿨': 'New School',
      '미니타투': 'Mini',
      '올드스쿨': 'Old School',
      '이레즈미': 'Irezumi',
      '일러스트': 'Illustrative',
      '컬러타투': 'Color',
      '커버업': 'Cover Up',
      '치카노': 'Chicano',
      '터치업': 'Touch Up',
      '트라이벌': 'Tribal',
      '애니/만화': 'Anime/Manga'
    },
    infoWindow: {
      viewProfile: 'View Profile',
      distance: 'Distance',
      meters: 'm',
      kilometers: 'km'
    }
  }
};

export default defineComponent({
  name: 'TattooMap',
  setup() {
    const router = useRouter();
    const map = ref(null);
    const markers = ref([]);
    const artists = ref([]);
    const visibleArtists = ref([]);
    const selectedGenre = ref(null);
    const isMapLoaded = ref(false);
    const isGenreOpen = ref(false);
    const currentCountry = ref('US'); // 기본값으로 초기화
    const filteredArtists = ref([]); // 필터링된 아티스트 목록
    const currentInfoWindow = ref(null);
    const mapState = ref({
      center: null,
      zoom: null,
      timestamp: null
    });

    const genres = [
      "감성타투", "동양화", "레터링", "라인워크", "블랙워크", "블랙앤그레이",
      "수채화", "뉴스쿨", "미니타투", "올드스쿨", "이레즈미", "일러스트",
      "컬러타투", "커버업", "치카노", "터치업", "트라이벌", "애니/만화"
    ];

    // 주소 캐시를 위한 Map 추가
    const addressCache = new Map();

    // 주소 표시를 위한 ref 추가
    const addressMap = ref(new Map());

    // 마커 생성 함수 수정
    const createMarker = (artist) => {
      if (!artist.location?.latitude || !artist.location?.longitude) return null;

      const markerContent = `
        <div class="marker-info-window">
          <div class="artist-preview">
            <div class="preview-info">
              <h3>${artist.nickname}</h3>
              <p class="genre">${translateGenre(artist.mainGenre)}</p>
              ${artist.distance ? `
                <p class="distance">
                  ${t.value.infoWindow.distance}: ${
                    artist.distance >= 1000 
                    ? `${(artist.distance / 1000).toFixed(1)}${t.value.infoWindow.kilometers}`
                    : `${Math.round(artist.distance)}${t.value.infoWindow.meters}`
                  }
                </p>
              ` : ''}
            </div>
          </div>
          <button class="view-profile-btn" onclick="window.location.href='/tattooist/${artist.id}'">
            ${t.value.infoWindow.viewProfile}
          </button>
        </div>
      `;

      const marker = new window.google.maps.Marker({
        position: {
          lat: artist.location.latitude,
          lng: artist.location.longitude
        },
        map: map.value,
        title: artist.nickname,
        icon: {
          url: artist.profileImageUrl || defaultAvatar,
          scaledSize: new window.google.maps.Size(40, 40)
        }
      });

      const infoWindow = new window.google.maps.InfoWindow({
        content: markerContent,
        maxWidth: 300,
        disableAutoPan: false,
        // X 버튼 제거를 위한 스타일
        pixelOffset: new window.google.maps.Size(0, -5)
      });

      // 마커 클릭 이벤트 수정
      marker.addListener('click', () => {
        if (currentInfoWindow.value === infoWindow) {
          // 같은 마커를 다시 클릭하면 InfoWindow 닫기
          currentInfoWindow.value.close();
          currentInfoWindow.value = null;
        } else {
          // 다른 InfoWindow가 열려있으면 닫기
          if (currentInfoWindow.value) {
            currentInfoWindow.value.close();
          }
          // 새로운 InfoWindow 열기
          infoWindow.open(map.value, marker);
          currentInfoWindow.value = infoWindow;
        }
      });

      // 지도 클릭 시 InfoWindow 닫기
      map.value.addListener('click', () => {
        if (currentInfoWindow.value) {
          currentInfoWindow.value.close();
          currentInfoWindow.value = null;
        }
      });

      return marker;
    };

    // 보이는 영역의 타투이스트 업데이트
    const updateVisibleArtists = () => {
      try {
        if (!map.value) return;

        const bounds = map.value.getBounds();
        if (!bounds) return;

        visibleArtists.value = artists.value.filter(artist => {
          if (!artist.location?.latitude || !artist.location?.longitude) {
            return false;
          }

          const latLng = new window.google.maps.LatLng(
            artist.location.latitude,
            artist.location.longitude
          );

          return bounds.contains(latLng);
        });

      } catch (error) {
        visibleArtists.value = [];
      }
    };

    // 장르별 필터링
    const filterByGenre = async (genre) => {
      selectedGenre.value = genre;
      isGenreOpen.value = false;

      try {
        // 기존 마커 모두 제거
        if (markers.value) {
          markers.value.forEach(marker => {
            if (marker) {
              marker.setMap(null);
              if (window.google && window.google.maps) {
                window.google.maps.event.clearInstanceListeners(marker);
              }
            }
          });
        }
        markers.value = [];

        // 장르에 따라 아티스트 필터링
        const filtered = !genre 
          ? artists.value 
          : artists.value.filter(artist => 
              artist.mainGenre === genre || 
              (artist.subGenres && artist.subGenres.includes(genre))
            );

        // 필터링된 아티스트들의 새 마커 생성
        const markerPromises = filtered.map(artist => createMarker(artist));
        markers.value = (await Promise.all(markerPromises)).filter(Boolean);

        // 필터링된 아티스트 목록 업데이트
        filteredArtists.value = filtered;

        // 보이는 아티스트 목록 업데이트
        updateVisibleArtists();

        console.log(`${markers.value.length}개의 마커가 필터링되어 표시됨`);
      } catch (error) {
        console.error('장르 필터링 중 오류:', error);
      }
    };

    // 프로필 페이지로 이동
    const navigateToProfile = (artistId) => {
      router.push(`/tattooist/${artistId}`);
    };

    // 현재 언어 설정 함수 수정 - 항상 영어 반환
    const getCurrentLanguage = () => 'en';

    // 타투이스트 데이터 로드
    const loadArtists = async () => {
      try {
        const usersRef = collection(db, 'users');
        const snapshot = await getDocs(usersRef);

        if (snapshot.empty) {
          artists.value = [];
          return;
        }

        const allArtists = snapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .filter(user => {
            const isTattooist = user.userType === 'tattooist';
            const hasLocation = user.location?.latitude && user.location?.longitude;
            return isTattooist && hasLocation;
          });

        artists.value = allArtists;

        // 새 마커 생성
        markers.value = artists.value
          .map(artist => createMarker(artist))
          .filter(Boolean);

        if (map.value && map.value.getBounds()) {
          updateVisibleArtists();
        }

      } catch (error) {
        console.error('Artists loading error:', error);
        artists.value = [];
        markers.value = [];
      }
    };

    // 현재 위치로 이동
    const moveToCurrentLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            
            map.value.setCenter(pos);
            map.value.setZoom(14);
            
            // 현재 위치 마커
            new window.google.maps.Marker({
              position: pos,
              map: map.value,
              icon: {
                path: window.google.maps.SymbolPath.CIRCLE,
                scale: 10,
                fillColor: '#4285F4',
                fillOpacity: 1,
                strokeColor: '#ffffff',
                strokeWeight: 2,
              }
            });
          },
          (error) => {
            console.error('위치 정보를 가져오는데 실패했습니다:', error);
            alert('위치 정보를 가져오는데 실패했습니다. 위치 권한을 확인해주세요.');
          }
        );
      }
    };

    const toggleGenreFilter = () => {
      isGenreOpen.value = !isGenreOpen.value;
    };

    const resetFilters = () => {
      selectedGenre.value = null;
      filterByGenre(null);
    };

    // Google Maps 초기화 수정
    const initMap = async () => {
      // Google Maps API가 완전히 로드될 때까지 대기
      if (!window.google || !window.google.maps) {
        throw new Error('Google Maps API가 아직 로드되지 않았습니다.');
      }

      try {
        // localStorage에서 선택된 국가 코드를 가져옴
        const selectedCountry = localStorage.getItem('selectedCountry')?.toUpperCase() || 'US';
        currentCountry.value = selectedCountry;
        
        const defaultCenter = centers[currentCountry.value];
        const language = getCurrentLanguage();
        
        map.value = new window.google.maps.Map(document.getElementById('map'), {
          center: { lat: defaultCenter.lat, lng: defaultCenter.lng },
          zoom: defaultCenter.zoom,
          mapTypeControl: false,
          streetViewControl: false,
          zoomControl: true,
          zoomControlOptions: {
            position: window.google.maps.ControlPosition.RIGHT_CENTER
          },
          gestureHandling: 'greedy',
          fullscreenControl: false,
          language: language
        });

        // 지도 이벤트 리스너 추가
        map.value.addListener('idle', updateVisibleArtists);
        
        isMapLoaded.value = true;
        await loadArtists();
        
        addCustomStyles();
      } catch (error) {
        console.error('지도 초기화 중 오류:', error);
        throw error;
      }
    };

    // Google Maps 스크립트 로드 수정
    const loadGoogleMapsApi = () => {
      return new Promise((resolve, reject) => {
        try {
          const script = document.createElement('script');
          script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAdpdeB1s5S-ZS9BSyhD5AjTHgN1VvBoms&language=${getCurrentLanguage()}&region=${currentCountry.value}&loading=async&callback=initGoogleMaps`;
          script.async = true;
          script.defer = true;
          
          // 전역 콜백 함수 정의
          window.initGoogleMaps = () => {
            resolve();
            delete window.initGoogleMaps;
          };
          
          script.addEventListener('error', (e) => reject(e));
          
          document.head.appendChild(script);
        } catch (error) {
          reject(error);
        }
      });
    };

    onMounted(async () => {
      currentCountry.value = getPreferredRegion();
      
      try {
        await loadGoogleMapsApi();
        // Google Maps API가 완전히 로드될 때까지 짧은 대기
        await new Promise(resolve => setTimeout(resolve, 100));
        await initMap();
        restoreMapState();
        await incrementPageView('TattooMap');
      } catch (error) {
        console.error('Google Maps 로딩 또는 초기화 실패:', error);
      }
    });

    // setup() 내부에 추가
    const currentLang = ref(getCurrentLanguage());
    const t = computed(() => translations[currentLang.value]);

    // 장르 번역 함수 수정
    const translateGenre = (genre) => {
      if (!genre) return '';
      return t.value.genres[genre] || genre;
    };

    // 주소 포맷팅을 위한 computed 함수 추가
    const formatLocationAddress = (artist) => {
      if (!artist.location?.address) return '';
      
      // country가 'korea'인 경우
      if (artist.country?.toLowerCase() === 'korea') {
        const addressParts = artist.location.address.split(',');
        return addressParts.slice(-2).join(',');
      }
      
      // 다른 국가의 경우 전체 주소 반환
      return artist.location.address;
    };

    // 컴포넌트 setup에서 formatAddress를 computed로 변경
    const formattedAddress = computed(() => {
      return async (location) => {
        try {
          return await formatAddress(location);
        } catch (error) {
          console.error('Address formatting error:', error);
          return location.address;
        }
      };
    });

    // setup 내부에 watch 추가
    watch(visibleArtists, async (newArtists) => {
      for (const artist of newArtists) {
        if (artist.location && !addressMap.value.has(artist.id)) {
          const formattedAddr = await formatAddress(artist.location);
          addressMap.value.set(artist.id, formattedAddr);
        }
      }
    }, { immediate: true });

    // 스타일 추가
    const addCustomStyles = () => {
      const style = document.createElement('style');
      style.textContent = `
        .gm-ui-hover-effect {
          display: none !important;
        }
        .gm-style .gm-style-iw-c {
          padding: 12px !important;
          border-radius: 8px !important;
        }
        .gm-style .gm-style-iw-d {
          overflow: hidden !important;
          padding: 0 !important;
        }
      `;
      document.head.appendChild(style);
    };

    // 지도 상태 복원
    const restoreMapState = () => {
      try {
        const savedState = sessionStorage.getItem('mapState');
        if (savedState) {
          const state = JSON.parse(savedState);
          // 저장된 상태가 30분 이내인 경우에만 복원
          if (Date.now() - state.timestamp < 30 * 60 * 1000) {
            if (map.value && state.center) {
              map.value.setCenter(state.center);
              map.value.setZoom(state.zoom);
            }
          } else {
            sessionStorage.removeItem('mapState');
          }
        }
      } catch (error) {
        console.error('지도 상태 복원 중 오류:', error);
      }
    };

    // 컴포넌트 언마운트 시 마커 정리
    onUnmounted(() => {
      // 다른 페이지로 이동할 때는 상태 유지
      if (!router.currentRoute.value.path.includes('/tattooist')) {
        sessionStorage.removeItem('mapState');
      }
    });

    // 지역 설정 감지 함수
    const getPreferredRegion = () => {
      const regionMapping = {
        'ko': 'KR',
        'ja': 'JP',
        'th': 'TH',
        'de': 'DE',
        'it': 'IT',
        'br': 'BR',
        'ca': 'CA',
        'fr': 'FR',
        'es': 'ES',
        'nl': 'NL',
        'mx': 'MX',
        'se': 'SE',
      };

      const browserLang = navigator.language;
      return regionMapping[browserLang] || regionMapping[browserLang.split('-')[0]] || 'US';
    };

    // 국가 이동 함수 수정
    const moveToCountry = (countryCode) => {
      const center = centers[countryCode];
      if (center && map.value) {
        map.value.panTo({ lat: center.lat, lng: center.lng });
        map.value.setZoom(center.zoom);
        currentCountry.value = countryCode;
      }
    };

    // 주소 포맷팅 함수 수정
    const formatAddress = async (location) => {
      if (!location || !location.address) {
        return '';
      }

      const currentLang = getCurrentLanguage();
      const cacheKey = `${location.latitude},${location.longitude},${currentLang}`;

      if (addressCache.has(cacheKey)) {
        return addressCache.get(cacheKey);
      }

      try {
        const geocoder = new window.google.maps.Geocoder();
        const latLng = {
          lat: location.latitude,
          lng: location.longitude
        };

        const geocodeResult = await new Promise((resolve, reject) => {
          geocoder.geocode({
            location: latLng,
            language: currentLang,
            region: currentCountry.value
          }, (results, status) => {
            if (status === 'OK' && results[0]) {
              resolve(results[0]);
            } else {
              reject(new Error('Geocoding failed'));
            }
          });
        });

        // address_components를 더 세밀하게 처리
        const components = geocodeResult.address_components;
        const addressParts = [];

        // 주소 컴포넌트 매핑
        const getComponent = (types) => {
          return components.find(component => 
            types.some(type => component.types.includes(type))
          )?.long_name;
        };

        // 동/町名 (neighborhood)
        const neighborhood = getComponent([
          'sublocality_level_2',
          'neighborhood',
          'sublocality'
        ]);
        if (neighborhood) addressParts.push(neighborhood);

        // 구/区 (district)
        const district = getComponent([
          'sublocality_level_1',
          'administrative_area_level_2'
        ]);
        if (district) addressParts.push(district);

        // 시/市 (city)
        const city = getComponent([
          'administrative_area_level_1',
          'locality'
        ]);
        if (city) addressParts.push(city);

        // 주소 형식을 언어에 맞게 조정
        let formattedResult;
        if (currentLang === 'ja') {
          // 일본어 형식: 市区町村
          formattedResult = addressParts.reverse().join('');
        } else if (currentLang === 'ko') {
          // 한국어 형식: 시 구 동
          formattedResult = addressParts.reverse().join(' ');
        } else {
          // 기타 언어: 동, 구, 시
          formattedResult = addressParts.join(', ');
        }
        addressCache.set(cacheKey, formattedResult);
        return formattedResult;

      } catch (error) {
        console.error('Geocoding error:', error);
        return location.address;
      }
    };

    return {
      map,
      markers,
      artists,
      visibleArtists,
      filteredArtists,
      isMapLoaded,
      currentCountry,
      selectedGenre,
      isGenreOpen,
      currentInfoWindow,
      mapState,
      genres,
      moveToCountry,
      filterByGenre,
      formatAddress,
      navigateToProfile,
      defaultAvatar,
      moveToCurrentLocation,
      toggleGenreFilter,
      resetFilters,
      countries,
      t,
      translateGenre,
      formattedAddress,
      addressMap,
      formatLocationAddress
    };
  }
});
</script>

<style scoped>
.map-container {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.map-wrapper {
  position: relative;
  height: 65vh;
  z-index: 1;
}

.map-area {
  width: 100%;
  height: 100%;
}

.artists-container {
  position: relative;
  z-index: 2;
  background: white;
  height: 35vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.location-info {
  position: sticky;
  top: 0;
  padding: 8px 16px;
  background: #f8f9fa;
  text-align: center;
  z-index: 3;
}

.artists-list {
  padding: 16px;
  background: white;
}

.country-buttons {
  margin-top: 60px;
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  display: flex;
  gap: 8px;
  padding: 8px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.country-button {
  padding: 8px 12px;
  border: none;
  border-radius: 15px;
  background: white;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.country-button.active {
  background: #007AFF;
  color: white;
}

.current-location-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: white;
  border: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #007AFF;
  z-index: 1;
}

.small-text {
  font-size: 12px;
  color: #666;
  margin: 0;
}

.artists-grid {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}

.artist-card {
  display: flex;
  padding: 12px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s ease;
}

.artist-card:active {
  transform: scale(0.98);
}

.artist-image {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  object-fit: cover;
  margin-right: 12px;
}

.artist-info {
  flex: 1;
}

.artist-info h4 {
  font-size: 16px;
  margin: 0 0 4px 0;
}

.artist-info .genre {
  font-size: 14px;
  color: #666;
  margin: 0 0 4px 0;
}

.artist-info .location {
  font-size: 12px;
  color: #888;
  margin: 0;
}

/* InfoWindow 스타일 */
:deep(.marker-info-window) {
  padding: 12px;
}

:deep(.artist-preview) {
  margin-bottom: 12px;
}

:deep(.preview-info h3) {
  font-size: 16px;
  margin: 0 0 4px 0;
}

:deep(.preview-info .genre) {
  font-size: 14px;
  color: #666;
  margin: 0 0 4px 0;
}

:deep(.preview-info .distance) {
  font-size: 12px;
  color: #888;
  margin: 0;
}

:deep(.view-profile-btn) {
  width: 100%;
  padding: 8px;
  background: #007AFF;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
}

/* 모바일 최적화 */
@media (max-width: 768px) {
  .map-wrapper {
    height: 60vh;
  }

  .artists-container {
    height: 40vh;
  }

  .artists-grid {
    grid-template-columns: 1fr;
  }

  .country-buttons {
    padding: 6px;
  }

  .country-button {
    padding: 6px 10px;
    font-size: 14px;
  }

  .artist-card {
    padding: 10px;
  }

  .artist-image {
    width: 50px;
    height: 50px;
  }

  .artist-info h4 {
    font-size: 14px;
  }

  .artist-info .genre {
    font-size: 12px;
  }

  .artist-info .location {
    font-size: 11px;
  }
}

/* 작은 모바일 화면 */
@media (max-width: 320px) {
  .country-button {
    padding: 4px 8px;
    font-size: 12px;
  }
}
</style>
  