<template>
  <div class="schedule-manager">

    <!-- 캘린더 컴포넌트 -->
    <div class="calendar-wrapper">
      <FullCalendar :options="calendarOptions" />
    </div>

    <!-- 예약 생성 모달 -->
    <div v-if="showCreateEventModal" class="modal" @click="showCreateEventModal = false">
      <div class="modal-content" @click.stop>
        <div class="modal-header">
          <h3>Create New Appointment</h3>
        </div>

        <div class="modal-body">
          <form @submit.prevent="createEvent">
            <div class="form-group">
              <label>Customer Name</label>
              <input v-model="newEvent.customerName" type="text" required>
            </div>

            <div class="form-group">
              <label>Phone Number</label>
              <input 
                v-model="newEvent.phone" 
                type="tel" 
                @input="handlePhoneInput"
                required
              >
            </div>

            <div class="form-group">
              <label>Date</label>
              <input v-model="newEvent.date" type="date" required lang="en">
            </div>

            <div class="form-group">
              <label>Start Time</label>
              <input v-model="newEvent.startTime" type="time" required>
            </div>

            <div class="form-group">
              <label>Duration (hours)</label>
              <input v-model="newEvent.duration" type="number" min="1" max="12" required>
            </div>

            <div class="form-group">
              <label>Price</label>
              <input v-model="newEvent.price" type="number" min="0">
            </div>

            <div class="form-group">
              <label>Details</label>
              <textarea v-model="newEvent.content" rows="3"></textarea>
            </div>

            <div class="modal-actions">
              <button type="button" @click="showCreateEventModal = false" class="btn-secondary">Cancel</button>
              <button type="submit" class="btn-primary">Create</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- 예약 상세정보 모달 추가 -->
    <div v-if="showEventDetailModal" class="modal" @click="showEventDetailModal = false">
      <div class="modal-content" @click.stop>
        <div class="modal-header">
          <h3>Appointment Details</h3>
        </div>

        <div class="modal-body" v-if="selectedEvent">
          <div class="info-group">
            <div class="customer-info-section">
              <div class="info-item">
                <strong>Customer Name</strong>
                <p>{{ selectedEvent.extendedProps.customerName }}</p>
              </div>
              <div class="info-item">
                <strong>Phone Number</strong>
                <p>{{ getCustomerPhone }}</p>
              </div>
              <div class="button-container">
                <a 
                  v-if="getCustomerPhone !== 'No phone number'"
                  :href="`tel:${getCustomerPhone.replace(/-/g, '')}`" 
                  class="btn-call"
                >
                  <i class="fas fa-phone"></i>
                  Call
                </a>
                <button 
                  v-if="selectedEvent?.extendedProps?.chatRoomId" 
                  class="btn-chat" 
                  @click="goToChat"
                >
                  <i class="fas fa-comments"></i>
                  Go to Chat
                </button>
              </div>
            </div>

            <div class="info-item">
              <strong>Appointment Time</strong>
              <p>{{ formatDateTime(selectedEvent.start) }}</p>
            </div>
            <div class="info-item">
              <strong>Duration</strong>
              <p>{{ calculateDuration(selectedEvent.start, selectedEvent.end) }} hours</p>
            </div>
            <div class="info-item">
              <strong>Price</strong>
              <p>{{ formatPrice(selectedEvent.extendedProps.price) }}</p>
            </div>
            <div class="info-item">
              <strong>Details</strong>
              <p>{{ selectedEvent.extendedProps.content || selectedEvent.extendedProps.description || 'No details' }}</p>
            </div>
            <div v-if="selectedEvent.extendedProps.images?.length" class="info-item">
              <strong>Reference Images</strong>
              <div class="images-grid">
                <div 
                  v-for="(image, index) in selectedEvent.extendedProps.images" 
                  :key="index" 
                  class="image-preview"
                  @click="openImageViewer(image)"
                >
                  <img :src="image" :alt="`Reference image ${index + 1}`">
                </div>
              </div>
            </div>
          </div>

          <div class="modal-actions">
            <button class="btn-danger" @click="deleteEvent">Delete Appointment</button>
            <button class="btn-secondary" @click="showEventDetailModal = false">Close</button>
          </div>
        </div>
      </div>
    </div>

    <!-- 이미지 뷰어 모달 추가 -->
    <div v-if="showImageViewer" class="image-viewer-modal" @click="showImageViewer = false">
      <div class="image-viewer-content" @click.stop>
        <img :src="selectedImage" alt="Reference image">
        <button class="close-viewer" @click="showImageViewer = false">Close</button>
      </div>
    </div>

    <!-- 예약 생성 버튼 -->
    <button class="create-event-button" @click="showCreateEventModal = true">
      <i class="fas fa-plus"></i>
    </button>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { db } from '@/firebase';
import { collection, getDocs, getDoc, addDoc, serverTimestamp, deleteDoc, doc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { useRouter } from 'vue-router';

export default {
  name: 'ScheduleManager',
  components: {
    FullCalendar
  },
  setup() {
    const auth = getAuth();
    const router = useRouter();
    const showCreateEventModal = ref(false);
    const selectedEvent = ref(null);
    const showEventDetailModal = ref(false);
    const newEvent = ref({
      customerName: '',
      phone: '',
      date: '',
      startTime: '',
      duration: 1,
      price: 0,
      content: ''
    });
    const showImageViewer = ref(false);
    const selectedImage = ref(null);
    const customerData = ref(null);

    let selectedLanguage = ref(localStorage.getItem('selectedLanguage') || 'en');
    if (selectedLanguage.value === 'jp') {
      selectedLanguage.value = 'ja'; // 'jp'를 'ja'로 변환
    }

    const getCustomerPhone = computed(() => {
      // 채팅룸 예약인 경우 (customerId가 있는 경우)
      if (selectedEvent.value?.extendedProps?.customerId && customerData.value?.phone) {
        return customerData.value.phone;
      }
      
      // 직접 생성 예약인 경우
      if (selectedEvent.value?.extendedProps?.phone) {
        return selectedEvent.value.extendedProps.phone;
      }
      
      return 'No phone number';
    });

    // Event Click Handler
    const handleEventClick = async (info) => {
      console.log('클릭된 이벤트:', info.event);
      console.log('이벤트 extendedProps:', info.event.extendedProps);
      
      selectedEvent.value = info.event;
      
      // 고객 정보 로드 (채팅룸 예약의 경우)
      if (info.event.extendedProps.customerId) {
        await loadCustomerData(info.event.extendedProps.customerId);
      }
      
      showEventDetailModal.value = true;
    };

    // Date Select Handler
    const handleDateSelect = (selectInfo) => {
      console.log('날짜 선택:', selectInfo);
    };

    // Calendar Options
    const calendarOptions = ref({
      plugins: [dayGridPlugin, interactionPlugin],
      initialView: 'dayGridMonth',
      locale: selectedLanguage.value,
      headerToolbar: {
        left: '',
        center: 'title',
        right: 'prev,next'
      },
      views: {
        dayGridMonth: {
          showNonCurrentDates: false,
          fixedWeekCount: false
        }
      },
      selectable: true,
      editable: false,
      eventClick: handleEventClick,
      select: handleDateSelect,
      events: [],
      eventDisplay: 'block',
      eventTimeFormat: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      },
      eventContent: (arg) => {
        const timeText = arg.timeText;
        const customerName = arg.event.extendedProps.customerName || '';
        
        return {
          html: `
            <div class="fc-event-main-content">
              <div class="fc-event-time">${timeText}</div>
              <div class="fc-event-title">${customerName}</div>
            </div>
          `
        };
      }
    });

    // 이벤트 스타일 추가
    const addEventStyles = () => {
      const style = document.createElement('style');
      style.textContent = `
        .fc-event {
          border: none !important;
          padding: 4px 6px !important;
          margin: 2px 0 !important;
        }
        .fc-event-main-content {
          display: flex;
          flex-direction: column;
          gap: 2px;
        }
        .fc-event-time {
          font-size: 0.85em !important;
          font-weight: 500 !important;
        }
        .fc-event-title {
          font-size: 0.9em !important;
          font-weight: 600 !important;
          white-space: nowrap !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
        }
      `;
      document.head.appendChild(style);
    };

    // Load Schedule
    const loadSchedule = async () => {
      try {
        if (!auth.currentUser) return;
        
        const eventsRef = collection(db, 'users', auth.currentUser.uid, 'events');
        const snapshot = await getDocs(eventsRef);
        
        const events = snapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            title: data.title,
            start: data.start.toDate(),
            end: data.end.toDate(),
            extendedProps: {
              customerName: data.customerName,
              phone: data.phone,
              customerId: data.customerId,
              chatRoomId: data.chatRoomId,
              price: data.price,
              content: data.content || data.description || '',
              images: data.images || []
            }
          };
        });

        calendarOptions.value = {
          ...calendarOptions.value,
          events: events
        };
      } catch (error) {
        console.error('스케줄 로드 실패:', error);
      }
    };

    // 날짜 포맷 함수
    const formatDate = (date) => {
      return new Date(date).toLocaleDateString('ko-KR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    };

    // 시간 포맷 함수
    const formatTime = (date) => {
      return new Date(date).toLocaleTimeString('ko-KR', {
        hour: '2-digit',
        minute: '2-digit'
      });
    };

    // 가격 포맷 함수
    const formatPrice = (price) => {
      return new Intl.NumberFormat('ko-KR', {
        style: 'currency',
        currency: 'KRW'
      }).format(price);
    };

    // 컴포넌트가 마운트될 때 스타일 추가 및 스케줄 로드
    onMounted(() => {
      addEventStyles();
      loadSchedule();
    });

    // 전화번호 입력 핸들러 수정
    const handlePhoneInput = (e) => {
      let value = e.target.value;
      
      // 숫자와 하이픈만 허용
      value = value.replace(/[^\d-]/g, '');
      
      newEvent.value.phone = value;
    };

    const createEvent = async () => {
      try {
        // 전화번호 유효성 검사
        if (!isValidPhoneNumber(newEvent.value.phone)) {
          alert('올바른 전화번호 형식이 아닙니다.\n예시: 010-1234-5678');
          return;
        }

        const startDateTime = new Date(`${newEvent.value.date}T${newEvent.value.startTime}`);
        const endDateTime = new Date(startDateTime.getTime() + (newEvent.value.duration * 60 * 60 * 1000));

        const eventData = {
          type: 'booking',
          title: `예약: ${newEvent.value.customerName}`,
          start: startDateTime,
          end: endDateTime,
          customerName: newEvent.value.customerName,
          phone: newEvent.value.phone,
          price: parseInt(newEvent.value.price),
          description: newEvent.value.content,
          content: newEvent.value.content,
          createdAt: serverTimestamp(),
          status: 'confirmed'
        };

        console.log('생성할 이벤트 데이터:', eventData); // 디버깅용 로그

        const eventsRef = collection(db, 'users', auth.currentUser.uid, 'events');
        await addDoc(eventsRef, eventData);

        // 모달 닫고 폼 초기화
        showCreateEventModal.value = false;
        newEvent.value = {
          customerName: '',
          phone: '',
          date: '',
          startTime: '',
          duration: 1,
          price: 0,
          content: ''
        };

        // 캘린더 새로고침
        loadSchedule();

      } catch (error) {
        console.error('예약 생성 실패:', error);
        alert('예약 생성에 실패했습니다. 다시 시도해주세요.');
      }
    };

    const formatDateTime = (date) => {
      const d = new Date(date);
      const year = d.getFullYear();
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const day = String(d.getDate()).padStart(2, '0');
      const hours = String(d.getHours()).padStart(2, '0');
      const minutes = String(d.getMinutes()).padStart(2, '0');
      
      return `${year}년 ${month}월 ${day}일 ${hours}:${minutes}`;
    };

    const calculateDuration = (start, end) => {
      const diff = new Date(end) - new Date(start);
      return Math.round(diff / (1000 * 60 * 60));
    };

    const deleteEvent = async () => {
      if (!selectedEvent.value || !confirm('이 예약을 삭제하시겠습니까?')) return;

      try {
        const eventId = selectedEvent.value.id;
        const eventRef = doc(db, 'users', auth.currentUser.uid, 'events', eventId);
        await deleteDoc(eventRef);
        
        showEventDetailModal.value = false;
        loadSchedule();
        alert('예약이 삭제되었습니다.');
      } catch (error) {
        console.error('예약 삭제 실패:', error);
        alert('예약 삭제에 실패했습니다.');
      }
    };

    const openImageViewer = (imageUrl) => {
      selectedImage.value = imageUrl;
      showImageViewer.value = true;
    };

    // 고객 정보 로드 함수
    const loadCustomerData = async (customerId) => {
      if (!customerId) return;
      
      try {
        const customerDoc = await getDoc(doc(db, 'users', customerId));
        if (customerDoc.exists()) {
          customerData.value = customerDoc.data();
        }
      } catch (error) {
        console.error('고객 정보 로드 실패:', error);
        customerData.value = null;
      }
    };

    // 채팅방으로 이동하는 함수
    const goToChat = () => {
      const chatRoomId = selectedEvent.value?.extendedProps?.chatRoomId;
      console.log('채팅방 ID:', chatRoomId); // 디버깅용 로그
      
      if (chatRoomId) {
        router.push(`/chat/${chatRoomId}`);
      } else {
        console.log('채팅방 ID가 없습니다.');
      }
    };

    // 전화번호 유효성 검사 함수
    const isValidPhoneNumber = (phone) => {
      const phonePattern = /^010-\d{4}-\d{4}$/;  // 010으로 시작하는 패턴만 허용
      return phonePattern.test(phone);
    };

    return {
      calendarOptions,
      showCreateEventModal,
      selectedEvent,
      showEventDetailModal,
      newEvent,
      createEvent,
      handleEventClick,
      handleDateSelect,
      formatDate,
      formatTime,
      formatPrice,
      formatDateTime,
      calculateDuration,
      deleteEvent,
      showImageViewer,
      selectedImage,
      openImageViewer,
      customerData,
      goToChat,
      getCustomerPhone,
      handlePhoneInput,
      isValidPhoneNumber,
    };
  }
};
</script>

<style scoped>
/* 기본 레이아웃 */
.schedule-manager {
  margin-top: 60px;
  background: #ffffff;
  border-radius: 0;
  box-shadow: none;
  
}

/* 캘린더 기본 스타일 */
:deep(.fc) {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  background: #ffffff;
  border-radius: 0;
  overflow: hidden;
  box-shadow: none;
  height: calc(80vh - 60px) !important;
}

/* 캘린더 헤더 */
:deep(.fc-header-toolbar) {
  padding: 20px 0 !important;
  margin: 0 !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  background: #ffffff !important;
  border-bottom: 1px solid #f1f5f9 !important;
}

:deep(.fc-button-group) {
  gap: 10px;
  position: absolute;
  right: 20px;
}

:deep(.fc-toolbar-chunk) {
  display: flex !important;
  align-items: center !important;
  margin-left: 10px;
}

/* 타이틀(날짜) 스타일 개선 */
:deep(.fc-toolbar-title) {
  font-size: 24px !important;
  font-weight: 700 !important;
  color: #1f2937 !important;
  padding: 0 !important;
  text-align: center !important;
  letter-spacing: -0.5px !important;
}

/* 좌우 버튼 스타일 개선 */
:deep(.fc-prev-button),
:deep(.fc-next-button) {
  width: 40px !important;
  height: 40px !important;
  border-radius: 12px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
  background: #f8fafc !important;
  border: 1px solid #e2e8f0 !important;
  color: #475569 !important;
  transition: all 0.2s ease !important;
  cursor: pointer !important;
}

:deep(.fc-prev-button:hover),
:deep(.fc-next-button:hover) {
  background: #f1f5f9 !important;
  border-color: #cbd5e1 !important;
  color: #1e293b !important;
  transform: translateY(-1px) !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1) !important;
}

:deep(.fc-prev-button:active),
:deep(.fc-next-button:active) {
  transform: translateY(0) !important;
  box-shadow: none !important;
}

/* 캘린더 그리드 */
:deep(.fc-scrollgrid) {
  border: none !important;
}

:deep(.fc-col-header-cell) {
  background: #f8fafc !important;
  border-color: #e5e7eb !important;
}

:deep(.fc-col-header-cell-cushion) {
  padding: 8px !important;
  color: #4b5563 !important;
  font-weight: 600 !important;
  text-decoration: none !important;
}

/* 날짜 셀 */
:deep(.fc-daygrid-day) {
  transition: background-color 0.2s;
}

:deep(.fc-daygrid-day:hover) {
  background-color: #f8fafc;
}

:deep(.fc-daygrid-day-frame) {
  padding: 2px !important;
  min-height: unset !important;
}

:deep(.fc-daygrid-day-number) {
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #374151 !important;
  padding: 4px !important;
  text-decoration: none !important;
}

/* 이벤트 스타일 */
:deep(.fc-event) {
  background: #ffffff !important;
  border: 1px solid #e5e7eb !important;
  border-left: 3px solid #2563eb !important;
  border-radius: 6px !important;
  padding: 2px 4px !important;
  margin: 1px 2px !important;
  cursor: pointer !important;
  transition: all 0.2s !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) !important;
  min-height: 20px !important;
}

:deep(.fc-event-main) {
  padding: 0 2px !important;
  color: #1a1a1a !important;
  display: flex !important;
  align-items: center !important;
  gap: 2px !important;
  font-size: 11px !important;
  line-height: 1.2 !important;
}

:deep(.fc-event-time) {
  font-size: 11px !important;
  font-weight: 500 !important;
  color: #2563eb !important;
  flex-shrink: 0 !important;
}

:deep(.fc-event-title) {
  font-size: 11px !important;
  font-weight: 400 !important;
  color: #374151 !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  flex: 1 !important;
}

/* 모보기 링크 */
:deep(.fc-daygrid-more-link) {
  font-size: 11px !important;
  font-weight: 500 !important;
  color: #2563eb !important;
  background: #f0f5ff !important;
  padding: 1px 4px !important;
  border-radius: 4px !important;
  margin-top: 2px !important;
}

/* 모달 스타일 */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 16px;
  width: calc(100% - 32px);
  max-height: 90vh;
  overflow-y: auto;
}

/* 고객 정보 섹션 */
.customer-info-section {
  background: #f8fafc;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 24px;
}

/* 정보 항목 */
.info-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid #e5e7eb;
}

.info-item:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.info-item strong {
  font-size: 14px;
  font-weight: 600;
  color: #4b5563;
}

.info-item p {
  font-size: 16px;
  color: #1a1a1a;
  margin: 0;
  line-height: 1.5;
}

/* 채팅 버튼 */
.chat-button-container {
  margin-top: 16px;
}

.btn-chat {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background: #2563eb;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.2s;
  width: 100%;
  justify-content: center;
}

.btn-chat:hover {
  background: #1d4ed8;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(37, 99, 235, 0.25);
}

.btn-chat i {
  font-size: 18px;
}

/* 이미지 그리드 */
.images-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 12px;
  margin-top: 12px;
}

.image-preview {
  aspect-ratio: 1;
  border-radius: 12px;
  cursor: pointer;
  transition: transform 0.2s;
}

.image-preview:hover {
  transform: scale(1.05);
}

.image-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 모달 액션 버튼 */
.modal-actions {
  display: flex;
  gap: 16px;
  margin-top: 32px;
  padding-top: 24px;
  border-top: 1px solid #e5e7eb;
}

.btn-danger {
  flex: 1;
  padding: 12px 24px;
  background: #ef4444;
  color: white;
  border: none;
  border-radius: 12px;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.2s;
}

.btn-secondary {
  flex: 1;
  padding: 12px 24px;
  background: #f3f4f6;
  color: #4b5563;
  border: 1px solid #e5e7eb;
  border-radius: 12px;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.2s;
}

.btn-primary {
  flex: 1;
  padding: 12px 24px;
  background: #3b82f6;
  color: white;
  border: none;
  border-radius: 12px;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.2s;
}

/* 이미지 뷰어 모달 */
.image-viewer-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
}

.image-viewer-content {
  position: relative;
  max-width: 90vw;
  max-height: 90vh;
}

.image-viewer-content img {
  max-width: 100%;
  max-height: 90vh;
  object-fit: contain;
}

.close-viewer {
  position: absolute;
  top: -40px;
  right: 0;
  background: none;
  border: none;
  color: white;
  font-size: 14px;
  cursor: pointer;
  padding: 8px;
  transition: opacity 0.2s;
}

.close-viewer:hover {
  opacity: 0.8;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e5e7eb;
}

.modal-header h3 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: #1a1a1a;
}

/* 폼 스타일 */
.form-group {
  margin-bottom: 12px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #374151;
  font-size: 15px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #e5e7eb;
  border-radius: 12px;
  font-size: 16px;
  color: #1a1a1a;
  background: #ffffff;
  transition: all 0.2s;
  box-sizing: border-box;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
}

/* 숫자 입력 필드 화살표 스타일 */
.form-group input[type="number"] {
  -moz-appearance: textfield;
}

.form-group input[type="number"]::-webkit-outer-spin-button,
.form-group input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* 생성 버튼 */
.btn-create {
  width: 100%;
  padding: 12px;
  background: #2563eb;
  color: white;
  border: none;
  border-radius: 12px;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
  transition: all 0.2s;
}

.btn-create:hover {
  background: #1d4ed8;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(37, 99, 235, 0.25);
}

.btn-create:active {
  transform: translateY(0);
  box-shadow: none;
}

/* 모달 액션 버튼 그룹 */
.modal-actions {
  display: flex;
  gap: 16px;
  margin-top: 32px;
}

/* 예약 생성 버튼 */
.create-event-button {
  position: fixed;
  bottom: 24px;
  right: 24px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: #2563eb;
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 4px 6px rgba(37, 99, 235, 0.25);
  z-index: 100;
}

.create-event-button:hover {
  transform: translateY(-2px);
  background: #1d4ed8;
  box-shadow: 0 6px 8px rgba(37, 99, 235, 0.3);
}

.create-event-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(37, 99, 235, 0.2);
}

/* 버튼 컨테이너 스타일 */
.button-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
}

/* 전화하기 버튼 스타일 */
.btn-call {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px;
  background-color: #1a1a1a;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease;
}

.btn-call:hover {
  background-color: #16a34a;
  transform: translateY(-1px);
}

.btn-call:active {
  transform: translateY(0);
}

.btn-call i {
  font-size: 18px;
}

/* 채팅 버튼 스타일 수정 */
.btn-chat {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px;
  background-color: #2563eb;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.btn-chat:hover {
  background-color: #1d4ed8;
  transform: translateY(-1px);
}

.btn-chat:active {
  transform: translateY(0);
}
</style> 