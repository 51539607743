<template>
  <div class="signup-container">
    <h1>{{ uiText.signUp }}</h1>
    
    <!-- 진행 상태 표시 -->
    <div class="progress-bar">
      <div class="progress-step" :class="{ active: step >= 1, completed: step > 1 }">
        <div class="step-number">1</div>
        <span>{{ uiText.stepTitles[0] }}</span>
      </div>
      <div class="progress-line" :class="{ active: step > 1 }"></div>
      <div class="progress-step" :class="{ active: step >= 2, completed: step > 2 }">
        <div class="step-number">2</div>
        <span>{{ uiText.stepTitles[1] }}</span>
      </div>
      <div class="progress-line" :class="{ active: step > 2 }"></div>
      <div class="progress-step" :class="{ active: step >= 3, completed: step > 3 }">
        <div class="step-number">3</div>
        <span>{{ uiText.stepTitles[2] }}</span>
      </div>
      <div class="progress-line" :class="{ active: step > 3 }"></div>
      <div class="progress-step" :class="{ active: step >= 4, completed: step > 4 }">
        <div class="step-number">4</div>
        <span>{{ uiText.stepTitles[3] }}</span>
      </div>
      <div class="progress-line" :class="{ active: step > 4 }"></div>
      <div class="progress-step" :class="{ active: step >= 5 }">
        <div class="step-number">5</div>
        <span>{{ uiText.stepTitles[4] }}</span>
      </div>
    </div>

    <!-- 스텝 1: 유형 선택 -->
    <div v-if="step === 1" class="signup-section">
      <div class="user-type-selection">
        <p>{{ uiText.selectType }}</p>
        <div class="type-cards">
          <label class="type-card" :class="{ active: userType === 'user' }">
            <input 
              type="radio" 
              v-model="userType" 
              value="user"
              name="userType"
            >
            <div class="card-content">
              <i :class="userTypeLabels.user.icon"></i>
              <h3>{{ userTypeLabels.user.title }}</h3>
              <p>{{ userTypeLabels.user.description }}</p>
            </div>
          </label>
          <label class="type-card" :class="{ active: userType === 'artist' }">
            <input 
              type="radio" 
              v-model="userType" 
              value="artist"
              name="userType"
            >
            <div class="card-content">
              <i :class="userTypeLabels.artist.icon"></i>
              <h3>{{ userTypeLabels.artist.title }}</h3>
              <p>{{ userTypeLabels.artist.description }}</p>
            </div>
          </label>
        </div>
      </div>
      <button @click="nextStep" :disabled="!userType">{{ uiText.next }}</button>
    </div>

    <!-- 스텝 2: 인증 방식 선택 -->
    <div v-if="step === 2" class="signup-section">
      <div class="auth-method-selection">
        <p>{{ uiText.chooseMethod }}</p>
        <div class="type-cards">
          <label class="type-card" :class="{ active: authMethod === 'email' }">
            <input 
              type="radio" 
              v-model="authMethod" 
              value="email"
              name="authMethod"
            >
            <div class="card-content">
              <i :class="authMethodLabels.email.icon"></i>
              <h3>{{ authMethodLabels.email.title }}</h3>
              <p>{{ authMethodLabels.email.description }}</p>
            </div>
          </label>
          <label class="type-card" :class="{ active: authMethod === 'phone' }">
            <input 
              type="radio" 
              v-model="authMethod" 
              value="phone"
              name="authMethod"
            >
            <div class="card-content">
              <i :class="authMethodLabels.phone.icon"></i>
              <h3>{{ authMethodLabels.phone.title }}</h3>
              <p>{{ authMethodLabels.phone.description }}</p>
            </div>
          </label>
        </div>
      </div>
      <div class="button-group">
        <button class="secondary" @click="prevStep">{{ uiText.back }}</button>
        <button @click="nextStep" :disabled="!authMethod">{{ uiText.next }}</button>
      </div>
    </div>

    <!-- 스텝 3: 이메일/핸드폰 입력 -->
    <div v-if="step === 3" class="signup-section">
      <div class="input-group">
        <template v-if="authMethod === 'email'">
          <input 
            v-model="email" 
            type="email" 
            :placeholder="uiText.emailPlaceholder"
            @input="validateEmail"
            :disabled="isEmailVerified"
          />
          <span v-if="emailError" class="error-message">{{ emailError }}</span>
          
          <!-- 이메일 인증 버튼 -->
          <button 
            @click="sendVerificationEmail" 
            :disabled="!email || emailError || isSendingVerification || isEmailVerified"
            class="verification-button"
          >
            {{ isSendingVerification ? uiText.sending : isEmailVerified ? uiText.emailVerified : uiText.sendVerificationEmail }}
          </button>
          
          <span v-if="isEmailVerified" class="success-message">{{ uiText.emailVerified }}</span>
          <span v-if="!isEmailVerified && emailSent" class="info-message">
            {{ uiText.verificationSent }}
          </span>
        </template>
        
        <template v-if="authMethod === 'phone'">
          <select 
            v-model="selectedCountryCode"
            class="country-code-select"
            :disabled="!!verificationId"
          >
            <option value="+82">+82 (Korea)</option>
            <option value="+1">+1 (USA)</option>
            <option value="+7">+7 (Russia)</option>
            <option value="+27">+27 (South Africa)</option>
            <option value="+33">+33 (France)</option>
            <option value="+34">+34 (Spain)</option>
            <option value="+39">+39 (Italy)</option>
            <option value="+44">+44 (UK)</option>
            <option value="+49">+49 (Germany)</option>
            <option value="+55">+55 (Brazil)</option>
            <option value="+61">+61 (Australia)</option>
            <option value="+62">+62 (Indonesia)</option>
            <option value="+66">+66 (Thailand)</option>
            <option value="+81">+81 (Japan)</option>
            <option value="+86">+86 (China)</option>
            <option value="+91">+91 (India)</option>
            <option value="+234">+234 (Nigeria)</option>
            <option value="+852">+852 (Hong Kong)</option>
            <option value="+886">+886 (Taiwan)</option>
          </select>
          <input 
            v-model="phoneNumber" 
            type="tel" 
            :placeholder="uiText.phonePlaceholder"
            @input="validatePhoneNumber"
            class="phone-input"
            :disabled="!!verificationId"
          />
          <span v-if="phoneNumberError" class="error-message">{{ phoneNumberError }}</span>
          
          <!-- 인증번호 요청/재요청 버튼 -->
          <button 
            @click="requestVerificationCode" 
            :disabled="!phoneNumber || phoneNumberError || isRequestingCode || isVerified"
            class="verification-button"
          >
            {{ isRequestingCode ? uiText.sending : !!verificationId ? uiText.resendCode : uiText.getVerificationCode }}
          </button>

          <!-- 인증번호 입력 필드 -->
          <div v-if="!!verificationId" class="verification-code-input">
            <input 
              v-model="verificationCode" 
              type="text" 
              :placeholder="uiText.enterVerificationCode"
              maxlength="6"
            />
            <button 
              @click="verifyCode" 
              :disabled="!verificationCode || verificationCode.length !== 6 || isVerifying || isVerified"
              class="verify-button"
            >
              {{ isVerifying ? uiText.verifying : uiText.verifyCode }}
            </button>
            <span v-if="verificationError" class="error-message">{{ verificationError }}</span>
            <span v-if="isVerified" class="success-message">{{ uiText.phoneVerified }}</span>
          </div>
        </template>
      </div>
      <div class="button-group">
        <button class="secondary" @click="prevStep">{{ uiText.back }}</button>
        <button @click="nextStep" :disabled="!isVerificationValid">{{ uiText.next }}</button>
      </div>
    </div>

    <!-- 스텝 4: 비밀번호 -->
    <div v-if="step === 4" class="signup-section">
      <div class="input-group">
        <input 
          v-model="password" 
          type="password" 
          :placeholder="uiText.passwordPlaceholder"
          @input="validatePassword"
        />
        <span v-if="passwordError" class="error-message">{{ passwordError }}</span>
        
        <input 
          v-model="confirmPassword" 
          type="password" 
          :placeholder="uiText.confirmPasswordPlaceholder"
          @input="validateConfirmPassword"
        />
        <span v-if="confirmPasswordError" class="error-message">{{ confirmPasswordError }}</span>
      </div>
      <div class="button-group">
        <button class="secondary" @click="prevStep">{{ uiText.back }}</button>
        <button @click="nextStep" :disabled="!password || password !== confirmPassword">{{ uiText.next }}</button>
      </div>
    </div>

    <div v-if="step === 5" class="signup-section">
      <div class="input-group">
        <template v-if="userType === 'user'">
          <input 
            v-model="name" 
            type="text" 
            :placeholder="uiText.namePlaceholder"
            @input="validateName"
          />
          <span v-if="nameError" class="error-message">{{ nameError }}</span>
        </template>
        
        <template v-else>
          <input 
            v-model="nickname" 
            type="text" 
            :placeholder="uiText.nicknamePlaceholder"
            @input="validateNickname"
          />
          <span v-if="nicknameError" class="error-message">{{ nicknameError }}</span>
        </template>
      </div>
      <div class="button-group">
        <button class="secondary" @click="prevStep">{{ uiText.back }}</button>
        <button 
          @click="signup"
          :disabled="!isFormValid || isLoading"
        >
          {{ isLoading ? uiText.processing : uiText.signupButton }}
        </button>
      </div>
    </div>

    <!-- reCAPTCHA container 추가 -->
    <div id="recaptcha-container"></div>

    <div class="login-section">
      <p>{{ uiText.alreadyHaveAccount }} <span class="login-link" @click="goToLogin">{{ uiText.loginLink }}</span></p>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { createUserWithEmailAndPassword, getAuth, RecaptchaVerifier, signInWithPhoneNumber, PhoneAuthProvider, signInWithCredential, sendEmailVerification, updatePassword } from 'firebase/auth';
import { db } from '@/firebase';
import { doc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const router = useRouter();
    const step = ref(1);
    const email = ref('');
    const password = ref('');
    const confirmPassword = ref('');
    const userType = ref('');
    const name = ref('');
    const nickname = ref('');
    const phoneNumber = ref('');
    const emailError = ref('');
    const passwordError = ref('');
    const confirmPasswordError = ref('');
    const nameError = ref('');
    const nicknameError = ref('');
    const phoneNumberError = ref('');
    const isLoading = ref(false);
    const selectedCountryCode = ref('+82');
    const authMethod = ref('');
    const verificationId = ref('');
    const verificationCode = ref('');
    const verificationError = ref('');
    const isRequestingCode = ref(false);
    const isVerifying = ref(false);
    const isVerified = ref(false);
    const emailVerificationId = ref('');
    const emailVerificationCode = ref('');
    const emailVerificationError = ref('');
    const isRequestingEmailCode = ref(false);
    const isVerifyingEmail = ref(false);
    const isEmailVerified = ref(false);
    const auth = getAuth();
    const isSendingVerification = ref(false);
    const emailSent = ref(false);
    const checkInterval = ref(null);
    const tempUser = ref(null);

    const translations = {
      en: {
        signUp: 'Sign Up',
        stepTitles: ['Type', 'Method', 'Verify', 'Password', 'Info'],
        selectType: 'Please select your account type',
        chooseMethod: 'Choose your sign up method',
        userType: {
          user: {
            title: 'Guest',
            description: 'Explore and interact with artists\' works',
            icon: 'fas fa-user'
          },
          artist: {
            title: 'Tattooist',
            description: 'Share your work and connect with fans',
            icon: 'fas fa-palette'
          }
        },
        authMethod: {
          email: {
            title: 'Email',
            description: 'Sign up using your email address',
            icon: 'fas fa-envelope'
          },
          phone: {
            title: 'Phone',
            description: 'Sign up using your phone number',
            icon: 'fas fa-mobile-alt'
          }
        },
        next: 'Next',
        back: 'Back',
        emailPlaceholder: 'Email',
        phonePlaceholder: 'Phone Number (without hyphens)',
        passwordPlaceholder: 'Password (min. 6 characters)',
        confirmPasswordPlaceholder: 'Confirm Password',
        namePlaceholder: 'Name',
        nicknamePlaceholder: 'Nickname',
        emailVerified: 'Email successfully verified!',
        signupSuccess: 'Sign up completed successfully',
        signupFailed: 'Sign up failed: ',
        loginLink: 'Log in here',
        verificationCodeSent: 'Verification code has been sent to your phone.',
        resendCode: 'Resend Code',
        verifyCode: 'Verify Code',
        invalidCode: 'Invalid verification code',
        phoneAlreadyRegistered: 'This phone number is already registered',
        sending: 'Sending...',
        verificationSent: 'Verification link has been sent to your email',
        sendVerificationEmail: 'Send Verification Email',
        getVerificationCode: 'Get Verification Code',
        enterVerificationCode: 'Enter verification code',
        verifying: 'Verifying...',
        phoneVerified: 'Phone number verified successfully!',
        sendCodeFailed: 'Failed to send verification code: ',
        emailRequired: 'Please enter your email.',
        emailInvalid: 'Please enter a valid email address.',
        passwordRequired: 'Please enter your password.',
        passwordLength: 'Password must be at least 6 characters.',
        confirmPasswordRequired: 'Please confirm your password.',
        passwordMismatch: 'Passwords do not match.',
        nameRequired: 'Please enter your name.',
        nameLength: 'Name must be at least 2 characters.',
        nicknameRequired: 'Please enter your nickname.',
        nicknameLength: 'Nickname must be at least 2 characters.',
        phoneRequired: 'Please enter your phone number.',
        phoneInvalid: 'Please enter a valid phone number.',
        alreadyHaveAccount: 'Already have an account?',
        emailAlreadyRegistered: 'This email is already registered',
        processing: 'Processing...',
        signupButton: 'Sign Up'
      },
      ko: {
        signUp: '회원가입',
        stepTitles: ['유형', '방법', '인증', '비밀번호', '정보'],
        selectType: '계정 유형을 선택해주세요',
        chooseMethod: '가입 방법을 선택해주세요',
        userType: {
          user: {
            title: '게스트',
            description: '전세계 타투를 감상하고 문의해보세요',
            icon: 'fas fa-user'
          },
          artist: {
            title: '타투이스트',
            description: '작품을 전세계에 공유하고 고객을 만나보세요',
            icon: 'fas fa-palette'
          }
        },
        authMethod: {
          email: {
            title: '이메일',
            description: '이메일 인증으로 회원가입',
            icon: 'fas fa-envelope'
          },
          phone: {
            title: '전화번호',
            description: '휴대폰 번호 인증으로 회원가입',
            icon: 'fas fa-mobile-alt'
          }
        },
        next: '다음',
        back: '이전',
        emailPlaceholder: '이메일',
        phonePlaceholder: '전화번호 (하이픈 없이)',
        passwordPlaceholder: '비밀번호 (6자 이상)',
        confirmPasswordPlaceholder: '비밀번호 확인',
        namePlaceholder: '이름',
        nicknamePlaceholder: '닉네임',
        emailVerified: '이메일 인증이 완료되었습니다!',
        signupSuccess: '회원가입이 완료되었습니다',
        signupFailed: '회원가입 실패: ',
        loginLink: '로그인 하기',
        verificationCodeSent: '인증번호가 휴대폰으로 전송되었습니다.',
        resendCode: '재전송',
        verifyCode: '인증하기',
        invalidCode: '잘못된 인증번호입니다',
        phoneAlreadyRegistered: '이미 등록된 전화번호입니다',
        sending: '전송 중...',
        verificationSent: '인증 링크가 이메일로 전송되었습니다. 이메일을 확인해주세요.',
        sendVerificationEmail: '인증 이메일 보내기',
        getVerificationCode: '인증번호 받기',
        enterVerificationCode: '인증번호 입력',
        verifying: '인증 중...',
        phoneVerified: '휴대폰 인증이 완료되었습니다!',
        sendCodeFailed: '인증번호 전송 실패: ',
        emailRequired: '이메일을 입력해주세요.',
        emailInvalid: '유효한 이메일 주소를 입력해주세요.',
        passwordRequired: '비밀번호를 입력해주세요.',
        passwordLength: '비밀번호는 6자 이상이어야 합니다.',
        confirmPasswordRequired: '비밀번호를 확인해주세요.',
        passwordMismatch: '비밀번호가 일치하지 않습니다.',
        nameRequired: '이름을 입력해주세요.',
        nameLength: '이름은 2자 이상이어야 합니다.',
        nicknameRequired: '닉네임을 입력해주세요.',
        nicknameLength: '닉네임은 2자 이상이어야 합니다.',
        phoneRequired: '전화번호를 입력해주세요.',
        phoneInvalid: '유효한 전화번호를 입력해주세요.',
        alreadyHaveAccount: '계정이 이미 있으신가요?',
        emailAlreadyRegistered: '이미 등록된 이메일입니다',
        processing: '처리 중...',
        signupButton: '가입 완료'
      },
      jp: {
        signUp: '新規登録',
        stepTitles: ['タイプ', '方法', '認証', 'パスワード', '情報'],
        selectType: 'アカウントタイプを選択してください',
        chooseMethod: '登録方法を選択してください',
        userType: {
          user: {
            title: 'ゲスト',
            description: 'アーティストの作品を探して交流しよう',
            icon: 'fas fa-user'
          },
          artist: {
            title: 'タトゥーアーティスト',
            description: '作品を共有してファンとつながろう',
            icon: 'fas fa-palette'
          }
        },
        authMethod: {
          email: {
            title: 'メール',
            description: 'メールアドレスで登録',
            icon: 'fas fa-envelope'
          },
          phone: {
            title: '電話',
            description: '電話番号で登録',
            icon: 'fas fa-mobile-alt'
          }
        },
        next: '次へ',
        back: '戻る',
        emailPlaceholder: 'メールアドレス',
        phonePlaceholder: '電話番号（ハイフンなし）',
        passwordPlaceholder: 'パスワード（6文字以上）',
        confirmPasswordPlaceholder: 'パスワード確認',
        namePlaceholder: '名前',
        nicknamePlaceholder: 'ニックネーム',
        emailVerified: 'メール認証が完了しました！',
        signupSuccess: '登録が完了しました',
        signupFailed: '登録に失敗しました: ',
        loginLink: 'ログインはこちら',
        verificationCodeSent: '確認コードが携帯に送信されました。',
        resendCode: '再送信',
        verifyCode: '認証する',
        invalidCode: '無効な確認コードです',
        phoneAlreadyRegistered: 'この電話番号は既に登録されています',
        sending: '送信中...',
        verificationSent: '確認リンクをメールで送信しました。メールを確認してください。',
        sendVerificationEmail: '確認メールを送信',
        getVerificationCode: '確認コードを取得',
        enterVerificationCode: '確認コードを入力',
        verifying: '確認中...',
        phoneVerified: '電話番号の確認が完了しました！',
        sendCodeFailed: '確認コードの送信に失敗しました: ',
        emailRequired: 'メールアドレスを入力してください。',
        emailInvalid: '有効なメールアドレスを入力してください。',
        passwordRequired: 'パスワードを入力してください。',
        passwordLength: 'パスワードは6文字以上必要です。',
        confirmPasswordRequired: 'パスワードを確認してください。',
        passwordMismatch: 'パスワードが一致しません。',
        nameRequired: '名前を入力してください。',
        nameLength: '名前は2文字以上必要です。',
        nicknameRequired: 'ニックネームを入力してください。',
        nicknameLength: 'ニックネームは2文字以上必要です。',
        phoneRequired: '電話番号を入力してください。',
        phoneInvalid: '有効な電話番号を入力してください。',
        alreadyHaveAccount: '既にアカウントをお持ちですか？',
        emailAlreadyRegistered: 'このメールアドレスは既に登録されています',
        processing: '処理中...',
        signupButton: '登録'
      }
    };

    const selectedLanguage = ref(localStorage.getItem('selectedLanguage') || 'en');
    const uiText = ref(translations[selectedLanguage.value]);

    const nextStep = () => {
      if (step.value < 5) {
        step.value++;
      }
    };

    const prevStep = () => {
      if (step.value > 1) {
        step.value--;
      }
    };

    const validateEmail = () => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!email.value) {
        emailError.value = uiText.value.emailRequired;
      } else if (!emailRegex.test(email.value)) {
        emailError.value = uiText.value.emailInvalid;
      } else {
        emailError.value = '';
      }
    };

    const validatePassword = () => {
      if (!password.value) {
        passwordError.value = uiText.value.passwordRequired;
      } else if (password.value.length < 6) {
        passwordError.value = uiText.value.passwordLength;
      } else {
        passwordError.value = '';
      }
      validateConfirmPassword();
    };

    const validateConfirmPassword = () => {
      if (!confirmPassword.value) {
        confirmPasswordError.value = uiText.value.confirmPasswordRequired;
      } else if (password.value !== confirmPassword.value) {
        confirmPasswordError.value = uiText.value.passwordMismatch;
      } else {
        confirmPasswordError.value = '';
      }
    };

    const validateName = () => {
      if (!name.value) {
        nameError.value = uiText.value.nameRequired;
      } else if (name.value.length < 2) {
        nameError.value = uiText.value.nameLength;
      } else {
        nameError.value = '';
      }
    };

    const validateNickname = () => {
      if (!nickname.value) {
        nicknameError.value = uiText.value.nicknameRequired;
      } else if (nickname.value.length < 2) {
        nicknameError.value = uiText.value.nicknameLength;
      } else {
        nicknameError.value = '';
      }
    };

    const validatePhoneNumber = () => {
      const phoneRegex = /^[0-9][0-9]{6,14}$/;
      if (!phoneNumber.value) {
        phoneNumberError.value = uiText.value.phoneRequired;
      } else if (!phoneRegex.test(phoneNumber.value)) {
        phoneNumberError.value = uiText.value.phoneInvalid;
      } else {
        phoneNumberError.value = '';
      }
    };

    const isFormValid = computed(() => {
      const commonValidation = !isLoading.value;

      if (userType.value === 'user') {
        return commonValidation && name.value && !nameError.value;
      } else {
        return commonValidation && nickname.value && !nicknameError.value;
      }
    });

    const isVerificationValid = computed(() => {
      if (authMethod.value === 'email') {
        return email.value && !emailError.value && isEmailVerified.value;
      } else {
        return phoneNumber.value && 
               !phoneNumberError.value && 
               !!verificationId.value && 
               isVerified.value;
      }
    });

    const signup = async () => {
      if (!isFormValid.value) return;
      
      isLoading.value = true;
      try {
        // 가입 로직 유지
        let userCredential;
        const formattedPhoneNumber = `${selectedCountryCode.value}${phoneNumber.value.replace(/^0/, '')}`;
        
        if (authMethod.value === 'email') {
          if (!tempUser.value) throw new Error('인증된 이메일 정보를 찾을 수 없습니다.');
          await updatePassword(tempUser.value, password.value);
          userCredential = { user: tempUser.value };
        } else {
          const phoneEmail = `${formattedPhoneNumber.replace(/[+]/g, '')}@phone.user`;
          userCredential = await createUserWithEmailAndPassword(auth, phoneEmail, password.value);
        }
        
        // 사용자 데이터 생성
        const userData = {
          userType: userType.value === 'user' ? 'guest' : 'nottattooist',
          authMethod: authMethod.value,
          createdAt: new Date().toISOString()
        };

        if (authMethod.value === 'email') {
          userData.email = email.value;
        } else {
          userData.phoneNumber = formattedPhoneNumber;
          userData.phoneEmail = userCredential.user.email;
        }

        if (userType.value === 'user') {
          userData.name = name.value;
        } else {
          userData.nickname = nickname.value;
        }

        // localStorage에서 selectedCountry 값을 확인하여 userData에 country 추가
        const selectedCountry = localStorage.getItem('selectedCountry');
        if (selectedCountry) {
          const countryMapping = {
            kr: 'Korea',
            us: 'United States',
            uk: 'United Kingdom',
            jp: 'Japan',
            au: 'Australia',
            th: 'Thailand'
          };
          userData.country = countryMapping[selectedCountry] || selectedCountry;
        }

        // Firestore에 사용자 데이터 저장
        const userDocRef = doc(db, `users/${userCredential.user.uid}`);
        await setDoc(userDocRef, userData);

        alert(uiText.value.signupSuccess);
        router.push('/');
      } catch (error) {
        alert(uiText.value.signupFailed + error.message);
      } finally {
        isLoading.value = false;
      }
    };

    const goToLogin = () => {
      router.push('/login');
    };

    // reCAPTCHA 설정
    onMounted(() => {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        'size': 'invisible'
      });
    });

    // 인증번호 요청
    const requestVerificationCode = async () => {
      try {
        isRequestingCode.value = true;
        
        // 전화번호 중복 체크
        const formattedPhoneNumber = `${selectedCountryCode.value}${phoneNumber.value.replace(/^0/, '')}`;
        const phoneQuery = query(
          collection(db, "users"),
          where("phoneNumber", "==", formattedPhoneNumber)
        );
        const phoneSnapshot = await getDocs(phoneQuery);
        
        if (!phoneSnapshot.empty) {
          throw new Error(uiText.value.phoneAlreadyRegistered);
        }

        const appVerifier = window.recaptchaVerifier;
        const confirmationResult = await signInWithPhoneNumber(auth, formattedPhoneNumber, appVerifier);
        verificationId.value = confirmationResult.verificationId;
        alert(uiText.value.verificationCodeSent);
      } catch (error) {
        alert(uiText.value.sendCodeFailed + error.message);
      } finally {
        isRequestingCode.value = false;
      }
    };

    // 인증번호 확인 함수
    const verifyCode = async () => {
      if (!verificationCode.value || verificationCode.value.length !== 6) return;
      
      isVerifying.value = true;
      verificationError.value = '';
      
      try {
        const credential = PhoneAuthProvider.credential(
          verificationId.value, 
          verificationCode.value
        );
        await signInWithCredential(auth, credential);
        isVerified.value = true;
        verificationError.value = '';
      } catch (error) {
        verificationError.value = 'Invalid verification code';
        isVerified.value = false;
      } finally {
        isVerifying.value = false;
      }
    };

    // 이메일 인증 링크 전송
    const sendVerificationEmail = async () => {
      try {
        isSendingVerification.value = true;
        
        // 이메일 중복 체크
        const emailQuery = query(
          collection(db, "users"),
          where("email", "==", email.value)
        );
        const emailSnapshot = await getDocs(emailQuery);
        
        if (!emailSnapshot.empty) {
          throw new Error(uiText.value.emailAlreadyRegistered);
        }

        const userCredential = await createUserWithEmailAndPassword(auth, email.value, 'tempPassword');
        tempUser.value = userCredential.user;
        await sendEmailVerification(tempUser.value);
        emailSent.value = true;
        
        // 5초마다 이메일 인증 상태 확인
        checkInterval.value = setInterval(async () => {
          if (tempUser.value) {
            await tempUser.value.reload(); // 유저 정보 새로고침
            if (tempUser.value.emailVerified) {
              isEmailVerified.value = true;
              clearInterval(checkInterval.value); // 인터벌 중지
              emailSent.value = false;
            }
          }
        }, 5000); // 5초 간격

        alert(uiText.value.verificationSent);
      } catch (error) {
        alert(error.message); // 번역된 메시지 표시
      } finally {
        isSendingVerification.value = false;
      }
    };

    // 컴포넌트가 제거될 때 인터벌 정리
    onUnmounted(() => {
      if (checkInterval.value) {
        clearInterval(checkInterval.value);
      }
    });

    return {
      step,
      email,
      password,
      confirmPassword,
      userType,
      name,
      nickname,
      phoneNumber,
      emailError,
      passwordError,
      confirmPasswordError,
      nameError,
      nicknameError,
      phoneNumberError,
      isLoading,
      isFormValid,
      isVerificationValid,
      nextStep,
      prevStep,
      signup,
      validateEmail,
      validatePassword,
      validateConfirmPassword,
      validateName,
      validateNickname,
      validatePhoneNumber,
      goToLogin,
      selectedCountryCode,
      authMethod,
      userTypeLabels: computed(() => uiText.value.userType),
      authMethodLabels: computed(() => uiText.value.authMethod),
      verificationId,
      verificationCode,
      verificationError,
      isRequestingCode,
      requestVerificationCode,
      isVerifying,
      isVerified,
      verifyCode,
      emailVerificationId,
      emailVerificationCode,
      emailVerificationError,
      isRequestingEmailCode,
      isVerifyingEmail,
      isEmailVerified,
      isSendingVerification,
      emailSent,
      sendVerificationEmail,
      uiText,
    };
  }
};
</script>

<style scoped>
.signup-container {
  max-width: 400px;
  margin: 40px auto;
  margin-top: 100px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  font-size: 24px;
}

.signup-section {
  margin-bottom: 25px;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 6px;
}

.input-group {
  margin-bottom: 15px;
}

input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

input:focus {
  border-color: #4CAF50;
}

button {
  width: 100%;
  padding: 12px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s;
  margin-top: 10px;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.error-message {
  color: #f44336;
  font-size: 12px;
  margin-bottom: 10px;
  display: block;
}

.login-section {
  text-align: center;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.login-section p {
  color: #666;
  font-size: 14px;
}

.login-link {
  color: #4CAF50;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 5px;
}

.user-type-selection {
  margin: 25px 0;
}

.user-type-selection > p {
  text-align: center;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}

.type-cards {
  display: flex;
  gap: 15px;
  margin: 10px 0;
}

.type-card {
  flex: 1;
  position: relative;
  cursor: pointer;
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  padding: 20px;
  transition: all 0.3s ease;
  background-color: white;
}

.type-card.active {
  border-color: #4CAF50;
  background-color: #f8fff8;
}

.type-card input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.card-content {
  text-align: center;
}

.card-content i {
  font-size: 24px;
  color: #4CAF50;
  margin-bottom: 10px;
}

.card-content h3 {
  font-size: 16px;
  color: #333;
  margin: 10px 0;
  font-weight: 500;
}

.card-content p {
  font-size: 13px;
  color: #666;
  margin: 0;
  line-height: 1.4;
}

.type-card.active .card-content i {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

.progress-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
  padding: 0 20px;
}

.progress-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
}

.step-number {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #f5f5f5;
  border: 2px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #666;
  margin-bottom: 8px;
  transition: all 0.3s ease;
}

.progress-step.active .step-number {
  background-color: #4CAF50;
  border-color: #4CAF50;
  color: white;
}

.progress-step.completed .step-number {
  background-color: #4CAF50;
  border-color: #4CAF50;
  color: white;
}

.progress-step span {
  font-size: 12px;
  color: #666;
}

.progress-line {
  flex: 1;
  height: 2px;
  background-color: #ddd;
  margin: 0 10px;
  margin-bottom: 25px;
  transition: background-color 0.3s ease;
}

.progress-line.active {
  background-color: #4CAF50;
}

.button-group {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.button-group button {
  flex: 1;
}

button.secondary {
  background-color: #f5f5f5;
  color: #666;
  border: 1px solid #ddd;
}

.phone-input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 10px;
}

.country-code-select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  background-color: white;
  cursor: pointer;
}

.phone-input {
  flex: 1;
}

#recaptcha-container {
  margin: 10px 0;
  display: flex;
  justify-content: center;
}

.auth-method-selection {
  margin: 25px 0;
}

.auth-method-selection > p {
  text-align: center;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}

.verification-button {
  margin-top: 10px;
  background-color: #4CAF50;
}

.verification-button:disabled {
  background-color: #cccccc;
}

/* 인증번호 재전송 버튼 스타일 */
.verification-button:not(:disabled) {
  background-color: #4CAF50;
}

.verification-button:not(:disabled):hover {
  background-color: #45a049;
}

.verification-code-input {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
}

.verification-code-input input {
  width: 100%;
}

.verify-button {
  margin-top: 10px;
  background-color: #4CAF50;
  width: auto;
  padding: 8px 16px;
}

.verify-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.success-message {
  color: #4CAF50;
  font-size: 12px;
  margin-top: 5px;
  display: block;
}

.phone-input:disabled,
.country-code-select:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.info-message {
  color: #2196F3;
  font-size: 12px;
  margin-top: 5px;
  display: block;
}
</style>
