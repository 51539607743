<template>
  <div class="product-detail">
    <div v-if="loading" class="loading">
      로딩 중...
    </div>
    
    <div v-else-if="product" class="product-content">
      <!-- 이미지 슬라이더 -->
      <div class="image-section">
        <div class="image-slider">
          <img 
            :src="product.images[currentImageIndex]" 
            :alt="product.title"
            class="main-image"
          >
          <div class="image-nav">
            <button 
              class="nav-button prev" 
              @click="prevImage"
              :disabled="currentImageIndex === 0"
            >←</button>
            <button 
              class="nav-button next" 
              @click="nextImage"
              :disabled="currentImageIndex === product.images.length - 1"
            >→</button>
          </div>
        </div>

        <!-- 썸네일 갤러리 -->
        <div class="thumbnail-gallery">
          <div 
            v-for="(image, index) in product.images" 
            :key="index"
            :class="['thumbnail', { active: currentImageIndex === index }]"
            @click="currentImageIndex = index"
          >
            <img :src="image" :alt="`${product.title} - ${index + 1}`">
          </div>
        </div>
      </div>

      <!-- 제품 정보 -->
      <div class="product-info">
        <h1 class="product-title">{{ product.title }}</h1>
        <div class="product-description" v-html="formatDescription(product.description)"></div>
      </div>

      <!-- 디자인하기 버튼 -->
      <div class="design-button-container">
        <button class="design-button" @click="startDesign">
          이 제품으로 디자인하기
        </button>
      </div>
    </div>
    
    <div v-else class="error">
      제품을 찾을 수 없습니다.
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, defineProps } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';

const router = useRouter();

const props = defineProps({
  productId: {
    type: [String, Number],
    required: true
  }
});

// Firebase Functions URL로 변경
const API_URL = "https://asia-northeast3-tattoomap3.cloudfunctions.net/api/products";

const product = ref(null);
const loading = ref(true);
const currentImageIndex = ref(0);

const loadProduct = async () => {
  try {
    loading.value = true;
    const response = await axios.get(`${API_URL}/${props.productId}`);
    
    if (!response.data) {
      throw new Error('제품을 찾을 수 없습니다.');
    }
    
    // 이미지 데이터 구조 변환
    product.value = {
      ...response.data,
      images: response.data.images.map(img => img.src || img)
    };
    
  } catch (error) {
    console.error('제품 로딩 실패:', error);
    product.value = null;
  } finally {
    loading.value = false;
  }
};

// productId가 변경될 때마다 데이터 다시 로드
watch(() => props.productId, () => {
  loadProduct();
});

const formatDescription = (description) => {
  return description
    .split('.:')  // .: 를 기준으로 분리
    .map((text, index) => {
      if (index === 0) {
        // 첫 번째 부분은 메인 설명
        return `<div class="main-description">${text.trim()}</div>`;
      }
      // 나머지는 스펙 목록
      return text.trim() && `<div class="spec-item">• ${text.trim()}</div>`;
    })
    .filter(Boolean)  // 빈 문자열 제거
    .join('');
};

const nextImage = () => {
  if (currentImageIndex.value < product.value.images.length - 1) {
    currentImageIndex.value++;
  }
};

const prevImage = () => {
  if (currentImageIndex.value > 0) {
    currentImageIndex.value--;
  }
};

const startDesign = () => {
  if (!product.value) return;
  
  // 'ProductDesign' 라우트로 이동하며 productId를 params로 전달
  router.push({
    name: 'ProductDesign',
    params: { productId: props.productId },
    query: { 
      productTitle: product.value.title
    }
  });
};

onMounted(() => {
  loadProduct();
});
</script>

<style scoped>
.product-detail {
  position: relative;
  min-height: 100vh;
  padding: 20px;
  padding-bottom: 80px; /* 버튼 높이만큼 여백 */
  background: #fff;
}

.loading, .error {
  text-align: center;
  padding: 20px;
  color: #666;
}

.product-content {
  display: flex;
  flex-direction: column;
}

.image-section {
  position: relative;
  width: 100%;
  background: #f8f8f8;
  margin-bottom: 0; /* 썸네일 갤러리와의 간격 조정 */
}

.image-slider {
  position: relative;
  width: 100%;
  background: #f8f8f8;
  margin-bottom: 0; /* 썸네일 갤러리와의 간격 조정 */
}

.main-image {
  width: 100%;
  height: auto;
  display: block;
  max-height: 500px;
  object-fit: contain;
}

.image-nav {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

.nav-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);
}

.nav-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.product-info {
  padding: 20px;
}

.product-title {
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 16px 0;
}

.product-description {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
}

.main-description {
  margin-bottom: 24px;
  color: #333;
  font-size: 16px;
  line-height: 1.6;
}

.spec-item {
  margin: 8px 0;
  color: #666;
  font-size: 14px;
  padding-left: 8px;
}

.thumbnail-gallery {
  display: flex;
  gap: 8px;
  padding: 16px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
}

.thumbnail-gallery::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.thumbnail {
  flex: 0 0 80px;
  height: 80px;
  border: 2px solid transparent;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease;
}

.thumbnail.active {
  border-color: #000;
}

.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.design-button-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  background: white;
  box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
  z-index: 1002; /* 모달보다 위에 표시 */
}

.design-button {
  width: 100%;
  padding: 16px;
  background: #000;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
}

.design-button:hover {
  background: #333;
}

@media (min-width: 768px) {
  .product-detail {
    max-width: 1200px;
    margin: 0 auto;
    padding: 80px 20px 20px 20px; /* 상단 여백 더 추가 */
  }
  
  .product-content {
    flex-direction: row;
    gap: 40px;
  }
  
  .image-slider {
    flex: 1;
  }
  
  .product-info {
    flex: 1;
  }

  .thumbnail-gallery {
    flex-wrap: wrap;
    padding: 16px 0;
  }

  .thumbnail {
    flex: 0 0 100px;
    height: 100px;
  }

  .design-button-container {
    max-width: 1200px;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px;
  }
}
</style>