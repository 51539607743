<template>
  <div class="country-selector">
    <h1 class="title">FIND YOUR TATTOO ARTIST</h1>
    
    <!-- 언어 선택 드롭다운 추가 -->
    <div class="language-selector">
      <label for="language">Select Language:</label>
      <select id="language" v-model="selectedLanguage" @change="saveLanguage">
        <option v-for="language in languages" :key="language.code" :value="language.code">
          {{ language.name }}
        </option>
      </select>
    </div>

    <div class="countries-grid">
      <div v-for="country in countries" 
           :key="country.code"
           class="country-card"
           @click="selectCountry(country.code)"
           :class="{ 'selected': selectedCountry === country.code }">
        <div class="card-content">
          <h2>{{ country.name }}</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const selectedCountry = ref(null)
const selectedLanguage = ref(localStorage.getItem('selectedLanguage') || 'en') // 기본 언어 설정

const countries = reactive([
  { code: 'kr', name: 'Korea' },
  { code: 'jp', name: 'Japan' },
  { code: 'uk', name: 'United Kingdom' },
  { code: 'us', name: 'United States' },
  { code: 'au', name: 'Australia' },
  { code: 'th', name: 'Thailand' },
  { code: 'de', name: 'Germany' },      // 유럽에서 가장 큰 타투 시장
  { code: 'it', name: 'Italy' },        // 예술적 타투 문화
  { code: 'br', name: 'Brazil' },       // 남미 최대 타투 시장
  { code: 'ca', name: 'Canada' },       // 북미 주요 타투 시장
  { code: 'fr', name: 'France' },       // 유럽 주요 타투 문화
  { code: 'es', name: 'Spain' },        // 유럽 주요 타투 문화
  { code: 'nl', name: 'Netherlands' },  // 혁신적인 타투 디자인
  { code: 'mx', name: 'Mexico' },       // 전통과 현대가 혼합된 타투 문화
])

const languages = reactive([
  { code: 'ko', name: 'Korean' },
  { code: 'en', name: 'English' },
  { code: 'jp', name: 'Japanese' }
])

const selectCountry = (code) => {
  selectedCountry.value = code
  localStorage.setItem('selectedCountry', code)
  localStorage.setItem('selectedLanguage', selectedLanguage.value)
  
  // 라우터로 이동할 때 쿼리 파라미터 추가
  router.push({ name: 'MainPage' })
}

const saveLanguage = () => {
  localStorage.setItem('selectedLanguage', selectedLanguage.value)
}

// 컴포넌트 마운트 시 로컬스토리지 체크
onMounted(() => {
  const savedCountry = localStorage.getItem('selectedCountry')
  
  if (savedCountry) {
    router.push({ name: 'MainPage' })
  }
})
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.country-selector {
  padding: 0;
  padding-top: 50px;
  width: 100vw;
  height: 100vh;
  margin: 0;
  background: #000000;
  color: #fff;
  position: fixed;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  overflow: hidden;
}

.title {
  font-size: 2rem;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 3rem;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  width: 100%;
}

.title::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: #ff3366;
}

.language-selector {
  margin-bottom: 1rem;
  text-align: center;
}

.language-selector label {
  margin-right: 0.5rem;
  color: #fff;
}

.language-selector select {
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #333;
  background: #111;
  color: #fff;
}

.countries-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  gap: 1.5rem;
  padding: 1.5rem;
  width: 100%;
  max-width: 100vw;
  max-height: calc(100vh - 280px);
  overflow-y: auto;
}

.countries-grid::-webkit-scrollbar {
  width: 6px;
}

.countries-grid::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
}

.countries-grid::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
}

.country-card {
  background: #111111;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
  border: 1px solid #333;
}

.country-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, #ff3366, #ff6b6b);
  opacity: 0;
  transition: opacity 0.4s ease;
  z-index: 1;
}

.country-card.selected {
  border: 2px solid #ff3366;
  background: linear-gradient(45deg, #111111, #1a1a1a);
}

.card-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  text-align: center;
  position: relative;
  z-index: 2;
}

h2 {
  font-size: 1.2rem;
  margin: 0;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* 애니메이션 */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.country-card {
  animation: fadeIn 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  animation-fill-mode: both;
}

.country-card:nth-child(1) { animation-delay: 0.1s; }
.country-card:nth-child(2) { animation-delay: 0.2s; }
.country-card:nth-child(3) { animation-delay: 0.3s; }
.country-card:nth-child(4) { animation-delay: 0.4s; }
.country-card:nth-child(5) { animation-delay: 0.5s; }
.country-card:nth-child(6) { animation-delay: 0.6s; }
.country-card:nth-child(7) { animation-delay: 0.7s; }
.country-card:nth-child(8) { animation-delay: 0.8s; }
.country-card:nth-child(9) { animation-delay: 0.9s; }
.country-card:nth-child(10) { animation-delay: 1s; }
.country-card:nth-child(11) { animation-delay: 1.1s; }
.country-card:nth-child(12) { animation-delay: 1.2s; }
.country-card:nth-child(13) { animation-delay: 1.3s; }
.country-card:nth-child(14) { animation-delay: 1.4s; }
.country-card:nth-child(15) { animation-delay: 1.5s; }
.country-card:nth-child(16) { animation-delay: 1.6s; }
.country-card:nth-child(17) { animation-delay: 1.7s; }
</style> 