<template>
  <div class="setting-notice">
    <div class="notice-header">
      <h2>{{ uiText.noticeManagement }}</h2>
      <p class="subtitle">{{ uiText.manageNotices }}</p>
    </div>

    <div class="notice-form">
      <form @submit.prevent="addNotice" class="add-notice-form">
        <div class="input-wrapper">
          <input 
            v-model="newNoticeTitle" 
            :placeholder="uiText.titlePlaceholder" 
            required 
            class="notice-title-input"
          />
          <textarea 
            v-model="newNoticeContent" 
            :placeholder="uiText.contentPlaceholder" 
            required 
            class="notice-content-input"
          ></textarea>
          <button type="submit">
            <i class="fas fa-plus"></i>
          </button>
        </div>
      </form>
    </div>

    <div class="notice-list">
      <transition-group name="list">
        <div 
          v-for="notice in notices" 
          :key="notice.id" 
          class="notice-item"
        >
          <div class="notice-content">
            <i class="fas fa-bell notice-icon"></i>
            <div class="notice-text">
              <h3>{{ notice.title }}</h3>
              <p>{{ notice.content }}</p>
            </div>
          </div>
          <button @click="deleteNotice(notice.id)" class="delete-btn">
            <i class="fas fa-trash-alt"></i>
          </button>
        </div>
      </transition-group>
      
      <div v-if="notices.length === 0" class="empty-state">
        <i class="fas fa-clipboard-list empty-icon"></i>
        <p>{{ uiText.noNotices }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { db } from '@/firebase';
import { collection, addDoc, getDocs, deleteDoc, doc, orderBy, query } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

export default {
  name: 'SettingNotice',
  setup() {
    const auth = getAuth();
    const userId = ref('');
    const newNoticeTitle = ref('');
    const newNoticeContent = ref('');
    const notices = ref([]);

    const translations = {
      en: {
        noticeManagement: 'Notice Management',
        manageNotices: 'Manage notices that will be displayed on your profile',
        titlePlaceholder: 'Title',
        contentPlaceholder: 'Enter content',
        noNotices: 'No notices registered',
      },
      ko: {
        noticeManagement: '공지사항',
        manageNotices: '프로필에 표시될 공지를 관리합니다',
        titlePlaceholder: '제목',
        contentPlaceholder: '내용 입력',
        noNotices: '등록된 공지가 없습니다',
      },
      jp: {
        noticeManagement: '通知管理',
        manageNotices: 'プロファイルに表示される通知を管理します',
        titlePlaceholder: 'タイトル',
        contentPlaceholder: '内容を入力',
        noNotices: '登録された通知はありません',
      },
    };

    const selectedLanguage = ref(localStorage.getItem('selectedLanguage') || 'en');
    const uiText = ref(translations[selectedLanguage.value]);

    onMounted(() => {
      if (auth.currentUser) {
        userId.value = auth.currentUser.uid;
        loadNotices();
      } else {
        auth.onAuthStateChanged((user) => {
          if (user) {
            userId.value = user.uid;
            loadNotices();
          }
        });
      }
    });

    const loadNotices = async () => {
      if (!userId.value) return;
      
      try {
        const noticesRef = collection(db, 'users', userId.value, 'Notices');
        const noticesQuery = query(
          noticesRef,
          orderBy('createdAt', 'asc')
        );
        const snapshot = await getDocs(noticesQuery);
        notices.value = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      } catch (error) {
        console.error('Failed to load notices:', error);
      }
    };

    const addNotice = async () => {
      if (!userId.value) return;
      
      try {
        const noticesRef = collection(db, 'users', userId.value, 'Notices');
        await addDoc(noticesRef, { 
          title: newNoticeTitle.value,
          content: newNoticeContent.value,
          createdAt: new Date().toISOString()
        });
        newNoticeTitle.value = '';
        newNoticeContent.value = '';
        loadNotices();
      } catch (error) {
        console.error('Failed to add notice:', error);
      }
    };

    const deleteNotice = async (noticeId) => {
      if (!userId.value) return;
      
      try {
        const noticeDoc = doc(db, 'users', userId.value, 'Notices', noticeId);
        await deleteDoc(noticeDoc);
        loadNotices();
      } catch (error) {
        console.error('Failed to delete notice:', error);
      }
    };

    return {
      newNoticeTitle,
      newNoticeContent,
      notices,
      addNotice,
      deleteNotice,
      uiText,
    };
  },
};
</script>

<style scoped>
.setting-notice {
  margin: 0 auto;
  padding: 20px;
  margin-top: 60px;
}

.notice-header {
  text-align: center;
  margin-bottom: 48px;
}

.notice-header h2 {
  font-size: 32px;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 8px;
}

.subtitle {
  color: #666;
  font-size: 16px;
}

.notice-form {
  margin-bottom: 32px;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: #fff;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
}

.notice-title-input {
  width: 100%;
  border: none;
  padding: 13px 16px;
  font-size: 16px;
  background: #f8f9fa;
  border-radius: 8px;
  transition: all 0.3s ease;
  font-weight: 600;
  box-sizing: border-box;
}

.notice-content-input {
  width: 100%;
  border: none;
  padding: 13px 16px;
  font-size: 16px;
  background: #f8f9fa;
  border-radius: 8px;
  transition: all 0.3s ease;
  min-height: 100px;
  resize: vertical;
  font-family: inherit;
  box-sizing: border-box;
}

.notice-title-input:focus,
.notice-content-input:focus {
  outline: none;
  background: #fff;
  box-shadow: 0 0 0 2px #007AFF20;
}

.input-wrapper button {
  align-self: flex-end;
  background: #1a1a1a;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  width: 100%;
  justify-content: center;
}

.notice-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.notice-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.notice-content {
  display: flex;
  align-items: center;
  gap: 16px;
}

.notice-icon {
  color: #1a1a1a;
  font-size: 19px;
}

.delete-btn {
  background: none;
  border: none;
  color: #dc3545;
  padding: 8px;
  cursor: pointer;
  opacity: 0.6;
  transition: all 0.3s ease;
  font-size: 19px;
}

.delete-btn:hover {
  opacity: 1;
  transform: scale(1.1);
}

.empty-state {
  text-align: center;
  padding: 48px;
  color: #666;
}

.empty-icon {
  font-size: 48px;
  margin-bottom: 16px;
  color: #ddd;
}

.notice-text {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.notice-text h3 {
  font-size: 16px;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0;
}

.notice-text p {
  font-size: 14px;
  color: #666;
  margin: 0;
  line-height: 1.5;
  white-space: pre-line;
}

/* 애니메이션 효과 */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from {
  opacity: 0;
  transform: translateX(30px);
}

.list-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}
</style>
