import { createRouter, createWebHistory } from 'vue-router';

import LoginPage from '@/components/auth/loginPage.vue'; //로그인
import OTPLoginPage from '@/components/auth/OTPLoginPage.vue'; //OTP 로그인
import SignupPage from '@/components/auth/signupPage.vue'; //회원가입
import MainMyPage from '@/components/tattooistSetting/MyPage.vue'; //타투이스트 마이페이지
import GuestMyPage from '@/components/guestSetting/guestMyPage.vue'; //게스트 마이페이지
import TattooistProfile from '@/components/tattooistSetting/TattooistProfile.vue'; //타투이스트 프로필
import TattooistEditPage from '@/components/tattooistSetting/tattooistEditPage.vue'; //타투이스트 프로필 수정
import PortfolioManager from '@/components/tattooistSetting/PortfolioManager.vue'; //포트폴리오 관리
import DesignManager from '@/components/tattooistSetting/DesignManager.vue'; //디자인 관리
import TattooMap from '@/components/TattooMap.vue'; //타투맵
import TattooGallery from '@/components/TattooGallery.vue'; //타투갤러리
import SettingNotice from '@/components/tattooistSetting/SettingNotice.vue'; //타투이스트 공지사항 관리
import SettingMultiLink from '@/components/tattooistSetting/SettingMultiLink.vue'; //타투이스트 링크 관리
import SettingAccount from '@/components/tattooistSetting/SettingAccount.vue'; //타투이스트 계좌 관리
import SettingWelcomeMessage from '@/components/tattooistSetting/SettingWelcomeMessage.vue'; //타투이스트 웰컴 메시지 관리
import SettingAwards from '@/components/tattooistSetting/SettingAwards.vue'; //타투이스트 수상 뱃지 관리
import ChatList from '@/components/chat/ChatList.vue';
import ChatRoom from '@/components/chat/ChatRoom.vue';
import ScheduleManager from '@/components/tattooistSetting/ScheduleManager.vue';
import DesignGallery from '@/components/DesignGallery.vue';
import GuestFavorite from '@/components/guestSetting/GuestFavorite.vue';
import SettingColor from '@/components/tattooistSetting/SettingColor.vue';
import ConfirmedReservations from '@/components/chat/ConfirmedReservations.vue'
import PageStats from '@/components/PageStats.vue'
import AdminTattooMap from '@/components/admintattoomap.vue'; // 관리자 페이지
import CountryTattooSelector from '@/components/CountryTattooSelector.vue'; // 추가
import MainPage from '@/components/MainPage.vue' // MainPage import 추가
import PrintifyDesigner from '@/components/PrintifyDesigner.vue'
import ProductDetail from '@/components/ProductDetail.vue';
import ProductSelector from '../components/ProductSelector.vue';
import StripeConnect from '@/components/StripeConnect.vue'; // 상단에 추가
import PayHome from '@/components/stripe/payHome.vue';
import PayReturn from '@/components/stripe/payReturn.vue';
import PayRefresh from '@/components/stripe/payRefresh.vue';
import PaymentRequest from '@/components/stripe/PaymentRequest.vue';
import PaymentPage from '@/components/stripe/PaymentPage.vue';
import PaymentSuccess from '@/components/stripe/PaymentSuccess.vue'
import PaymentDetails from '@/components/stripe/PaymentDetails.vue'
import UserClass from '@/components/UserClass.vue';
import StudioList from '@/components/studio/studioList.vue';
import StudioDetail from '@/components/studio/StudioDetail.vue';
import StudioCreate from '@/components/studio/StudioCreate.vue';

const routes = [
  { path: '/', redirect: '/select-country' }, // 초기 진입점을 국가 선택 페이지로 변경
  { path: '/select-country', name: 'CountrySelector', component: CountryTattooSelector },
  { path: '/main', name: 'MainPage', component: MainPage },
  { path: '/login', name: 'LoginPage', component: LoginPage },
  { path: '/otplogin', name: 'OTPLoginPage', component: OTPLoginPage },
  { path: '/signup', name: 'SignupPage', component: SignupPage },
  { path: '/tattoomap', name: 'TattooMap', component: TattooMap },
  { path: '/tattoogallery', name: 'TattooGallery', component: TattooGallery },
  { path: '/designgallery', name: 'DesignGallery', component: DesignGallery },
  { path: '/guestmypage', name: 'guestMyPage', component: GuestMyPage },
  { path: '/guestfavorite', name: 'GuestFavorite', component: GuestFavorite },
  { path: '/portfoliomanager', name: 'PortfolioManager', component: PortfolioManager },
  { path: '/mypage', name: 'MyPage', component: MainMyPage }, 
  { path: '/tattooist/:id', name: 'TattooistProfile', component: TattooistProfile, props: true },
  { path: '/tattooisteditpage', name: 'TattooistEditPage', component: TattooistEditPage },
  { path: '/designmanager', name: 'DesignManager', component: DesignManager },
  { path: '/settingnotice', name: 'SettingNotice', component: SettingNotice },
  { path: '/settingmultilink', name: 'SettingMultiLink', component: SettingMultiLink },
  { path: '/settingaccount', name: 'SettingAccount', component: SettingAccount },
  { path: '/settingwelcomemessage', name: 'SettingWelcomeMessage', component: SettingWelcomeMessage },
  { path: '/settingawards', name: 'SettingAwards', component: SettingAwards },
  { path: '/chat', name: 'ChatList', component: ChatList, meta: { requiresAuth: true } },
  { path: '/chat/:id', name: 'ChatRoom', component: ChatRoom, meta: { requiresAuth: true } },
  { path: '/schedule-manager', name: 'ScheduleManager', component: ScheduleManager, meta: { requiresAuth: true, requiresTattooist: true } },
  { path: '/settingcolor', name: 'SettingColor', component: SettingColor },
  { path: '/reservations', name: 'Reservations', component: ConfirmedReservations, meta: { requiresAuth: true } },
  { path: '/page-stats', name: 'PageStats', component: PageStats, meta: { requiresAuth: true, allowedUserTypes: ['tattooist'] } },
  { path: '/admin', name: 'AdminTattooMap', component: AdminTattooMap, meta: { requiresAuth: true } },
  { path: '/design', name: 'PrintifyDesigner', component: PrintifyDesigner, meta: { requiresAuth: true } },  
  {
    path: '/products/select',
    name: 'ProductSelect',
    component: ProductSelector,
    meta: { requiresAuth: true }
  },
  {
    path: '/products/design/:productId',
    name: 'ProductDesign',
    component: PrintifyDesigner,
    meta: { requiresAuth: true }
  },
  {
    path: '/product/:id',
    name: 'ProductDetail',
    component: ProductDetail,
    props: true  // URL 파라미터를 props로 전달
  },
  {
    path: '/stripe-connect',
    name: 'StripeConnect',
    component: StripeConnect,
    meta: { 
      requiresAuth: true,
      allowedUserTypes: ['artist'] // 아티스트만 접근 가능
    }
  },
  { path: '/payhome', name: 'PayHome', component: PayHome, meta: { requiresAuth: true } },
  { path: '/return', name: 'PayReturn', component: PayReturn, meta: { requiresAuth: true } },
  { path: '/refresh', name: 'PayRefresh', component: PayRefresh, meta: { requiresAuth: true } },
  { 
    path: '/create-payment/:recipientId/:chatRoomId',
    name: 'PaymentRequest', 
    component: PaymentRequest, 
    props: true, 
    meta: { requiresAuth: true } 
  },
  { path: '/pay/:paymentRequestId', name: 'PaymentPage', component: PaymentPage, meta: { requiresAuth: true } },
  { path: '/payment-success', name: 'PaymentSuccess', component: PaymentSuccess, meta: { requiresAuth: true } },
  { path: '/payment-details/:paymentId', name: 'PaymentDetails', component: PaymentDetails, meta: { requiresAuth: true } },
  { 
    path: '/membership', 
    name: 'UserClass', 
    component: UserClass, 
    meta: { 
      requiresAuth: true,  // 로그인 필요
      allowedUserTypes: ['tattooist']  // 타투이스트만 접근 가능
    } 
  },
  { path: '/studio', name: 'StudioList', component: StudioList, meta: { requiresAuth: true } },
  { path: '/studio/:id', name: 'StudioDetail', component: StudioDetail, meta: { requiresAuth: true } },
  { path: '/studio/create', name: 'StudioCreate', component: StudioCreate, meta: { requiresAuth: true } },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    // 라우터 이동 시 맨 위로 스크롤
    if (to.hash) {
      // 만약 hash(#)가 있으면 해당 위치로 스크롤
      return { selector: to.hash };
    } else {
      // 기본적으로 페이지가 이동할 때 맨 위로 스크롤
      return { top: 0 };
    }
  },
});

// 네비게이션 가드 수정
router.beforeEach((to, from, next) => {
  // 메인 페이지로 이동할 때 국가 선택 여부 확인
  if (to.name === 'MainPage') {
    const savedCountry = localStorage.getItem('selectedCountry')
    if (!savedCountry) {
      // 국가 선택이 안 되어 있으면 선택 페이지로 리다이렉트
      next({ name: 'CountrySelector' })
      return
    }
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // 인증 로직 추가 가능
    next()
  } else {
    next()
  }
})

export default router;