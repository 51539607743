<template>
  <nav class="navbar">
    <div class="logo-box" @click="gotohome">
      <img src="@/assets/logo.png" alt="로고" class="logo" />
    </div>
    <div class="navbar-right">
      <button class="icon-button" @click="gotolanguage">
        <span class="material-icons">language</span>
      </button>
      <button class="icon-button" @click="gotochat">
        <span class="material-icons">forum</span>
      </button>
      <button class="icon-button" @click="handleProfileClick">
        <span class="material-icons">person</span>
      </button>
    </div>
  </nav>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { auth } from '@/firebase';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

export default {
  name: 'AppNavbar',
  setup() {
    const router = useRouter();
    const db = getFirestore();
    const isMenuOpen = ref(false);
    const userType = ref(null);

    const gotohome = () => {
      router.push({ path: '/' });
      isMenuOpen.value = false;
    };

    const gotolanguage = () => {
      localStorage.removeItem('selectedCountry');
      router.push({ path: '/select-country' });
      isMenuOpen.value = false;
    };

    const gotochat = () => {
      const user = auth.currentUser;
      if (!user) {
        router.push({ path: '/login' });
        return;
      }
      router.push({ path: '/chat' });
      isMenuOpen.value = false;
    };

    const goToGuestRequests = () => {
      router.push({ path: '/guest-requests' });
      isMenuOpen.value = false;
    };

    // 로그인 상태 변경 감지
    let unsubscribe;
    onMounted(() => {
      unsubscribe = auth.onAuthStateChanged(async (user) => {
        if (user) {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            userType.value = userDoc.data().userType;
          }
        } else {
          userType.value = null;
        }
      });
    });

    // 컴포넌트 언마운트 시 구독 해제
    onUnmounted(() => {
      if (unsubscribe) {
        unsubscribe();
      }
    });

    // 프로필 클릭 시 처리하는 함수
    const handleProfileClick = async () => {
      const user = auth.currentUser;
      if (!user) {
        router.push({ path: '/login' });
        return;
      }

      try {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userType = userDoc.data().userType;
          if (userType === 'tattooist' || userType === 'nottattooist') {
            router.push({ path: '/mypage' });
          } else if (userType === 'guest') {
            router.push({ path: '/guestmypage' });
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    return {
      isMenuOpen,
      userType,
      goToGuestRequests,
      handleProfileClick,
      gotolanguage,
      gotochat,
      gotohome,
    };
  }
};
</script>

<style scoped>
.navbar {
  background-color: black; /* 배경색을 검정색으로 설정 */
  color: white; /* 글자색 */
  height: 60px; /* 높이를 50px로 고정 */
  display: flex; /* 플렉스 박스 사용 */
  justify-content: space-between; /* 양쪽 끝으로 정렬 */
  align-items: center; /* 수직 중앙 정렬 */
  position: fixed; /* 고정 위치 */
  top: 0; /* 최상단에 위치 */
  left: 0; /* 왼쪽에 위치 */
  right: 0; /* 오른쪽에 위치 */
  z-index: 1000; /* 다른 요소 위에 표시 */
  padding: 0 10px;
}

.navbar-right {
  display: flex; /* 플렉스 박스 사용 */
  align-items: center; /* 수직 중앙 정렬 */
}

.logo-box {
  cursor: pointer;
}

.icon-button {
  background: none; /* 배경 제거 */
  border: none; /* 테두리 제거 */
  color: white; /* 글자색 */
  cursor: pointer; /* 커서 변경 */
  font-size: 24px; /* 아이콘 크기 조정 */
  padding: 10px;
}

.logo {
  height: 20px; /* 로고 높이 설정 */
}
</style> 