<template>
  <div class="my-page-container">
    <div class="my-page-wrap">
      <div class="header-section">
        <h2>{{ uiText.myPage }}</h2>
        <button class="logout-button" @click="handleLogout">
          <span class="material-icons">logout</span>
          {{ uiText.logout }}
        </button>
      </div>
      <div class="user-info">
        <div class="profile-section">
          <div class="image-container">
            <img :src="profileImageUrl || require('@/assets/default-avatar.png')" alt="Profile Image" class="avatar" />
          </div>
          <div class="user-details">
            <h3>{{ nickname }}</h3>
            <p>{{ email }}</p>
            <button @click="navigateTo('TattooistEditPage')" class="edit-profile-button">
              <i class="fas fa-edit"></i> {{ uiText.editProfile }}
            </button>
          </div>
        </div>
      </div>
      <div v-if="userType === 'nottattooist'" class="unverified-notice">
        <div class="notice-content">
          <i class="fas fa-exclamation-circle"></i>
          <p v-html="uiText.unverifiedProfileMessage"></p>
          <button class="verify-button" @click="changeUserType">
            {{ uiText.verifyNow }}
          </button>
        </div>
      </div>
      <div class="settings">
        <h3>{{ uiText.profileManagement }}</h3>
        <div class="settings-grid">
          <div class="setting-item" @click="viewProfile">
            <i class="fas fa-user"></i>
            <span>{{ uiText.myProfile }}</span>
          </div>
          <div class="setting-item" @click="navigateTo('SettingNotice')">
            <i class="fas fa-bell"></i>
            <span>{{ uiText.notice }}</span>
          </div>
          <div class="setting-item" @click="navigateTo('SettingMultiLink')">
            <i class="fas fa-link"></i>
            <span>{{ uiText.multiLink }}</span>
          </div>
          <div class="setting-item" @click="navigateTo('SettingAwards')">
            <i class="fas fa-award"></i>
            <span>{{ uiText.awards }}</span>
          </div>
          <div class="setting-item" @click="navigateTo('SettingWelcomeMessage')">
            <i class="fas fa-comment-dots"></i>
            <span>{{ uiText.welcomeMessage }}</span>
          </div>
          <div class="setting-item" @click="navigateTo('SettingColor')">
            <i class="fas fa-brush"></i>
            <span>{{ uiText.profileColor }}</span>
          </div>
        </div>
      </div>
      <div class="settings">
        <h3>{{ uiText.worksManagement }}</h3>
        <div class="settings-grid">
          <div class="setting-item" @click="navigateTo('PortfolioManager')">
            <i class="fas fa-images"></i>
            <span>{{ uiText.portfolio }}</span>
          </div>
          <div class="setting-item" @click="navigateTo('DesignManager')">
            <i class="fas fa-palette"></i>
            <span>{{ uiText.design }}</span>
          </div>
        </div>
      </div>
      
      <div class="settings">
        <h3>{{ uiText.others }}</h3>
        <div class="settings-grid">
          <div class="setting-item" @click="navigateTo('UserClass')">
            <i class="fas fa-star"></i>
            <span>{{ uiText.membership }}</span>
          </div>
          <div class="setting-item" @click="navigateTo('StripeConnect')">
            <i class="fas fa-wallet"></i>
            <span>{{ uiText.obd }}</span>
          </div>
          <!--
          <div class="setting-item" @click="navigateTo('SettingAccount')">
            <i class="fas fa-wallet"></i>
            <span>{{ uiText.account }}</span>
          </div>
          -->
          <div class="setting-item" @click="navigateTo('PageStats')">
            <i class="fas fa-chart-line"></i>
            <span>{{ uiText.statistics }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { db } from "@/firebase";
import { doc, getDoc, updateDoc, query, collection, getDocs, where } from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";
import { useRouter } from 'vue-router';

export default {
  name: "MyPage",
  setup() {
    const email = ref("");
    const nickname = ref("");
    const profileImageUrl = ref("");
    const userType = ref("");
    const userId = ref("");
    const auth = getAuth();
    const router = useRouter();

    const translations = {
      en: {
        myPage: 'My Page',
        logout: 'Logout',
        editProfile: 'Edit Profile',
        profileManagement: 'Profile Management',
        myProfile: 'My Profile',
        notice: 'Notice',
        multiLink: 'Multi-Link',
        awards: 'Awards',
        welcomeMessage: 'Welcome Message',
        profileColor: 'Profile Color',
        worksManagement: 'Works Management',
        portfolio: 'Portfolio',
        design: 'Design',
        others: 'Others',
        membership: 'Membership',
        obd: 'On Boarding',
        account: 'Account',
        statistics: 'Statistics',
        unverifiedProfileMessage: 'Your profile is not verified.<br>Your profile will be visible after verification.',
        verifyNow: 'Verify Now',
      },
      ko: {
        myPage: '내정보',
        logout: '로그아웃',
        editProfile: '프로필 수정',
        profileManagement: '프로필 설정',
        myProfile: '내 프로필',
        notice: '공지사항',
        multiLink: '외부링크',
        awards: '수상내역',
        welcomeMessage: '환영메시지',
        profileColor: '프로필 색상',
        worksManagement: '작업물',
        portfolio: '포트폴리오',
        design: '디자인',
        others: '기타',
        membership: '멤버십',
        obd: '온보딩',
        account: '계좌정보',
        statistics: '방문통계',
        unverifiedProfileMessage: '프로필이 인증되지 않았습니다.<br>인증 후 프로필이 표시됩니다.',
        verifyNow: '인증하기',
      },
      jp: {
        myPage: 'マイページ',
        logout: 'ログアウト',
        editProfile: '修整',
        profileManagement: 'プロファイル',
        myProfile: 'マイプロファイル',
        notice: '公知事項',
        multiLink: '外部リンク',
        awards: '受賞内訳',
        welcomeMessage: '歓迎のメッセージ',
        profileColor: 'プロフィール 色',
        worksManagement: '作業物',
        portfolio: 'ポートフォリオ',
        design: 'デザイン',
        others: '等',
        membership: 'メンバーシップ',
        obd: '財布',
        account: '口座情報',
        statistics: '訪問統計',
        unverifiedProfileMessage: 'プロファイルが確認されていません。<br>確認後にプロファイルが表示されます。',
        verifyNow: '確認',
      },
    };

    const selectedLanguage = ref(localStorage.getItem('selectedLanguage') || 'en');
    const uiText = ref(translations[selectedLanguage.value]);

    const loadUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        userId.value = user.uid;
        const userDoc = doc(db, "users", userId.value);
        const userSnapshot = await getDoc(userDoc);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          email.value = userData.email || "";
          nickname.value = userData.nickname || "";
          profileImageUrl.value = userData.profileImageUrl || "";
          userType.value = userData.userType || "";
        }
      } else {
        router.push('/login');
      }
    };

    const navigateTo = (routeName) => {
      router.push({ name: routeName });
    };

    const viewProfile = () => {
      const user = auth.currentUser;
      if (user) {
        router.push({
          name: 'TattooistProfile',
          params: { id: user.uid }
        });
      }
    };

const changeUserType = async () => {
  const user = auth.currentUser;
  if (user) {
    const userDoc = doc(db, "users", user.uid);
    const userSnapshot = await getDoc(userDoc);
    
    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();
      
      const portfoliosQuery = query(
        collection(db, "portfolios"), 
        where("userId", "==", user.uid)
      );
      const portfoliosSnapshot = await getDocs(portfoliosQuery);
      const portfolioCount = portfoliosSnapshot.size;

      if (userData.introduce && userData.mainGenre && userData.subGenres && userData.location && portfolioCount >= 3) {
        try {
          await updateDoc(userDoc, {
            userType: 'tattooist'
          });
          const successMessage = {
            en: 'Successfully verified as a tattooist.',
            ko: '타투이스트로 성공적으로 인증되었습니다.',
            jp: 'タトゥーアーティストとして正常に確認されました。'
          };
          alert(successMessage[selectedLanguage.value]);
          loadUserData();
        } catch (error) {
          console.error('Type change failed:', error);
          const errorMessage = {
            en: 'Failed to change type.',
            ko: '타입 변경에 실패했습니다.',
            jp: 'タイプの変更に失敗しました。'
          };
          alert(errorMessage[selectedLanguage.value]);
        }
      } else {
        if (portfolioCount < 3) {
          const portfolioMessage = {
            en: 'You need at least 3 portfolio items for tattooist verification.',
            ko: '타투이스트 인증을 위해 최소 3개의 포트폴리오 항목이 필요합니다.',
            jp: 'タトゥーアーティストの確認には少なくとも3つのポートフォリオ項目が必要です。'
          };
          alert(portfolioMessage[selectedLanguage.value]);
          router.push('/portfoliomanager');
        } else {
          const profileMessage = {
            en: 'Please complete your profile for verification.',
            ko: '인증을 위해 프로필을 완성해 주세요.',
            jp: '確認のためにプロフィールを完成させてください。'
          };
          alert(profileMessage[selectedLanguage.value]);
          router.push('/tattooisteditpage');
        }
      }
    }
  }
};

    const handleLogout = async () => {
      try {
        await signOut(auth);
        alert('Successfully logged out');
        router.push('/');
      } catch (error) {
        alert('Logout failed: ' + error.message);
      }
    };

    onMounted(() => {
      loadUserData();
    });

    return {
      email,
      nickname,
      profileImageUrl,
      userType,
      navigateTo,
      viewProfile,
      changeUserType,
      handleLogout,
      uiText,
    };
  }
};
</script>

<style scoped>

.my-page-container {
 margin-top: 80px;
 position: absolute;
 left: 0;
 top: 0;
 width: 100%;
}

.my-page-wrap {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.profile-section {
 background: white;
 border-radius: 16px;
 padding: 15px;
 box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
 display: flex;
 align-items: center;
 gap: 32px;
 margin: 0;
 width: 100%;
 box-sizing: border-box;
}

.image-container {
 position: relative;
}

.avatar {
 width: 120px;
 height: 120px;
 border-radius: 50%;
 object-fit: cover;
 border: 4px solid #fff;
 box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
 transition: transform 0.3s ease;
}

.user-details {
 display: flex;
 flex-direction: column;
 gap: 8px;
}

.user-details h3 {
 font-size: 28px;
 font-weight: 600;
 color: #2c3e50;
 margin: 0;
}

.user-details p {
 color: #6c757d;
 font-size: 16px;
 margin: 0;
}

.edit-profile-button {
 background: #333;
 color: white;
 border: none;
 padding: 12px;
 border-radius: 12px;
 font-weight: 500;
 cursor: pointer;
}

.unverified-notice {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 16px;
  padding: 20px;
  margin: 20px 0;
}

.notice-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.notice-content i {
  color: #856404;
  font-size: 24px;
}

.notice-content p {
  color: #856404;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

.verify-button {
  background: #856404;
  color: white;
  border: none;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s ease;
  margin-left: auto;
}

.settings {
 background: white;
 border-radius: 16px;
 padding: 15px;
 box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
 margin-top: 20px;
}

.settings h3 {
 color: #2c3e50;
 font-size: 24px;
 margin: 0 0 20px 0;
}

.settings-grid {
 display: grid;
 grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
 gap: 16px;
}

.setting-item {
 display: flex;
 align-items: center;
 padding: 16px;
 border-radius: 12px;
 cursor: pointer;
 transition: all 0.3s ease;
 background: #f8f9fa;
 gap: 10px;
}

.setting-item i {
 display: flex;
 justify-content: center;
 width: 20px;
 font-size: 19px;
 color: #333;
}

.header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header-section h2 {
  margin: 0;
}

.logout-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s ease;
}

.logout-button .material-icons {
  font-size: 14px;
}

</style> 