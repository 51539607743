<template>
    <div class="account-manager-container">
      <div class="header-section">
        <h2>{{ uiText.accountManagement }}</h2>
      </div>
  
      <div class="account-form" v-if="!loading">
        <div class="form-group">
          <label>{{ uiText.bankName }}</label>
          <input 
            type="text" 
            v-model="bankName"
            class="input-field"
            :placeholder="uiText.enterBankName"
          />
        </div>
  
        <div class="form-group">
          <label>{{ uiText.accountNumber }}</label>
          <input 
            type="text" 
            v-model="accountNumber"
            class="input-field"
            :placeholder="uiText.enterAccountNumber"
          />
        </div>
  
        <div class="form-group">
          <label>{{ uiText.accountHolder }}</label>
          <input 
            type="text" 
            v-model="accountHolder"
            class="input-field"
            :placeholder="uiText.enterAccountHolder"
          />
        </div>
  
        <div class="form-group">
          <label>{{ uiText.reservationFee }}</label>
          <input 
            type="number" 
            v-model="reservationFee"
            class="input-field"
            :placeholder="uiText.enterReservationFee"
          />
        </div>
  
        <button class="save-button" @click="saveAccountInfo">
          <i class="fas fa-save"></i> {{ uiText.save }}
        </button>
      </div>
  
      <div v-else class="loading">
        <p>{{ uiText.loading }}</p>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue'
  import { db } from '@/firebase'
  import { doc, getDoc, updateDoc } from 'firebase/firestore'
  import { getAuth } from 'firebase/auth'
  import { useRouter } from 'vue-router'

  export default {
    name: 'SettingAccount',
    setup() {
      const auth = getAuth()
      const loading = ref(true)
      const bankName = ref('')
      const accountNumber = ref('')
      const accountHolder = ref('')
      const reservationFee = ref('')
      const router = useRouter();

      const translations = {
        en: {
          accountManagement: 'Account Management',
          bankName: 'Bank Name',
          accountNumber: 'Account Number',
          accountHolder: 'Account Holder',
          reservationFee: 'Reservation Fee',
          enterBankName: 'Enter your bank name',
          enterAccountNumber: 'Enter without hyphens (-)',
          enterAccountHolder: 'Enter account holder name',
          enterReservationFee: 'Enter reservation fee',
          save: 'Save',
          loading: 'Loading...',
          fillAllFields: 'Please fill in all fields.',
          loginRequired: 'Login required.',
          saveSuccess: 'Account information has been saved successfully.',
          saveFailure: 'Failed to save account information.',
          loadFailure: 'Failed to load account information.',
        },
        ko: {
          accountManagement: '계정 관리',
          bankName: '은행 이름',
          accountNumber: '계좌 번호',
          accountHolder: '예금주',
          reservationFee: '예약금',
          enterBankName: '은행 이름을 입력하세요',
          enterAccountNumber: '하이픈(-) 없이 입력하세요',
          enterAccountHolder: '예금주 이름을 입력하세요',
          enterReservationFee: '예약금을 입력하세요',
          save: '저장',
          loading: '로딩 중...',
          fillAllFields: '모든 필드를 입력하세요.',
          loginRequired: '로그인이 필요합니다.',
          saveSuccess: '계정 정보가 성공적으로 저장되었습니다.',
          saveFailure: '계정 정보를 저장하는 데 실패했습니다.',
          loadFailure: '계정 정보를 불러오는 데 실패했습니다.',
        },
        jp: {
          accountManagement: 'アカウント管理',
          bankName: '銀行名',
          accountNumber: '口座番号',
          accountHolder: '口座名義人',
          reservationFee: '予約金',
          enterBankName: '銀行名を入力してください',
          enterAccountNumber: 'ハイフン(-)なしで入力してください',
          enterAccountHolder: '口座名義人を入力してください',
          enterReservationFee: '予約金を入力してください',
          save: '保存',
          loading: '読み込み中...',
          fillAllFields: 'すべてのフィールドに入力してください。',
          loginRequired: 'ログインが必要です。',
          saveSuccess: 'アカウント情報が正常に保存されました。',
          saveFailure: 'アカウント情報の保存に失敗しました。',
          loadFailure: 'アカウント情報の読み込みに失敗しました。',
        },
      };

      const selectedLanguage = ref(localStorage.getItem('selectedLanguage') || 'en');
      const uiText = ref(translations[selectedLanguage.value]);

      const loadAccountInfo = async () => {
        try {
          const user = auth.currentUser
          if (!user) return
  
          const userDoc = await getDoc(doc(db, 'users', user.uid))
          if (userDoc.exists()) {
            const data = userDoc.data()
            bankName.value = data.bankName || ''
            accountNumber.value = data.accountNumber || ''
            accountHolder.value = data.accountHolder || ''
            reservationFee.value = data.reservationFee || ''
          }
        } catch (error) {
          console.error('Failed to load account info:', error)
          alert(uiText.value.loadFailure)
        } finally {
          loading.value = false
        }
      }
  
      const saveAccountInfo = async () => {
        if (!bankName.value || !accountNumber.value || !accountHolder.value || !reservationFee.value) {
          alert(uiText.value.fillAllFields)
          return
        }
  
        try {
          const user = auth.currentUser
          if (!user) {
            alert(uiText.value.loginRequired)
            return
          }
  
          await updateDoc(doc(db, 'users', user.uid), {
            bankName: bankName.value,
            accountNumber: accountNumber.value,
            accountHolder: accountHolder.value,
            reservationFee: reservationFee.value,
            updatedAt: new Date()
          })
  
          alert(uiText.value.saveSuccess)
          router.push('/mypage')
        } catch (error) {
          console.error('Failed to save account info:', error)
          alert(uiText.value.saveFailure)
        }
      }
  
      onMounted(() => {
        loadAccountInfo()
      })
  
      return {
        loading,
        bankName,
        accountNumber,
        accountHolder,
        reservationFee,
        saveAccountInfo,
        uiText
      }
    }
  }
  </script>
  
  <style scoped>
  .account-manager-container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .header-section {
    margin-bottom: 30px;
  }
  
  .header-section h2 {
    font-size: 24px;
    color: #333;
    margin: 0;
  }
  
  .account-form {
    background: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: #333;
  }
  
  .input-field {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    box-sizing: border-box;
  }
  
  .input-field:focus {
    outline: none;
    border-color: #666;
  }
  
  select.input-field {
    background-color: white;
  }
  
  .save-button {
    width: 100%;
    padding: 12px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    transition: background-color 0.3s;
  }
  
  .save-button:hover {
    background-color: #444;
  }
  
  .loading {
    text-align: center;
    padding: 20px;
    color: #666;
  }
  </style>