<template>
  <div class="login-container">
    <h1>{{ uiText.login }}</h1>
    
    <div class="login-type-toggle">
      <button 
        :class="['toggle-btn', { active: !isPhoneLogin }]" 
        @click="isPhoneLogin = false"
      >
        {{ uiText.loginWithEmail }}
      </button>
      <button 
        :class="['toggle-btn', { active: isPhoneLogin }]" 
        @click="isPhoneLogin = true"
      >
        {{ uiText.loginWithPhone }}
      </button>
    </div>

    <div v-if="!isPhoneLogin" class="login-section">
      <div class="input-group">
        <input v-model="email" type="email" :placeholder="uiText.email" />
        <input v-model="password" type="password" :placeholder="uiText.password" />
        <button @click="loginWithEmail" :disabled="!email || !password">{{ uiText.login }}</button>
      </div>
    </div>

    <div v-else class="login-section">
      <div class="input-group">
        <div class="phone-input-container">
          <select 
            v-model="selectedCountryCode"
            class="country-code-select"
          >
            <option value="+82">+82 (Korea)</option>
            <option value="+1">+1 (USA)</option>
            <option value="+7">+7 (Russia)</option>
            <option value="+27">+27 (South Africa)</option>
            <option value="+33">+33 (France)</option>
            <option value="+34">+34 (Spain)</option>
            <option value="+39">+39 (Italy)</option>
            <option value="+44">+44 (UK)</option>
            <option value="+49">+49 (Germany)</option>
            <option value="+55">+55 (Brazil)</option>
            <option value="+61">+61 (Australia)</option>
            <option value="+62">+62 (Indonesia)</option>
            <option value="+66">+66 (Thailand)</option>
            <option value="+81">+81 (Japan)</option>
            <option value="+86">+86 (China)</option>
            <option value="+91">+91 (India)</option>
            <option value="+234">+234 (Nigeria)</option>
            <option value="+852">+852 (Hong Kong)</option>
            <option value="+886">+886 (Taiwan)</option>
          </select>
          <input 
            v-model="phoneNumber" 
            type="tel" 
            :placeholder="uiText.phoneNumber"
            class="phone-input"
          />
        </div>
        <input 
          v-model="password" 
          type="password" 
          :placeholder="uiText.password"
        />
        <button 
          @click="loginWithPhone"
          :disabled="!phoneNumber || !password"
        >
          {{ uiText.login }}
        </button>
      </div>
    </div>

    <div class="signup-section">
      <span v-if="isPhoneLogin" class="otp-login-btn" @click="goToOTPLogin"> 기존회원 로그인 </span>
      <p>{{ uiText.noAccount }} <span class="signup-link" @click="goToSignup">{{ uiText.signUp }}</span></p>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '@/firebase';
import { useRouter } from 'vue-router';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '@/firebase';

export default {
  setup() {
    const router = useRouter();
    const isPhoneLogin = ref(false);
    const email = ref('');
    const password = ref('');
    const phoneNumber = ref('');
    const selectedCountryCode = ref('+82');

    const translations = {
      en: {
        login: 'Log in',
        loginWithEmail: 'Log in with email',
        loginWithPhone: 'Log in with phone',
        email: 'Email',
        password: 'Password',
        phoneNumber: 'Phone Number (without hyphens)',
        noAccount: "Don't have an account?",
        signUp: 'Sign up',
        loginSuccess: 'Login successful',
        loginFailed: 'Login failed: ',
        phoneNotFound: 'Phone number not found. Please sign up first.',
        wrongPassword: 'Incorrect password',
      },
      ko: {
        login: '로그인',
        loginWithEmail: '이메일로 로그인',
        loginWithPhone: '전화번호로 로그인',
        email: '이메일',
        password: '비밀번호',
        phoneNumber: '전화번호 (하이픈 없이)',
        noAccount: '계정이 없으신가요?',
        signUp: '회원가입',
        loginSuccess: '로그인 성공',
        loginFailed: '로그인 실패: ',
        phoneNotFound: '해당 전화번호로 등록된 사용자가 없습니다. 먼저 회원가입을 해주세요.',
        wrongPassword: '비밀번호가 틀립니다',
      },
      jp: {
        login: 'ログイン',
        loginWithEmail: 'メールでログイン',
        loginWithPhone: '電話でログイン',
        email: 'メール',
        password: 'パスワード',
        phoneNumber: '電話番号（ハイフンなし）',
        noAccount: 'アカウントをお持ちでないですか？',
        signUp: 'サインアップ',
        loginSuccess: 'ログインに成功しました',
        loginFailed: 'ログインに失敗しました：',
        phoneNotFound: '電話番号が見つかりません。先に会員登録をしてください。',
        wrongPassword: 'パスワードが正しくありません',
      },
    };

    const selectedLanguage = ref(localStorage.getItem('selectedLanguage') || 'en');
    const uiText = ref(translations[selectedLanguage.value]);

    const loginWithEmail = async () => {
      try {
        await signInWithEmailAndPassword(auth, email.value, password.value);
        alert(uiText.value.loginSuccess);
        router.push('/');
      } catch (error) {
        alert(uiText.value.loginFailed + ': ' + error.message);
      }
    };

    const loginWithPhone = async () => {
      try {
        const formattedPhoneNumber = `${selectedCountryCode.value}${phoneNumber.value.replace(/^0/, '')}`;
        const q = query(
          collection(db, "users"),
          where("phoneNumber", "==", formattedPhoneNumber)
        );
        const querySnapshot = await getDocs(q);
        
        if (querySnapshot.empty) {
          alert(uiText.value.phoneNotFound);
          return;
        }

        const userData = querySnapshot.docs[0].data();
        const phoneEmail = userData.phoneEmail;

        await signInWithEmailAndPassword(auth, phoneEmail, password.value);
        alert(uiText.value.loginSuccess);
        router.push('/');
      } catch (error) {
        if (error.code === 'auth/wrong-password') {
          alert(uiText.value.wrongPassword);
        } else {
          alert(uiText.value.loginFailed + ': ' + error.message);
        }
      }
    };

    const goToSignup = () => {
      router.push('/signup');
    };

    const goToOTPLogin = () => {
      router.push('/otplogin');
    };

    return {
      isPhoneLogin,
      email,
      password,
      phoneNumber,
      selectedCountryCode,
      loginWithEmail,
      loginWithPhone,
      goToSignup,
      goToOTPLogin,
      uiText,
    };
  },
};
</script>

<style scoped>
.login-container {
  max-width: 400px;
  margin: 40px auto;
  margin-top: 100px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  font-size: 24px;
}

.login-section {
  margin-bottom: 25px;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 6px;
}

.input-group {
  margin-bottom: 15px;
}

input {
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

input:focus {
  border-color: #4CAF50;
}

input:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

button {
  width: 100%;
  padding: 12px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.divider {
  margin: 20px 0;
  text-align: center;
  position: relative;
}

.divider::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #eee;
  z-index: 1;
}

.divider span {
  background-color: #fff;
  padding: 0 10px;
  color: #666;
  position: relative;
  z-index: 2;
}

.login-type-toggle {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.toggle-btn {
  flex: 1;
  padding: 10px;
  background-color: #f5f5f5;
  color: #666;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}

.toggle-btn.active {
  background-color: #4CAF50;
  color: white;
  border-color: #4CAF50;
}

#recaptcha-container {
  margin: 10px 0;
}

.phone-input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 10px;
}

.country-code-select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  background-color: white;
  cursor: pointer;
}

.country-code-select:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.phone-input {
  flex: 1;
}

.signup-section {
  text-align: center;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.signup-section p {
  color: #666;
  font-size: 14px;
}

.signup-link {
  color: #4CAF50;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 5px;
}

.otp-login-btn {
  color: #4CAF50;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 5px;
}
</style> 