import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/fontstyle.css'
import './chunk-common.js'
import 'fabric'

import { auth, messaging, getToken, db } from '@/firebase'
import { onAuthStateChanged } from 'firebase/auth'
import { onMessage } from 'firebase/messaging'
import { doc, updateDoc } from 'firebase/firestore'

const app = createApp(App)

app.use(router)
app.mount('#app')

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
      .then(registration => {
        console.log('ServiceWorker 등록 성공:', registration)
      })
      .catch(error => {
        console.log('ServiceWorker 등록 실패:', error)
      })
  })
}

// FCM 초기화 함수
const initializeFCM = async () => {
  try {
    const isPWA = window.matchMedia('(display-mode: standalone)').matches || 
                 window.navigator.standalone || 
                 document.referrer.includes('android-app://');
    
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const userRef = doc(db, "users", auth.currentUser.uid);

    // 현재 알림 권한 상태 확인
    const permission = Notification.permission;
    
    // 플랫폼 정보 업데이트
    await updateDoc(userRef, {
      tokenLastUpdated: new Date(),
      platform: isPWA ? 'pwa' : (isMobile ? 'mobile' : 'desktop'),
      notificationBlocked: permission === 'denied'
    });

    // 알림 권한이 허용된 경우 토큰 요청 및 업데이트
    if (permission === 'granted') {
      try {
        const registration = await navigator.serviceWorker.ready;
        const currentToken = await getToken(messaging, {
          vapidKey: 'BOva3icccl2C8tCfNFgXvsUiCFCBxVscb1SNbtxaFySTjlEZc0A9DE7gxSLpPTbC05kMU2F8h3qy7Y_pDwr-87c',
          serviceWorkerRegistration: registration
        });

        if (currentToken) {
          await updateDoc(userRef, {
            fcmToken: currentToken,
            notificationBlocked: false,
            tokenLastUpdated: new Date()
          });
        }
      } catch (error) {
        console.error('토큰 가져오기 실패:', error);
      }
    }
  } catch (error) {
    console.error('FCM 초기화 실패:', error);
  }
};

// FCM 메시지 핸들러 (포그라운드)
const onMessageHandler = (payload) => {
  try {
    console.log('Message received. ', payload);
    
    // 데이터 메시지에서 필요한 정보 추출
    const title = payload.data?.title || 'New Message';
    const body = payload.data?.body || '';
    const clickAction = payload.data?.click_action || '/chat';

    // 알림 권한 확인
    if (Notification.permission === 'granted') {
      const notification = new Notification(title, {
        body,
        icon: '/icons/icon-192x192.png',
        badge: '/icons/badge-96x96.png',
        data: { url: clickAction },
        tag: 'chat'  // 알림 태그 설정
      });

      // 알림 클릭 이벤트 처리
      notification.onclick = (event) => {
        event.preventDefault();
        window.focus();
        if (notification.data.url) {
          router.push(notification.data.url);
        }
        notification.close();
      };
    }
  } catch (error) {
    console.error('알림 처리 중 오류 발생:', error);
  }
};

// FCM 메시지 리스너 등록
onMessage(messaging, onMessageHandler);

// 인증 상태 변경 감지
onAuthStateChanged(auth, async (user) => {
  if (user) {
    try {
      await initializeFCM();
    } catch (error) {
      console.error('FCM 초기화 실패:', error);
    }
  }
}); 
