<template>
  <div class="payment-request-container">
    <div class="payment-request-card">
      <div class="card-header">
        <h2>결제 요청 생성</h2>
      </div>
      
      <form @submit.prevent="createPaymentRequest">
        <div class="form-group">
          <label>금액</label>
          <div class="amount-input">
            <input 
              type="number" 
              v-model="amount" 
              required
              placeholder="금액을 입력하세요"
            >
            <select v-model="currency" required class="currency-select">
              <option value="usd">USD</option>
              <option value="krw">KRW</option>
              <option value="jpy">JPY</option>
            </select>
          </div>
        </div>

        <div class="form-group">
          <label>설명</label>
          <textarea 
            v-model="description" 
            required
            placeholder="결제에 대한 설명을 입력하세요"
            rows="4"
          ></textarea>
        </div>

        <button type="submit" :disabled="isLoading" class="submit-button">
          <span v-if="isLoading" class="loading-spinner"></span>
          {{ isLoading ? '처리 중...' : '결제 요청 생성' }}
        </button>
      </form>

      <div v-if="paymentLink" class="payment-link">
        <h3>결제 링크가 생성되었습니다</h3>
        <div class="link-box">
          <a :href="paymentLink" target="_blank">{{ paymentLink }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc, getDocs, collection, addDoc } from 'firebase/firestore';
import { useRouter } from 'vue-router';

export default {
  props: {
    recipientId: {
      type: String,
      required: true
    },
    chatRoomId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      amount: null,
      currency: 'usd',
      description: '',
      isLoading: false,
      paymentLink: null,
      apiBaseUrl: 'https://asia-northeast3-tattoomap3.cloudfunctions.net/sendNotification',
      userId: null,
      designerAccountId: null,
      router: useRouter()
    };
  },
  async created() {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      this.userId = user.uid;
      const db = getFirestore();
      
      // 디자이너의 Stripe 계정 ID 가져오기
      const userDoc = await getDoc(doc(db, "users", this.userId));
      this.designerAccountId = userDoc.data()?.stripeAccountId;
    }
  },
  methods: {
    async createPaymentRequest() {
      this.isLoading = true;
      try {
        const requestData = {
          amount: this.amount,
          currency: this.currency,
          description: this.description,
          designerAccountId: this.designerAccountId,
          userId: this.recipientId,
          requesterId: this.userId,
          chatRoomId: this.chatRoomId,
          on_behalf_of: this.designerAccountId
        };
        
        console.log('결제 요청 데이터:', requestData); // 요청 데이터 확인
        
        const response = await fetch(`${this.apiBaseUrl}/create_payment_request`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestData)
        });

        if (!response.ok) {
          const errorData = await response.json();
          console.error('서버 응답 에러:', errorData);
          throw new Error(errorData.message || '결제 요청 생성에 실패했습니다.');
        }

        const data = await response.json();
        console.log('서버 응답 데이터:', data); // 응답 데이터 확인
        
        this.paymentLink = `${window.location.origin}/pay/${data.paymentRequestId}`;
        
        // 채팅방에 결제 링크 메시지 전송
        await this.sendPaymentLinkMessage(data.paymentRequestId);
        
        // 채팅방으로 돌아가기
        this.router.back();
      } catch (error) {
        console.error('결제 요청 생성 중 오류 발생:', error);
        alert('결제 요청 생성에 실패했습니다.');
        this.router.push('/stripe-connect');
        
      } finally {
        this.isLoading = false;
      }
    },
    
    async sendPaymentLinkMessage(paymentRequestId) {
      const db = getFirestore();
      
      // 채팅방 ID 찾기 (현재 사용자와 수신자 간의 채팅방)
      const chatRoomsRef = collection(db, 'chatRooms');
      const chatRoomSnapshot = await getDocs(chatRoomsRef);
      let chatRoomId = null;
      
      chatRoomSnapshot.forEach((doc) => {
        const roomData = doc.data();
        if (roomData.participants.includes(this.userId) && 
            roomData.participants.includes(this.recipientId)) {
          chatRoomId = doc.id;
        }
      });
      
      if (!chatRoomId) {
        console.error('채팅방을 찾을 수 없습니다.');
        return;
      }
      
      // 결제 링크 메시지 전송
      const messagesRef = collection(db, 'chatRooms', chatRoomId, 'messages');
      await addDoc(messagesRef, {
        type: 'payment',
        content: {
          text: '결제 요청이 생성되었습니다.',
          link: this.paymentLink,
          amount: this.amount,
          currency: this.currency,
          description: this.description
        },
        paymentRequestId: paymentRequestId,
        senderId: this.userId,
        timestamp: new Date(),
        read: false
      });
    }
  }
};
</script>

<style scoped>
.payment-request-container {
  min-height: 100vh;
  padding: 20px;
  background-color: #f8f9fa;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 100px;
}

.payment-request-card {
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
  overflow: hidden;
}

.card-header {
  background: #1a1a1a;
  color: white;
  padding: 24px;
  text-align: center;
}

.card-header h2 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
}

form {
  padding: 24px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 8px;
  color: #4a4a4a;
  font-weight: 500;
  font-size: 0.95rem;
}

.amount-input {
  display: flex;
  gap: 12px;
}

input, textarea {
  width: 100%;
  padding: 12px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.2s;
  box-sizing: border-box;
}

input:focus, textarea:focus {
  border-color: #1a1a1a;
  outline: none;
}

.currency-select {
  padding: 12px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  background: white;
  font-size: 1rem;
  min-width: 100px;
}

.submit-button {
  width: 100%;
  padding: 14px;
  background: #1a1a1a;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.submit-button:hover {
  background: #333;
}

.submit-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.loading-spinner {
  width: 20px;
  height: 20px;
  border: 3px solid #ffffff;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.payment-link {
  padding: 24px;
  background: #f8f9fa;
  border-top: 1px solid #e0e0e0;
}

.payment-link h3 {
  margin: 0 0 16px 0;
  color: #1a1a1a;
  font-size: 1.1rem;
}

.link-box {
  background: white;
  padding: 16px;
  border-radius: 8px;
  border: 2px solid #e0e0e0;
  word-break: break-all;
}

.link-box a {
  color: #007bff;
  text-decoration: none;
}

.link-box a:hover {
  text-decoration: underline;
}
</style> 