<template>
  <div class="stripe-connect-container">
    <div v-if="!stripeAccountId" class="onboarding-section">
      <h3>{{ uiText.connectRequired }}</h3>
      <p>{{ uiText.connectDescription }}</p>
      
      <!-- 국가 선택 -->
      <div class="input-wrapper">
        <label for="country">{{ uiText.selectCountry }}</label>
        <select 
          v-model="selectedCountry" 
          id="country"
          class="country-select"
        >
          <option value="" disabled>{{ uiText.selectCountryPlaceholder }}</option>
          <option v-for="(country, code) in countries" 
                  :key="code" 
                  :value="code">
            {{ country[currentLanguage] }}
          </option>
        </select>
        <p class="help-text">{{ uiText.countryChangeWarning }}</p>
      </div>

      <!-- 계정 생성 버튼 -->
      <button 
        @click="startOnboarding"
        :disabled="isLoading || !selectedCountry"
        class="connect-button"
      >
        <span v-if="isLoading">{{ uiText.processing }}</span>
        <span v-else>{{ uiText.createAccount }}</span>
      </button>

      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </div>

    <div v-else class="connected-section">
      <!-- 계정 상태 헤더 -->
      <div class="status-header">
        <div class="status-badge" :class="{ 'status-complete': isFullyVerified }">
          <i class="fas" :class="isFullyVerified ? 'fa-check-circle' : 'fa-clock'"></i>
          <span>{{ isFullyVerified ? '계정 설정 완료' : '계정 설정 진행 중' }}</span>
        </div>
        <div class="last-updated">
          마지막 업데이트: {{ formatDate(stripeLastUpdated) }}
        </div>
      </div>

      <!-- 대시보드 링크 버튼 추가 -->
      <div class="action-buttons">
        <button 
          @click="refreshOnboarding" 
          class="connect-button dashboard"
        >
          <i class="fas fa-cog"></i>
          계정 설정 수정
        </button>
        <button 
          @click="openStripeDashboard" 
          class="connect-button dashboard secondary"
        >
          <i class="fas fa-external-link-alt"></i>
          Stripe 대시보드
        </button>
      </div>

      <!-- 계정 정보 카드 -->
      <div class="info-card">
        <h4>기본 정보</h4>
        <div class="info-grid">
          <div class="info-item">
            <span class="label">국가</span>
            <span class="value">{{ getCountryName(stripeCountry) }}</span>
          </div>
          <div class="info-item">
            <span class="label">통화</span>
            <span class="value">{{ stripeAccountDetails?.default_currency?.toUpperCase() }}</span>
          </div>
          <div class="info-item">
            <span class="label">계정 유형</span>
            <span class="value">Express</span>
          </div>
        </div>
      </div>

      <!-- 은행 계좌 정보 -->
      <div class="info-card" v-if="bankAccount">
        <h4>은행 계좌 정보</h4>
        <div class="info-grid">
          <div class="info-item">
            <span class="label">은행</span>
            <span class="value">{{ bankAccount.bank_name }}</span>
          </div>
          <div class="info-item">
            <span class="label">계좌번호</span>
            <span class="value">**** {{ bankAccount.last4 }}</span>
          </div>
          <div class="info-item">
            <span class="label">예금주</span>
            <span class="value">{{ bankAccount.account_holder_name }}</span>
          </div>
        </div>
      </div>

      <!-- 계정 상태 -->
      <div class="info-card">
        <h4>계정 상태</h4>
        <div class="status-grid">
          <div class="status-item" :class="{ active: stripeChargesEnabled }">
            <i class="fas" :class="stripeChargesEnabled ? 'fa-check' : 'fa-times'"></i>
            <div class="status-text">
              <span class="status-label">결제 기능</span>
              <span class="status-value">{{ stripeChargesEnabled ? '활성화' : '비활성화' }}</span>
            </div>
          </div>
          <div class="status-item" :class="{ active: stripePayoutsEnabled }">
            <i class="fas" :class="stripePayoutsEnabled ? 'fa-check' : 'fa-times'"></i>
            <div class="status-text">
              <span class="status-label">정산 기능</span>
              <span class="status-value">{{ stripePayoutsEnabled ? '활성화' : '비활성화' }}</span>
            </div>
          </div>
        </div>
      </div>

      <!-- 필요한 추가 정보 -->
      <div class="info-card warning" v-if="hasRequirements">
        <h4>필요한 추가 정보</h4>
        <ul class="requirements-list">
          <li v-for="requirement in currentRequirements" :key="requirement">
            <i class="fas fa-exclamation-triangle"></i>
            {{ getRequirementText(requirement) }}
          </li>
        </ul>
        <button 
          @click="refreshOnboarding"
          class="connect-button update"
          :disabled="isLoading"
        >
          <span v-if="isLoading">처리 중...</span>
          <span v-else>정보 업데이트</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue';
import { getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '@/firebase';
import { format } from 'date-fns';
import { ko } from 'date-fns/locale';

const auth = getAuth();
const isLoading = ref(false);
const errorMessage = ref('');
const stripeAccountId = ref('');
const stripeCountry = ref('');
const accountStatus = ref('');
const selectedCountry = ref('');
const apiUrl = 'https://asia-northeast3-tattoomap3.cloudfunctions.net/sendNotification';

// 현재 언어 설정
const currentLanguage = ref(localStorage.getItem('selectedLanguage') || 'ko');

// 국가 정보
const countries = {
  'KR': {
    ko: '대한민국 (KRW ₩)',
    en: 'South Korea (KRW ₩)',
    jp: '韓国 (KRW ₩)'
  },
  'JP': {
    ko: '일본 (JPY ¥)',
    en: 'Japan (JPY ¥)',
    jp: '日本 (JPY ¥)'
  },
  'US': {
    ko: '미국 (USD $)',
    en: 'United States (USD $)',
    jp: 'アメリカ (USD $)'
  },
  'GB': {
    ko: '영국 (GBP £)',
    en: 'United Kingdom (GBP £)',
    jp: 'イギリス (GBP £)'
  }
};

// UI 텍스트
const translations = {
  ko: {
    connectRequired: '아티스트 계정 연동 필요',
    connectDescription: '상품 등록 및 수익 창출을 위해 Stripe 계정을 연결해주세요',
    selectCountry: '정산 받을 국가를 선택해주세요',
    selectCountryPlaceholder: '국가를 선택하세요',
    countryChangeWarning: '* 한번 선택한 국가는 변경할 수 없습니다',
    processing: '처리 중...',
    createAccount: 'Stripe 계정 만들기',
    accountConnected: 'Stripe 계정이 연결되었습니다',
    settlementCountry: '정산 국가',
    connectionStatus: '연동 상태',
    setupRequired: '설정 필요',
    completed: '완료',
    continueSetup: '계정 설정 계속하기'
  },
  en: {
    connectRequired: 'Artist Account Connection Required',
    connectDescription: 'Please connect your Stripe account to register products and generate revenue',
    selectCountry: 'Select your settlement country',
    selectCountryPlaceholder: 'Select a country',
    countryChangeWarning: '* The selected country cannot be changed later',
    processing: 'Processing...',
    createAccount: 'Create Stripe Account',
    accountConnected: 'Stripe Account Connected',
    settlementCountry: 'Settlement Country',
    connectionStatus: 'Connection Status',
    setupRequired: 'Setup Required',
    completed: 'Completed',
    continueSetup: 'Continue Account Setup'
  },
  jp: {
    connectRequired: 'アーティストアカウント連携が必要です',
    connectDescription: '商品登録および収益創出のためStripeアカウントを連携してください',
    selectCountry: '決済を受け取る国を選択してください',
    selectCountryPlaceholder: '国を選択してください',
    countryChangeWarning: '* 一度選択した国は変更できません',
    processing: '処理中...',
    createAccount: 'Stripeアカウントを作成',
    accountConnected: 'Stripeアカウントが連携されました',
    settlementCountry: '決済国',
    connectionStatus: '連携状態',
    setupRequired: '設定が必要',
    completed: '完了',
    continueSetup: 'アカウント設定を続ける'
  }
};

// UI 텍스트 가져오기
const uiText = ref(translations[currentLanguage.value]);

// 국가 이름 변환 함수
const getCountryName = (code) => {
  return countries[code]?.[currentLanguage.value] || code;
};

// 언어 변경 감지 및 UI 텍스트 업데이트
watch(() => localStorage.getItem('selectedLanguage'), (newLang) => {
  if (newLang) {
    currentLanguage.value = newLang;
    uiText.value = translations[newLang];
  }
});

// 에러 처리 함수 추가
const handleError = (error) => {
  console.error('상세 에러:', error);
  
  if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
    return '서버 연결에 실패했습니다. 네트워크 연결을 확인해주세요.';
  }
  
  return error.message || '알 수 없는 오류가 발생했습니다.';
};

// Stripe 연동 시작
const startOnboarding = async () => {
  try {
    if (!selectedCountry.value) {
      errorMessage.value = '국가를 선택해주세요';
      return;
    }

    isLoading.value = true;
    errorMessage.value = '';
    
    const user = auth.currentUser;
    if (!user) throw new Error('로그인이 필요합니다');

    const idToken = await user.getIdToken();
    
    // API URL 수정
    const response = await fetch(`${apiUrl}/stripe/account`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
        'X-Stripe-Mode': 'test'
      },
      body: JSON.stringify({
        uid: user.uid,
        country: selectedCountry.value,
        testMode: true
      }),
      credentials: 'include' // 쿠키 포함
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || '계정 생성에 실패했습니다');
    }

    const data = await response.json();
    window.location.href = data.url;

  } catch (error) {
    console.error('Stripe 테스트 연동 오류:', error);
    errorMessage.value = handleError(error);
  } finally {
    isLoading.value = false;
  }
};

// Stripe 온보딩 계속하기
const refreshOnboarding = async () => {
  try {
    isLoading.value = true;
    errorMessage.value = '';
    
    const user = auth.currentUser;
    if (!user) throw new Error('로그인이 필요합니다');

    const idToken = await user.getIdToken();
    
    const response = await fetch(`${apiUrl}/account_link`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
        'X-Stripe-Mode': 'test'
      },
      body: JSON.stringify({
        account: stripeAccountId.value,
        userId: user.uid
      }),
      credentials: 'include'
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || '계정 설정을 계속할 수 없습니다');
    }

    const data = await response.json();
    window.location.href = data.url;

  } catch (error) {
    console.error('Stripe 온보딩 계속하기 오류:', error);
    errorMessage.value = handleError(error);
  } finally {
    isLoading.value = false;
  }
};

// 계정 상태 확인 함수
const checkAccountStatus = async () => {
  try {
    const user = auth.currentUser;
    if (!user) return;

    const idToken = await user.getIdToken();
    
    const response = await fetch(`${apiUrl}/account_status`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
        'X-Stripe-Mode': 'test'
      },
      body: JSON.stringify({
        accountId: stripeAccountId.value,
        userId: user.uid
      })
    });

    if (!response.ok) throw new Error('계정 상태 확인 실패');

    const data = await response.json();
    console.log('Stripe 계정 상태:', data);
    
    if (data.accountDetails) {
      accountStatus.value = data.isOnboarded ? 'completed' : 'pending';
    }

  } catch (error) {
    console.error('상태 확인 오류:', error);
  }
};

// Stripe 계정 상세 정보
const stripeAccountDetails = ref(null);
const stripeRequirements = ref(null);

// Stripe 상태 관련 ref 추가
const stripeIsOnboarded = ref(false);
const stripeChargesEnabled = ref(false);
const stripePayoutsEnabled = ref(false);
const stripeLastUpdated = ref(null);

// Firestore에서 데이터 가져오기
onMounted(async () => {
  const user = auth.currentUser;
  if (user?.uid) {
    const userDoc = await getDoc(doc(db, 'users', user.uid));
    if (userDoc.exists()) {
      const data = userDoc.data();
      stripeAccountId.value = data.stripeAccountId || '';
      stripeCountry.value = data.stripeCountry || '';
      stripeAccountDetails.value = data.stripeAccountDetails || null;
      stripeRequirements.value = data.stripeRequirements || null;
      // 새로운 상태값 설정
      stripeIsOnboarded.value = data.stripeIsOnboarded || false;
      stripeChargesEnabled.value = data.stripeChargesEnabled || false;
      stripePayoutsEnabled.value = data.stripePayoutsEnabled || false;
      stripeLastUpdated.value = data.stripeLastUpdated || null;
    }
  }
  if (stripeAccountId.value) {
    await checkAccountStatus();
  }
});

// 은행 계좌 정보 computed 속성
const bankAccount = computed(() => {
  return stripeAccountDetails.value?.external_accounts?.data?.[0];
});

// 요구사항 관련 computed 속성
const hasRequirements = computed(() => {
  return stripeRequirements.value?.currently_due?.length > 0;
});

const currentRequirements = computed(() => {
  return stripeRequirements.value?.currently_due || [];
});

// 날짜 포맷 함수
const formatDate = (date) => {
  if (!date) return '';
  return format(date.toDate(), 'yyyy년 MM월 dd일 HH:mm', { locale: ko });
};

// 요구사항 텍스트 변환 함수
const getRequirementText = (requirement) => {
  const requirementMap = {
    'individual.verification.document': '신분증 인증이 필요합니다',
    'business_profile.url': '비즈니스 웹사이트 URL이 필요합니다',
    'business_profile.mcc': '비즈니스 카테고리 선택이 필요합니다',
    'individual.verification.additional_document': '추가 신분증 인증이 필요합니다',
    'individual.first_name': '이름을 입력해주세요',
    'individual.last_name': '성을 입력해주세요',
    'individual.dob.day': '생년월일(일)을 입력해주세요',
    'individual.dob.month': '생년월일(월)을 입력해주세요',
    'individual.dob.year': '생년월일(년)을 입력해주세요',
    'individual.phone': '전화번호를 입력해주세요',
    'individual.email': '이메일 주소를 입력해주세요',
    'individual.address.line1': '주소를 입력해주세요',
    'individual.address.city': '도시를 입력해주세요',
    'individual.address.postal_code': '우편번호를 입력해주세요',
  };
  return requirementMap[requirement] || requirement;
};

// 계정이 완전히 검증되었는지 확인하는 computed 속성
const isFullyVerified = computed(() => {
  return stripeIsOnboarded.value && 
         stripeChargesEnabled.value && 
         stripePayoutsEnabled.value && 
         !hasRequirements.value;
});

// 디버깅을 위한 watch
watch([stripeIsOnboarded, stripeChargesEnabled, stripePayoutsEnabled, hasRequirements], 
  ([isOnboarded, charges, payouts, requirements]) => {
    console.log('계정 상태:', {
      isOnboarded,
      charges,
      payouts,
      hasRequirements: requirements
    });
  });

// Stripe 대시보드 열기
const openStripeDashboard = async () => {
  try {
    isLoading.value = true;
    const user = auth.currentUser;
    if (!user) throw new Error('로그인이 필요합니다');

    const idToken = await user.getIdToken();
    
    const response = await fetch(`${apiUrl}/login_link`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
        'X-Stripe-Mode': 'test'
      },
      body: JSON.stringify({
        account: stripeAccountId.value,
        userId: user.uid
      })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || '대시보드를 열 수 없습니다');
    }

    const data = await response.json();
    window.open(data.url, '_blank');

  } catch (error) {
    console.error('대시보드 열기 오류:', error);
    errorMessage.value = handleError(error);
  } finally {
    isLoading.value = false;
  }
};
</script>

<style scoped>
.stripe-connect-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  margin-top: 80px;
}

.status-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.status-badge {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background: #fff7ed;
  border: 1px solid #fdba74;
  border-radius: 20px;
  color: #9a3412;
}

.status-badge.status-complete {
  background: #ecfdf5;
  border-color: #6ee7b7;
  color: #065f46;
}

.last-updated {
  font-size: 0.875rem;
  color: #6b7280;
}

.info-card {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.info-card h4 {
  margin: 0 0 1rem 0;
  color: #1f2937;
  font-size: 1.1rem;
  font-weight: 600;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
}

.info-item {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.label {
  color: #6b7280;
  font-size: 0.875rem;
}

.value {
  color: #111827;
  font-weight: 500;
}

.status-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 1rem;
}

.status-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: #f3f4f6;
  border-radius: 8px;
  color: #6b7280;
}

.status-item.active {
  background: #ecfdf5;
  color: #059669;
}

.status-text {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.status-label {
  font-size: 0.875rem;
}

.status-value {
  font-weight: 500;
}

.warning {
  background: #fff7ed;
  border: 1px solid #fdba74;
}

.requirements-list {
  list-style: none;
  padding: 0;
  margin: 0 0 1.5rem 0;
}

.requirements-list li {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem;
  background: rgba(255,255,255,0.5);
  border-radius: 6px;
  margin-bottom: 0.5rem;
  color: #9a3412;
}

.requirements-list li i {
  color: #f97316;
}

.connect-button.update {
  background: #f97316;
  width: 100%;
}

.connect-button.update:hover {
  background: #ea580c;
}

.action-buttons {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.connect-button.dashboard {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #4f46e5;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 500;
  transition: background-color 0.2s;
}

.connect-button.dashboard:hover {
  background: #4338ca;
}

.connect-button.dashboard.secondary {
  background: #ffffff;
  color: #4f46e5;
  border: 1px solid #4f46e5;
}

.connect-button.dashboard.secondary:hover {
  background: #f5f5f5;
}

.connect-button i {
  font-size: 1rem;
}

@media (max-width: 640px) {
  .stripe-connect-container {
    padding: 1rem;
  }
  
  .status-header {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }
  
  .info-grid {
    grid-template-columns: 1fr;
  }

  .action-buttons {
    flex-direction: column;
  }
}
</style> 