<template>
  <div class="create-studio-container">
    <form @submit.prevent="handleSubmit" class="create-form">
      <h2>모집 공고 등록</h2>
      
      <!-- 기본 정보 섹션 -->
      <section class="form-section">
        <div class="form-group">
          <label for="title">제목</label>
          <input 
            id="title"
            v-model="formData.title"
            type="text"
            required
            placeholder="공고 제목"
          >
        </div>

        <div class="form-group">
          <label for="about">스튜디오 소개</label>
          <textarea
            id="about"
            v-model="formData.about"
            rows="4"
            required
            placeholder="스튜디오를 소개해주세요"
          ></textarea>
        </div>
      </section>

      <!-- 이미지 업로드 섹션 -->
      <section class="form-section">
        <h3>스튜디오 사진</h3>
        <div class="image-upload">
          <label for="images" class="upload-label">
            <i class="fas fa-cloud-upload-alt"></i>
            <span>이미지 업로드</span>
            <small>최대 5장까지 업로드 가능</small>
          </label>
          <input
            id="images"
            type="file"
            multiple
            accept="image/*"
            @change="handleImageUpload"
            class="hidden"
          >
        </div>
        <div class="image-preview" v-if="imageUrls.length">
          <div v-for="(url, index) in imageUrls" :key="index" class="preview-item">
            <img :src="url" :alt="`Preview ${index + 1}`">
            <button type="button" @click="removeImage(index)" class="remove-image">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </section>

      <!-- 위치 정보 섹션 -->
      <section class="form-section">
        <h3>위치 정보</h3>
        <div class="form-group">
          <label for="place">주소</label>
          <input
            id="place"
            v-model="formData.place"
            type="text"
            required
            placeholder="스튜디오 주소를 입력하세요"
          >
        </div>
      </section>

      <!-- 연락처 섹션 -->
      <section class="form-section">
        <h3>연락처</h3>
        <div class="form-group">
          <label for="instagram">인스타그램</label>
          <input
            id="instagram"
            v-model="formData.instagram"
            type="text"
            placeholder="인스타그램 아이디를 입력하세요"
          >
        </div>
        <div class="form-group">
          <label for="phone">전화번호</label>
          <input
            id="phone"
            v-model="formData.phone"
            type="tel"
            placeholder="전화번호를 입력하세요"
          >
        </div>
        <div class="form-group">
          <label for="website">웹사이트</label>
          <input
            id="website"
            v-model="formData.website"
            type="url"
            placeholder="웹사이트 주소를 입력하세요"
          >
        </div>
      </section>

      <!-- 편의시설 섹션 -->
      <section class="form-section">
        <h3>편의시설</h3>
        <div class="form-group">
          <div class="service-input-container">
            <input
              type="text"
              v-model="newService"
              @keydown.enter.prevent="addService"
              placeholder="편의시설을 입력하고 엔터를 누르세요"
              class="service-input"
            >
          </div>
          <div class="service-tags">
            <div 
              v-for="(service, index) in formData.service" 
              :key="index"
              class="service-tag"
            >
              {{ service }}
              <button 
                type="button" 
                class="remove-service" 
                @click="removeService(index)"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
      </section>

      <!-- 이용 요금 섹션 -->
      <section class="form-section">
        <h3>이용 요금</h3>
        <div class="form-group fee-group">
          <div class="fee-item">
            <div class="fee-header">
              <label for="feePercent">수수료 (%)</label>
              <div class="toggle-switch" @click="toggleFee('percent')">
                <input type="checkbox" v-model="feeToggles.percent">
                <span class="slider"></span>
              </div>
            </div>
            <input
              id="feePercent"
              v-model.number="formData.feePercent"
              type="number"
              :disabled="!feeToggles.percent"
              min="0"
              max="100"
              placeholder="수수료 입력"
            >
          </div>

          <div class="fee-item">
            <div class="fee-header">
              <label for="feeDeposit">보증금</label>
              <div class="toggle-switch" @click="toggleFee('deposit')">
                <input type="checkbox" v-model="feeToggles.deposit">
                <span class="slider"></span>
              </div>
            </div>
            <input
              id="feeDeposit"
              v-model.number="formData.feeDeposit"
              type="number"
              :disabled="!feeToggles.deposit"
              min="0"
              placeholder="보증금 입력"
            >
          </div>

          <div class="fee-item">
            <div class="fee-header">
              <label for="feeRent">월세</label>
              <div class="toggle-switch" @click="toggleFee('rent')">
                <input type="checkbox" v-model="feeToggles.rent">
                <span class="slider"></span>
              </div>
            </div>
            <input
              id="feeRent"
              v-model.number="formData.feeRent"
              type="number"
              :disabled="!feeToggles.rent"
              min="0"
              placeholder="월세 입력"
            >
          </div>
        </div>
      </section>

      <div class="form-actions">
        <button type="button" class="cancel-button" @click="router.back()">
          취소
        </button>
        <button type="submit" class="submit-button" :disabled="isSubmitting">
          {{ isSubmitting ? '등록 중...' : '등록하기' }}
        </button>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { collection, addDoc, serverTimestamp, doc, getDoc } from 'firebase/firestore'
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage'
import { getAuth } from 'firebase/auth'
import { db } from '@/firebase'

const router = useRouter()
const auth = getAuth()
const storage = getStorage()
const userInfo = ref(null)

const formData = ref({
  title: '',
  about: '',
  place: '',
  instagram: '',
  phone: '',
  website: '',
  service: [],
  feePercent: 0,
  feeDeposit: 0,
  feeRent: 0,
  images: [],
  // 사용자 정보는 제출 시 추가될 것입니다
})

const imageUrls = ref([])
const isSubmitting = ref(false)
const newService = ref('')

const feeToggles = ref({
  percent: false,
  deposit: false,
  rent: false
})

// 사용자 정보 가져오기
const fetchUserInfo = async () => {
  try {
    const user = auth.currentUser
    if (!user) {
      alert('로그인이 필요한 서비스입니다.')
      router.push('/login')
      return
    }

    const userDoc = await getDoc(doc(db, 'users', user.uid))
    if (userDoc.exists()) {
      userInfo.value = userDoc.data()
    } else {
      console.error('User document not found')
      alert('사용자 정보를 찾을 수 없습니다.')
      router.push('/login')
    }
  } catch (error) {
    console.error('Error fetching user info:', error)
    alert('사용자 정보를 불러오는 중 오류가 발생했습니다.')
  }
}

// 컴포넌트 마운트 시 사용자 정보 가져오기
onMounted(() => {
  fetchUserInfo()
})

const uploadImageToStorage = async (blob) => {
  const fileName = `studios/${auth.currentUser.uid}/${Date.now()}.jpg`
  const imageRef = storageRef(storage, fileName)
  
  try {
    const snapshot = await uploadBytes(imageRef, blob)
    const downloadURL = await getDownloadURL(snapshot.ref)
    return downloadURL
  } catch (error) {
    console.error('Error uploading image:', error)
    throw error
  }
}

const compressImage = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      const img = new Image()
      img.onload = () => {
        const canvas = document.createElement('canvas')
        let width = img.width
        let height = img.height

        // 최대 크기 설정 (예: 1200px)
        const MAX_SIZE = 1200
        if (width > height) {
          if (width > MAX_SIZE) {
            height = Math.round((height * MAX_SIZE) / width)
            width = MAX_SIZE
          }
        } else {
          if (height > MAX_SIZE) {
            width = Math.round((width * MAX_SIZE) / height)
            height = MAX_SIZE
          }
        }

        canvas.width = width
        canvas.height = height
        const ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0, width, height)

        // Blob으로 변환
        canvas.toBlob(async (blob) => {
          try {
            const downloadURL = await uploadImageToStorage(blob)
            resolve(downloadURL)
          } catch (error) {
            console.error('Error in image processing:', error)
            resolve(null)
          }
        }, 'image/jpeg', 0.6)
      }
      img.src = e.target.result
    }
    reader.readAsDataURL(file)
  })
}

const handleImageUpload = async (event) => {
  const files = Array.from(event.target.files)
  if (files.length + imageUrls.value.length > 5) {
    alert('최대 5장까지만 업로드 가능합니다.')
    return
  }

  for (const file of files) {
    try {
      const downloadURL = await compressImage(file)
      if (downloadURL) {
        imageUrls.value.push(downloadURL)
      }
    } catch (error) {
      console.error('Error handling image upload:', error)
      alert('이미지 업로드 중 오류가 발생했습니다.')
    }
  }
}

const removeImage = (index) => {
  imageUrls.value.splice(index, 1)
}

const addService = () => {
  const service = newService.value.trim()
  if (service && !formData.value.service.includes(service)) {
    formData.value.service.push(service)
    newService.value = ''
  }
}

const removeService = (index) => {
  formData.value.service.splice(index, 1)
}

const toggleFee = (type) => {
  feeToggles.value[type] = !feeToggles.value[type]
  if (!feeToggles.value[type]) {
    formData.value[`fee${type.charAt(0).toUpperCase() + type.slice(1)}`] = 0
  }
}

const handleSubmit = async () => {
  if (!userInfo.value) {
    alert('사용자 정보를 불러오는 중입니다. 잠시 후 다시 시도해주세요.')
    return
  }

  if (imageUrls.value.length === 0) {
    alert('최소 1장의 이미지를 업로드해주세요.')
    return
  }

  try {
    isSubmitting.value = true
    
    const studioData = {
      ...formData.value,
      images: imageUrls.value,
      userId: auth.currentUser.uid,
      nickname: userInfo.value.nickname || auth.currentUser.nickname,
      profileUrl: userInfo.value.profileImageUrl || auth.currentUser.profileImageUrl,
      createdAt: serverTimestamp()
    }

    await addDoc(collection(db, 'studios'), studioData)
    
    alert('스튜디오가 성공적으로 등록되었습니다.')
    router.push('/studio')
  } catch (error) {
    console.error('Error creating studio:', error)
    alert('스튜디오 등록 중 오류가 발생했습니다.')
  } finally {
    isSubmitting.value = false
  }
}
</script>

<style scoped>
.create-studio-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
}

.create-form {
  background: white;
}

.create-form h2 {
  font-size: 2rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 40px;
  text-align: center;
}

.form-section {
  margin-bottom: 40px;
}

.form-section h3 {
  font-size: 1.3rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.form-group {
  margin-bottom: 12px;
}

.form-group label {
  display: block;
  font-size: 1rem;
  font-weight: 500;
  color: #444;
  margin-bottom: 8px;
}

input[type="text"],
input[type="url"],
input[type="number"],
input[type="tel"],
textarea {
  width: 100%;
  padding: 12px 16px;
  border: 2px solid #e9ecef;
  border-radius: 12px;
  font-size: 1rem;
  transition: all 0.2s ease;
  background: white;
  box-sizing: border-box;
}

input:focus,
textarea:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 4px rgba(0, 123, 255, 0.1);
}

textarea {
  min-height: 120px;
  resize: vertical;
  line-height: 1.6;
}

.image-upload {
  border: 2px dashed #e9ecef;
  border-radius: 16px;
  padding: 40px 20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  background: white;
}

.upload-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  color: #666;
}

.upload-label i {
  font-size: 2.5rem;
  color: #007bff;
}

.upload-label small {
  color: #adb5bd;
  font-size: 0.9rem;
}

.hidden {
  display: none;
}

.image-preview {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
  margin-top: 24px;
}

.preview-item {
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.preview-item img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.remove-image {
  position: absolute;
  top: 8px;
  right: 8px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.service-input-container {
  margin-bottom: 16px;
}

.service-input {
  width: 100%;
  padding: 12px 16px;
  border: 2px solid #e9ecef;
  border-radius: 12px;
  font-size: 1rem;
  transition: all 0.2s ease;
  background: white;
}

.service-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 4px rgba(0, 123, 255, 0.1);
}

.service-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.service-tag {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 25px;
  background: #007bff;
  color: white;
  font-size: 0.95rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.remove-service {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid #e9ecef;
}

.cancel-button,
.submit-button {
  padding: 14px 28px;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cancel-button {
  background: #f8f9fa;
  border: none;
  color: #495057;
}

.submit-button {
  background: #007bff;
  border: none;
  color: white;
}

.submit-button:disabled {
  background: #b3d7ff;
  cursor: not-allowed;
  transform: none;
}

.fee-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.fee-item {
  width: 100%;
}

.fee-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
  cursor: pointer;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e9ecef;
  border-radius: 34px;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  border-radius: 50%;
  transition: .4s;
}

input:checked + .slider {
  background-color: #007bff;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

input[type="number"] {
  width: 100%;
  padding: 12px 16px;
  border: 2px solid #e9ecef;
  border-radius: 12px;
  font-size: 1rem;
  transition: all 0.2s ease;
  background: white;
}

input[type="number"]:disabled {
  background-color: #f8f9fa;
  cursor: not-allowed;
  color: #adb5bd;
}

input[type="number"]:focus:not(:disabled) {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 4px rgba(0, 123, 255, 0.1);
}
</style> 