<template>
  <div class="login-container">
    <h1>기존회원 간편 로그인</h1>

    <div class="login-section">
      <div class="input-group">
        <div class="phone-input-container">
          <select 
            v-model="selectedCountryCode"
            class="country-code-select"
          >
            <option value="+82">+82 (Korea)</option>
          </select>
          <input 
            v-model="phoneNumber" 
            type="tel" 
            placeholder="전화번호 (하이픈 없이)"
            class="phone-input"
          />
        </div>
        
        <button 
          @click="sendOTP"
          :disabled="!phoneNumber || isOTPSent"
          class="primary-button"
        >
          인증번호 전송
        </button>

        <div v-if="isOTPSent" class="otp-section">
          <input 
            v-model="otpCode" 
            type="text" 
            placeholder="인증번호 6자리 입력"
            maxlength="6"
          />
          <div class="timer" v-if="remainingTime > 0">
            {{ formatTime(remainingTime) }}
          </div>
          <button 
            @click="verifyOTP"
            :disabled="!otpCode || otpCode.length !== 6"
            class="primary-button"
          >
            인증하기
          </button>
          <button 
            v-if="remainingTime === 0"
            @click="sendOTP"
            class="secondary-button"
          >
            인증번호 재전송
          </button>
        </div>
      </div>
    </div>

    <div class="signup-section">
      <p>
        다른 방법으로 로그인하기 
        <span class="login-link" @click="goToLogin">이메일/전화번호 로그인</span>
      </p>
    </div>

    <div id="recaptcha-container"></div>
  </div>
</template>

<script>
import { ref, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { auth } from '@/firebase';

export default {
  setup() {
    const router = useRouter();
    const phoneNumber = ref('');
    const selectedCountryCode = ref('+82');
    const otpCode = ref('');
    const isOTPSent = ref(false);
    const remainingTime = ref(0);
    const confirmationResult = ref(null);
    let timerInterval;

    const startTimer = () => {
      remainingTime.value = 180;
      clearInterval(timerInterval);
      timerInterval = setInterval(() => {
        if (remainingTime.value > 0) {
          remainingTime.value--;
        } else {
          clearInterval(timerInterval);
        }
      }, 1000);
    };

    const formatTime = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const setupRecaptcha = () => {
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
          'size': 'normal',
          'callback': () => {
            // reCAPTCHA solved
          }
        });
      }
    };

    const sendOTP = async () => {
      try {
        setupRecaptcha();
        const formattedPhoneNumber = `${selectedCountryCode.value}${phoneNumber.value.replace(/^0/, '')}`;
        
        confirmationResult.value = await signInWithPhoneNumber(
          auth, 
          formattedPhoneNumber, 
          window.recaptchaVerifier
        );
        
        isOTPSent.value = true;
        startTimer();
        alert('인증번호가 전송되었습니다');
      } catch (error) {
        console.error('OTP 전송 에러:', error);
        alert('인증번호 전송에 실패했습니다');
      }
    };

    const verifyOTP = async () => {
      try {
        await confirmationResult.value.confirm(otpCode.value);
        alert('인증이 완료되었습니다');
        router.push('/');
      } catch (error) {
        console.error('인증 에러:', error);
        alert('인증에 실패했습니다');
      }
    };

    const goToLogin = () => {
      router.push('/login');
    };

    onUnmounted(() => {
      clearInterval(timerInterval);
    });

    return {
      phoneNumber,
      selectedCountryCode,
      otpCode,
      isOTPSent,
      remainingTime,
      sendOTP,
      verifyOTP,
      formatTime,
      goToLogin
    };
  }
};
</script>

<style scoped>
.login-container {
  max-width: 400px;
  margin: 40px auto;
  margin-top: 100px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
  font-size: 24px;
}

.login-section {
  margin-bottom: 25px;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 6px;
}

.input-group {
  margin-bottom: 15px;
}

.phone-input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 10px;
}

.country-code-select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

input {
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

input:focus, .country-code-select:focus {
  border-color: #4CAF50;
}

.primary-button {
  width: 100%;
  padding: 12px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s;
  margin-bottom: 10px;
}

.secondary-button {
  width: 100%;
  padding: 12px;
  background-color: #666;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.otp-section {
  margin-top: 20px;
}

.timer {
  text-align: center;
  color: #666;
  margin: 10px 0;
  font-size: 14px;
  font-weight: bold;
}

.signup-section {
  text-align: center;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.signup-section p {
  color: #666;
  font-size: 14px;
}

.login-link {
  color: #4CAF50;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 5px;
}

#recaptcha-container {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}
</style> 